import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { PATH, makePath } from 'config'

const propTypes = {}

const defaultProps = {}

function PrivacyPolicy() {
  return (
    <div className="page-container">
      <h4 className="page-title heavy title-underline">
        ALPHAEON PRIVACY POLICY
      </h4>

      <h4 className="section-title">Last Updated: June 01 2022</h4>

      <p className="paragraph-basic">
        <strong>
          <a
            href={makePath(PATH.LEGAL, PATH.CA_PRIVACY_NOTICE)}
            target="_blank"
            rel="noopener noreferrer"
            className="terms-link no-decor"
          >
            Notice to California Residents{' '}
          </a>
        </strong>
        (Click here to learn more about your California Privacy Policy Rights
        and for our Notice of Collection of Personal Information).
      </p>

      <p className="paragraph-basic">
        <strong>
          <a
            href={makePath(PATH.LEGAL, PATH.CONSUMER_FINANCIAL_PRIVACY_POLICY)}
            target="_blank"
            rel="noopener noreferrer"
            className="terms-link no-decor"
          >
            Consumer Financial Privacy Policy
          </a>
        </strong>
      </p>

      <p className="paragraph-basic">
        Alphaeon Credit, Inc. (“Alphaeon,” “we,” “us,” or “our”) recognizes the
        importance of protecting the privacy of our online visitors. It is our
        intent to balance our legitimate business interests in collecting and
        using information received from and about you with your reasonable
        expectations of privacy. The following privacy policy (“Privacy Policy”)
        describes the way we handle information learned about you from your
        visits to our website,{' '}
        <a
          href="https://goalphaeon.com"
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          https://goalphaeon.com
        </a>
        , and other online services where we have posted this Privacy Policy
        (collectively, the “Site”). For more information about how we handle
        your financial information, please refer to our Financial Privacy
        Notice. For California residents, you may have additional privacy rights
        relating to your financial information in our California Financial
        Privacy Notice.
      </p>

      <p className="paragraph-basic">
        <strong>PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY.</strong> When you
        submit information to or through the Site, you consent to the collection
        and processing of your information as described in this Privacy Policy.
        By using the Site, you accept the terms of this Privacy Policy, which
        has been incorporated by reference into our{' '}
        <a
          href={makePath(PATH.LEGAL, PATH.WEBSITE_TERMS_OF_USE)}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          Terms of Use
        </a>
        , and consent to our collection, use, disclosure, and retention of your
        information as described in this Privacy Policy. If you do not agree to
        this Privacy Policy, you must continue any further use of the Site.
      </p>

      <h4 className="section-title">Personal Information</h4>

      <p className="paragraph-basic">
        Alphaeon may collect personally identifiable information from you when
        you use the Site, which may include name, address, income, mobile
        telephone number, partial Social Security number, and zip code.
      </p>

      <h4 className="section-title">Information Collected Automatically</h4>

      <p className="paragraph-basic">
        Information about your computer hardware and software may automatically
        be collected by Alphaeon when you use the Site. This information may
        include: your IP address, browser type, domain names, access times,
        geographic location, referring website addresses, and other technical
        information such as protocol status and substatus, bytes sent and
        received, and server information. We may also collect information about
        how you interact with the Site.
      </p>

      <h4 className="section-title">
        Cookies and Other Information Collection Tools
      </h4>

      <p className="paragraph-basic">
        <strong>
          <i>What are cookies?</i>
        </strong>{' '}
        A cookie is a small file containing a string of characters that is sent
        to your computer when you visit a website or use an online service. When
        you visit the website or use the service again, the cookie allows that
        website or online service to recognize your browser or device. Cookies
        may store unique identifiers, user preferences, and other information.
      </p>

      <p className="paragraph-basic">
        <strong>
          <i>Duration of cookies.</i>
        </strong>{' '}
        We may use “session cookies” or “persistent cookies.” Session cookies
        are temporary and expire once you close your browser or once your
        session ends. Persistent cookies remain on your device until you or your
        browser erase them. Persistent cookies have varying durations that are
        dependent on their expiration date.
      </p>

      <p className="paragraph-basic">
        <strong>
          <i>What types of cookies do we use?</i>
        </strong>
        Alphaeon uses:
      </p>
      <ul className="paragraph-list terms-list">
        <li>
          <strong>Necessary Cookies.</strong> These cookies are necessary for
          you to browse the Site and use its features, such as accessing secure
          areas of the Site. Without these cookies, certain aspects of the Site
          may not be available to you.
        </li>
        <li>
          <strong>Preferences Cookies.</strong> These cookies collect
          information about how you have used the Site in the past and allow the
          Site to remember the choices you have made. These cookies allow us to
          improve how the Site works for you and tailor the Site to your
          preferences.
        </li>
        <li>
          <strong>Statistics Cookies.</strong> These cookies collect information
          about how you use the Site, such as which pages you most often visit
          on the Site, the time you spend on the Site, which links you click on,
          and any issues encountered. These cookies help us understand how
          visitors interact with the Site.All information collected is
          aggregated and does not identify you.
        </li>
        <li>
          <strong>Marketing Cookies.</strong> These cookies track your online
          activity to help deliver advertisements that are relevant to you and
          your interests as well as measure the effectiveness of our advertising
          campaigns. They also limit how many times you see a certain
          advertisement. We may share the information collected through these
          cookies with others, such as advertisers.
        </li>
      </ul>

      <p className="paragraph-basic">
        <strong>
          <i>What about third-party cookies?</i>
        </strong>{' '}
        We may use third-party service providers to help us provide the Site to
        you, and these third-party service providers may also utilize cookies.
        These third-party service providers may collect personally identifiable
        information about your online activities over time and across different
        websites when you use the Site. For example, we use Google to help
        analyze how our users navigate the Site. If you would like to opt-out
        from the use of your information by Google, please visit
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          {' '}
          Google’s analytics opt-out browser add-on{' '}
        </a>{' '}
        designed for this purpose.
      </p>

      <p className="paragraph-basic">
        <strong>
          <i>How do I control cookies?</i>
        </strong>{' '}
        Many web browsers allow some control of cookies through the browser
        settings. To find out more about cookies, including how to manage and
        delete cookies, visit{' '}
        <a
          href="www.allaboutcookies.org"
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          www.allaboutcookies.org
        </a>
        . Some web browsers provide settings that allow a user to reject cookies
        or to alert a user when a cookie is placed on the user’s computer,
        tablet or mobile device. Most mobile devices also offer settings to
        reject mobile device identifiers. Although users are not required to
        accept cookies or mobile device identifiers, blocking or rejecting them
        may prevent access to some features available through the Site.
      </p>

      <h4 className="section-title">How Information is Used</h4>

      <p className="paragraph-basic">
        We may use the information we collect for any of the following purposes:
      </p>
      <ul className="paragraph-list terms-list">
        <li>to provide the Site to you and to improve the Site;</li>
        <li>to provide you with our products and services;</li>
        <li>
          to provide you with information about products and services that we
          think may be of interest to you;
        </li>
        <li>
          for Site security as well as to detect and prevent fraud or illegal
          activities;
        </li>
        <li>for research and analysis purposes;</li>
        <li>
          customer service, such as to fulfil your requests related to ordering
          and payment;
        </li>
        <li>for our legitimate business purposes;</li>
        <li>to communicate with you; or</li>
        <li>for any other uses for which we have obtained your consent.</li>
      </ul>

      <h4 className="section-title">Sharing of Information</h4>

      <ul className="paragraph-list terms-list">
        <li>
          <strong>
            With Third Party Service Providers Performing Services on Our
            Behalf.
          </strong>{' '}
          We may share your personal information with our service providers to
          perform the functions for which we engage them. For example, we may
          use third parties to host the Site or assist us in providing
          functionality on the Site, provide data analysis and research Site, to
          send out email updates about the Site, or remove repetitive
          information from our user lists.
        </li>
        <li>
          <strong>With Our Business Partners.</strong> We share your information
          with other businesses with whom we have a relationship.
        </li>
        <li>
          <strong>For Legal Purposes.</strong> We also may share information
          that we collect from users as needed to enforce our rights; protect
          our property or protect the rights, property, or safety of others; or
          as needed to support external auditing, compliance, and corporate
          governance functions. We will disclose personal information as we deem
          necessary to respond to a subpoena, regulation, binding order of a
          data protection agency, legal process, governmental request, or other
          legal or regulatory process. We may also share personal information as
          required to pursue available remedies or limit damages we may sustain.
        </li>
        <li>
          <strong>Changes of Control.</strong> We may transfer or assign
          information, including your personal information, in connection with a
          merger, acquisition, reorganization, liquidation, change in control,
          or other sale by or of us or any affiliated entity (in each case
          whether in whole or in part).
        </li>
        <li>
          <strong>Legitimate Business Purposes.</strong> We reserve the right to
          share information as appropriate to perform other legitimate business
          purposes.
        </li>
        <li>
          <strong>With Your Consent.</strong> We may share your information as
          appropriate with third parties subject to your consent.
        </li>
      </ul>

      <h4 className="section-title">Links to External Websites</h4>

      <p className="paragraph-basic">
        Our Site may contain links to third-party websites. Any access to and
        use of such third-party websites is not governed by this Privacy Policy.
        We are not responsible for the information practices of such third-party
        websites.
      </p>

      <h4 className="section-title">Security</h4>

      <p className="paragraph-basic">
        Alphaeon uses reasonable security measures designed to prevent
        unauthorized intrusion to the Site and the alteration, acquisition, or
        misuse of personal information. However, we will not be responsible for
        the loss, corruption, or unauthorized acquisition or misuse of personal
        information that you provide through the Site or that is stored by us.
        We will retain your personal information for as long as necessary to
        fulfill the purpose for which it was collected, or as required by
        applicable laws or regulation.
      </p>

      <p className="paragraph-basic">
        You are responsible for maintaining the security of any account
        credentials that you may establish with our Site. Please contact us
        immediately if you believe that the security of your account or personal
        information may have been compromised.
      </p>

      <h4 className="section-title">Your California Privacy Rights</h4>

      <p className="paragraph-basic">
        Under California Civil Code Section 1798.83, also known as California’s
        “Shine the Light” law, California residents with whom we have an
        established business relationship are entitled to request and receive,
        free of charge, once per calendar year, information about the customer
        information we shared, if any, with other businesses for their own
        direct marketing uses in the previous calendar year. To request a copy,
        please contact us at (800) 754 4013 or privacy@alphaeoncredit.com.
        Please be aware that not all information sharing is covered by the
        “Shine the Light” law requirements and only information on covered
        sharing will be included in our response.
      </p>

      <h4 className="section-title">Do Not Track</h4>

      <p className="paragraph-basic">
        Our Site does not currently respond to Do Not Track signals.
      </p>

      <h4 className="section-title">Children</h4>

      <p className="paragraph-basic">
        We do not knowingly collect personal information from any person under
        the age of thirteen. No parts of our Site are directed to or designed
        for anyone under the age of thirteen. If you believe that we have
        inadvertently collected the personal information from any person under
        the age of thirteen, please contact us.
      </p>

      <h4 className="section-title">Notification of Changes</h4>

      <p className="paragraph-basic">
        Any changes to our Privacy Policy will be posted to this page so users
        are always aware of our current practices regarding the information we
        collect and how we use it. Accordingly, please reference this Privacy
        Policy frequently as it may change over time. Our current Privacy Policy
        will go into effect as soon as it is posted to this page as indicated by
        the “Last Updated” date at the beginning of this Privacy Policy.
      </p>

      <h4 className="section-title">Contact Information</h4>

      <p className="paragraph-basic">
        If you have any questions regarding this Privacy Policy or otherwise
        wish to communicate with us, please contact us at (800) 754-4013 or
        privacy@alphaeoncredit.com.
      </p>
    </div>
  )
}

PrivacyPolicy.propTypes = exact(propTypes)
PrivacyPolicy.defaultProps = defaultProps

export default memo(PrivacyPolicy)
