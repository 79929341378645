import { utcToZonedTime } from 'date-fns-tz'
import { isSameDay, getHours } from 'date-fns'
import { EST_TIMEZONE } from 'config'

export default function checkIfESTTimeisPast10Pm(date) {
  const todaysDate = new Date()
  const currentESTDate = utcToZonedTime(todaysDate, EST_TIMEZONE)

  const estDate = utcToZonedTime(new Date(date), EST_TIMEZONE)
  const sameDay = isSameDay(estDate, currentESTDate)
  const estHours = getHours(estDate)
  return estHours <= 22 && sameDay
}
