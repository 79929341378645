import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { propTypes as formPropTypes } from 'redux-form'
import { SubmitButton } from 'lp-components'
import PropTypes from 'prop-types'

const propTypes = {
  ...formPropTypes,
  cancelVoidSale: PropTypes.func.isRequired,
}

function VoidSaleForm({ handleSubmit, cancelVoidSale, submitting }) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="account-summary summary">
        <div className="summary__actions m-t-50">
          <div className="submit">
            <SubmitButton
              disabled={submitting}
              submitting={submitting}
              className={`button-warn`}
            >
              CONFIRM
            </SubmitButton>
          </div>
          <div className="cancel">
            <div onClick={() => cancelVoidSale()}>CANCEL</div>
          </div>
        </div>
      </div>
    </form>
  )
}

VoidSaleForm.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'voidSaleForm',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(VoidSaleForm)
