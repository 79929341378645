import { createAction } from 'redux-actions'

export const clearMerchantPortal = createAction('CLEAR_MERCHANT_PORTAL')
export const setApplicationInfo = createAction(
  'MERCHANT_APPLICATION/SET_APPLICATION_INFO'
)
export const clearMerchantApplicationEnvironment = createAction(
  'CLEAR_MERCHANT_APPLICATION_ENVIRONMENT'
)
export const clearAllLocations = createAction('CLEAR_ALL_LOCATIONS')
export const setCurrentLocationIndex = createAction(
  'SET_CURRENT_LOCATION_INDEX'
)

export const setLocationNotificationsDismissed = createAction(
  'SET_LOCATION_NOTIFICATIONS_DISMISSED'
)
export const setLenderReferenceId = createAction(
  'MERCHANT_APPLICATION/SET_LENDER_REFERENCE_ID'
)
