import { flatten } from 'lodash'

export default function approveTerms(...args) {
  const allTerms = flatten(args)

  return allTerms.map(({ termContentId }) => ({
    termContentId,
    acceptedOn: new Date().toISOString(),
  }))
}
