import { isNil } from 'lodash'

const formatDateFromISOtoUSdateString = (dateString) => {
  if (isNil(dateString) || dateString === '') {
    return ''
  }

  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  date.setMinutes(date.getMinutes() + offset)
  const convertedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  return convertedDate
}

export default formatDateFromISOtoUSdateString
