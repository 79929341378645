import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'

import { selectorForSlice, unsetState } from 'lp-redux-utils'

const reducerKey = 'user'
const slice = 'root.merchantPortal.user'

const initialState = {}

const reducer = handleActions(
  {
    [actions.clearMangers]: unsetState('managers'),
    [apiActions.getManagers]: setOnSuccess('managers'),
    [apiActions.getManagerById]: setOnSuccess('managerById'),
    [actions.clearMangerById]: unsetState('managerById'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  managers: select('managers'),
  managerById: select('managerById'),
}

export { reducer, selectors, reducerKey }
