import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const defaultProps = {
  className: '',
  styleAsAnchor: false,
}

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  styleAsAnchor: PropTypes.bool,
}
function ExternalLink({ children, className, href, styleAsAnchor, ...rest }) {
  // Ensure that all web addresses begin with a protocol (the expectation). If
  // they don't, assume the current protocol (typically https) by prepending
  // "//" to the URL.
  const normalizedUrl = href.startsWith('http') ? href : `//${href}`

  return (
    <>
      <a
        className={styleAsAnchor ? '' : classnames('external-link', className)}
        target="_blank"
        rel="noopener noreferrer"
        href={normalizedUrl}
        {...rest}
      >
        {children}
      </a>
      <span hidden>Opens in a new window</span>
    </>
  )
}

ExternalLink.propTypes = propTypes
ExternalLink.defaultProps = defaultProps

export default React.memo(ExternalLink)
