import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { PATH } from '../../../config'

const propTypes = {}

const defaultProps = {}

function WebsiteTermsOfUse() {
  return (
    <div className="page-container">
      <h4 className="page-title heavy title-underline">TERMS OF USE</h4>

      <h4 className="section-title">
        <strong>Effective Date:</strong> June 1, 2022
      </h4>

      <p className="paragrahp-basic">
        Alphaeon Credit, Inc. (“Alphaeon”, “our”, “us”, or “we”) grants you
        access to our website located at{' '}
        <a
          href="https://goalphaeon.com/"
          taget="_blank"
          rel="noreferrer"
          className="terms-link"
        >
          https://goalphaeon.com/
        </a>{' '}
        (the “Website”) conditioned on your acceptance of these Terms of Use
        (these “Terms of Use”).
      </p>

      <p className="paragraph-basic">
        PLEASE READ THESE TERMS OF USE CAREFULLY. THESE TERMS OF USE CONSTITUTE
        A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US, AND BY USING THE
        WEBSITE, YOU AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF USE. IF YOU
        DO NOT AGREE TO THESE TERMS OF USE, YOU MUST CEASE USING THE WEBSITE
        IMMEDIATELY.
      </p>

      <h4 className="section-title">
        <strong>Binding Arbitration</strong>
      </h4>
      <p className="paragraph-basic">
        These Terms of Use provide that all disputes between you and us will be
        resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE TO GIVE UP YOUR
        RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING) to assert
        or defend your rights under these Terms of Use (except for matters that
        may be taken to small claims court). Your rights will be determined by a
        NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot be
        brought as a class action. Please review the section of these Terms of
        Use titled “Dispute Resolution; Arbitration Agreement” for the details
        regarding your agreement to arbitrate any disputes with us.
      </p>

      <h4 className="section-title">
        <strong>Accessing the Website</strong>
      </h4>
      <p className="paragraph-basic">
        Subject to these Terms of Use, Alphaeon grants you a limited,
        nonexclusive, nontransferable, non-sublicensable license to access our
        Website for your personal, non-commercial use.
      </p>
      <p className="paragraph-basic">
        You will need a working connection to the Internet via a web browser on
        your computer for which you are responsible for all costs, expenses, and
        fees. From time to time, due to the performance of maintenance,
        malfunctions or failures of software, equipment, or telecommunications
        devices, unusual transaction volume, or similar reasons the Website may
        not be available for your use. We minimize the periods of time during
        which Website is unavailable, but we are not responsible for your
        inability to access the Website caused directly or indirectly, in whole
        or in part, by your computer or mobile device, your inability to
        establish a connection to the Internet, your Internet Service Provider
        (“ISP”), or capacity or other limitations or constraints of the
        Internet.
      </p>

      <h4 className="section-title">
        <strong>Access Restrictions</strong>
      </h4>
      <p className="paragraph-basic">
        The Website is not intended for use by persons in any jurisdiction or
        country where such use would be contrary to applicable laws or
        regulations. We reserve the right to restrict your access to the Website
        during times you are in a country outside of the United States. You are
        responsible for compliance with all local laws.
      </p>
      <p className="paragraph-basic">
        The Website is intended only for persons who have reached the age of
        majority in their state of residence. The Website is not intended to be
        accessed by children.
      </p>

      <h4 className="section-title">
        <strong>Modifications to These Terms of Use and Website</strong>
      </h4>
      <p className="paragraph-basic">
        We reserve the right, in our sole discretion, to modify these Terms of
        Use at any time without notice to you. We will post all modifications to
        these Terms of Use on the Website and they will become effective
        immediately upon being posted to the Website. Your continued use of the
        Website following the posting of such modifications constitutes your
        acceptance of them. Therefore, you should check the Effective Date of
        these Terms of Use each time that you visit the Website and review any
        modifications made since the last time that you visited it.
      </p>
      <p className="paragraph-basic">
        We also reserve the right to terminate, change, suspend or discontinue
        any aspect of the Website at any time without notice to you.
      </p>

      <h4 className="section-title">
        <strong>Privacy Policy</strong>
      </h4>
      <p className="paragraph-basic">
        We will treat all personal information that you choose to provide to us
        through the Website in accordance with our privacy policy located at
        <a href={PATH.PRIVACY_POLICY}>(the “Privacy Policy”)</a>, which is
        incorporated by reference into these Terms of Use. By using the Website,
        you consent to the privacy practices set forth in the Privacy Policy.
      </p>

      <h4 className="section-title">
        <strong>Proprietary Materials</strong>
      </h4>
      <p className="paragraph-basic">
        The Website contains forms, content, information, trademarks and other
        proprietary materials that belong to us and our licensors and that are
        protected by copyright, trademark, and other intellectual property laws
        (collectively, “Proprietary Materials”).
      </p>
      <p className="paragraph-basic">
        You should assume that everything on the Website is Proprietary
        Materials, and, except as otherwise explicitly provided on certain
        Proprietary Materials that you may access through the Website, you shall
        not reproduce, distribute, publicly display, publicly perform, prepare
        derivative works of, or otherwise use or exploit Proprietary Materials
        in any way without our prior written consent. Commercial use of
        Proprietary Materials is strictly prohibited. All rights not expressly
        granted herein are reserved to us and our licensors.
      </p>

      <h4 className="section-title">
        <strong>Restrictions</strong>
      </h4>
      <p className="paragraph-basic">
        You agree that you will not yourself or through any third party: (i) use
        the Website in a manner that violates applicable laws or the rights of
        third parties, including intellectual property laws and third-party
        rights with respect thereto; (ii) engage in any conduct that restricts
        or inhibits anyone’s use or enjoyment of the Website or which may harm
        us or other users of the Website; (iii) violate the security of the
        Website, including by using any device, software, or routine that
        interferes with the proper functioning of the Website, accessing or
        attempting to access any systems or servers on which the Website is
        hosted, modifying or altering the Website in any way or forging headers,
        misrepresenting your identity, or otherwise manipulating identifiers to
        deceive others; (iv) use automated data extraction tools or manual
        processes to extract Proprietary Materials from the Website; (v) except
        as otherwise explicitly provided on certain Proprietary Materials that
        you may access through the Website, remove, conceal, or alter any
        identification, copyright, or other proprietary rights notices or labels
        on the Website or Proprietary Materials; or (vi) otherwise engage in any
        objectionable conduct, which shall be determined in our sole discretion.
      </p>

      <h4 className="section-title">
        <strong>Security</strong>
      </h4>
      <p className="paragraph-basic">
        We take commercially reasonable measure to protect the Website, but not
        online services can guarantee 100% security. If you submit any personal
        information to us through this Website, you understand that there are
        risks associated with communicating online, including the possibility of
        unauthorized access, such as when electronic communications being
        misdirected or intercepted. For security reasons, we may require you to
        authenticate your identity (i.e., prove that you are who you say you
        are) to conduct certain transactions on our Website. If you believe that
        information that you have submitted through the Website has been used
        without your permission, you must notify us immediately.
      </p>

      <h4 className="section-title">
        <strong>Third-Party Websites</strong>
      </h4>
      <p className="paragraph-basic">
        We may include links on the Website to third-party websites that we do
        not control or operate (each, a “Third-Party Website”). We are not
        responsible for any information, content, advertising, products,
        services, or other materials on any Third-Party Website, and the
        presence of such links does not constitute our endorsement, approval, or
        sponsorship of any Third-Party Website. If you choose to visit any
        Third-Party Website, you are doing so at your own risk, and you will be
        subject to the Terms of Use of that website. Therefore, before
        interacting with any Third-Party Website, you should consult the legal
        terms governing the use of such Third-Party Website. We expressly
        disclaim any and all liability resulting from your use of any
        Third-Party Website.
      </p>

      <h4 className="section-title">
        <strong>Disclaimer</strong>
      </h4>
      <p className="paragraph-basic">
        THE WEBSITE AND PROPRIETARY MATERIALS ARE PROVIDED “AS-IS”, “WHERE-IS”
        AND “AS-AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTIES OF ANY KIND.
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALPHAEON HEREBY
        EXPRESSLY DISCLAIMS ON BEHALF OF OURSELVES AND OUR OFFICERS, DIRECTORS,
        EMPLOYEES, AND AGENTS (COLLECTIVELY, THE “ALPHAEON PARTIES”) ANY AND ALL
        WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        AND NON-INFRINGEMENT. NEITHER ALPHAEON NOR ANY OF THE ALPHAEON PARTIES
        REPRESENTS OR WARRANTS THAT THE WEBSITE WILL BE ERROR-FREE, THAT
        DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THE WEBSITE IS FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS; NOR DOES ALPHAEON OR ANY OF THE
        ALPHAEONPARTIES MAKE ANY REPRESENTATIONS OR WARRANTIES ABOUT THE
        ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, QUALITY, PERFORMANCE,
        OR SUITABILITY OF THE WEBSITE OR PROPRIETARY MATERIALS.
      </p>

      <h4 className="section-title">
        <strong>Limitation of Liability</strong>
      </h4>
      <p className="paragraph-basic">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        ALPHAEON OR ANY OF THE ALPHAEON PARTIES BE LIABLE TO YOU OR ANY THIRD
        PARTY FOR ANY (I) CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE,
        INDIRECT, OR SPECIAL DAMAGES OF ANY NATURE ARISING FROM BREACH OF
        WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, OR ANY OTHER LEGAL THEORY,
        WHETHER IN TORT OR CONTRACT, EVEN IF SUCH PARTY HAS BEEN APPRISED OF THE
        LIKELIHOOD OF SUCH DAMAGES OCCURRING OR (II) DIRECT DAMAGES IN EXCESS OF
        ONE ($1) U.S. DOLLAR.
      </p>

      <h4 className="section-title">
        <strong>Indemnification</strong>
      </h4>
      <p className="paragraph-basic">
        You shall indemnify, defend, and hold harmless Alphaeon and the Alphaeon
        Parties from and against any and all claims, actions, damages,
        obligations, losses, liabilities, costs, and expenses (including
        reasonable attorneys’ fees, costs of collection, and other costs of
        defense) arising out of or relating to your use of the Website or
        Proprietary Materials or your violation of these Terms of Use.
      </p>

      <h4 className="section-title">
        <strong>Termination / Suspension</strong>
      </h4>
      <p className="paragraph-basic">
        We may, without notice to you, immediately terminate or suspend your
        license to access the Website, including in the event that you breach
        these Terms of Use or engage in conduct that we, in our sole discretion,
        believe is in violation of applicable law or our rights or the rights of
        other users of the Website. Upon termination of your license, your right
        to use the Website and Proprietary Materials will immediately terminate.
      </p>

      <h4 className="section-title">
        <strong>Complete Agreement</strong>
      </h4>
      <p className="paragraph-basic">
        These Terms of Use and the Privacy Policy constitute the entire
        agreement between you and us with respect to the subject matter hereof
        and thereof and supersede all prior and contemporaneous agreements,
        whether oral or written, between us and you with respect to the same.
      </p>
      <p className="paragraph-basic">
        No failure, omission, or delay on our part to exercise any right under
        these Terms of Use will preclude any other further exercise of that
        right or other right under these Terms of Use.
      </p>

      <h4 className="section-title">
        <strong>Dispute Resolution; Arbitration Agreement </strong>
      </h4>
      <p className="paragraph-basic">
        We and you agree that all disputes arising from or relating to the
        Website or any relationship or dispute between you and us or you and any
        company or person employed by us, these Terms of Use, and any policies
        or practices of any of the above-mentioned companies or persons (a
        “Dispute”) will only be resolved subject to FINAL AND BINDING
        ARBITRATION as set forth in this section, and may only be resolved
        through an individual arbitration governed by the Federal Arbitration
        Act to the maximum extent permitted by applicable law. You further agree
        that we shall not have a legal obligation to mitigate any of your
        potential or actual losses sustained hereunder.
      </p>
      <p className="paragraph-basic">
        WE AND YOU GIVE UP OUR RESPECTIVE RIGHTS TO GO TO COURT in connection
        with any Dispute and such rights will be determined by a NEUTRAL
        ARBITRATOR, NOT A JUDGE OR JURY. NEITHER WE NOR YOU SHALL BRING OR
        PARTICIPATE IN ANY CLASS ACTION OR OTHER CLASS PROCEEDING IN CONNECTION
        WITH ANY DISPUTE. Neither we nor you agree to class arbitration or any
        other arbitration proceedings where a person brings a dispute as a
        representative of other persons.
      </p>
      <p className="paragraph-basic">
        If we and you are unable to resolve a Dispute by informal means, the
        arbitration of that Dispute will be administered by the American
        Arbitration Association (AAA) in California in accordance with the
        Commercial Arbitration Rules. Any proceeding to enforce this arbitration
        agreement must be brought in the U.S. District Court, Central District
        of California or in any California state court of competent jurisdiction
        with venue lying in Orange County to the exclusion of all other forums.
      </p>

      <h4 className="section-title">
        <strong>Governing Law</strong>
      </h4>
      <p className="paragraph-basic">
        All Disputes will be governed by and construed in accordance with the
        laws of California without reference to the choice of law provisions of
        any jurisdiction.
      </p>

      <h4 className="section-title">
        <strong>Severability</strong>
      </h4>
      <p className="paragraph-basic">
        If any provision of these Terms of Use is found to be invalid or
        unenforceable by a court of competent jurisdiction, the invalid or
        unenforceable provision will be deemed superseded by a valid and
        enforceable provision that most closely matches the intent of the
        original provision, and the remainder of these Terms of Use will remain
        in full force and effect.
      </p>

      <h4 className="section-title">
        <strong>Contact Us</strong>
      </h4>
      <p className="paragraph-basic">
        If you have any questions about these Terms of Use, please contact us at
        privacy@alphaeoncredit.com.
      </p>
    </div>
  )
}

WebsiteTermsOfUse.propTypes = exact(propTypes)
WebsiteTermsOfUse.defaultProps = defaultProps

export default memo(WebsiteTermsOfUse)
