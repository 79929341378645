import React, { useState } from 'react'
import { Input } from 'lp-components'
import { FormSpy } from 'components'

function InputWithToolTip({ input, tooltips: ToolTips, ...props }) {
  const [focus, setFocus] = useState(false)

  return (
    <div>
      <Input
        onFocus={() => {
          setFocus(true)
        }}
        {...props}
      />
      <FormSpy name={input.name} focused={focus}>
        {(val) => {
          if (val || focus) {
            return <ToolTips />
          }
          return null
        }}
      </FormSpy>
    </div>
  )
}

export default InputWithToolTip
