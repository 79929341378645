// eslint-disable-next-line no-unused-vars
export default function contactFormInitialValues({ email, sms = undefined }) {
  // Favor SMS over email for the initial value
  // For now, disable considering SMS.
  // const patientContactOptions = sms ?? email
  const patientContactOptions = email

  return {
    patientContactOptions,
  }
}
