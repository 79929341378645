import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useReactToPrint } from 'react-to-print'

import { SectionSeparator } from 'components'
import { ExportFiles } from 'merchant-portal-components'

const propTypes = {
  children: PropTypes.node.isRequired,
  csvData: PropTypes.array.isRequired,
  csvFilename: PropTypes.string.isRequired,
  csvHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportRef: PropTypes.object.isRequired,
}
const defaultProps = {}

function ReportDetailsContainer({
  children,
  csvData,
  csvFilename,
  csvHeaders,
  reportRef,
}) {
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  })

  return (
    <div className="report-detail">
      <SectionSeparator />
      <div className="report-detail__container m-t-30">
        <ExportFiles
          csvHeaders={csvHeaders}
          csvData={csvData}
          handlePrint={handlePrint}
          fileName={csvFilename}
        />
        {children}
      </div>
    </div>
  )
}

ReportDetailsContainer.propTypes = exact(propTypes)
ReportDetailsContainer.defaultProps = defaultProps

export default React.memo(ReportDetailsContainer)
