import { handleActions } from 'redux-actions'
import { compose } from 'redux'
import { set } from 'lodash/fp'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import { handleSuccess, setOnSuccess, setOnResponse } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'creditApplication'
const slice = 'root.creditPortal.creditApplication'

const initialState = {}
const reducer = handleActions(
  {
    [actions.clearApplicationEnvironment]: unsetState('applicationEnvironment'),
    [actions.clearPractice]: unsetState('practice'),
    [actions.clearPracticeLocations]: unsetState('practiceLocations'),
    [actions.setPractice]: setState('practice'),
    [actions.setPersonalInfo]: setState('personalInfo'),
    [actions.setPracticesList]: setState('practicesList'),
    [actions.setPracticeLocations]: setState('practiceLocations'),
    [actions.setPrequalification]: setState('prequalification'),
    [actions.setLenderReferenceId]: setState('lenderReferenceId'),
    [actions.setCreditApplication]: setState('creditApplication'),

    [apiActions.applyAfterPrequalification]: setOnSuccess(
      'approvedApplication'
    ),

    [apiActions.getPracticeLocations]: setOnSuccess('practiceLocations'),
    [apiActions.getCreditApplicationsByStatus]: handleSuccess(
      (state, { payload: { data } }) => {
        return compose(
          set('prequalifications', data.prequalifications),
          set('personalInfo', data.applicant)
        )(state)
      }
    ),
    [apiActions.newCreditApplication]: setOnSuccess('applicationEnvironment'),
    [apiActions.prequalifyWithNextLender]: handleSuccess(
      (state, { payload: { data } }) => {
        return compose(set('lenderReferenceId', data.lenderRequestReferenceId))(
          state
        )
      }
    ),
    [apiActions.requestPrequalification]: setOnSuccess('prequalification'),
    [apiActions.searchForPractices]: setOnResponse(
      'practicesList',
      'practiceSearchError'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  // creditPortalCreditApplication: select('creditPortalCreditApplication'),
  practice: select('practice'),
  preQualificationData: select('preQualificationData'),
  approvedApplication: select('approvedApplication'),
  applicationEnvironment: select('applicationEnvironment'),
  creditApplication: select('creditApplication'),
  personalInfo: select('personalInfo'),
  practicesList: select('practicesList'),
  practiceSearchError: select('practiceSearchError'),
  prequalification: select('prequalification'),
  prequalifications: select('prequalifications'),
  practiceLocations: select('practiceLocations'),
  lenderReferenceId: select('lenderReferenceId'),
}

export { reducer, selectors, reducerKey }
