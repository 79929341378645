import { createAction } from 'redux-actions'

export const clearApplicant = createAction('CLEAR_APPLICANT')
export const clearApplicationEnvironment = createAction(
  'CLEAR_APPLICATION_ENVIRONMENT'
)
export const setPersonalInfo = createAction(
  'CREDIT_APPLICATION/SET_PERSONAL_INFO'
)

export const setPractice = createAction('CREDIT_APPLICATION/SET_PRACTICE')
