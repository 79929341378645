import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ExternalLink } from 'components'
import { FullApplicationForm } from '../forms/'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNT_CREATED_URL,
  MERCHANT_ACCOUNT_EXISTS_URL,
  MERCHANT_DECLINED_URL,
  MERCHANT_PENDING_URL,
  PRODUCT_TYPE,
  MERCHANT_DECLINED_ACCESS_LOAN_AVAILABLE,
  //INSTALLMENT,
} from 'config'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import { approveTerms, flashSubmitFailure, withApiAuth } from 'utils'
import { ApplicationEnvironmentType, LocationType } from 'types'
import { selectors } from '../reducer'

const propTypes = {
  applyForCredit: PropTypes.func.isRequired,
  clearMerchantApplicationEnvironment: PropTypes.func.isRequired,
  currentLocation: LocationType.isRequired,
  merchantApplicationEnvironment: ApplicationEnvironmentType,
  newMerchantCreditApplication: PropTypes.func.isRequired,
  setApplicationInfo: PropTypes.func.isRequired,
  setLenderReferenceId: PropTypes.func.isRequired,
}
const defaultProps = {}

function Application({
  applyForCredit,
  clearMerchantApplicationEnvironment,
  currentLocation,
  merchantApplicationEnvironment,
  newMerchantCreditApplication,
  setApplicationInfo,
  setLenderReferenceId,
}) {
  const history = useHistory()

  useEffect(() => {
    newMerchantCreditApplication(currentLocation.locationId, 'en')
    return () => clearMerchantApplicationEnvironment()
  }, [])

  const handleSubmit = (applicationValues) => {
    const { alphaeonTerms, lenderTerms } = merchantApplicationEnvironment
    const approvedTerms = approveTerms(alphaeonTerms, lenderTerms)

    const applicationInfo = {
      locationId: currentLocation.locationId,
      ...applicationValues,
      prerequisiteTermsAccepted: approvedTerms,
    }

    setApplicationInfo(applicationInfo)
    return applyForCredit(applicationInfo)
  }

  const handleSubmitSuccess = ({
    applicationStatus,
    //  applicationServiceProviderPriority,
    locationServiceProviderPriorities,
    lenderRequestReferenceId,
  }) => {
    setLenderReferenceId(lenderRequestReferenceId)
    if (applicationStatus === 'approved') {
      history.push(ACCOUNT_CREATED_URL)
      return
    } else if (applicationStatus === 'account_exists') {
      history.push(MERCHANT_ACCOUNT_EXISTS_URL)
      return
    } else if (applicationStatus === 'pending') {
      history.push(MERCHANT_PENDING_URL)
      return
    } else {
      //const locationProvideAccessLoans = applicationServiceProviderPriority.productType === INSTALLMENT
      const locationOffersAccessLoans = Boolean(
        locationServiceProviderPriorities?.consumer?.some(
          ({ productType }) => productType === PRODUCT_TYPE.ACCESS_LOAN
        )
      )
      const next_path = locationOffersAccessLoans
        ? MERCHANT_DECLINED_ACCESS_LOAN_AVAILABLE
        : MERCHANT_DECLINED_URL

      history.push({
        pathname: next_path,
        state: {
          showConsumerPortalLink: locationOffersAccessLoans,
        },
      })
      return
    }
  }

  return (
    <div className="account-lookup full-application m-t-50">
      <h1>Full Application</h1>
      <div className="full-application__content m-t-20">
        This application will result in a hard credit check. Patients who would
        like to pre-qualify without impacting their credit should visit{' '}
        <ExternalLink href="https://goalphaeon.com/">
          goalphaeon.com
        </ExternalLink>
      </div>
      <FullApplicationForm
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={flashSubmitFailure}
        applicationEnvironment={merchantApplicationEnvironment}
      />
    </div>
  )
}

Application.propTypes = propTypes

Application.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentLocation: selectors.currentLocation(state),
    merchantApplicationEnvironment:
      selectors.merchantApplicationEnvironment(state),
  }
}

const mapDispatchToProps = {
  setApplicationInfo: actions.setApplicationInfo,
  setLenderReferenceId: actions.setLenderReferenceId,
  clearMerchantApplicationEnvironment:
    actions.clearMerchantApplicationEnvironment,
}

const mapApiAuthToProps = {
  applyForCredit: apiActions.applyForCredit,
  newMerchantCreditApplication: apiActions.newMerchantCreditApplication,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Application)
