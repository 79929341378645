import React from 'react'
import { Modal } from 'lp-components'
import PropTypes from 'prop-types'
import ConfirmationForm from '../forms/ConfirmationForm'

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleSubmitSuccess: PropTypes.func.isRequired,
  handleSubmitFailure: PropTypes.func.isRequired,
}

function LoanCancelConfirmationModal({
  handleClose,
  handleConfirm,
  handleSubmitSuccess,
  handleSubmitFailure,
}) {
  return (
    <Modal
      onClose={() => {
        handleClose()
      }}
      hideCloseButton
      className="modal-inner prequal-decline-modal modal"
    >
      <div className="sales-confirmation">
        <div className="sales-confirmation__container">
          <div className="title">Cancel Loan</div>
          <table className="details m-t-20">
            <tr>
              <td>
                Please confirm the cancelation request. Once submitted, the
                patient must reapply order to pay for their service.
              </td>
            </tr>
          </table>
        </div>
        <ConfirmationForm
          onSubmit={handleConfirm}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFailure}
          handleClose={handleClose}
        />
      </div>
    </Modal>
  )
}

LoanCancelConfirmationModal.propTypes = propTypes

export default LoanCancelConfirmationModal
