import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ErrorIcon from '@material-ui/icons/Error'
import { Button } from 'lp-components'
import { ERROR_STATUS_NAME, QUERY_KEY } from 'config'
import { forceRedirect, parseQuery } from 'utils'

const propTypes = {}
const defaultProps = {}

function AuthorizationError() {
  const location = useLocation()
  const { search } = location
  const query = parseQuery(search)
  const applicationPath = query[QUERY_KEY.APPLICATION_PATH]
  const errorDescription = query[QUERY_KEY.ERROR_DESCRIPTION]
  let AuthorizationErrorComponent

  switch (query[QUERY_KEY.ERROR]) {
    case ERROR_STATUS_NAME.UNAUTHORIZED:
      AuthorizationErrorComponent = UserAuthorizationError
      break

    default:
      AuthorizationErrorComponent = AuthorizationServiceError
  }

  return (
    <div className="page-container centered-container">
      <div className="error-page-container">
        <ErrorIcon style={{ fontSize: 100 }} color="disabled" />
        <AuthorizationErrorComponent
          {...{ applicationPath, errorDescription }}
        />
      </div>
    </div>
  )
}

AuthorizationError.propTypes = propTypes
AuthorizationError.defaultProps = defaultProps

export default React.memo(AuthorizationError)

function UserAuthorizationError({ applicationPath, errorDescription }) {
  const errorMessage = errorDescription?.includes('blocked')
    ? 'Your account has been disabled. Please contact your administrator if you believe your account should be active.'
    : 'You are unauthorized. Please contact your administration if you believe this is an error.'

  return (
    <>
      <h4 className="error-page-title">Unauthorized Request</h4>
      <p className="error-page-paragraph">{errorMessage}</p>
      <Button
        className="button-warn-outline"
        onClick={() => forceRedirect({ to: applicationPath })}
      >
        Retry Login
      </Button>
    </>
  )
}

function AuthorizationServiceError({ errorDescription }) {
  const history = useHistory()

  return (
    <>
      <h4 className="error-page-title">Authorization Service Error</h4>
      <p className="error-page-paragraph">
        Oops. It appears that the authorization service returned an error: [
        {errorDescription}] If you believe this is an error please contact us.
      </p>
      <Button className="button-warn-outline" onClick={() => history.goBack()}>
        Go Back
      </Button>
    </>
  )
}
