import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function ConsentAutodialed() {
  return (
    <div className="page-container">
      <h4 className="page-title">Consent to Autodialed Calls/Texts</h4>
      <p className="paragraph-basic">
        If you listed a cell phone number in your application or provide an
        updated cell phone number, then you authorize Alphaeon and its
        affiliates, agents, assigns and service providers to call (including
        sending SMS text messages) using automatic telephone dialing systems,
        automatic texting systems and artificial or prerecorded messages to your
        cell phone number to provide information related to, or about, your
        application or any relationships or transactions you have with us. You
        agree to notify Alphaeon immediately if you change your number or
        transfer your phone to another person. You understand text messages may
        be seen by anyone with access to your phone. Accordingly, you should
        take steps to safeguard your phone and your text messages if you want
        them to remain private. If you do not want to receive calls and/or SMS
        text messages from Alphaeon, you can unsubscribe by sending an email to{' '}
        <span className="heavy">unsubscribe@alphaeoncredit.com</span> or by
        calling a customer service representative at{' '}
        <span className="heavy">1 800 754 4013</span> specifying your
        preference. To stop text messages, you can also simply reply “STOP” to
        any text message we may send you.
      </p>
    </div>
  )
}

ConsentAutodialed.propTypes = exact(propTypes)
ConsentAutodialed.defaultProps = defaultProps

export default memo(ConsentAutodialed)
