/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Spinner } from 'components'

const propTypes = {
  children: PropTypes.node.isRequired,
}
const defaultProps = {}

function AuthenticatedRoutes({ children }) {
  return children
}

AuthenticatedRoutes.propTypes = propTypes
AuthenticatedRoutes.defaultProps = defaultProps

export default withAuthenticationRequired(AuthenticatedRoutes, {
  onRedirecting: () => <Spinner />,
})
