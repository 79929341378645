import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { SubmitButton, Button } from 'lp-components'
import { propTypes as formPropTypes } from 'redux-form'
import PropTypes from 'prop-types'

const propTypes = {
  ...formPropTypes,
  handleClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function LoanAdjustConfirmationForm({ handleSubmit, handleClose }) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="loan-adjustment-actions">
        <SubmitButton className="button-warn m-t-30">Continue</SubmitButton>
        <Button
          className="button-warn-outline m-t-30"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

LoanAdjustConfirmationForm.propTypes = propTypes

LoanAdjustConfirmationForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'loanAdjustmentConfirmationForm',
    constraints: {},
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(LoanAdjustConfirmationForm)
