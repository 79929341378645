/* eslint-disable import/namespace */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { createStubRequest } from 'lp-redux-api'
import * as Fixtures from 'fixtures'

export const applyAfterPrequalification = createStubRequest(
  'APPLY_AFTER_PREQUALIFICATION',
  (application) => {
    console.log(
      '[NOTE]: accepting pre-qualification and applyung for credit with data: ',
      JSON.stringify(application, null, 2)
    )

    return Fixtures.applicationApproval
  },
  { delay: 1000 }
)

export const newCreditApplication = createStubRequest(
  'NEW_CREDIT_APPLICATION',
  (locationId, language = 'en') => {
    console.log(
      `[NOTE]: requesting new credit application for location ID: ${locationId} and language: ${language}`
    )
    return Fixtures.newCreditApplication
  },
  { delay: 1000 }
)

export const getCreditApplicationsByStatus = createStubRequest(
  'GET_CREDIT_APPLICATIONS_BY_STATUS',
  (applicationSearchCriteria, status) => {
    console.log(
      `[NOTE]: finding existing application with criteria: ${applicationSearchCriteria} for status ${status}`
    )
    return Fixtures.prequalifiedCreditApplications
  }
)

export const getCreditApplicationsByToken = createStubRequest(
  'GET_CREDIT_APPLICATION_BY_TOKEN',
  (applicationSearchCriteria, token) => {
    console.log(
      `[NOTE]: finding existing application with criteria: ${applicationSearchCriteria} for token ${token}`
    )
    return Fixtures.existingCreditApplication
  }
)

export const patchApplicant = createStubRequest(
  'PATCH_APPLICANT',
  (applicationId, updatedApplicant) => {
    console.log(
      '[NOTE]: patching existing applicant with data: ',
      updatedApplicant,
      ', for application: ',
      applicationId
    )
    return Fixtures.existingCreditApplication
  }
)

export const requestPrequalification = createStubRequest(
  'REQUEST_PREQUALIFICATION',
  (prequalificationData) => {
    console.log(
      '[NOTE]: requesting pre-qualification with data: ',
      JSON.stringify(prequalificationData, null, 2)
    )

    return Fixtures.prequalificationSuccess
  },
  { delay: 1000 }
)

export const searchForPractices = createStubRequest(
  'PRACTICE_DATA',
  () => {
    console.log(`[NOTE]: requesting practice Data`)
    return Fixtures.practiceData
  },
  { delay: 1000 }
)

export const prequalifyWithNextLender = createStubRequest(
  'PREQUALIFY_WITH_NEXT_LENDER',
  (applicationId, prequalificationData) => {
    console.log(
      '[NOTE]: requesting next prequalification with application id: ',
      applicationId,
      ', with data: ',
      JSON.stringify(prequalificationData, null, 2)
    )

    return Fixtures.prequalificationSuccess
  },
  { delay: 1000 }
)

export const searchForAccounts = createStubRequest(
  'ACCOUNT_DATA',
  () => {
    console.log(`[NOTE]: requesting account Data`)
    return Fixtures.accountData
  },
  { delay: 1000 }
)

export const fetchAllLocationsForManager = createStubRequest(
  'FETCH_ALL_LOCATIONS_FOR_MANAGER',
  () => {
    console.log('[NOTE]: requesting all location data for manager')
    return Fixtures.allLocations
  },
  { delay: 1000 }
)

export const fetchTransactions = createStubRequest(
  'FETCH_ALL_TRANSACTIONS',
  () => {
    console.log('[NOTE]: requesting all transactions')
    const transactionData = Array(10).fill(Fixtures.transaction)
    return transactionData
  },
  { delay: 1000 }
)

export const fetchApplicationActivity = createStubRequest(
  'FETCH_ALL_APPLICATION_ACTIVITY',
  () => {
    console.log('[NOTE]: requesting all Application Activity')
    const transactionData = Array(10).fill(Fixtures.activity)
    return transactionData
  },
  { delay: 1000 }
)

export const getReconciliationReport = createStubRequest(
  'GET_RECONCILIATION_REPORT',
  (reconciliationReportRequest) => {
    console.log(
      '[NOTE]: requesting reconciliation report with: ',
      JSON.stringify(reconciliationReportRequest, null, 2)
    )

    return Fixtures.reconciliationReport
  },
  { delay: 1000 }
)
