import React from 'react'
import { Field } from 'redux-form'

function ComponentWithValue({ input, comps }) {
  return comps(input.value)
}

function FormSpy({ children, ...props }) {
  return <Field component={ComponentWithValue} comps={children} {...props} />
}

export default FormSpy
