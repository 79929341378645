import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { matchingDetailCriteria } from 'utils'
import { ButtonLink } from 'components'

const propTypes = {
  data: PropTypes.object.isRequired,
  detailedLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleLocation: PropTypes.func.isRequired,
  selectAllLocation: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
}
const defaultProps = {}

const ReconciliationReportActionCell = ({
  data,
  detailedLocations,
  toggleLocation,
  selectAllLocation,
  allSelected,
}) => {
  const { achEffectiveDate, locationNumber, productType, postDate } = data

  const [checked, setChecked] = useState(
    detailedLocations.some(
      matchingDetailCriteria(
        achEffectiveDate,
        locationNumber,
        productType,
        postDate
      )
    )
  )

  const handleChange = () => {
    if (isNil(locationNumber)) {
      selectAllLocation(!allSelected)
      return null
    }
    setChecked(!checked)
    toggleLocation(achEffectiveDate, locationNumber, productType, postDate)
  }

  if (isNil(locationNumber)) {
    return (
      <td className="actions select-all-td">
        <ButtonLink onClick={handleChange} className="select-all button-link">
          {allSelected ? 'Deselect All' : 'Select All'}
        </ButtonLink>
      </td>
    )
  }

  return (
    <td className="actions">
      <input type="checkbox" checked={checked} onChange={handleChange} />
    </td>
  )
}

ReconciliationReportActionCell.propTypes = propTypes
ReconciliationReportActionCell.defaultProps = defaultProps

export default memo(ReconciliationReportActionCell)
