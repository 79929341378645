import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { PROGRAM_MANAGEMENT_URL } from 'config'

const propTypes = {}

const defaultProps = {}

function Routes() {
  return (
    <Layout>
      <Switch>
        <Route exact path={PROGRAM_MANAGEMENT_URL}>
          <Views.ProgramManagement />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
