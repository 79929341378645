import React, { useState, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import PropTypes from 'prop-types'
import { PlansType } from 'types'
import { PlanCard } from 'merchant-portal-components'
import { Button } from 'lp-components'
import logo from 'images/Alphaeon_Credit_Logo.png'
import { PRODUCT_TYPE } from 'config'

const propTypes = {
  sortedPlans: PropTypes.arrayOf(PlansType),
  handlePlanSelection: PropTypes.func,
  isSaleTransaction: PropTypes.bool.isRequired,
}

const defaultProps = {}
function PromotionalPlanList({
  sortedPlans,
  handlePlanSelection,
  isSaleTransaction,
}) {
  const plansPrintRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => plansPrintRef.current,
  })

  sortedPlans = sortedPlans.filter(
    (plan) =>
      plan.productType === PRODUCT_TYPE.AC_PRIME &&
      plan?.locationPlanSettings?.dashboardDisplayEnabled !== false
  )

  const [plansForPrinting, updatePlansForPrinting] = useState([])

  const handleSelectForPrint = (e) => {
    var selectedPlan = sortedPlans.find((plan) => plan.id == e.target.value)
    if (plansForPrinting.find((addedPlan) => addedPlan.id == selectedPlan.id)) {
      updatePlansForPrinting(
        plansForPrinting.filter((addedPlan) => addedPlan.id !== selectedPlan.id)
      )
    } else {
      updatePlansForPrinting((plansForPrinting) => [
        ...plansForPrinting,
        selectedPlan,
      ])
    }
  }

  return (
    <div className="promotional-plans-container">
      <div className="m-t-40 refund-request--header">
        <span>Promotional Plans</span>
        {!isSaleTransaction && (
          <Button
            onClick={handlePrint}
            className={`button-primary button-warn-outline ${
              plansForPrinting.length == 0 ? 'is-disabled' : ''
            }`}
            disabled={plansForPrinting.length == 0}
          >
            Print Plans
          </Button>
        )}
      </div>
      <div id="promo-plans-content">
        {sortedPlans.map((plan) => (
          <PlanCard
            onCardClick={handlePlanSelection}
            handleSelectForPrint={(plan) => handleSelectForPrint(plan)}
            plan={plan}
            key={plan.id}
            isSaleTransaction={isSaleTransaction}
          />
        ))}
      </div>
      {/* */}
      <div style={{ display: 'none' }}>
        <div id="plans-content-to-print" ref={plansPrintRef}>
          <div className="page-header">
            <img src={logo} className="logo" alt="Logo" />
          </div>
          {plansForPrinting.map((plan) => (
            <>
              <div className="page-break" />
              <PlanCard
                onCardClick={handlePlanSelection}
                handleSelectForPrint={(plan) => handleSelectForPrint(plan)}
                plan={plan}
                isSaleTransaction={isSaleTransaction}
                forPrint={true}
                key={plan.id}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

PromotionalPlanList.propTypes = propTypes
PromotionalPlanList.defaultProps = defaultProps

export default React.memo(PromotionalPlanList)
