import React from 'react'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import LanguageIcon from '@material-ui/icons/Language'
import LocationIcon from '@material-ui/icons/Room'
import PropTypes from 'prop-types'
import { PracticeType } from 'types'
import { Button } from 'lp-components'
import { formatPhoneNumber } from 'utils'
import getFullAddress from '../../../../utils/getAddress'
const propTypes = {
  practice: PracticeType.isRequired,
  onPracticeSelection: PropTypes.func.isRequired,
}

const defaultProps = {}
function Card({ practice, onPracticeSelection }) {
  const { name, phone, address, website } = practice

  return (
    <div className="card">
      <div className="card-content" style={{ textAlign: 'left' }}>
        <div>
          <p className="card-title">{name}</p>
          <div className="info-container">
            <LocationIcon />
            <p className="card-area"> {getFullAddress(address)}</p>
          </div>
          <div className="info-container">
            <LocalPhoneIcon />

            <p className="card-area">
              {phone ? (
                <a href={`tel:${phone}`} className="email-phone-link">
                  {formatPhoneNumber(phone)}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          </div>
          <div className="info-container">
            <LanguageIcon />
            <p className="card-area">
              {website ? (
                <a
                  href={website}
                  className="email-phone-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {website}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          </div>
        </div>
        <div className="card__button">
          <Button
            onClick={() => onPracticeSelection(practice)}
            className="button-warn qualify"
          >
            PRE-QUALIFY
          </Button>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
