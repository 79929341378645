import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import {
  TransactionCard,
  AuthorizedButton,
  LoanCard,
} from 'merchant-portal-components'
import { useHistory, useParams } from 'react-router-dom'
import { AccountDetailType, LocationType } from 'types'
import { selectors } from '../reducer'
import { selectors as merchantSelectors } from '../../reducer'
import { AccountNumber, AuthorizedBuyers, Spinner } from 'components'
import * as actions from '../actions'
import {
  CONTACT_NUMBER,
  PERMISSION,
  PRODUCT_TYPE,
  REFUND_REQUEST_URL,
  REFUNDABLE_STATES,
  RISK_MESSAGE,
  SALES_URL,
  SUPPLEMENTAL_FUNDING_URL,
  TRANSACTION_STATUS,
  VOID_SALE_URL,
  ACCOUNT_VERIFICATION_URL,
} from 'config'
import {
  formatCurrency,
  formatISODateStringAsUSMonthDayYear,
  isPresent,
  withApiAuth,
} from 'utils'
import InfoIcon from '@material-ui/icons/Info'
import { Tooltip, IconButton } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import SaleInitiatorModal from '../components/SaleInitiatorModal'

const propTypes = {
  getAccountDetails: PropTypes.func.isRequired,
  accountDetail: AccountDetailType,
  currentLocation: LocationType,
  clearAccountDetail: PropTypes.func.isRequired,
}

const defaultProps = {}

function AccountLookUpDetail({
  getAccountDetails,
  accountDetail,
  currentLocation,
  clearAccountDetail,
}) {
  const history = useHistory()
  const { id } = useParams()
  const [saleInitiatorAccountMemberId, setSaleInitiatorAccountMemberId] =
    useState(0)
  const [showModal, setShowModal] = useState(false)

  const handleModalSelect = (buyerId) => {
    setSaleInitiatorAccountMemberId(buyerId)
    if (buyerId == accountMemberId) {
      if (internalRiskReason?.startsWith('RISKY')) {
        history.push({
          pathname: `${ACCOUNT_VERIFICATION_URL}`,
          state: {
            accountDetail,
          },
        })
      } else {
        history.push({
          pathname: `${SALES_URL}/${alphaeonAccountNumber}`,
        })
      }
    } else {
      history.push({
        pathname: `${ACCOUNT_VERIFICATION_URL}`,
        state: {
          accountDetail,
          buyerId,
        },
      })
    }
  }

  const handleModalClose = (value) => {
    setShowModal(value)
  }

  useEffect(() => {
    getAccountDetails(
      id,
      currentLocation.locationId,
      saleInitiatorAccountMemberId
    )

    return () => {
      clearAccountDetail()
    }
  }, [id, currentLocation, saleInitiatorAccountMemberId])

  if (!accountDetail) {
    return <Spinner />
  }

  const {
    name,
    email,
    alphaeonAccountNumber,
    dateOfBirth,
    address,
    availableCreditLimit,
    creditLimit,
    ssnLast4,
    ssn,
    primaryPhone,
    authorizedBuyers,
    consumerCreditInstrumentType,
    transactions,
    accountMemberId,
    derogatoryInd,
    internalRiskReason,
  } = accountDetail

  const salesTransactions = transactions
    .filter(
      ({ status, transactionType }) =>
        transactionType === 'sale' &&
        status !== TRANSACTION_STATUS.ENTERED &&
        status !== TRANSACTION_STATUS.SETTLED &&
        status !== TRANSACTION_STATUS.SENT_FOR_SETTLEMENT
    )
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })

  const refundTransactions = assemble(
    transactions
      .filter(
        ({ status, transactionType }) =>
          ((transactionType === 'sale' || transactionType === 'loan') &&
            REFUNDABLE_STATES.includes(status)) ||
          transactionType === 'refund' ||
          transactionType === 'loan_refund'
      )
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
  )

  const isServiceDateInFuture = (value) => {
    const newDate = new Date()
    const today = new Date(newDate)
    today.setDate(newDate.getDate() - 1)
    return new Date(value) > today
  }

  const voidTransaction = ({
    alphaeonAccountNumber,
    creditLimit,
    name,
    transaction,
  }) =>
    history.push({
      pathname: `${VOID_SALE_URL}${alphaeonAccountNumber}`,
      state: {
        alphaeonAccountNumber,
        creditLimit,
        name,
        transaction,
      },
    })
  const refundTransaction = ({
    alphaeonAccountNumber,
    creditLimit,
    name,
    transaction,
  }) =>
    history.push({
      pathname: `${REFUND_REQUEST_URL}${alphaeonAccountNumber}`,
      state: {
        accountMemberId,
        alphaeonAccountNumber,
        creditLimit,
        name,
        transaction,
      },
    })
  const derogMessage = `This account is inactive, please have the patient contact ${CONTACT_NUMBER}.`
  const zeroBalanceMessage = 'Credit Available for this account is $0.00'
  const disabledToolTipMessage =
    internalRiskReason && internalRiskReason.startsWith('RISKY')
      ? RISK_MESSAGE
      : derogatoryInd === 'Y'
      ? derogMessage
      : zeroBalanceMessage
  const { locationServiceProviderPriorities } = currentLocation

  // "Supplemental funding", in the current implementation, refers to an
  // access loan (ACCESS_LOAN consumerCreditInstrumentType) supplementing
  // an existing AC_CARD. The rules for whether "supplemental funding"
  // is available for a location are:
  //    + The supplemented account type must be of type AC_PRIME (one cannot
  //      supplement an access loan with another access loan)
  //    + The set of service_provider_priotities associated with the location
  //      must have an ACCESS_LOAN product type present.
  const accessLoanPriority = locationServiceProviderPriorities.find(
    ({ productType }) => productType === PRODUCT_TYPE.ACCESS_LOAN
  )
  const canOfferSupplementalFunding =
    consumerCreditInstrumentType === PRODUCT_TYPE.AC_PRIME &&
    isPresent(accessLoanPriority) &&
    currentLocation.supplementalFundingEnabled &&
    derogatoryInd !== 'Y' &&
    (isPresent(ssn) || isPresent(ssnLast4))
  return (
    <>
      {authorizedBuyers?.length > 0 &&
        saleInitiatorAccountMemberId == 0 &&
        showModal && (
          <SaleInitiatorModal
            accountDetail={accountDetail}
            onConfirm={handleModalSelect}
            onClose={handleModalClose}
          />
        )}

      <div className="account-lookup">
        <h1>Account Summary</h1>

        <div className="account-summary summary">
          <div className="summary__card card">
            <div className="card-content">
              <div className="summary__account-info">
                <p className="card-title">
                  {name.firstName} {name.lastName}
                </p>
                <div className="info-container sub-title">
                  <p className="card-area">
                    {consumerCreditInstrumentType === 'installment'
                      ? 'Loan Number'
                      : 'Account Number'}
                    {':'}
                  </p>
                  <AccountNumber accountNumberString={alphaeonAccountNumber} />
                </div>
                <p className="card-area">
                  <strong>Product Type</strong>
                  <span className="m-l-5 capitalize">
                    {consumerCreditInstrumentType === 'installment'
                      ? 'Access Loan'
                      : 'AC Prime'}
                  </span>
                </p>

                <div className="card-main">
                  <div className="card-main__left">
                    <div className="info-container ">
                      <p className="card-area">
                        <strong>Email Address</strong>{' '}
                        {email ? (
                          <a
                            className="email-phone-link"
                            href={`mailto:${email}`}
                          >
                            {email}
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Date of Birth</strong>{' '}
                        {dateOfBirth
                          ? formatISODateStringAsUSMonthDayYear(dateOfBirth)
                          : 'N/A'}
                      </p>
                    </div>

                    <div className="footer">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Address </strong>
                          {address
                            ? `${address.street1 || ''}${' '}
                            ${address?.street2 || ''}, ${address.city || ''}, ${
                                address.state || ''
                              }
                            ${address.zip || ''}`
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-main__right">
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Phone Number </strong>
                        {primaryPhone ? (
                          <a
                            className="email-phone-link"
                            href={`tel:${primaryPhone}`}
                          >
                            {primaryPhone}
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Social Security Number </strong>
                        {ssnLast4 ? `XXX-XX-${ssnLast4}` : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="account-actions">
                {canOfferSupplementalFunding && (
                  <AuthorizedButton
                    className="button-warn-outline"
                    onClick={() =>
                      history.push({
                        pathname: SUPPLEMENTAL_FUNDING_URL,
                        state: {
                          accountDetail,
                        },
                      })
                    }
                    requiredPermission={PERMISSION.PROCESS_SALE}
                  >
                    SUPPLEMENTAL FUNDING
                  </AuthorizedButton>
                )}
                {consumerCreditInstrumentType !== 'installment' && (
                  <AuthorizedButton
                    className="button-warn"
                    onClick={() => {
                      if (
                        derogatoryInd !== 'Y' ||
                        (derogatoryInd === 'Y' &&
                          internalRiskReason?.startsWith('RISKY'))
                      ) {
                        if (authorizedBuyers?.length > 0) {
                          if (saleInitiatorAccountMemberId == 0) {
                            setShowModal(true)
                          }
                        } else {
                          if (internalRiskReason?.startsWith('RISKY')) {
                            history.push({
                              pathname: `${ACCOUNT_VERIFICATION_URL}`,
                              state: {
                                accountDetail,
                              },
                            })
                          } else {
                            history.push({
                              pathname: `${SALES_URL}/${alphaeonAccountNumber}`,
                            })
                          }
                        }
                      }
                    }}
                    invalid={
                      availableCreditLimit <= 0 ||
                      (derogatoryInd === 'Y' &&
                        !internalRiskReason?.startsWith('RISKY'))
                    }
                    disabledToolTip={disabledToolTipMessage}
                    requiredPermission={PERMISSION.PROCESS_SALE}
                  >
                    NEW SALE
                  </AuthorizedButton>
                )}
              </div>
            </div>
          </div>
          {consumerCreditInstrumentType !== 'installment' && (
            <div className="summary__card--bottom card">
              <div className="card-content">
                <div className="summary__account-info">
                  <div className="card-main">
                    <div className="card-main__left w-70 ">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong className="m-r-5">Authorized Buyer(s)</strong>
                          <AuthorizedBuyers authBuyers={authorizedBuyers} />
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Date Opened </strong>
                          N/A
                        </p>
                      </div>
                      <div className="footer">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Credit Available</strong>{' '}
                            {formatCurrency(availableCreditLimit, 2)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p
                          className="card-area"
                          style={{ visibility: 'hidden' }}
                        ></p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Credit Limit</strong>{' '}
                          {formatCurrency(creditLimit, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>
                            Increase Credit Limit{' '}
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px', color: 'white' }}>
                                  Please have the patient contact Comenity Bank
                                  at {CONTACT_NUMBER} to request a credit limit
                                  increase.
                                </p>
                              }
                            >
                              <IconButton className="icon-button">
                                <InfoIcon style={{ color: blue[800] }} />
                              </IconButton>
                            </Tooltip>
                          </strong>{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="account-lookup">
        <h1>
          {consumerCreditInstrumentType === 'installment'
            ? 'Account'
            : 'Transaction'}{' '}
          History
          <span className="heading-subtitle">(up to 18 months)</span>
        </h1>
        <p className="sub-title">
          Account history will only display the transactions for the currently
          selected location.
        </p>
        <div className="transaction-summary summary w-80 m-b-50 ">
          {transactions.length === 0 &&
            consumerCreditInstrumentType === 'installment' && (
              <div className="empty-transaction-card">
                No past account history
              </div>
            )}

          {consumerCreditInstrumentType === 'installment' &&
            transactions &&
            transactions.map(
              (transaction, index) =>
                transaction.transactionType == 'loan' && (
                  <LoanCard
                    key={index}
                    transaction={transaction}
                    accountDetail={accountDetail}
                    withCancel={!isServiceDateInFuture(transaction.effectiveAt)}
                    handleCancelOrRefund={refundTransaction}
                  />
                )
            )}
          <div className="voidable-transactions">
            {salesTransactions &&
              salesTransactions.map((transaction, index) => (
                <TransactionCard
                  key={index}
                  transaction={transaction}
                  accountDetail={accountDetail}
                  refundTransaction={refundTransaction}
                  voidTransaction={voidTransaction}
                />
              ))}
            {salesTransactions.length > 0 && (
              <p className="text-center voidable-transactions__void-helper">
                Void available up until 9:59 PM EST the day of a transaction.
                <br />
                Funds will be released back to the account owner’s Alphaeon
                Card.
              </p>
            )}
          </div>
          <div className="refundable-transactions">
            {refundTransactions &&
              refundTransactions.map(
                (transaction, index) =>
                  transaction.transactionType != 'loan' && (
                    <TransactionCard
                      key={index}
                      transaction={transaction}
                      accountDetail={accountDetail}
                      refundTransaction={refundTransaction}
                      voidTransaction={voidTransaction}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    </>
  )
}

AccountLookUpDetail.propTypes = propTypes

AccountLookUpDetail.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    accountDetail: selectors.accountDetail(state),
    currentLocation: merchantSelectors.currentLocation(state),
  }
}
const mapDispatchToProps = {
  clearAccountDetail: actions.clearAccountDetail,
}

const mapApiAuthToProps = {
  getAccountDetails: apiActions.getAccountDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AccountLookUpDetail)

function assemble(transactions) {
  const table = {}

  transactions.forEach((transaction) => {
    if (!table[transaction.refundedTransactionId]) {
      table[transaction.refundedTransactionId] = []
    }
    table[transaction.refundedTransactionId].push(transaction)
  })

  const result = []
  const constructResult = (key) => {
    if (table[key]) {
      table[key].forEach((transaction) => {
        result.push(transaction)
        constructResult(transaction.transactionId)
      })
    }
  }

  constructResult(null)
  return result
}
