import React from 'react'
import { Input, MaskedInput } from 'lp-components'

import { InputTip } from 'components'
import { removeNonDigits, formatPhoneNumber } from 'utils'
import RadioButtonEditableInput from './RadioButtonEditableInput'

function ContactPatientRadioGroup({ ...props }) {
  const email = props?.options?.email
  const sms =
    sms != undefined ? removeNonDigits(props?.options?.sms) : undefined
  const onChange = props.input.onChange

  return (
    <fieldset className="RadioGroup">
      {sms != undefined && (
        <RadioButtonEditableInput
          id="patientContactOptions.sms"
          radioGroupName="patientContactOptions"
          initialValue={sms}
          onChange={onChange}
          format={formatPhoneNumber}
          labelPrefix="SMS: "
          labelSuffix="standard message and data rates may
            apply"
          editField={{
            name: 'primaryPhone',
            placeholder: 'Mobile Number',
            parse: removeNonDigits,
            inputType: MaskedInput,
            component: InputTip,
            maskOptions: {
              numericOnly: true,
              blocks: [0, 3, 3, 4],
              delimiters: ['(', ') ', '-'],
            },
          }}
        />
      )}
      <RadioButtonEditableInput
        id="patientContactOptions.email"
        checked={true}
        radioGroupName="patientContactOptions"
        initialValue={email}
        labelPrefix="Email: "
        labelSuffix=""
        onChange={onChange}
        editField={{
          name: 'email',
          inputType: 'email',
          component: Input,
          placeholder: 'Email Address',
        }}
      />
    </fieldset>
  )
}

export default React.memo(ContactPatientRadioGroup)
