import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components'

// An internal link styled as a button...
function LinkButton({ children, className, invalid, to, ...rest }, ref) {
  const buttonClassName = className ?? 'button-secondary'
  const buttonInvalid = invalid ?? false

  return (
    <Link to={buttonInvalid ? '#' : to} {...rest}>
      <Button
        ref={ref}
        type="button"
        className={buttonClassName}
        invalid={buttonInvalid}
      >
        {children}
      </Button>
    </Link>
  )
}

export default React.forwardRef(LinkButton)
