import React from 'react'
import exact from 'prop-types-exact'

const propTypes = {}
const defaultProps = {}

function ServiceProviderDisclosures() {
  return (
    <div className="stepper-end">
      <p>*Not a final credit decision.</p>
      <p>
        - Alphaeon Credit Card Accounts are issued by Comenity Capital Bank{' '}
        <br />
        Credit card offers are subject to credit approval.
      </p>

      <p>
        - Alphaeon Credit Access Loans are a Covered Care product. In some
        states loans are <br /> originated by Medallion Bank, member FDIC and
        serviced by Covered Care.
      </p>
    </div>
  )
}

ServiceProviderDisclosures.propTypes = exact(propTypes)
ServiceProviderDisclosures.defaultProps = defaultProps

export default React.memo(ServiceProviderDisclosures)
