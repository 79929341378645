import React from 'react'
import exact from 'prop-types-exact'
import { URL, PATH } from 'config'
import ExternalLink from './ExternalLink'
import { useLocation } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function Footer() {
  const location = useLocation()

  const showPracticeSupport = !location?.pathname.includes(PATH.ACCESS_LOANS)

  return (
    <footer>
      <div className="footer-container">
        {showPracticeSupport && (
          <ExternalLink href={URL.PRACTICE_SUPPORT}>
            <span>PRACTICE SUPPORT</span>
          </ExternalLink>
        )}

        <ExternalLink href={URL.PATIENT_SUPPORT}>
          <span>PATIENT SUPPORT</span>
        </ExternalLink>
      </div>
      <div className="copyright">
        Copyright @ {new Date().getFullYear()} Alphaeon Credit. All rights
        reserved.
      </div>
    </footer>
  )
}

Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps

export default React.memo(Footer)
