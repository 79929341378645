import React, { useState, useRef, useEffect } from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import iconEdit from 'images/utility-icons/edit-2.svg'
import iconSave from 'images/utility-icons/check.svg'

const propTypes = {
  initialValue: PropTypes.any,
  onSave: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  inputValidator: PropTypes.func,
  inputFormatter: PropTypes.func.isRequired,
  inputType: PropTypes.string,
}

const defaultProps = {}

function EditableInput({
  initialValue,
  prefix,
  suffix,
  onSave,
  inputType,
  inputValidator,
  inputFormatter,
}) {
  const [editMode, setEditMode] = useState(false)

  const inputBox = useRef()

  const [value, setValue] = useState(inputFormatter(initialValue))

  useEffect(() => {
    inputBox?.current?.focus()
  }, [editMode])

  let prefixDisplay = ''
  let suffixDisplay = ''
  if (prefix) {
    prefixDisplay = prefix
  }
  if (suffix) {
    suffixDisplay = suffix
  }

  const edit = () => {
    setEditMode(true)
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      save()
    }
  }

  const save = () => {
    if (inputValidator) {
      inputValidator(value)
    }
    let formattedValue = value
    if (inputFormatter) {
      formattedValue = inputFormatter(formattedValue)
      setValue(formattedValue)
    }
    setEditMode(false)
    if (onSave) {
      onSave(formattedValue)
    }
  }

  const updateValue = (targetValue) => {
    let targetValueToUpdate = targetValue
    if (inputValidator && inputValidator(targetValueToUpdate)) {
      setValue(targetValueToUpdate)
      return targetValueToUpdate
    }

    return targetValueToUpdate
  }

  return (
    <>
      {!editMode && (
        <div style={{ paddingTop: '15%' }}>
          <span
            style={{
              paddingLeft: '10%',
              width: '40%',
              display: 'inline-block',
              font: 'inherit',
            }}
          >
            {prefixDisplay}
            {value}
            {suffixDisplay}
          </span>
          <span onClick={edit} style={{ paddingLeft: '3%', cursor: 'pointer' }}>
            <img
              src={iconEdit}
              alt=""
              style={{ height: '20px', width: '20px' }}
            />
          </span>
        </div>
      )}
      {!!editMode && (
        <div style={{ paddingTop: '10%' }}>
          {prefixDisplay}
          <input
            style={{
              width: '35%',
              font: 'inherit',
              display: 'inline-block',
              border: 0,
              borderBottom: '1px solid #9B9B9B',
              backgroundColor: '#F2FBFF',
            }}
            name="jake"
            type={inputType}
            value={value}
            ref={inputBox}
            onChange={(e) => updateValue(e.target.value)}
            onBlur={save}
            onKeyDown={handleEnter}
          />
          {suffix}
          <span
            onClick={save}
            style={{
              paddingLeft: '3%',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            <img
              src={iconSave}
              alt=""
              style={{ height: '25px', width: '25px%' }}
            />
          </span>
        </div>
      )}
    </>
  )
}

EditableInput.propTypes = exact(propTypes)
EditableInput.defaultProps = defaultProps

export default React.memo(EditableInput)
