import React, { useState } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { FormSpy } from 'components'

function SSNInput(props) {
  const [focus, setFocus] = useState(false)
  const [visible, setVisible] = useState(false)
  const [rawSSN, setRawSSN] = useState('')

  const {
    input: { onChange },
  } = props

  const format = (v) => {
    v = v.slice(0, 11).replace(/-/g, '')
    if (v.length <= 3) {
      return v
    }
    if (v.length > 3 && v.length <= 5) {
      return `${v.slice(0, 3)}-${v.slice(3)}`
    }
    if (v.length > 5) {
      return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`
    }
  }

  const mask = (v) => {
    const masked = v.slice(0, 6).replace(/[0-9]/g, 'X')
    const unmaskedValue = v.length > 6 && v.slice(6, 11)
    return unmaskedValue ? `${masked}${unmaskedValue}` : masked
  }

  const handleChange = (e) => {
    let { value } = e.target
    if (value.replace(/-/g, '').length < rawSSN.length) {
      const last = rawSSN.length - 1
      setRawSSN(rawSSN.slice(0, last))
      return
    }

    let numValue = value.replace(/\D/g, '')
    let newSSN = ''

    if (rawSSN.length > 5) {
      newSSN = visible ? numValue : rawSSN.slice(0, 5) + numValue
    } else {
      newSSN = visible ? numValue : rawSSN + numValue
    }

    if (newSSN.length > 9) {
      return
    }
    setRawSSN(newSSN)
    onChange(format(newSSN))
  }

  const handleVisibility = () => {
    setVisible(!visible)
    onChange(format(rawSSN))
  }

  return (
    <div className="ssn-input--wrapper">
      <div className="ssn-input d-flex-center">
        <div style={{ display: 'none' }}>
          <props.inputType name={props.input.name} {...props} />
        </div>

        {props.label && (
          <label>
            Social Security Number{' '}
            <span className="required-indicator" aria-hidden="true">
              *
            </span>
          </label>
        )}

        <input
          onFocus={() => {
            setFocus(true)
          }}
          placeholder="Social Security Number"
          onChange={handleChange}
          required
          value={visible ? format(rawSSN) : mask(format(rawSSN))}
        />

        {props.meta.touched && props.meta.error && (
          <span
            id="securityNumberError"
            className="error-message"
            form="personalInfoForm"
            required=""
            label="Social Security Number"
            placeholder="Social Security Number"
          >
            {props.meta.error}
          </span>
        )}

        <div onClick={handleVisibility} className="ssn-input__visibility-icon">
          {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      </div>

      <FormSpy name={props.input.name} focused={focus}>
        {(val) => {
          if ((val || focus) && props.tooltips) {
            return <props.tooltips />
          }
          return null
        }}
      </FormSpy>
    </div>
  )
}

export default SSNInput
