import React from 'react'
import { selectors as merchantSelectors } from '../reducer'
import { LocationType } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import QrCode from '../components/QrCode'
import { Spinner } from 'components'
import { MarketingToolbar } from 'merchant-portal-components'

const propTypes = {
  currentLocation: LocationType,
}

function QrCodeCustomizer({ currentLocation }) {
  if (!currentLocation) return <Spinner />

  return (
    <>
      <MarketingToolbar />
      <div className="qr-code-customizer">
        <h1>Customize your QR Code</h1>
        <div className="qr-code-customizer__content">
          <QrCode storeNumber={currentLocation?.adsStoreNumber} />
        </div>
      </div>
    </>
  )
}

QrCodeCustomizer.propTypes = propTypes

function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  QrCodeCustomizer
)
