import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function CreditReportAuthorization() {
  return (
    <div className="page-container">
      <h4 className="page-title heavy title-underline">
        Credit Report Authorization
      </h4>
      <p>
        You authorize Alphaeon Credit, Comenity Capital Bank and/or Covered Care
        to each obtain consumer reports and related information about you from
        one or more consumer reporting agencies, such as Experian. You also
        authorize Alphaeon Credit, Comenity Capital Bank and/or Covered Care to
        verify information in your application for credit, and you agree that
        Alphaeon Credit, Comenity Capital Bank and/or Covered Care may contact
        third parties to verify any such information. You direct Alphaeon
        Credit, Comenity Capital Bank and/or Covered Care to use your consumer
        reports to authenticate your identity, to make/facilitate
        prequalification decisions and for other related purposes. You expressly
        authorize Alphaeon Credit, Comenity Capital Bank and/or Covered Care to
        obtain consumer reports about you, as well as your income information,
        from consumer reporting agencies when you apply for credit, during the
        60 days following your initial application date, and periodically
        throughout the term of your credit product. You expressly authorize
        Alphaeon Credit, Comenity Capital Bank and/or Covered Care to use and
        share among themselves the information that we obtain from such reports
        along with your application information to, among other things, market
        credit products to you, determine your eligibility for other products
        that Alphaeon Credit, Comenity Capital Bank and/or Covered Care may
        offer or that you may apply for, make decisions related to the servicing
        and collection of your account, measure how any credit product you
        obtained impacts your credit, perform other statistical analysis, and
        share information with you about your credit profile.
      </p>
    </div>
  )
}

CreditReportAuthorization.propTypes = exact(propTypes)
CreditReportAuthorization.defaultProps = defaultProps

export default memo(CreditReportAuthorization)
