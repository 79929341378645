import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { SubmitButton, MaskedInput } from 'lp-components'
import { InputTip } from 'components'
import { toAmount } from 'utils'
import { Field } from 'redux-form'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

function CalculatorForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="calculator-form m-t-30">
        <div className="calculator-form-container">
          <Field
            component={InputTip}
            inputType={MaskedInput}
            name="amount"
            label="Total Amount of Sale"
            required
            maskOptions={{
              prefix: '$',
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
            }}
            parse={toAmount}
          />
        </div>
      </div>
      <div className="calculator-form-submit">
        <SubmitButton
          className={`button-warn`}
          disabled={submitting}
          submitting={submitting}
        >
          Estimate Payments
        </SubmitButton>
      </div>
    </form>
  )
}

CalculatorForm.propTypes = propTypes
CalculatorForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'CalculatorForm',
    constraints: {},
  })
)(CalculatorForm)
