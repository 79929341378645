import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'

const propTypes = {
  lenderReferenceId: PropTypes.any.isRequired,
}
const defaultProps = {}

function PreQualificationAcceptFailedPage({ lenderReferenceId }) {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Credit Application</h1>
        <p className="first-subtitle">
          Thank you for your interest in the Alphaeon Credit Card.
        </p>
        <p>
          We're reviewing the information you provided and will contact you by
          mail within 10-14 business days regarding the status of your
          application.
        </p>
        {lenderReferenceId && <p> Application ID: {lenderReferenceId}</p>}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lenderReferenceId: selectors.lenderReferenceId(state),
  }
}

const mapDispatchToProps = {}

PreQualificationAcceptFailedPage.propTypes = propTypes
PreQualificationAcceptFailedPage.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PreQualificationAcceptFailedPage
)
