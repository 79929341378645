import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function ConsentElectronicDisclosures() {
  return (
    <div className="page-container">
      <h4 className="heavy page-title title-underline">
        CONSENT TO ELECTRONIC DISCLOSURES
      </h4>

      <p className="paragraph-basic">
        This Consent to Electronic Disclosures (“Consent”) applies to any and
        all interactions between you and Alphaeon Credit (the “Company”), and
        between you and Comenity Capital Bank and/or Covered Care, in connection
        with any prequalifications or applications for credit or any resulting
        extensions of credit (individually an “Account” and collectively
        “Accounts”), as well as your dealings with Company and any third-party
        creditors in the future in connection with your Accounts.
      </p>
      <p className="paragraph-basic">
        In this Consent, the words “you” and “your” refer to each person
        applying for or on an Account; “we,” “us,” and “our” refer to Company,
        Comenity Capital Bank and Covered Care, and any of their affiliates,
        agents, independent contractors, designees, service providers or
        assignees.
      </p>
      <p className="paragraph-basic">
        Please read this document carefully and retain a copy for your
        reference.
      </p>
      <p className="paragraph-basic">
        <strong>Your Legal Rights Regarding Electronic Communications:</strong>{' '}
        We must provide you with certain communications and disclosures in
        writing (i.e., you have a right to receive such communications on paper)
        pursuant to applicable laws and regulations. This information may be
        provided to you electronically if you agree to this Consent.
      </p>
      <p className="paragraph-basic">
        <strong>Types of Electronic Communications You May Receive:</strong> You
        agree and consent to receive electronically all communications we may
        provide, including agreements, documents, notices, statements and
        disclosures (each a “Communication” and collectively the
        “Communications”) in connection with your applications or Accounts.
        Categories of communications may include, but are not limited to, the
        following:
      </p>
      <ul className="paragraph-list terms-list">
        <li>
          agreements and policies you agree and consent to and updates to such
          agreements and policies;
        </li>
        <li>
          notices, receipts and confirmations related to your Account, including
          authorizations for consumer reports and receipt of telephonic or text
          outreach;
        </li>
        <li>any notices and disclosures required by state or federal law;</li>
        <li>marketing information and preferences;</li>
        <li>
          any other account and transaction notices and information from us.
        </li>
      </ul>
      <p className="paragraph-basic">
        <strong>Electronic Agreements and Updates:</strong> Any Communication
        that you accept or agree to receive electronically or any Account update
        you submit to us electronically will be enforceable without your
        signature on a paper form.
      </p>
      <p className="paragraph-basic">
        <strong>Paper Communications:</strong> We will not be obligated to
        provide any Communication to you in paper form unless you specifically
        request us to do so. We may send Communications to you in paper form
        because we are legally required to do so or because we determine in our
        sole discretion that you should receive the Communication in paper
        rather than electronic form.
      </p>
      <p className="paragraph-basic">
        <strong>Copies of Electronic Communications:</strong> You may retain a
        copy of any electronic Communication by using your print function or
        saving an electronic copy for your records. If, after you receive a
        Communication electronically, you would like a paper copy of a
        Communication previously provided to you, you may request a copy during
        the period we retain your Account documentation by contacting us at ___.
        We will send your paper copy to you by U.S. mail. In order for us to
        send you a paper copy, you must have a current mailing address on file
        with us. You may be charged a fee for each paper copy of any electronic
        record.
      </p>
      <p className="paragraph-basic">
        <strong>
          Withdrawal of Consent to Electronic Delivery of Communications:
        </strong>{' '}
        You can withdraw your consent to electronic Communications by contacting
        Company and asking that Communications be sent to you in paper or other
        non-electronic form using one of the following methods:
      </p>
      <ul className="paragraph-list">
        <li>Telephone: 1 800 754 4013</li>
        <li>Email: legal@alphaeoncredit.com</li>
        <li>Postal Mail: 4040 MacArthur Blvd #260 Newport Beach, CA 92660</li>
      </ul>
      <p className="paragraph-basic">
        Your withdrawal of consent to electronic Communications is effective
        only after we have had a reasonable period of time to act upon your
        withdrawal.
      </p>
      <p className="paragraph-basic">
        <strong>Your Contact Information:</strong> If you give us an incorrect
        email address or fail to update or correct your email address, an
        electronic Communication will be deemed provided to you if we use the
        email address in our records for the electronic Communication. You can
        update your contact information by using one of the methods outlined
        above.
      </p>
      <p className="paragraph-basic">
        <strong>Hardware and Software Requirements:</strong> In order to receive
        access to and retain electronic Communications, you will need the
        following hardware and software:
      </p>
      <ul className="paragraph-list">
        <li>
          a recent-generation computer, mobile device, tablet or similar device
          with an internet connection;
        </li>
        <li>
          a current web browser that includes 128-bit encryption with cookies
          enabled;
        </li>
        <li>
          a current version of a software program that can open and display PDF
          files (such as an Adobe Acrobat reader);
        </li>
        <li>
          a valid email address with email software to communicate with us
          electronically; and
        </li>
        <li>
          an installed printer or sufficient storage space to save, store, print
          or otherwise retain electronic Communications.
        </li>
      </ul>
      <p className="paragraph-basic">
        By giving your consent, you are confirming that you have access to the
        necessary software and hardware, and can receive, open, print, download
        a copy or otherwise retain a copy of any electronic Communication for
        your records. You are advised to retain a copy of all electronic
        Communications as they may not be accessible online or in paper form at
        a later date.
      </p>
      <p className="paragraph-basic">
        <strong>Federal Electronic Signatures Law.</strong> You acknowledge and
        agree that your consent to electronic Communications is being provided
        in connection with a transaction affecting interstate commerce that is
        subject to the federal Electronic Signatures in Global and National
        Commerce Act and that you and we both intend that the Act apply to the
        fullest extent possible to validate our ability to conduct business with
        you by electronic means.
      </p>
      <p className="paragraph-basic">
        <strong>Termination/Changes.</strong> We reserve the right, in our sole
        discretion, to discontinue the provision of your electronic
        Communications, or to terminate or change the terms and conditions on
        which we provide electronic Communications. We will provide you with
        notice of any such termination or change as required by law.
      </p>
      <p className="paragraph-basic">
        <strong>Reservation of Rights.</strong> This Consent does not apply to
        any Communication that we determine, in our sole discretion, that we are
        required to deliver in paper form under applicable law or that you
        should receive in paper rather than electronic form.
      </p>
    </div>
  )
}

ConsentElectronicDisclosures.propTypes = exact(propTypes)
ConsentElectronicDisclosures.defaultProps = defaultProps

export default memo(ConsentElectronicDisclosures)
