import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import { Modal } from 'lp-components'
import { useHistory } from 'react-router-dom'
import { flashSuccessMessage, flashErrorMessage } from 'redux-flash'
import { SIGN_RECEIPT_URL, ACCOUNT_LOOKUP_URL } from 'config'
import { selectors } from '../reducer'
import { AccountNumber, Button, Spinner } from 'components'
import { formatCurrency, isRevolving, withApiAuth } from 'utils'
import { LocationType, PaymentDetailsType, PlansType } from 'types'
import { selectors as merchantSelectors } from '../../reducer'
import * as actions from '../actions'
import * as merchantActions from '../../actions'
import { isNil, partition } from 'lodash'
import { SalesConfirmationModal } from 'merchant-portal-components'
import PromotionalPlanList from '../../components/PromotionalPlanList'
import { LocationSelectorForm } from '../../forms'
import { setLocation } from 'local-storage'
import AuthBuyerSaleCard from '../components/AuthBuyerSaleCard'

const propTypes = {
  currentLocation: LocationType,
  fetchPlansByLocationId: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  paymentDetails: PaymentDetailsType.isRequired,
  plans: PlansType,
  salesRequest: PropTypes.func.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  selectedPlan: PlansType.isRequired,
  currentLocationIndex: PropTypes.number.isRequired,
  setCurrentLocationIndex: PropTypes.func.isRequired,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  fetchLocationDetails: PropTypes.func.isRequired,
}

const defaultProps = {}

function PlanSelection({
  currentLocation,
  fetchPlansByLocationId,
  flashSuccessMessage,
  flashErrorMessage,
  paymentDetails,
  plans,
  salesRequest,
  setSelectedPlan,
  selectedPlan,
  currentLocationIndex,
  setCurrentLocationIndex,
  allLocations,
  fetchLocationDetails,
}) {
  const history = useHistory()
  const [modalShown, setModalShown] = useState(false)

  const [displayLocationSelector, setDisplayLocationSelector] = useState(false)
  const closeLocationSelector = () => setDisplayLocationSelector(false)

  if (isNil(paymentDetails)) {
    history.push(ACCOUNT_LOOKUP_URL)
    return <></>
  }

  const {
    name,
    alphaeonAccountNumber,
    availableCreditLimit,
    ssn,
    creditLimit,
    amount,
    authorizationCode,
    referenceId,
    consumerCreditInstrumentId,
    accountMemberId,
    identificationConfirmation,
    buyer,
  } = paymentDetails

  useEffect(() => {
    fetchPlansByLocationId(currentLocation.locationId, amount, true).then(
      function (plans) {
        if (plans && plans.plans && plans.plans.length == 0) {
          flashErrorMessage(
            `No plans returned for purchase amount of $${amount} at this location. Review your plan settings in Program Management under the admin tab.`
          )
        }
      }
    )
  }, [currentLocation])

  const sortedPlans = useMemo(() => {
    if (!plans) return []

    const [revolvingPlans, nonRevolvingPlans] = partition(
      plans.plans,
      ({ name }) => isRevolving(name)
    )
    return [...nonRevolvingPlans, ...revolvingPlans]
  }, [plans])

  const handleSales = (plan) => {
    return salesRequest({
      accountMemberId,
      amount,
      consumerCreditInstrumentId,
      identificationConfirmation,
      locationId: currentLocation.locationId,
      planId: plan.id,
      user_authorization_code: authorizationCode,
      user_reference_id: referenceId,
    })
  }

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan)
    setModalShown(true)
  }

  const handleSubmitSuccess = () => {
    flashSuccessMessage('Payment success!')
    history.push(SIGN_RECEIPT_URL)
  }

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Plan Selection, Step 2 of 3</h1>
        <>
          <div className="account-summary summary refund-complete">
            {buyer && (
              <AuthBuyerSaleCard
                paymentDetails={{ ...paymentDetails }}
                currentLocation={currentLocation}
              />
            )}
            {!buyer && (
              <div className="summary__card card m-t-30">
                <div className="card-content">
                  <div className="summary__account-info">
                    <p className="card-title">
                      {name.firstName} {name.lastName}
                    </p>
                    <div className="info-container sub-title">
                      <p className="card-area">Account Number:</p>
                      <AccountNumber
                        accountNumberString={alphaeonAccountNumber}
                      />
                    </div>

                    <div className="card-main">
                      <div className="card-main__left">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Available Credit</strong>{' '}
                            {formatCurrency(availableCreditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Social Security Number </strong>
                            {ssn ? `XXX-XX-${ssn.slice(-4)}` : 'N/A'}
                          </p>
                        </div>
                      </div>

                      <div className="card-main__right">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Credit Limit</strong>{' '}
                            {formatCurrency(creditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Location </strong> {currentLocation.name} -{' '}
                            {currentLocation.adsStoreNumber}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <Button
                              onClick={() =>
                                setDisplayLocationSelector(
                                  !displayLocationSelector
                                )
                              }
                              className="button-warn-outline"
                            >
                              Change Location
                            </Button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="summary__card--bottom card">
              <div className="card-content">
                <div className="summary__account-info">
                  <div className="card-main">
                    <div className="card-main__left">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong>Total Sales Amount</strong>{' '}
                          {formatCurrency(amount, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Authorization Code</strong>{' '}
                          {authorizationCode}
                        </p>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Reference ID</strong> {referenceId}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-t-50 sales-plan">
              <div className="sales-plan--header">Plan Options</div>
              <div className="sales-plan--helper">
                Ask the customer to choose a plan
              </div>

              {!plans ? (
                <Spinner />
              ) : (
                <div>
                  <PromotionalPlanList
                    isSaleTransaction
                    sortedPlans={sortedPlans}
                    handlePlanSelection={handlePlanSelection}
                  />
                  {modalShown && (
                    <SalesConfirmationModal
                      onCompleteSaleClick={handleSales}
                      plan={selectedPlan}
                      currentLocation={currentLocation}
                      paymentDetails={paymentDetails}
                      handleSubmitSuccess={handleSubmitSuccess}
                      handleClose={() => setModalShown(false)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      </div>
      {displayLocationSelector && (
        <Modal onClose={closeLocationSelector}>
          <LocationSelectorForm
            allLocations={allLocations}
            initialValues={{ selectedLocationIndex: currentLocationIndex }}
            defaultValue={{
              label: currentLocation.name,
              value: currentLocationIndex,
            }}
            onSubmit={({ selectedLocationIndex }) => {
              closeLocationSelector()

              fetchLocationDetails(
                allLocations[selectedLocationIndex.value].locationId
              ).then((res) => setLocation(res))
              return setCurrentLocationIndex(selectedLocationIndex.value)
            }}
          />
        </Modal>
      )}
    </>
  )
}

PlanSelection.propTypes = propTypes

PlanSelection.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    paymentDetails: selectors.paymentDetails(state),
    currentLocation: merchantSelectors.currentLocation(state),
    plans: selectors.plans(state),
    selectedPlan: selectors.selectedPlan(state),
    processedSale: selectors.processedSale(state),
    currentLocationIndex: merchantSelectors.currentLocationIndex(state),
    allLocations: merchantSelectors.allLocations(state),
  }
}
const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
  setSelectedPlan: actions.setSelectedPlan,
  setCurrentLocationIndex: merchantActions.setCurrentLocationIndex,
  flashErrorMessage: flashErrorMessage,
}
const mapApiAuthToProps = {
  salesRequest: apiActions.salesRequest,
  fetchPlansByLocationId: apiActions.fetchPlansByLocationId,
  fetchLocationDetails: apiActions.fetchLocationDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(PlanSelection)
