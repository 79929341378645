import React, { useEffect, useState } from 'react'
import { ButtonLink } from 'components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'

import ReactHtmlParser from 'react-html-parser'

function NotificationsBanner({ notifications, notificationsDismissed }) {
  const [notificationsArray, setNotifications] = useState(notifications)

  // const [
  //   notifications,
  //   setNotifications,
  // ] = useState([])

  // setNotifications(notificationsInput)

  // useEffect(() => {
  //   setNotifications(not)
  // }, [notificationsInput])

  useEffect(() => {
    if (
      notifications &&
      notifications.length > 0 &&
      notificationsDismissed &&
      notificationsDismissed > 0
    ) {
      let filteredNotifications = notifications.filter(
        (notification) => !notificationsDismissed.includes(notification.id)
      )
      setNotifications(filteredNotifications)
    } else {
      setNotifications(notifications)
    }
  }, [notifications])

  function clearNotification(notificationToRemove) {
    if (!notificationsDismissed) {
      notificationsDismissed = []
    }
    notificationsDismissed.push(notificationToRemove.id)
    sessionStorage.setItem('notificationsDismissed', notificationsDismissed)
    setNotifications(
      notificationsArray.filter(
        (notification) => notification.id !== notificationToRemove.id
      )
    )
  }

  return (
    <>
      <div className="notifications-banner-section">
        {notificationsArray &&
          notificationsArray.length > 0 &&
          notificationsArray.map((notification) => (
            <>
              <div className="notification-banner odd">
                {ReactHtmlParser(notification.content)}

                <ButtonLink
                  style={{ paddingLeft: '1.5%' }}
                  onClick={() => clearNotification(notification)}
                  className="link-secondary"
                >
                  Dismiss
                </ButtonLink>
              </div>
            </>
          ))}
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    notifications: selectors.locationNotifications(state),
    notificationsDismissed: selectors.locationNotificationsDismissed(state),
  }
}

const mapDispatchToProps = {
  setLocationNotificationsDismissed: actions.setLocationNotificationsDismissed,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationsBanner
)
