import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
import { LocationType } from 'types'
import { optionsFromLocations } from 'utils'
import { Select } from 'components'

const propTypes = {
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  defaultValue: PropTypes.any,
  ...formPropTypes,
}

const defaultProps = {}

function LocationSelectorForm({ allLocations, handleSubmit, defaultValue }) {
  const locationData = useMemo(
    () => optionsFromLocations(allLocations),
    [allLocations]
  )

  return (
    <div className="start-application__form ">
      <form noValidate onSubmit={handleSubmit}>
        <h1 className="m-b-50">Select Location</h1>

        <Field
          name="selectedLocationIndex"
          component={Select}
          initialValue={defaultValue}
          label="Location"
          required={true}
          options={locationData}
        />

        <div className="start-application__form--actions m-t-50 m-b-50">
          <div className="submit">
            <SubmitButton>Select Location</SubmitButton>
          </div>
        </div>
      </form>
    </div>
  )
}

LocationSelectorForm.propTypes = propTypes
LocationSelectorForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'locationSelectorForm',
    enableReinitialize: true,
    constraints: {
      selectedLocationIndex: { presence: true },
    },
  }),
  formValues({
    selectedLocationIndex: 'selectedLocationIndex',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(LocationSelectorForm)
