import React, { useState } from 'react'
import { FormSpy } from 'components'

function InputTip(props) {
  const [focus, setFocus] = useState(false)
  return (
    <>
      <props.inputType
        onFocus={() => {
          setFocus(true)
        }}
        {...props}
      />
      <FormSpy name={props.input.name} focused={focus}>
        {(val) => {
          if ((val || focus) && props.tooltips) {
            return <props.tooltips />
          }
          return null
        }}
      </FormSpy>
    </>
  )
}

export default InputTip
