/* eslint-disable no-console */
import {
  APPLICATION_ROOT_URL,
  REACT_APP_BUILDID_FETCH_INTERVAL,
  isProduction,
} from 'config'

let errorCount = 0

export default async function fetchApplicationBuildId() {
  if (!isProduction() || REACT_APP_BUILDID_FETCH_INTERVAL === 0) {
    return Promise.resolve(`DEFAULT APPLICATION VERSION`)
  }

  try {
    const response = await fetch(`${APPLICATION_ROOT_URL}/buildHash`, {
      cache: 'no-store',
    })
    const buildHash = await response.json()
    const { hash: applicationBuildId } = buildHash

    errorCount = 0

    return applicationBuildId
  } catch (error) {
    if (errorCount === 0) {
      ++errorCount
      console.error(
        '[ERROR]: cannot obtain application buildId from server. ERROR: ',
        error?.message
      )
      console.error(
        '[NOTE]: future errors will not be reported until exception is resolved.'
      )
    }
  }
}
