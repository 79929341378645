import React from 'react'
import exact from 'prop-types-exact'
import { Link } from 'react-router-dom'
import logo from 'images/Alphaeon_Credit_Logo_Linear.png'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { CREDIT_PORTAL_URL } from 'config'

const propTypes = {}
const defaultProps = {}

function Header() {
  return (
    <div className="header">
      <Link to={CREDIT_PORTAL_URL}>
        <img src={logo} className="logo" alt="Logo" />
      </Link>
      <div className="wizard-header-title">
        <LockOutlinedIcon />
        Secure Application
      </div>
    </div>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default React.memo(Header)
