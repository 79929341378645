import React from 'react'
import { Link } from 'react-router-dom'
import { CREDIT_PORTAL_URL } from 'config'

const propTypes = {}
const defaultProps = {}

function ApplicationNotFound() {
  return (
    <div className="action-result-container">
      <div className="main-content">
        <header>
          <h1>No Application Found</h1>
        </header>
        <p>Thank you for your interest in the Alphaeon Credit Card.</p>
        <p>We were unable to find any open applications.</p>
      </div>
      <div className="next-step">
        <p>
          You can start a new application by clicking{' '}
          <Link to={CREDIT_PORTAL_URL}>here</Link>.
        </p>
      </div>
    </div>
  )
}

ApplicationNotFound.propTypes = propTypes
ApplicationNotFound.defaultProps = defaultProps

export default React.memo(ApplicationNotFound)
