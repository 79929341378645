import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import * as Views from './views'
import Layout from './Layout'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route exact path={path}>
          <Views.Applicants />
        </Route>
        <Route path={makePath(path, PATH.CONTINUE_APPLICATION)}>
          <Views.ContinuedCreditApplication />
        </Route>
        <Route path={makePath(path, PATH.NEW_ACCESS_LOAN)}>
          <Views.NewAccessLoan />
        </Route>
        <Route path={makePath(path, PATH.PUSH_APPLICATION)}>
          <Views.PushApplication />
        </Route>

        <Redirect path="*" to={makePath(path, PATH.CREDIT_APPLICATION)} />
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
