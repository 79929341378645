import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { TableColumn as Column, SortableTable } from 'lp-components'
import {
  formatCurrency,
  formatISODateStringAsUSMonthDayYear,
  formatUpperCase,
  mapTransactionTypeToProductType,
  getFullName,
} from 'utils'
import { Pagination, Spinner } from 'components'
import { AuthorizedLink } from 'merchant-portal-components'
import {
  ACCOUNT_DETAIL_URL,
  PERMISSION,
  LOAN_ADJUSTMENT_URL,
  VOID_SALE_URL,
  TRANSACTION_TYPE,
} from 'config'
import { SalesReportType } from 'types'
import PropTypes from 'prop-types'
import { TransactionFilterForm } from '../forms'

const propTypes = {
  displayedTransactions: PropTypes.any,
  salesReports: SalesReportType,
  allTransactions: PropTypes.any,
  handlePageChange: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  formKey: PropTypes.string.isRequired,
}

const defaultProps = {}

function TransactionTable({
  salesReports,
  allTransactions,
  displayedTransactions,
  formKey,
  initialValues,
  handlePageChange,
  handleFilters,
}) {
  return (
    <>
      <TransactionFilterForm
        name={`transactionFilterForm-${formKey}`}
        onSubmit={handleFilters}
        initialValues={initialValues}
      />
      {!salesReports ? (
        <Spinner />
      ) : (
        <>
          {allTransactions.length > 0 ? (
            <>
              <SortableTable
                data={displayedTransactions}
                initialAscending={false}
              >
                <Column
                  name="name"
                  label="Customer Name"
                  component={({ value }) => {
                    return <td>{getFullName(value)}</td>
                  }}
                  disabled
                />

                <Column
                  name="transactionType"
                  label="Product Type"
                  valueGetter={({ transactionType }) =>
                    mapTransactionTypeToProductType(transactionType)
                  }
                />
                <Column
                  name="transactionType"
                  label="Trans Type"
                  format={formatUpperCase}
                />

                <Column
                  name="alphaeonAccountNumber"
                  label="Account / Loan Number"
                  disabled
                />

                <Column
                  name="effectiveAt"
                  label="Service / Trans Date"
                  disabled
                  component={ServiceDateCell}
                />

                <Column
                  name="amount"
                  label="Amount"
                  disabled
                  format={(amount) => formatCurrency(amount, 2)}
                />

                <Column
                  name="action"
                  label="Action"
                  component={TransactionActionCell}
                  disabled
                />
              </SortableTable>
              <table>
                <tfoot>
                  <tr>
                    <td colSpan={2}></td>
                    <td className="bold">
                      TOTAL{' '}
                      {displayedTransactions &&
                        formatCurrency(
                          displayedTransactions.reduce(
                            (acc, obj) => acc + obj.amount,
                            0
                          ),
                          2
                        )}
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div className="m-b-20">
                <Pagination
                  handlePageChange={handlePageChange}
                  data={allTransactions}
                />
              </div>
            </>
          ) : (
            <div className="empty-state-container">
              <p>No Transactions</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

TransactionTable.propTypes = propTypes
TransactionTable.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {}

const TransactionActionCell = ({ data }) => {
  const isInstallment = data.transactionType === TRANSACTION_TYPE.LOAN
  const buttonLink = isInstallment
    ? `${ACCOUNT_DETAIL_URL}${data.alphaeonAccountNumber}${LOAN_ADJUSTMENT_URL}`
    : `${VOID_SALE_URL}${data.alphaeonAccountNumber}`

  return (
    <td className="actions">
      <AuthorizedLink
        to={`${ACCOUNT_DETAIL_URL}${data.alphaeonAccountNumber}`}
        requiredPermission={PERMISSION.ACCOUNT_LOOKUP}
      >
        VIEW
      </AuthorizedLink>

      {data.status === 'approved' && (
        <AuthorizedLink
          to={{
            pathname: `${buttonLink}`,
            state: {
              alphaeonAccountNumber: data.alphaeonAccountNumber,
              creditLimit: data.amount,
              id: data.alphaeonAccountNumber,
              name: data.name,
              transaction: {
                amount: data.amount,
                planName: data.planName,
                locationName: data.locationName,
                transactionId: data.id,
                createdAt: data.createdAt,
                transactionType: data.transactionType,
                status: data.status,
                voidedAt: data.voidedAt,
              },
            },
          }}
          requiredPermission={PERMISSION.PROCESS_VOID}
        >
          ADJUST
        </AuthorizedLink>
      )}
    </td>
  )
}

const ServiceDateCell = ({
  data: { transactionType, effectiveAt, installmentServiceDate },
}) => {
  if (transactionType === TRANSACTION_TYPE.LOAN) {
    return (
      <td>{formatISODateStringAsUSMonthDayYear(installmentServiceDate)}</td>
    )
  } else {
    return <td>{formatISODateStringAsUSMonthDayYear(effectiveAt)}</td>
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TransactionTable
)
