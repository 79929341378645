import { combineReducers } from 'redux'
import { resetState } from './actions'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './global-reducer'

import {
  reducer as creditPortalReducer,
  reducerKey as creditPortalReducerKey,
} from './credit-portal'

import {
  reducer as merchantPortalReducer,
  reducerKey as merchantPortalReducerKey,
} from './merchant-portal'

import {
  reducer as legallReducer,
  reducerKey as legalReducerKey,
} from './legal'
import {
  reducer as styleguideReducer,
  reducerKey as styleguideReducerKey,
} from './styleguide'

const reducerKey = 'root'

const rootReducer = combineReducers({
  [globalReducerKey]: globalReducer,
  [creditPortalReducerKey]: creditPortalReducer,
  [legalReducerKey]: legallReducer,
  [merchantPortalReducerKey]: merchantPortalReducer,
  [styleguideReducerKey]: styleguideReducer,
})

function reducer(state, action) {
  if (action.type === resetState.toString()) {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

export { reducer, reducerKey }
