import React, { useEffect } from 'react'
import { selectors as merchantSelectors } from '../reducer'
import { LocationType } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { MarketingToolbar } from 'merchant-portal-components'

const propTypes = {
  currentLocation: LocationType,
}

function ShopifyStore({ currentLocation }) {
  const setNote = (cart) => {
    var store =
      currentLocation.name + ' (' + currentLocation.adsStoreNumber + ')'
    var iframe = document.getElementsByName('frame-cart')[0]
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document
    innerDoc.getElementsByClassName(
      'shopify-buy__cart__note__text-area'
    )[0].value = store
    cart.setNote({ target: { value: store } })
  }

  function fireUpShopify() {
    (function () {
      var scriptURL =
        'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          document.getElementById('shopify-script').remove()
          window.ShopifyBuy = undefined
          loadScript()
        } else {
          loadScript()
        }
      } else {
        loadScript()
      }
      function loadScript() {
        var script = document.createElement('script')
        script.async = true
        script.src = scriptURL
        script.id = 'shopify-script'
        ;(
          document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]
        ).appendChild(script)
        script.onload = ShopifyBuyInit
      }
      function ShopifyBuyInit() {
        var storefrontAccessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN
        if (!storefrontAccessToken) {
          return
        }
        var client = window.ShopifyBuy.buildClient({
          domain: 'alphaeoncredit.myshopify.com',
          storefrontAccessToken: storefrontAccessToken,
        })
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
          document.getElementById(
            'collection-component-1639187786604'
          ).innerHTML = ''
          var existing_frame = document.getElementsByName('frame-cart')
          if (existing_frame && existing_frame[0]) {
            existing_frame[0].remove()
          }

          ui.createComponent('collection', {
            id: '398764703967',
            node: document.getElementById('collection-component-1639187786604'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              product: {
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': 'calc(33.33333% - 30px)',
                      'margin-left': '30px',
                      'margin-bottom': '50px',
                      width: 'calc(33.33333% - 30px)',
                    },
                    img: {
                      height: 'calc(100% - 15px)',
                      position: 'absolute',
                      left: '0',
                      right: '0',
                      top: '0',
                    },
                    imgWrapper: {
                      'padding-top': 'calc(75% + 15px)',
                      position: 'relative',
                      height: '0',
                    },
                  },
                  title: {
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '19px',
                  },
                  button: {
                    'font-family': 'Lato, sans-serif',
                    ':hover': {
                      'background-color': '#af2232',
                    },
                    'background-color': '#c22638',
                    ':focus': {
                      'background-color': '#af2232',
                    },
                    'border-radius': '40px',
                    'padding-left': '63px',
                    'padding-right': '63px',
                  },
                  price: {
                    'font-family': 'Lato, sans-serif',
                    color: '#ffffff',
                  },
                  compareAt: {
                    'font-family': 'Lato, sans-serif',
                    color: '#ffffff',
                  },
                  unitPrice: {
                    'font-family': 'Lato, sans-serif',
                    color: '#ffffff',
                  },
                },
                buttonDestination: 'modal',
                contents: {
                  options: false,
                },
                text: {
                  button: 'View product',
                },
                googleFonts: ['Lato'],
              },
              productSet: {
                styles: {
                  products: {
                    '@media (min-width: 601px)': {
                      'margin-left': '-30px',
                    },
                  },
                },
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': '100%',
                      'margin-left': '0px',
                      'margin-bottom': '0px',
                    },
                  },
                  button: {
                    'font-family': 'Lato, sans-serif',
                    ':hover': {
                      'background-color': '#af2232',
                    },
                    'background-color': '#c22638',
                    ':focus': {
                      'background-color': '#af2232',
                    },
                    'border-radius': '40px',
                    'padding-left': '63px',
                    'padding-right': '63px',
                  },
                  title: {
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '26px',
                    color: '#4c4c4c',
                  },
                  price: {
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '18px',
                    color: '#4c4c4c',
                  },
                  compareAt: {
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '15.299999999999999px',
                    color: '#4c4c4c',
                  },
                  unitPrice: {
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '15.299999999999999px',
                    color: '#4c4c4c',
                  },
                  description: {
                    'font-family': 'Lato, sans-serif',
                  },
                },
                googleFonts: ['Lato'],
                text: {
                  button: 'Add to cart',
                },
              },
              option: {},
              cart: {
                contents: {
                  note: true,
                },
                events: {
                  openCheckout: function (cart) {
                    setNote(cart)
                    return true
                  },
                },
                styles: {
                  button: {
                    'font-family': 'Lato, sans-serif',
                    ':hover': {
                      'background-color': '#af2232',
                    },
                    'background-color': '#c22638',
                    ':focus': {
                      'background-color': '#af2232',
                    },
                    'border-radius': '40px',
                  },
                  title: {
                    color: '#000000',
                  },
                  header: {
                    color: '#000000',
                  },
                  lineItems: {
                    color: '#000000',
                  },
                  subtotalText: {
                    color: '#000000',
                  },
                  subtotal: {
                    color: '#000000',
                  },
                  notice: {
                    color: '#000000',
                  },
                  currency: {
                    color: '#000000',
                  },
                  close: {
                    color: '#000000',
                    ':hover': {
                      color: '#000000',
                    },
                  },
                  empty: {
                    color: '#000000',
                  },
                  noteDescription: {
                    color: '#000000',
                  },
                  note: {
                    display: 'none',
                  },
                  discountText: {
                    color: '#000000',
                  },
                  discountIcon: {
                    fill: '#000000',
                  },
                  discountAmount: {
                    color: '#000000',
                  },
                },
                text: {
                  total: 'Subtotal',
                  notice: '',
                  button: 'Checkout',
                },
                googleFonts: ['Lato'],
              },
              toggle: {
                styles: {
                  toggle: {
                    'font-family': 'Lato, sans-serif',
                    'background-color': '#c22638',
                    ':hover': {
                      'background-color': '#af2232',
                    },
                    ':focus': {
                      'background-color': '#af2232',
                    },
                  },
                },
                googleFonts: ['Lato'],
              },
              lineItem: {
                styles: {
                  variantTitle: {
                    color: '#000000',
                  },
                  title: {
                    color: '#000000',
                  },
                  price: {
                    color: '#000000',
                  },
                  fullPrice: {
                    color: '#000000',
                  },
                  discount: {
                    color: '#000000',
                  },
                  discountIcon: {
                    fill: '#000000',
                  },
                  quantity: {
                    color: '#000000',
                  },
                  quantityIncrement: {
                    color: '#000000',
                    'border-color': '#000000',
                  },
                  quantityDecrement: {
                    color: '#000000',
                    'border-color': '#000000',
                  },
                  quantityInput: {
                    color: '#000000',
                    'border-color': '#000000',
                  },
                },
              },
            },
          })
        })
      }
    })()
    /*]]>*/

    useEffect(() => {}, [currentLocation])
  }

  return (
    <>
      <MarketingToolbar />
      <div className="store">
        <h1>Marketing Materials</h1>
        <div id="collection-component-1639187786604"></div>
        {fireUpShopify(currentLocation)}
      </div>
    </>
  )
}

ShopifyStore.propTypes = propTypes

function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ShopifyStore
)
