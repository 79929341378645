import React from 'react'
import { useHistory } from 'react-router-dom'
import ErrorIcon from '@material-ui/icons/Error'
import { Button } from 'lp-components'
import { ALPHAEON_CONTACT_PHONE } from 'config'

const propTypes = {}
const defaultProps = {}

function ServerError() {
  const history = useHistory()

  return (
    <div className="page-container centered-container">
      <div className="error-page-container">
        <ErrorIcon style={{ fontSize: 100 }} color="disabled" />
        <h4 className="error-page-title">Oops! Something went wrong!</h4>
        <p className="error-page-paragraph">
          Please contact our hotline to let us know what you were trying to
          accomplish at {ALPHAEON_CONTACT_PHONE}.
        </p>
        <Button
          className="button-warn-outline"
          onClick={() => history.goBack()}
        >
          Retry Request
        </Button>
      </div>
    </div>
  )
}

ServerError.propTypes = propTypes
ServerError.defaultProps = defaultProps

export default React.memo(ServerError)
