import React from 'react'
import exact from 'prop-types-exact'
import { Link } from 'react-router-dom'

import logo from 'images/Alphaeon_Credit_Logo.png'
import { PRIVACY_URL } from 'config'

const propTypes = {}
const defaultProps = {}

function SiteInitializationFooter() {
  return (
    <footer>
      <div className="simplified-footer-container">
        <div className="footer-container__logo">
          <img src={logo} className="logo" alt="Logo" />
        </div>
      </div>

      <div className="seperator"></div>

      <div className="footer__footer">
        <div className="footer-container__content">
          This site gives access to services offered by Alphaeon Credit.
        </div>
        <div>
          <Link to={PRIVACY_URL}>WEBSITE PRIVACY</Link>
        </div>
      </div>
    </footer>
  )
}
SiteInitializationFooter.propTypes = exact(propTypes)
SiteInitializationFooter.defaultProps = defaultProps

export default React.memo(SiteInitializationFooter)
