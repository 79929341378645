import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  SiteInitializationHeader,
  SiteInitializationFooter,
} from 'merchant-portal-components'
import { useLocation } from 'react-router-dom'
import { FlashMessageContainer, NetworkErrorReporter } from 'components'
import { scrollToTop } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  const location = useLocation()

  // Scroll to top of page when route changes
  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  return (
    <div className="merchant-portal">
      <FlashMessageContainer />
      <SiteInitializationHeader />
      <div className="container m-b-50">{children}</div>
      <NetworkErrorReporter />
      <SiteInitializationFooter />
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
