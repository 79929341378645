import React from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import CardImage from 'images/Credit_Card.png'
import { URL } from 'config'
import { ExternalLink, ExternalLinkButton } from 'components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { ApprovedApplicationType, PersonalInfoType, PracticeType } from 'types'
import { isNil } from 'lodash'
import { formatCurrency, formatPhoneNumber } from 'utils'

const propTypes = {
  personalInfo: PersonalInfoType.isRequired,
  practice: PracticeType,
  approvedApplication: ApprovedApplicationType.isRequired,
}

function Approval({ personalInfo, practice, approvedApplication }) {
  const { firstName, lastName } = personalInfo
  const { creditLimit, alphaeonAccountNumber } = approvedApplication

  return (
    <div>
      <p className="first-title">
        Congratulations, {firstName} {lastName}
      </p>
      <p className="first-subtitle">
        You're approved for the Alphaeon Credit Card
      </p>

      <div className="first-card">
        <div className="card-image">
          <img src={CardImage} alt="Card" />
        </div>
        <div className="first-card-header">
          <p className="first-limit">APPROVED CREDIT LIMIT</p>
          <p className="first-price">{formatCurrency(creditLimit, 2)}</p>
        </div>
        <div className="credit-card-body">
          <div className="credit-card--content">
            <p className="key">Name on Card</p>
            <p className="value">
              {firstName}, {lastName}
            </p>
          </div>
          <div className="credit-card--content">
            <p className="key">Account Number</p>
            <p className="value">{alphaeonAccountNumber}</p>
          </div>
          <div className="credit-card--content">
            <p className="key">Credit Limit</p>
            <p className="value">{formatCurrency(creditLimit, 2)}</p>
          </div>
        </div>
      </div>

      <p className="last-card-body-header">
        Your account has been created and is ready to use immediately
      </p>
      <ul className="ready-to-use">
        <li>You do not need your card to make a purchase</li>
        <li>
          Simply tell your provider that you want to pay with your Alphaeon
          Credit card and present your valid government issued ID
        </li>
        <li>
          A welcome letter and your Alphaeon Credit card will arrive in the mail
          within the next 10 business days
        </li>
      </ul>

      {isNil(practice) ? (
        <>
          <p className="last-card-body-header">
            Haven't decided on a provider yet?
          </p>
          <ExternalLinkButton
            className="button-warn qualify"
            href={'https://goalphaeon.com/doctor-locator'}
          >
            FIND A PRACTICE
          </ExternalLinkButton>
        </>
      ) : (
        <>
          <p className="last-card-body-header">Ready to schedule?</p>
          <p className="contact">
            Contact {practice.name} at{' '}
            <a href={`tel:${practice.phone}`} className="email-phone-link">
              {formatPhoneNumber(practice.phone)}
            </a>{' '}
            to get started!
          </p>
        </>
      )}

      <div className="follow-us">
        <p className="follow">FOLLOW US</p>
        <ExternalLink href={URL.LINKEDIN}>
          <LinkedInIcon className="social-icon" />
        </ExternalLink>
        <ExternalLink href={URL.FACEBOOK}>
          <FacebookIcon className="social-icon" />
        </ExternalLink>
        <ExternalLink href={URL.INSTAGARAM}>
          <InstagramIcon className="social-icon" />
        </ExternalLink>
      </div>
    </div>
  )
}

Approval.propTypes = propTypes

function mapStateToProps(state) {
  return {
    approvedApplication: selectors.approvedApplication(state),
    practice: selectors.practice(state),
    personalInfo: selectors.personalInfo(state),
  }
}

const mapDispatchToProps = {}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Approval)
