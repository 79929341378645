import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import * as Views from './views'
import { PATH, makePath } from 'config'
import Layout from './Layout'

const propTypes = {}
const defaultProps = {}

const Routes = () => {
  const { path } = useRouteMatch()
  return (
    <Layout>
      <Switch>
        <Route path={makePath(path, PATH.WIZARD)} exact={true}>
          <Views.Wizard />
        </Route>

        <Route path={makePath(path, PATH.APPLICATION_FINDER)} exact={true}>
          <Views.ApplicationFinder />
        </Route>

        <Route>
          <Switch>
            <Route path={makePath(path, PATH.ROOT)} exact={true}>
              <Views.Home />
            </Route>
            <Route path={makePath(path, PATH.PRACTICE)} exact={true}>
              <Views.Practice />
            </Route>
            <Route
              path={makePath(path, PATH.PRE_QUALIFICATION_INFORMATION_REQUIRED)}
              exact={true}
            >
              <Views.PreQualificationAcceptFailedPage />
            </Route>
            <Route
              path={makePath(path, PATH.PRE_QUALIFICATION_DECLINED_ADS_ONLY)}
              exact={true}
            >
              <Views.PreQualificationDeclinedAdsOnlyPage />
            </Route>

            <Route
              path={makePath(path, PATH.PRE_QUALIFICATION_ACCEPT_DECLINED)}
              exact={true}
            >
              <Views.PreQualificationAcceptDeclinedPage />
            </Route>

            <Route
              path={makePath(path, PATH.PRE_QUALIFICATION_ACCOUNT_EXISTS)}
              exact={true}
            >
              <Views.AccountExistsPage applicationStep="Pre-qualification" />
            </Route>
            <Route
              path={makePath(path, PATH.PRE_QUALIFICATION_DECLINED)}
              exact={true}
            >
              <Views.PreQualificationDeclinedPage />
            </Route>
            <Route path={makePath(path, PATH.DECLINED)} exact={true}>
              <Views.DeclinedPage />
            </Route>
            <Route path={makePath(path, PATH.ACCOUNT_EXISTS)} exact={true}>
              <Views.AccountExistsPage applicationStep="Credit Application" />
            </Route>

            <Route path={makePath(path, PATH.CREDIT_DECLINED)} exact={true}>
              <Views.CreditDeclinedPage />
            </Route>

            <Route path={makePath(path, PATH.ACCESS_LOANS)} exact={true}>
              <Views.CoveredIframePage />
            </Route>

            <Route path={makePath(path, PATH.APPLICATION_FOUND)} exact={true}>
              <Views.ApplicationFound />
            </Route>

            <Route path={makePath(path, PATH.NO_APPLICATIONS)} exact={true}>
              <Views.ApplicationNotFound />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
