import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectors as merchantSelectors } from '../reducer'
import { PermissionsType } from 'types'
import { operationNotPermitted } from 'utils'
import AuthorizationTooltip from './AuthorizationTooltip'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentPermissions: PermissionsType.isRequired,
  requiredPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  selectionCriteria: PropTypes.string,
}
const defaultProps = {
  selectionCriteria: 'ALL',
}

function AuthorizedLink({
  children,
  currentPermissions,
  requiredPermission,
  selectionCriteria,
  ...rest
}) {
  return operationNotPermitted(
    currentPermissions,
    requiredPermission,
    selectionCriteria
  ) ? (
    <DisabledLink>{children}</DisabledLink>
  ) : (
    <Link {...rest}>{children}</Link>
  )
}

AuthorizedLink.propTypes = propTypes
AuthorizedLink.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPermissions: merchantSelectors.currentPermissions(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthorizedLink
)

const DisabledLink = React.forwardRef(function DisabledLink(
  { children, ...rest },
  ref
) {
  return (
    <AuthorizationTooltip displayed>
      <span className="disabled-link" ref={ref} {...rest}>
        {children}
      </span>
    </AuthorizationTooltip>
  )
})
DisabledLink.displayName = 'DisabledLink'
