import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PrefilledPersonalInfoForm } from '../../forms'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import {
  ApplicationEnvironmentType,
  PracticeType,
  PersonalInfoType,
  CreditApplicationType,
} from 'types'
import InfoIcon from '@material-ui/icons/Info'
import { Tooltip, IconButton } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import {
  APPLICATION_FINDER_URL,
  PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
  PRE_QUALIFICATION_DECLINED_URL,
  ACCESS_LOAN_URL,
  PRE_QUALIFICATION_DECLINED_ADS_ONLY_URL,
  CREDIT_PORTAL_URL,
  PRODUCT_TYPE,
} from 'config'
import { approveTerms, flashSubmitFailure } from 'utils'
import { useIovation } from 'hooks'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { ServiceProviderDisclosures } from 'credit-portal-components'
import { isNil } from 'lodash'

const propTypes = {
  applicationEnvironment: ApplicationEnvironmentType.isRequired,
  onContinue: PropTypes.func.isRequired,
  requestPrequalification: PropTypes.func.isRequired,
  setPersonalInfo: PropTypes.func.isRequired,
  practice: PracticeType,
  setLenderReferenceId: PropTypes.func.isRequired,
  personalInfo: PersonalInfoType,
  allowChangePractice: PropTypes.bool,
  showFindApplication: PropTypes.bool,
  creditApplication: CreditApplicationType,
  patchApplicant: PropTypes.func.isRequired,
  prequalifyCreditApplication: PropTypes.func.isRequired,
  setPrequalification: PropTypes.func.isRequired,
}

const PersonalInfo = ({
  applicationEnvironment,
  onContinue,
  requestPrequalification,
  setPersonalInfo,
  practice,
  setLenderReferenceId,
  personalInfo,
  allowChangePractice,
  showFindApplication,
  creditApplication,
  patchApplicant,
  prequalifyCreditApplication,
  setPrequalification,
}) => {
  const history = useHistory()

  const location = useLocation()
  const practiceFromState = location.state

  if (!practice?.locationId && practiceFromState?.practice?.locationId) {
    practice = practiceFromState.practice
  }

  const handleAppRoutingByAppState = (creditAppStatus) => {
    if (creditAppStatus === 'prequalified') {
      history.push(APPLICATION_FINDER_URL)
    } else if (creditAppStatus === 'prequalified_account_exists') {
      history.push(PRE_QUALIFICATION_ACCOUNT_EXISTS_URL)
    } else if (creditAppStatus === 'pending_approval') {
      history.push(APPLICATION_FINDER_URL)
    } else {
      history.push(PRE_QUALIFICATION_DECLINED_URL)
      return
    }
  }

  if (!isNil(creditApplication)) {
    let creditAppStatus = creditApplication.applicationStatus
    if (creditAppStatus !== 'created') {
      handleAppRoutingByAppState(creditAppStatus)
    }
  }

  const [getBlackboxData] = useIovation()
  let payloadData = null

  const handlePushAppSubmit = (prequalificationFormValues) => {
    const { alphaeonTerms, lenderTerms } = applicationEnvironment

    if (
      !isNil(creditApplication) &&
      creditApplication.applicationStatus !== 'created'
    ) {
      handleAppRoutingByAppState(creditApplication.applicationStatus)
    }

    return patchApplicant(
      creditApplication.applicationId,
      prequalificationFormValues
    ).then(() => {
      const { consumer } = creditApplication.locationServiceProviderPriorities
      const acPrimePriority = consumer.find(
        ({ productType }) => productType === PRODUCT_TYPE.AC_PRIME
      )

      const prequalificationRequest = {
        priority: acPrimePriority.priority,
        iovationBlackBox: getBlackboxData().blackbox,
        prerequisiteTermsAccepted: approveTerms(alphaeonTerms, lenderTerms),
      }

      payloadData = {
        ...prequalificationRequest,
        ...prequalificationFormValues,
        locationId: practice.locationId,
      }

      return prequalifyCreditApplication(
        creditApplication.applicationId,
        payloadData
      ).then((response) => {
        setPrequalification(response)
        return { ...response }
      })
    })
  }

  const handleSubmit = (prequalificationFormValues) => {
    setPersonalInfo(prequalificationFormValues)

    if (!isNil(creditApplication)) {
      return handlePushAppSubmit(prequalificationFormValues)
    }

    const { alphaeonTerms, lenderTerms } = applicationEnvironment
    const approvedTerms = approveTerms(alphaeonTerms, lenderTerms)

    const blackboxData = getBlackboxData()
    const data = {
      ...prequalificationFormValues,
      ...(practice && { locationId: practice.locationId }),
      iovationBlackBox: blackboxData.blackbox,
      prerequisiteTermsAccepted: approvedTerms,
    }

    payloadData = data
    return requestPrequalification(data)
  }

  const handleSubmitSuccess = ({
    applicationStatus,
    lenderNextStepUrl,
    applicationServiceProviderPriority,
    lenderRequestReferenceId,
  }) => {
    setLenderReferenceId(lenderRequestReferenceId)
    if (applicationStatus === 'prequalified') {
      onContinue(payloadData)
    } else if (applicationStatus === 'prequalified_account_exists') {
      history.push(PRE_QUALIFICATION_ACCOUNT_EXISTS_URL)
    } else if (applicationStatus === 'pending_approval') {
      // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
      //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."

      history.push({
        pathname: ACCESS_LOAN_URL,
        state: {
          lenderNextStepUrl,
          modalType: 'prequal',
        },
      })
    } else {
      if (applicationServiceProviderPriority.productType === 'installment') {
        history.push(PRE_QUALIFICATION_DECLINED_URL)
        //prequalification declined for both
      } else {
        history.push(PRE_QUALIFICATION_DECLINED_ADS_ONLY_URL)
        //prequalification declined for Bread only
        //DeclinedPage
      }
      return
    }
  }

  const handleChangeLocation = () => {
    history.push(CREDIT_PORTAL_URL)
  }

  return (
    <div className="application-instructions">
      <div className="info-header">
        <p>
          Find out now if you <span>pre-qualify</span>* without impacting your
          credit.
        </p>
        <p>
          It's quick, easy, and secure with instant results, including your
          credit limit.
        </p>
      </div>
      <ul className="info-content">
        {practice &&
          practice.name &&
          practice.locationId &&
          !window.location.host.includes('iframe') && (
            <li className="info">
              You've selected practice <b>{practice.name}</b>.
              {allowChangePractice && (
                <>
                  If this is incorrect,{' '}
                  <a
                    onClick={handleChangeLocation}
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      cursor: 'pointer',
                    }}
                  >
                    click here
                  </a>{' '}
                  to find a different practice.
                </>
              )}
            </li>
          )}
        <li>
          If your credit is locked or frozen, you'll need to remove the freeze
          before applying.
          <Tooltip
            placement="left"
            title={
              <p style={{ fontSize: '14px', color: 'white' }}>
                If you placed a freeze on your credit file, please contact each
                credit reporting agency directly to remove the credit freeze
                before going through the apply process.
              </p>
            }
          >
            <IconButton className="icon-button">
              <InfoIcon style={{ color: blue[800] }} />
            </IconButton>
          </Tooltip>
        </li>
        {showFindApplication && (
          <li>
            If you have already started an application and would like to
            complete it, please{' '}
            <Link to={APPLICATION_FINDER_URL}>click here</Link>.
          </li>
        )}
      </ul>

      <PrefilledPersonalInfoForm
        data={personalInfo}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={flashSubmitFailure}
        practice={practice}
      />
      <ServiceProviderDisclosures />
    </div>
  )
}

PersonalInfo.propTypes = propTypes

function mapStateToProps(state) {
  return {
    applicationEnvironment: selectors.applicationEnvironment(state),
    practice: selectors.practice(state),
    personalInfo: selectors.personalInfo(state),
    creditApplication: selectors.creditApplication(state),
  }
}

const mapDispatchToProps = {
  requestPrequalification: apiActions.requestPrequalification,
  setPersonalInfo: actions.setPersonalInfo,
  setLenderReferenceId: actions.setLenderReferenceId,
  patchApplicant: apiActions.patchApplicant,
  prequalifyCreditApplication: apiActions.prequalifyCreditApplication,
  setPrequalification: actions.setPrequalification,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PersonalInfo
)
