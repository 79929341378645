import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Checkbox,
  SubmitButton,
  MaskedInput,
  Select,
  Input,
} from 'lp-components'
import { InputTip } from 'components'
import { verification_methods, STATES, RISK_MESSAGE } from 'config'
import InfoIcon from '@material-ui/icons/Info'
import { Tooltip, IconButton } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import {
  Field,
  propTypes as formPropTypes,
  formValues,
  FormSection,
} from 'redux-form'
import PropTypes from 'prop-types'
import { toAmount } from 'utils'
import { IdentificationConfirmationType } from 'types'

const propTypes = {
  validIdProvided: PropTypes.bool,
  identificationConfirmation: IdentificationConfirmationType,
  derogatoryInd: PropTypes.string,

  ...formPropTypes,
}

const defaultProps = {}

function PaymentDetailsForm({
  handleSubmit,
  validIdProvided,
  identificationConfirmation,
  derogatoryInd,
  internalRiskReason,
}) {
  const riskFlag =
    derogatoryInd === 'Y' &&
    internalRiskReason &&
    internalRiskReason != '' &&
    internalRiskReason.startsWith('RISKY')

  return (
    <div className="start-application__form  m-t-50 sales">
      <form noValidate onSubmit={handleSubmit}>
        <div className="checkbox-container">
          <Field name="validIdProvided" component={Checkbox} label={false} />
          Please confirm the customer’s identity with a valid photo ID: driver’s
          license, government issued state, federal, military ID or passport.
        </div>
        <div className="m-t-40">
          <FormSection name="identificationConfirmation">
            <Field
              label={'Select Customer’s Identity Verification Method'}
              name="type"
              component={Select}
              placeholder="Select Customer’s Identity Verification Method"
              options={verification_methods}
              required
              requiredIndicator=" *"
            />
            {identificationConfirmation &&
              (identificationConfirmation?.type === 'drivers-license' ||
                identificationConfirmation?.type === 'state-id') && (
                <div className="refund-amount data-of-birth">
                  <Field
                    label={'State'}
                    name="state"
                    component={Select}
                    placeholder="State"
                    options={STATES}
                    required
                    requiredIndicator=" *"
                  />
                </div>
              )}
          </FormSection>
        </div>

        <div>
          <div className="m-t-40 refund-request--header">
            Payment Information
          </div>
          <div className="one-line-form m-t-30">
            <Field
              component={InputTip}
              inputType={MaskedInput}
              name="amount"
              placeholder="Total Amount of Sale"
              required
              requiredIndicator=" *"
              maskOptions={{
                prefix: '$',
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }}
              parse={toAmount}
            />
            <Field
              name="referenceId"
              component={Input}
              placeholder="Reference ID"
              label="Reference ID (Optional)"
            />
          </div>
        </div>

        <div className="start-application__form--actions  m-b-50">
          <div className="submit">
            <SubmitButton
              disabled={!validIdProvided || riskFlag}
              className={`button-warn ${
                !validIdProvided || riskFlag ? `warn-disabled` : ``
              }`}
            >
              CONTINUE
              {riskFlag && (
                <Tooltip
                  placement="left"
                  title={
                    <p style={{ fontSize: '14px', color: 'white' }}>
                      {RISK_MESSAGE}
                    </p>
                  }
                >
                  <IconButton className="icon-button" style={{ width: '5%' }}>
                    <InfoIcon style={{ color: blue[800] }} />
                  </IconButton>
                </Tooltip>
              )}
            </SubmitButton>
          </div>
        </div>
      </form>
    </div>
  )
}

PaymentDetailsForm.propTypes = propTypes

PaymentDetailsForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'paymentDetailsForm',
    constraints: {
      amount: { presence: true },
      'identificationConfirmation.type': { presence: true },
      'identificationConfirmation.state': { presence: true },
      referenceId: { length: { maximum: 20 } },
    },
    submitFilters: { reject: ['validIdProvided'] },
  }),
  formValues('validIdProvided', 'identificationConfirmation'),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentDetailsForm)
