import React, { useState } from 'react'
import { Modal } from 'lp-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { PaymentDetailsType, PlansType, LocationType } from 'types'
import { flashSubmitFailure, formatCurrency, withApiAuth } from 'utils'
import PlanSelectionForm from '../sales/forms/PlanSelectionForm'
import { AccountNumber, Button } from 'components'
import * as merchantActions from '../actions'
import { LocationSelectorForm } from '../forms'
import { setLocation } from 'local-storage'
import { selectors as merchantSelectors } from '../reducer'

import * as apiActions from 'api-actions'
import { isNil } from 'lodash'

const propTypes = {
  currentLocation: LocationType,
  onCompleteSaleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  plan: PlansType.isRequired,
  paymentDetails: PaymentDetailsType.isRequired,
  handleSubmitSuccess: PropTypes.func,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  currentLocationIndex: PropTypes.number.isRequired,
  setCurrentLocationIndex: PropTypes.func.isRequired,
  fetchLocationDetails: PropTypes.func.isRequired,
}

function SalesConfirmationModal({
  handleSubmitSuccess,
  currentLocation,
  paymentDetails,
  currentLocationIndex,
  setCurrentLocationIndex,
  onCompleteSaleClick,
  handleClose,
  plan,
  allLocations,
  fetchLocationDetails,
}) {
  const { name, alphaeonAccountNumber, amount, buyer } = paymentDetails

  const [displayLocationSelector, setDisplayLocationSelector] = useState(false)
  const closeLocationSelector = () => setDisplayLocationSelector(false)

  return (
    <Modal
      className="fit-auto"
      onClose={() => {
        handleClose()
      }}
      hideCloseButton
    >
      <div className="sales-confirmation">
        <div className="sales-confirmation__container">
          <div className="title">Confirm Sale Transaction Details</div>
          <table className="details m-t-20">
            <tr>
              <td className="detail-header">Practice Name</td>
              <td>{currentLocation.name}</td>
            </tr>
            <tr>
              <td className="detail-header">ADS Location #</td>
              <td>{currentLocation.adsStoreNumber}</td>
              <td>
                {' '}
                <Button
                  onClick={() =>
                    setDisplayLocationSelector(!displayLocationSelector)
                  }
                  className="button-warn-outline"
                >
                  Change Location
                </Button>
              </td>
            </tr>
            <tr>
              <td className="detail-header">Customer Name</td>
              <td>
                {isNil(buyer)
                  ? `${name.firstName} ${name.lastName}`
                  : `${buyer.firstName} ${buyer.lastName}`}
              </td>
            </tr>
            {!isNil(buyer) && (
              <tr>
                <td className="detail-header">Responsible Party</td>
                <td>{`${name.firstName} ${name.lastName}`}</td>
              </tr>
            )}
            <tr>
              <td className="detail-header">Account Number</td>
              <td>
                <AccountNumber accountNumberString={alphaeonAccountNumber} />
              </td>
            </tr>
            <tr>
              <td className="detail-header">Transaction Amount</td>
              <td>{formatCurrency(amount, 2)}</td>
            </tr>
            <tr>
              <td className="detail-header">Plan Description</td>
              <td>{plan.name}</td>
            </tr>
          </table>

          <PlanSelectionForm
            onSubmit={() => onCompleteSaleClick(plan)}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitFail={flashSubmitFailure}
          />
        </div>
      </div>

      {displayLocationSelector && (
        <Modal onClose={closeLocationSelector}>
          <LocationSelectorForm
            allLocations={allLocations}
            initialValues={{ selectedLocationIndex: currentLocationIndex }}
            defaultValue={{
              label: currentLocation.name,
              value: currentLocationIndex,
            }}
            onSubmit={({ selectedLocationIndex }) => {
              closeLocationSelector()

              fetchLocationDetails(
                allLocations[selectedLocationIndex.value].locationId
              ).then((res) => setLocation(res))
              return setCurrentLocationIndex(selectedLocationIndex.value)
            }}
          />
        </Modal>
      )}
    </Modal>
  )
}

SalesConfirmationModal.propTypes = propTypes

function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
    allLocations: merchantSelectors.allLocations(state),
    currentLocationIndex: merchantSelectors.currentLocationIndex(state),
  }
}
const mapDispatchToProps = {
  setCurrentLocationIndex: merchantActions.setCurrentLocationIndex,
}

const mapApiAuthToProps = {
  fetchLocationDetails: apiActions.fetchLocationDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SalesConfirmationModal)
