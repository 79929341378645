import { isNil, compact } from 'lodash'
import checkForNull from './checkForNull'

export default function getFullAddress(address) {
  if (isNil(address)) return 'N/A'

  const { street1, street2, city, state, zip } = address

  const streetAddress = compact([
    checkForNull(street1),
    checkForNull(street2),
  ]).join(' ')
  return `${streetAddress}, ${city}, ${state}, ${zip}`
}
