import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import AlphaeonLogo from 'images/Alphaeon_Credit_Logo.png'
import LoginImage from 'images/PMP_Login_Image.png'
import { ApplicationFinderForm } from '../../forms'
import {
  APPLICATION_FOUND_URL,
  CREDIT_PORTAL_URL,
  NO_APPLICATIONS_URL,
} from 'config'
import * as apiActions from 'api-actions'

const propTypes = {
  getCreditApplicationsByStatus: PropTypes.func.isRequired,
}
const defaultProps = {}

function ApplicationFinder({ getCreditApplicationsByStatus }) {
  const history = useHistory()
  const handleSubmit = (formValues) => {
    return getCreditApplicationsByStatus(formValues, 'prequalified')
  }
  const handleSubmitSuccess = () => {
    history.push(APPLICATION_FOUND_URL)
  }
  const handleSubmitFailure = () => history.push(NO_APPLICATIONS_URL)

  return (
    <div className="application-finder-container">
      <div className="left-content-container">
        <img src={LoginImage} alt="Person holding an Alphaeon credit card" />
      </div>
      <div className="right-content-container">
        <header>
          <img className="logo" src={AlphaeonLogo} alt="Alphaeon logo" />
          <h1>Let's Find Your Application</h1>
        </header>
        <ApplicationFinderForm
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFailure}
        />
        <p>
          Haven't applied yet? <Link to={CREDIT_PORTAL_URL}>Apply</Link>
        </p>
      </div>
    </div>
  )
}

ApplicationFinder.propTypes = propTypes
ApplicationFinder.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  getCreditApplicationsByStatus: apiActions.getCreditApplicationsByStatus,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplicationFinder
)
