import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import { Spinner } from 'components'
import { useParams, useHistory } from 'react-router-dom'
import { PracticeType } from 'types'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { flashMessage } from 'redux-flash'
import { Wizard } from '.'

const propTypes = {
  searchForPractices: PropTypes.func.isRequired,
  practicesList: PracticeType,
  setPractice: PropTypes.func.isRequired,
  practiceSearchError: PropTypes.any,
  flashMessage: PropTypes.func,
  practice: PracticeType,
}
function StoreLookup({
  searchForPractices,
  practicesList,
  setPractice,
  flashMessage,
  practiceSearchError,
  practice,
}) {
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (id) {
      searchForPractices(id)
    }
  }, [])

  useEffect(() => {
    if (practicesList && practicesList.locationId) {
      setPractice(practicesList)
    }
  }, [practicesList])

  useEffect(() => {
    if (practiceSearchError) {
      flashMessage('Practice Not Found!', {
        isError: true,
      })

      history.push('/')
    }
  }, [practiceSearchError])

  if (practice) {
    return <Wizard />
  } else {
    return <Spinner />
  }
}

StoreLookup.propTypes = propTypes

function mapStateToProps(state) {
  return {
    practicesList: selectors.practicesList(state),
    practiceSearchError: selectors.practiceSearchError(state),
    practice: selectors.practice(state),
  }
}
const mapDispatchToProps = {
  searchForPractices: apiActions.searchForPractices,
  setPractice: actions.setPractice,
  flashMessage,
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  StoreLookup
)
