import React, { useEffect, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import CalculatorForm from '../forms/CalculatorForm'
import { isRevolving, withApiAuth } from 'utils'
import { LocationType, PlansType } from 'types'
import PromotionalPlanList from '../components/PromotionalPlanList'
import * as actions from '../sales/actions'
import { selectors } from '../sales/reducer'
import { selectors as merchantSelectors } from '../reducer'
import { isEmpty, partition } from 'lodash'

const propTypes = {
  clearPlans: PropTypes.func.isRequired,
  currentLocation: LocationType,
  fetchPlansByLocationId: PropTypes.func.isRequired,
  plans: PlansType,
}

const defaultProps = {}
function Calculator({
  clearPlans,
  currentLocation,
  fetchPlansByLocationId,
  plans,
}) {
  const handleSubmit = ({ amount }) => {
    return fetchPlansByLocationId(currentLocation.locationId, amount, true)
  }

  useEffect(() => {
    clearPlans()
  }, [])

  const sortedPlans = useMemo(() => {
    if (!plans) return []

    const [revolvingPlans, nonRevolvingPlans] = partition(
      plans.plans,
      ({ name }) => isRevolving(name)
    )
    return [...nonRevolvingPlans, ...revolvingPlans]
  }, [plans])

  return (
    <div className="calculator m-t-50">
      <h1>Payment Calculator</h1>
      <p className="calculator__sub-title">
        Use this tool to estimate payments
      </p>
      <CalculatorForm onSubmit={handleSubmit} />
      {!isEmpty(sortedPlans) && (
        <PromotionalPlanList
          isSaleTransaction={false}
          sortedPlans={sortedPlans}
        />
      )}
    </div>
  )
}

Calculator.propTypes = propTypes
Calculator.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
    plans: selectors.plans(state),
  }
}

const mapDispatchToProps = {
  clearPlans: actions.clearPlans,
}

const mapApiAuthToProps = {
  fetchPlansByLocationId: apiActions.fetchPlansByLocationId,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Calculator)
