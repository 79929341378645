import React from 'react'
import Routes from './Routes'
import history from 'browser-history'
import store from 'store'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  makePath,
  PATH,
  REACT_APP_ALPHAEON_MERCHANT_MUX_API,
  REACT_APP_AUTH0_ADMIN_CLIENTID,
  REACT_APP_AUTH0_DOMAIN,
  APPLICATION_ROOT_URL,
} from 'config'

function App() {
  return (
    <Auth0Provider
      audience={REACT_APP_ALPHAEON_MERCHANT_MUX_API}
      clientId={REACT_APP_AUTH0_ADMIN_CLIENTID}
      domain={REACT_APP_AUTH0_DOMAIN}
      redirectUri={makePath(APPLICATION_ROOT_URL, PATH.ADMIN)}
      onRedirectCallback={onRedirectCallback}
      leeway={3600}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    </Auth0Provider>
  )
}

export default App

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname, appState)
}
