import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Button } from 'components'
import { AuthorizationTooltip } from 'merchant-portal-components'
import { selectors as merchantSelectors } from '../reducer'
import { PermissionsType } from 'types'
import { operationNotPermitted } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  disabledToolTip: PropTypes.string,
  currentPermissions: PermissionsType.isRequired,
  invalid: PropTypes.bool,
  requiredPermission: PropTypes.string.isRequired,
}
const defaultProps = {
  invalid: false,
}

function AuthorizedButton({
  children,
  disabledToolTip,
  currentPermissions,
  invalid,
  requiredPermission,
  ...rest
}) {
  const unauthorized =
    operationNotPermitted(currentPermissions, requiredPermission) || invalid
  const message = invalid && disabledToolTip ? disabledToolTip : null

  return (
    <AuthorizationTooltip displayed={unauthorized} message={message}>
      <Button invalid={unauthorized} {...rest}>
        {children}
      </Button>
    </AuthorizationTooltip>
  )
}

AuthorizedButton.propTypes = propTypes
AuthorizedButton.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPermissions: merchantSelectors.currentPermissions(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthorizedButton
)
