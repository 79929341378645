import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import exact from 'prop-types-exact'

import { Button } from 'lp-components'
import { AccountNumber } from 'components'
import * as apiActions from 'api-actions'
import {
  REFUNDABLE_STATES,
  PERMISSION,
  TRANSACTION_TYPE,
  VOIDABLE_STATES,
} from 'config'
import { TransactionDisplayType, AccountDetailType } from 'types'
import {
  formatCurrency,
  formatISODateStringAsUSMonthDayYear,
  titleCase,
  withApiAuth,
} from 'utils'
import { flashErrorMessage } from 'redux-flash'
import AuthorizedButton from './AuthorizedButton'
import { isNil } from 'lodash'

const propTypes = {
  accountDetail: AccountDetailType.isRequired,
  flashErrorMessage: PropTypes.func,
  getSalesReceipt: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
  transaction: TransactionDisplayType.isRequired,
  voidTransaction: PropTypes.func.isRequired,
}

const defaultProps = {}

function TransactionCard({
  accountDetail,
  flashErrorMessage,
  getSalesReceipt,
  transaction,
  refundTransaction,
  voidTransaction,
}) {
  const [loading, setLoading] = useState(false)
  const { alphaeonAccountNumber, creditLimit, name, authorizedBuyers } =
    accountDetail

  const {
    amount,
    createdAt,
    locationName,
    planIdentifier,
    planName,
    status,
    transactionId,
    transactionType,
    refundedTransactionId,
    accountMemberId,
  } = transaction
  const { operationLabel, operation, requiredPermission } =
    operationFromTransaction(
      status,
      transactionType,
      refundTransaction,
      voidTransaction
    )
  const isLoanTransaction = transactionType.includes('loan')

  const handlePrint = (transactionId) => {
    setLoading(true)
    getSalesReceipt(transactionId)
      .then((pdfWindowURL) => {
        const pdfWindow = window.open(pdfWindowURL)
        pdfWindow.print()
      })
      .catch(() => {
        flashErrorMessage('PDF download and print failed')
      })
      .finally(() => setLoading(false))
  }

  const transactionAuthBuyer = !isNil(authorizedBuyers)
    ? authorizedBuyers.find(
        (authBuyer) => authBuyer.accountMemberId === accountMemberId
      )
    : null

  return (
    <div className="summary__card card">
      <div className="card-content">
        <div className="summary__account-info">
          <div className="summary__account-info--header">
            <div>
              <div className="card-title">
                {!isNil(transactionAuthBuyer)
                  ? `${transactionAuthBuyer.firstName} ${transactionAuthBuyer.lastName} `
                  : `${name.firstName} ${name.lastName} `}
              </div>
              <div className="info-container sub-title">
                <p className="card-area">Account Number: </p>
                <AccountNumber accountNumberString={alphaeonAccountNumber} />
              </div>
            </div>
            <div className="header-actions">
              {status !== 'failed' && !isLoanTransaction && (
                <Button
                  className="button-primary button-warn print-receipt-button"
                  disabled={loading}
                  submitting={loading}
                  onClick={() => {
                    handlePrint(transactionId)
                  }}
                >
                  PRINT RECEIPT
                </Button>
              )}

              {operation && (
                <>
                  <div>
                    <AuthorizedButton
                      className="button-warn-outline sale"
                      onClick={() =>
                        operation({
                          alphaeonAccountNumber,
                          creditLimit,
                          name,
                          transaction,
                        })
                      }
                      requiredPermission={requiredPermission}
                    >
                      {operationLabel}
                    </AuthorizedButton>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="card-main">
            <div className="card-main__left">
              <div className="info-container ">
                <p className="card-area">
                  <strong>Location</strong> {locationName}
                </p>
              </div>
              <div className="info-container">
                <p className="card-area">
                  <strong>Date</strong>{' '}
                  {formatISODateStringAsUSMonthDayYear(createdAt)}
                </p>
              </div>

              {isLoanTransaction && (
                <div className="info-container">
                  <p className="card-area">
                    <strong>Plan Name</strong> {planName}
                  </p>
                </div>
              )}
              {!isLoanTransaction && (
                <div className="info-container">
                  <p className="card-area">
                    <strong>Plan Number</strong> {planIdentifier}
                  </p>
                </div>
              )}
              <div className="info-container">
                <p className="card-area">
                  <strong>Transaction ID</strong> {transactionId}
                </p>
              </div>
              {refundedTransactionId && (
                <div className="info-container">
                  <p className="card-area">
                    <strong>Refunded Transaction ID</strong>{' '}
                    {refundedTransactionId}
                  </p>
                </div>
              )}

              <div className="info-container">
                <p className="card-area"></p>
              </div>
            </div>
            <div className="card-main__right">
              <div className="info-container">
                <p className="card-area">
                  <strong>Transaction Type</strong>{' '}
                  <span className="capitalize">{status} </span>
                  <span className="capitalize">
                    {titleCase(transactionType)}
                  </span>
                </p>
              </div>
              {!isLoanTransaction && (
                <div className="info-container">
                  <p className="card-area">
                    <strong>Plan</strong> {planName}
                  </p>
                </div>
              )}

              <div className="info-container">
                <p className="card-area">
                  <strong>Amount</strong> {formatCurrency(amount, 2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TransactionCard.propTypes = exact(propTypes)
TransactionCard.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashErrorMessage: flashErrorMessage,
}

const mapApiAuthToProps = {
  getSalesReceipt: apiActions.getSalesReceipt,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(TransactionCard)

function operationFromTransaction(
  status,
  transactionType,
  refundTransaction,
  voidTransaction
) {
  if (VOIDABLE_STATES.includes(status)) {
    return {
      operationLabel: 'VOID',
      operation: voidTransaction,
      requiredPermission: PERMISSION.PROCESS_VOID,
    }
  }

  if (REFUNDABLE_STATES.includes(status)) {
    // Only sales can be refunded; refunds cannot be refunded!
    if (transactionType === TRANSACTION_TYPE.SALE) {
      return {
        operationLabel: 'REFUND',
        operation: refundTransaction,
        requiredPermission: PERMISSION.PROCESS_REFUND,
      }
    }
  }

  // The transaction is in a state that cannot be operated on...
  return { operationLabel: null, operation: null, requiredPermission: null }
}
