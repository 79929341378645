/* eslint-disable react/display-name */
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { AccountCard } from 'merchant-portal/account-lookup-components'
import * as apiActions from 'api-actions'
import { SubmitButton } from 'lp-components'
import { Spinner } from 'components'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_LOOKUP_URL, ACCOUNT_DETAIL_URL, PATH } from 'config'
import { AccountsList } from 'types'
import { withApiAuth } from 'utils'

const propTypes = {
  accountsList: AccountsList,
}

const defaultProps = {}

function AccountLookUpResult({ accountsList }) {
  const history = useHistory()

  if (!accountsList) {
    history.push(PATH.MERCHANT_PORTAL)
  }

  const accountListLength = accountsList?.accounts?.length ?? 0

  return (
    <div
      className="account-lookup w-80"
      style={{ margin: '0 auto', marginTop: '30px' }}
    >
      <h1>Account Lookup Results</h1>

      {!accountsList ? (
        <Spinner />
      ) : (
        <>
          {accountListLength === 0 && (
            <div className="account-lookup__empty-state">No Results Found</div>
          )}
          {accountListLength > 0 && (
            <>
              <div className="account-lookup__info">
                <div>
                  We found {accountListLength} result
                  {accountListLength > 1 && 's'}
                </div>
              </div>
              <div className="account-lookup-result">
                {accountsList?.accounts?.map((account, index) => (
                  <AccountCard
                    key={index}
                    account={account}
                    onAccountSelection={() =>
                      history.push(
                        `${ACCOUNT_DETAIL_URL}${account.alphaeonAccountNumber}`
                      )
                    }
                  />
                ))}
              </div>
            </>
          )}
          <div className="account-lookup__submit">
            <SubmitButton
              className={`button-warn-outline`}
              onClick={() => {
                history.push(ACCOUNT_LOOKUP_URL)
              }}
            >
              Search For A New Account
            </SubmitButton>
          </div>
        </>
      )}
    </div>
  )
}

AccountLookUpResult.propTypes = propTypes

AccountLookUpResult.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    accountsList: selectors.accountsList(state),
  }
}
const mapDispatchToProps = {}

const mapApiAuthToProps = {
  searchForAccounts: apiActions.searchForAccounts,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AccountLookUpResult)
