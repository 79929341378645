import React, { useEffect, useState, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, isNil } from 'lodash'
import { Pagination, Spinner } from 'components'
import {
  AuthorizedLink,
  AuthorizedLinkButton,
  AuthorizedExternalLinkButton,
  TransactionTable,
} from 'merchant-portal-components'
import {
  ApplicationReportType,
  LocationType,
  SalesReportType,
  PermissionsType,
} from 'types'
import { selectors } from '../reducer'
import {
  ACCOUNT_DETAIL_URL,
  DASHBOARD_ACTIONS,
  PERMISSION,
  REPORTS_START_OFFSET_DAYS,
  SALES_URL,
  TRANSACTION_FILTERS,
  PRODUCT_TYPE_MAP,
  PAGINATION_PAGE_SIZE,
  PST_TIMEZONE,
  TRANSACTION_TYPE,
} from 'config'
import { Table, TableColumn as Column } from 'lp-components'
import { ActivityFilterForm } from '../forms'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import { format, subDays, isAfter, endOfDay } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import {
  formatCurrency,
  withApiAuth,
  formatISODateStringAsUSMonthDayYear,
  checkIfESTTimeisPast10Pm,
  operationPermitted,
  mapTransactionTypeToProductType,
  getFullName,
  formatProductType,
} from 'utils'
import { formValueSelector } from 'redux-form'

const propTypes = {
  currentLocation: LocationType,
  currentPermissions: PermissionsType.isRequired,
  getSalesReport: PropTypes.func.isRequired,
  applicationStatus: PropTypes.array,
  salesReports: SalesReportType,
  applicationReports: ApplicationReportType,
  getApplicationsReport: PropTypes.func.isRequired,
  searchField: PropTypes.string,
}

const defaultProps = {}
function Home({
  currentPermissions,
  currentLocation,
  getSalesReport,
  salesReports,
  applicationReports,
  getApplicationsReport,
  applicationStatus,
  searchField,
}) {
  const locationState = currentLocation?.address?.state
  const pushApplicationEnabled = currentLocation?.pushApplicationEnabled
  const isActivityTableAccessible = operationPermitted(
    currentPermissions,
    PERMISSION.APPLICATION_ACTIVITY_TABLE
  )
  const [displayedApplicationData, setDisplayedApplicationData] = useState([])
  const [displayedNextDayTransactions, setDisplayedNextDayTransactions] =
    useState([])
  const [displayedPendingTransactions, setDisplayedPendingTransactions] =
    useState([])

  const todaysDate = new Date()
  const startDate = subDays(todaysDate, REPORTS_START_OFFSET_DAYS)
  let endDate = new Date()
  endDate.setDate(todaysDate.getDate() + 1)

  const shouldShowNextDayPendingAccessLoanTransaction = (transaction) => {
    const timestampToCareAbout =
      transaction.transactionType === TRANSACTION_TYPE.LOAN
        ? transaction?.installmentServiceDate
        : transaction?.effectiveAt
    return (
      checkIfESTTimeisPast10Pm(timestampToCareAbout) &&
      transaction.status === 'approved'
    )
  }

  const accessLoanTransactions = useMemo(() => {
    return salesReports?.transactions
      .filter((transaction) => {
        const productType = mapTransactionTypeToProductType(
          transaction.transactionType
        )
        if (productType === PRODUCT_TYPE_MAP.installment) {
          return transaction
        }
      })
      .sort(
        (a, b) =>
          new Date(b.installmentServiceDate) -
          new Date(a.installmentServiceDate)
      )
  }, [salesReports])

  const lineOfCreditTransactions = useMemo(() => {
    return salesReports?.transactions
      .filter((transaction) => {
        const productType = mapTransactionTypeToProductType(
          transaction.transactionType
        )
        if (productType === PRODUCT_TYPE_MAP.line_of_credit) {
          return transaction
        }
      })
      .sort((a, b) => new Date(b.effectiveAt) - new Date(a.effectiveAt))
  }, [salesReports])

  const nextDayTransactions = useMemo(() => {
    const mergedTransaction = [].concat(
      lineOfCreditTransactions?.filter((transaction) => {
        return (
          checkIfESTTimeisPast10Pm(transaction?.effectiveAt) &&
          transaction.status === 'approved'
        )
      }),
      accessLoanTransactions?.filter((transaction) => {
        return shouldShowNextDayPendingAccessLoanTransaction(transaction)
      })
    )
    return mergedTransaction
  }, [accessLoanTransactions, lineOfCreditTransactions])

  const scheduledPendingTransaction = useMemo(() => {
    const lineOfCreditTransactions = salesReports?.transactions.filter(
      (transaction) =>
        PRODUCT_TYPE_MAP.line_of_credit ===
        mapTransactionTypeToProductType(transaction.transactionType)
    )
    const accessLoanTransactions = salesReports?.transactions.filter(
      (transaction) =>
        PRODUCT_TYPE_MAP.installment ===
        mapTransactionTypeToProductType(transaction.transactionType)
    )

    const mergedTransaction = [].concat(
      lineOfCreditTransactions,
      accessLoanTransactions
    )

    return mergedTransaction.filter(
      (transaction) =>
        isAfter(
          new Date(transaction?.effectiveAt),
          endOfDay(utcToZonedTime(todaysDate.toISOString(), PST_TIMEZONE))
        ) && transaction.status === 'approved'
    )
  }, [salesReports])

  const applications = useMemo(() => {
    if (isEmpty(applicationStatus)) return []

    const filteredData = applicationStatus.includes('All')
      ? applicationReports?.applications
      : applicationReports?.applications.filter((application) =>
          applicationStatus.includes(application.applicationStatus)
        )

    return filteredData?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )
  }, [applicationStatus, applicationReports])

  const fetchApplications = (values = {}) => {
    const reportCriteria = {
      startDate: format(values.startDate || startDate, 'yyyy-MM-dd'),
      endDate: format(values.endDate || endDate, 'yyyy-MM-dd'),
    }
    getApplicationsReport({
      ...reportCriteria,
      location_ids: [currentLocation?.locationId],
    })
  }

  const fetchSalesReport = (values = {}) => {
    const reportCriteria = {
      startDate: formatInTimeZone(
        values.startDate || startDate,
        PST_TIMEZONE,
        'yyyy-MM-dd'
      ),
      endDate: formatInTimeZone(
        values.endDate || endDate,
        PST_TIMEZONE,
        'yyyy-MM-dd'
      ),
    }
    getSalesReport({
      ...reportCriteria,
      location_ids: [currentLocation?.locationId],
    })
  }

  useEffect(() => {
    fetchSalesReport()
    fetchApplications()
  }, [currentLocation])

  const handleActivityFilter = (values) => {
    const reportCriteria = {
      startDate: format(values.startDate || startDate, 'yyyy-MM-dd'),
      endDate: format(values.endDate || endDate, 'yyyy-MM-dd'),
    }
    getApplicationsReport({
      ...reportCriteria,
      location_ids: [currentLocation.locationId],
    })
  }

  const handleNextDayTransactionPageChange = (paginatedData) => {
    setDisplayedNextDayTransactions(paginatedData)
  }

  const handlePendingTransactionPageChange = (paginatedData) => {
    setDisplayedPendingTransactions(paginatedData)
  }

  const handleFilters = (filterCriteria, data) => {
    const { filterOption, searchField } = filterCriteria

    if (!searchField) {
      return data
    }

    const filteredData = data.filter((transaction) => {
      if (filterOption === TRANSACTION_FILTERS.CUSTOMER_NAME) {
        const fullName = getFullName(transaction.name).toLowerCase()
        return fullName.includes(searchField.toLowerCase())
      } else if (filterOption === TRANSACTION_FILTERS.ACCOUNT_NUMBER) {
        return transaction.alphaeonAccountNumber.includes(searchField)
      }
    })
    return filteredData
  }

  const handleNextTransactionFilter = (values) => {
    const data = handleFilters(values, nextDayTransactions)
    setDisplayedNextDayTransactions(data)
  }

  const handlePendingTransactionFilter = (values) => {
    const data = handleFilters(values, scheduledPendingTransaction)
    setDisplayedPendingTransactions(data)
  }

  const handleApplicationReportChange = (paginatedData) => {
    setDisplayedApplicationData(paginatedData)
  }
  const max = searchField
    ? isNil(displayedApplicationData)
      ? 1
      : Math.ceil(displayedApplicationData.length / PAGINATION_PAGE_SIZE)
    : null

  return (
    <>
      <div className="merchant dashboard">
        <div className="dashboard--header">Welcome to your Dashboard</div>
        <div className="dashboard--announcement"></div>
        <div className="dashboard__actions">
          {DASHBOARD_ACTIONS.map((action) => {
            if (
              action.id === 'apply' &&
              locationState === 'CA' &&
              pushApplicationEnabled !== true
            )
              return false
            return (
              <div className="dashboard__actions--item" key={action.id}>
                <div className="action-content">
                  {<action.icon />}
                  <div className="action-content--title">{action.title}</div>
                  <div className="action-content--description">
                    {action.description}
                  </div>
                  {action.externalLink ? (
                    <AuthorizedExternalLinkButton
                      href={action.link}
                      className="button-primary button-warn-outline"
                      requiredPermission={action.requiredPermission}
                    >
                      {action.title}
                    </AuthorizedExternalLinkButton>
                  ) : (
                    <AuthorizedLinkButton
                      to={action.link}
                      className="button-primary button-warn-outline"
                      requiredPermission={action.requiredPermission}
                    >
                      {action.title}
                    </AuthorizedLinkButton>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className="dashboard__transactions m-t-50">
          <div className="dashboard__transactions--title">
            Next Day Pending Transactions
          </div>

          <div className="dashboard__activities--table m-t-30 m-b-30">
            <TransactionTable
              formKey={'next-day-transactions'}
              displayedTransactions={displayedNextDayTransactions}
              salesReports={salesReports}
              initialValues={{
                filterOption: TRANSACTION_FILTERS.CUSTOMER_NAME,
              }}
              allTransactions={nextDayTransactions}
              handlePageChange={handleNextDayTransactionPageChange}
              handleFilters={handleNextTransactionFilter}
            />
          </div>
        </div>

        <div className="dashboard__transactions m-t-50">
          <div className="dashboard__transactions--title">
            Scheduled Pending Transactions
          </div>
          <div className="dashboard__activities--table m-t-30 m-b-30">
            <TransactionTable
              formKey={'scheduled-transactions'}
              displayedTransactions={displayedPendingTransactions}
              initialValues={{
                filterOption: TRANSACTION_FILTERS.ACCOUNT_NUMBER,
              }}
              salesReports={salesReports}
              allTransactions={scheduledPendingTransaction}
              handlePageChange={handlePendingTransactionPageChange}
              handleFilters={handlePendingTransactionFilter}
            />
          </div>
        </div>

        {isActivityTableAccessible && (
          <div className="dashboard__activities m-t-50 m-b-30">
            <div className="dashboard__activities--title">
              Application Activity (last {REPORTS_START_OFFSET_DAYS} days)
            </div>
            <div className="dashboard__activities--table m-t-30 ">
              <ActivityFilterForm
                onSubmit={handleActivityFilter}
                initialValues={{
                  applicationStatus: ['All'],
                  startDate: startDate,
                  endDate: endDate,
                }}
              />

              {!applicationReports ? (
                <Spinner />
              ) : (
                <>
                  {applications.length > 0 && applicationStatus ? (
                    <>
                      <Table data={displayedApplicationData}>
                        <Column name="customerName" label="Customer" />
                        <Column
                          name="productType"
                          label="Product Type"
                          valueGetter={({ productType }) =>
                            formatProductType(productType)
                          }
                        />
                        <Column
                          name="creditLimit"
                          label="Amount"
                          disabled
                          valueGetter={({ creditLimit }) =>
                            formatCurrency(creditLimit, 2)
                          }
                        />

                        <Column
                          name="createdAt"
                          label="App Date"
                          format={(createdAt) =>
                            formatISODateStringAsUSMonthDayYear(createdAt)
                          }
                        />
                        <Column
                          name="applicationStatus"
                          label="Status"
                          component={StatusHighlightCell}
                        />
                        <Column
                          name="action"
                          label="Action"
                          component={ActivityActionCell}
                        />
                      </Table>
                      <div className="m-b-20">
                        <Pagination
                          handlePageChange={handleApplicationReportChange}
                          data={applications}
                          maxPageNumber={max}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="empty-state-container">
                      <p>No Applications</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const ActivityActionCell = ({ data }) => {
  return (
    <td className="actions">
      {data.applicationStatus !== 'Declined' && data.accountNumber ? (
        <>
          <AuthorizedLink
            to={`${ACCOUNT_DETAIL_URL}${data.accountNumber}`}
            requiredPermission={PERMISSION.ACCOUNT_LOOKUP}
          >
            VIEW
          </AuthorizedLink>
          {formatProductType(data.productType) ===
            PRODUCT_TYPE_MAP.line_of_credit && (
            <AuthorizedLink
              to={{
                pathname: `${SALES_URL}/${data.accountNumber}`,
                state: {
                  alphaeonAccountNumber: data.alphaeonAccountNumber,
                  availableCreditLimit: data.amount,
                  address: {},
                  name: data.name,
                },
              }}
              requiredPermission={PERMISSION.PROCESS_SALE}
            >
              PROCESS SALE
            </AuthorizedLink>
          )}
        </>
      ) : (
        '-'
      )}
    </td>
  )
}

const StatusHighlightCell = ({ value }) => {
  return (
    <td
      style={{
        color:
          value === 'Declined'
            ? 'red'
            : value === 'Approved'
            ? 'green'
            : value === 'Pending'
            ? 'orange'
            : '',
        textTransform: 'uppercase',
      }}
    >
      {value}
    </td>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

const selector = formValueSelector('activityFilterForm')

function mapStateToProps(state) {
  return {
    applicationStatus: selector(state, 'applicationStatus'),
    searchField: selector(state, 'searchField'),
    applicationReports: selectors.applicationReports(state),
    currentLocation: selectors.currentLocation(state),
    currentPermissions: selectors.currentPermissions(state),
    salesReports: selectors.salesReports(state),
  }
}
const mapDispatchToProps = {}

const mapApiAuthToProps = {
  getApplicationsReport: apiActions.getApplicationsReport,
  getSalesReport: apiActions.getSalesReport,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Home)
