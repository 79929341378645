import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import AccountLookupForm from '../forms/AccountLookupForm'
import * as apiActions from 'api-actions'
import { Spinner } from 'components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_LOOK_UP_RESULT_URL } from 'config'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors } from '../../reducer'
import { LocationType } from 'types'
import { flashSubmitFailure, withApiAuth } from 'utils'

const propTypes = {
  currentLocation: LocationType.isRequired,
  loading: PropTypes.bool.isRequired,
  searchForAccounts: PropTypes.func,
}

const defaultProps = {}

function AccountLookup({ currentLocation, loading, searchForAccounts }) {
  const history = useHistory()

  const handleSubmit = (data) => {
    return searchForAccounts({
      ...data,
      locationId: currentLocation.locationId,
    })
  }

  const handleSubmitSuccess = () => {
    history.push(ACCOUNT_LOOK_UP_RESULT_URL)
  }

  return (
    <div className="account-lookup">
      <h1>Account Lookup</h1>
      <p className="account-lookup__sub-title">
        Select how you would like to search for an account
      </p>

      <AccountLookupForm
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={flashSubmitFailure}
      />

      {loading && <Spinner />}
    </div>
  )
}

AccountLookup.propTypes = propTypes

AccountLookup.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentLocation: selectors.currentLocation(state),
    loading: apiSelectors.isLoading(state, 'ACCOUNT_DATA'),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  searchForAccounts: apiActions.searchForAccounts,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AccountLookup)
