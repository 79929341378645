import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ErrorIcon from '@material-ui/icons/Error'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'lp-components'
import { ERROR_STATUS, QUERY_KEY, makeApplicationURL } from 'config'
import { parseQuery } from 'utils'

const propTypes = {}
const defaultProps = {}

function ClientError() {
  const location = useLocation()
  const { search } = location
  const query = parseQuery(search)
  const application = query[QUERY_KEY.APPLICATION]
  let ErrorReportingComponent

  switch (query[QUERY_KEY.STATUS]) {
    case ERROR_STATUS.API_DOWN:
      ErrorReportingComponent = ApiDownError
      break

    case ERROR_STATUS.FORBIDDEN:
    case ERROR_STATUS.UNAUTHORIZED:
      ErrorReportingComponent = AuthorizationError
      break

    case ERROR_STATUS.NOT_FOUND:
      ErrorReportingComponent = NotFoundError
      break

    default:
      throw new Error('Unknown client error')
  }

  return (
    <div className="page-container centered-container">
      <div className="error-page-container">
        <ErrorIcon style={{ fontSize: 100 }} color="disabled" />
        <ErrorReportingComponent app={application} />
      </div>
    </div>
  )
}

ClientError.propTypes = propTypes
ClientError.defaultProps = defaultProps

export default React.memo(ClientError)

function ApiDownError() {
  const history = useHistory()

  return (
    <>
      <h4 className="error-page-title">Network Unavailable</h4>
      <p className="error-page-paragraph">
        The network is unavailable. Please retry your request again.
      </p>
      <Button className="button-warn-outline" onClick={() => history.goBack()}>
        Retry Request
      </Button>
    </>
  )
}

// Authorization errors will be handled only for applications that require
// login...
function AuthorizationError({ app }) {
  const { logout } = useAuth0()

  return (
    <>
      <h4 className="error-page-title">Unauthorized Request</h4>
      <p className="error-page-paragraph">
        You are unauthorized. Please re-login and try your request again.
      </p>
      <Button
        className="button-warn-outline"
        onClick={() => logout({ returnTo: makeApplicationURL(app) })}
      >
        Logout and Retry
      </Button>
    </>
  )
}

function NotFoundError() {
  const history = useHistory()

  return (
    <>
      <h4 className="error-page-title">Unknown Page Error</h4>
      <p className="error-page-paragraph">
        Oops there appears to be a problem. The page you requested doesn’t
        exist. If you believe this is an error please contact us.
      </p>
      <Button className="button-warn-outline" onClick={() => history.goBack()}>
        Go Back
      </Button>
    </>
  )
}
