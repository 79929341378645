import React from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import { SiteInitializationLayout } from 'merchant-portal-components'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <SiteInitializationLayout>
      <Switch>
        <Route path={makePath(path, PATH.NO_MANAGER_ACCESS)}>
          <Views.NoManagerAccess />
        </Route>
        <Route path={makePath(path, PATH.CONTACT_US)}>
          <Views.SiteInitializationContactUs />
        </Route>
      </Switch>
    </SiteInitializationLayout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
