import React from 'react'
import exact from 'prop-types-exact'
import { URL } from 'config'
import ExternalLink from '../../../../components/ExternalLink'

const propTypes = {}

const defaultProps = {}

function WizardFooter() {
  return (
    <footer>
      <div className="footer-container">
        <ExternalLink href={URL.PATIENT_SUPPORT}>
          <span>PATIENT SUPPORT</span>
        </ExternalLink>
      </div>
      <div className="copyright">
        Copyright @ {new Date().getFullYear()} Alphaeon Credit. All rights
        reserved.
      </div>
    </footer>
  )
}

WizardFooter.propTypes = exact(propTypes)
WizardFooter.defaultProps = defaultProps

export default React.memo(WizardFooter)
