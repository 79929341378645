import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
const defaultProps = {
  className: 'link-primary',
}

// A button that is styled to appear as a link...
function ButtonLink({ children, className, disabled, ...rest }) {
  return (
    <button className={className} {...rest} disabled={disabled}>
      {children}
    </button>
  )
}

ButtonLink.propTypes = propTypes
ButtonLink.defaultProps = defaultProps

export default React.memo(ButtonLink)
