import React, { useEffect } from 'react'
import exact from 'prop-types-exact'

import { Card as PracticeCard, PracticeSearchInput } from '../components'
import { WIZARD_URL } from 'config'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions'
import PropTypes from 'prop-types'
import { selectors } from '../reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import { PracticeType } from 'types'

const propTypes = {
  setPractice: PropTypes.func.isRequired,
  practicesList: PracticeType,
  loading: PropTypes.bool,
  setPracticesList: PropTypes.func.isRequired,
}

const defaultProps = {}

function Practice({ setPractice, practicesList, loading, setPracticesList }) {
  const history = useHistory()

  const handlePracticeSelection = (practice) => {
    setPractice(practice)
    history.push(WIZARD_URL)
  }
  useEffect(() => {
    return () => {
      setPracticesList(null)
    }
  }, [])

  return (
    <>
      <div className="practice-content">
        <p className="practice">Select Your Practice</p>
        <PracticeSearchInput
          practicesList={practicesList}
          searchPromptText="SEARCH AGAIN"
          withBackButton={true}
        />

        <div className="practice-result">
          {!loading && (
            <>
              {practicesList ? (
                <PracticeCard
                  practice={practicesList}
                  onPracticeSelection={handlePracticeSelection}
                />
              ) : (
                <p className="found-result">We found 0 results.</p>
              )}

              <p className="cannot-found">Can't find your provider?</p>
              <p className="cannot-found-content">
                Alphaeon Credit will happily contact your doctor about enrolling
                in the program.
              </p>
              <a href="#" className="doctor-refer">
                Refer My Doctor
              </a>
            </>
          )}
        </div>
      </div>
    </>
  )
}

Practice.propTypes = exact(propTypes)
Practice.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    practicesList: selectors.practicesList(state),
    loading: apiSelectors.isLoading(state, 'PRACTICE_DATA'),
  }
}
const mapDispatchToProps = {
  setPractice: actions.setPractice,
  setPracticesList: actions.setPracticesList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Practice)
