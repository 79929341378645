import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { ButtonLink } from 'components'

const propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  selectAllLocation: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
}

const ApplicationActionCell = ({
  data,
  setData,
  selectAllLocation,
  allSelected,
}) => {
  const [checked, setChecked] = useState(data.checked)
  const { locationName } = data

  const handleChange = () => {
    if (locationName === 'SUMMARY') {
      selectAllLocation(!allSelected)
      return null
    }
    const val = !checked
    setChecked(val)
    setData({ locationName: locationName, checked: val })
  }
  return (
    <td
      className={`actions ${locationName === 'SUMMARY' ? 'select-all-td' : ''}`}
      style={{ justifyContent: 'center' }}
    >
      {locationName === 'SUMMARY' ? (
        <ButtonLink onClick={handleChange} className="select-all button-link">
          {allSelected ? 'Deselect All' : 'Select All'}
        </ButtonLink>
      ) : (
        <input type="checkbox" checked={checked} onChange={handleChange} />
      )}
    </td>
  )
}
ApplicationActionCell.propTypes = propTypes

export default memo(ApplicationActionCell)
