import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Input, CheckboxGroup } from 'lp-components'
import { USER_ACTIVE_STATUS } from 'config'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}
function UserFilterForm({ onSubmit }) {
  return (
    <div className="start-application__form">
      <form noValidate onSubmit={onSubmit}>
        <div className="activity-filter">
          <div className="activity-filter--item search">
            <Field
              name="searchQuery"
              component={Input}
              label={false}
              autoComplete="off"
              placeholder="Find a User or Email"
            />
          </div>
          <div className="checkbox-group activity-filter--item">
            <label className="label">User Status</label>
            <Field
              label={false}
              component={CheckboxGroup}
              name="userStatus"
              options={USER_ACTIVE_STATUS}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

UserFilterForm.propTypes = propTypes

UserFilterForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'userFilterForm',
    constraints: {},
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserFilterForm)
