import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import logo from 'images/Alphaeon_Credit_Logo.png'
import { Link } from 'react-router-dom'
import { Modal } from 'lp-components'
import { ButtonLink } from 'components'
import { LocationSelectorForm } from '../forms'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { withApiAuth, operationPermitted } from 'utils'

import {
  ACCOUNT_LOOKUP_URL,
  MERCHANT_PORTAL_URL,
  CALCULATOR_URL,
  PATH,
  PERMISSION,
  REPORT_URL,
  START_APPLICATION_URL,
  makePath,
  USER_PORTAL_URL,
  makeApplicationURL,
  PROGRAM_MANAGEMENT_URL,
  QR_CODE_URL,
} from 'config'
import { AuthorizedLink, NotificationsBanner } from 'merchant-portal-components'
import { LocationType, PermissionsType } from 'types'
import { setLocation } from 'local-storage'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const propTypes = {
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  currentLocation: LocationType,
  currentLocationIndex: PropTypes.number.isRequired,
  setCurrentLocationIndex: PropTypes.func.isRequired,
  getLocationNotifications: PropTypes.func.isRequired,
  currentPermissions: PermissionsType.isRequired,
  fetchLocationDetails: PropTypes.func.isRequired,
}
const defaultProps = {}

function Header({
  allLocations,
  currentLocation,
  currentLocationIndex,
  setCurrentLocationIndex,
  getLocationNotifications,
  currentPermissions,
  fetchLocationDetails,
}) {
  const [displayLocationSelector, setDisplayLocationSelector] = useState(false)
  const closeLocationSelector = () => setDisplayLocationSelector(false)
  const locationName = currentLocation?.name
  const locationState = currentLocation?.address?.state
  const locationStoreNumber = currentLocation?.adsStoreNumber
  const pushApplicationEnabled = currentLocation?.pushApplicationEnabled
  const { user, logout, isAuthenticated } = useAuth0()
  const location = useLocation()

  const showAdminDropdown =
    operationPermitted(currentPermissions, PERMISSION.MANAGE_USERS) ||
    operationPermitted(currentPermissions, PERMISSION.MANAGE_PROGRAMS)

  useEffect(() => {
    getLocationNotifications(currentLocation.locationId)
  }, [currentLocation])

  const displayedLocationName = `at ${locationName} (${locationStoreNumber})`

  return (
    <>
      {isAuthenticated && (
        <div className="header-login">
          <div className="header-login__content">
            <div>
              {user.email} {displayedLocationName}
            </div>
            <ul className="nav">
              <li className="navlink">
                {/* <a href="#">Change Location</a> */}
                <ButtonLink
                  onClick={() =>
                    setDisplayLocationSelector(!displayLocationSelector)
                  }
                  className="button-link"
                  disabled={location.pathname.includes(
                    '/merchant/account/refund/'
                  )}
                >
                  Change Locations
                </ButtonLink>
              </li>
              <li className="navlink">
                <Link to={makePath(PATH.MERCHANT_PORTAL, PATH.CONTACT_US)}>
                  Contact Us
                </Link>
              </li>

              {showAdminDropdown && (
                <li className="navDropdown">
                  <button className="dropbtn button-link">
                    Admin
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown-content">
                    <AuthorizedLink
                      to={USER_PORTAL_URL}
                      requiredPermission={PERMISSION.MANAGE_USERS}
                    >
                      User Management
                    </AuthorizedLink>
                    <AuthorizedLink
                      to={PROGRAM_MANAGEMENT_URL}
                      requiredPermission={PERMISSION.MANAGE_PROGRAMS}
                    >
                      Program Management
                    </AuthorizedLink>

                    <AuthorizedLink
                      to={makePath(PATH.MERCHANT_PORTAL, PATH.MARKETING)}
                      requiredPermission={PERMISSION.MANAGE_USERS}
                    >
                      Marketing Materials
                    </AuthorizedLink>
                  </div>
                </li>
              )}
              <li className="navlink">
                <ButtonLink
                  onClick={() =>
                    logout({
                      returnTo: makeApplicationURL(PATH.MERCHANT_PORTAL),
                    })
                  }
                  className="button-link"
                >
                  Sign out
                </ButtonLink>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="header">
        <div className="header__content">
          <Link to={MERCHANT_PORTAL_URL}>
            <img src={logo} className="logo" alt="Alphaeon rose logo" />
          </Link>
          <ul className="nav">
            <li className="navlink">
              <Link to={MERCHANT_PORTAL_URL}>Dashboard</Link>
            </li>
            <li className="navlink">
              <AuthorizedLink
                to={ACCOUNT_LOOKUP_URL}
                requiredPermission={PERMISSION.ACCOUNT_LOOKUP}
              >
                Account Lookup
              </AuthorizedLink>
            </li>
            {(locationState !== 'CA' || pushApplicationEnabled === true) && (
              <li className="navlink">
                <AuthorizedLink
                  to={START_APPLICATION_URL}
                  requiredPermission={[
                    PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS,
                    PERMISSION.SUBMIT_PUSH_APPLICATIONS,
                  ]}
                  selectionCriteria="OR"
                >
                  Apply
                </AuthorizedLink>
              </li>
            )}
            <li className="navlink">
              <Link to={ACCOUNT_LOOKUP_URL}>Transact</Link>
            </li>

            <li className="navlink">
              <AuthorizedLink
                to={CALCULATOR_URL}
                requiredPermission={PERMISSION.CALCULATOR}
              >
                Calculator
              </AuthorizedLink>
            </li>

            <li className="navlink">
              <AuthorizedLink
                to={REPORT_URL}
                requiredPermission={[
                  PERMISSION.VIEW_APPLICATION_TRACKING_REPORT,
                  PERMISSION.VIEW_BANK_RECONCILIATION_REPORT,
                  PERMISSION.VIEW_SALE_REPORT,
                ]}
                selectionCriteria="OR"
              >
                REPORTS
              </AuthorizedLink>
            </li>

            <li className="navlink right-align">
              <Link to={QR_CODE_URL}>MARKETING</Link>
            </li>
          </ul>
        </div>
      </div>
      <NotificationsBanner />

      {displayLocationSelector && (
        <Modal onClose={closeLocationSelector}>
          <LocationSelectorForm
            allLocations={allLocations}
            initialValues={{ selectedLocationIndex: currentLocationIndex }}
            defaultValue={{
              label: currentLocation.name,
              value: currentLocationIndex,
            }}
            onSubmit={({ selectedLocationIndex }) => {
              closeLocationSelector()

              fetchLocationDetails(
                allLocations[selectedLocationIndex.value].locationId
              ).then((res) => setLocation(res))
              return setCurrentLocationIndex(selectedLocationIndex.value)
            }}
          />
        </Modal>
      )}
    </>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allLocations: selectors.allLocations(state),
    currentLocation: selectors.currentLocation(state),
    currentLocationIndex: selectors.currentLocationIndex(state),
    currentPermissions: selectors.currentPermissions(state),
  }
}

const mapDispatchToProps = {
  setCurrentLocationIndex: actions.setCurrentLocationIndex,
}

const mapApiAuthToProps = {
  getLocationNotifications: apiActions.getLocationNotifications,
  fetchLocationDetails: apiActions.fetchLocationDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Header)
