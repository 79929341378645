// eslint-disable-next-line no-unused-vars
export default function validateAddress(value, _allValues) {
  // Regex to match NON PO box - Same as validation in MC-951 in BE, here:
  // https://github.com/alphaeon-credit/merchant-mux/blob/main/app/api/extensions/validators.rb
  // from: https://stackoverflow.com/questions/52880617/regex-validate-address-string-to-not-contain-po-box-patterns
  const PO_BOX_REGEX =
    /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i

  const addressNotPoBox = !!value?.match(PO_BOX_REGEX)

  if (!!value && !addressNotPoBox) {
    return 'PO Box cannot be used in the address.'
  }
}
