import React from 'react'
import exact from 'prop-types-exact'
import { AccountNumber } from 'components'
import { formatCurrency } from 'utils'
import { LocationType, PaymentDetailsType } from 'types'

const propTypes = {
  paymentDetails: PaymentDetailsType,
  currentLocation: LocationType,
}

const defaultProps = {}

function AuthBuyerSaleCard({ paymentDetails, currentLocation }) {
  const {
    alphaeonAccountNumber,
    creditLimit,
    name,
    availableCreditLimit,
    ssn,
    buyer,
  } = paymentDetails

  return (
    <div className="account-summary refund-complete">
      <div className="summary__card card m-t-30">
        <div className="card-content">
          <div className="card-half">
            <div className="summary__account-info">
              <p className="card-title">
                {`${buyer.firstName} ${buyer.lastName} `}
              </p>

              <div className="card-main">
                <div className="card-main__left">
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Authorized Buyer</strong>
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Account Number:</strong>
                    </p>
                    <AccountNumber
                      accountNumberString={alphaeonAccountNumber}
                    />
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Available Credit</strong>{' '}
                      {formatCurrency(availableCreditLimit, 2)}
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Social Security Number </strong>
                      {ssn ? `XXX-XX-${ssn.slice(-4)}` : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-half card-half__right">
            <div className="summary__account-info">
              <p className="card-title">
                {`${name?.firstName} ${name?.lastName}`}
              </p>

              <div className="card-main">
                <div className="card-main__left">
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Responsible Party</strong>
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>&nbsp;</strong>
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Credit Limit</strong>{' '}
                      {formatCurrency(creditLimit, 2)}
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>Location</strong> {currentLocation.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AuthBuyerSaleCard.propTypes = exact(propTypes)
AuthBuyerSaleCard.defaultProps = defaultProps

export default AuthBuyerSaleCard
