import React from 'react'

import { QR_CODE_URL, MARKETING_MATERIALS } from 'config'
import qrIcon from 'images/utility-icons/qr_code_2.svg'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import { Link } from 'react-router-dom'

function MarketingToolbar() {
  return (
    <div className="marketing-toolbar">
      <span className="toolbar-icon">
        <Link className="icon-link" to={QR_CODE_URL}>
          <img
            src={qrIcon}
            alt=""
            style={{ height: '60px', width: '60px', textAlign: 'center' }}
          />
          QR Code
        </Link>
      </span>

      <span className="toolbar-icon cart">
        <Link className="icon-link" to={MARKETING_MATERIALS}>
          <ShoppingCart
            fill="white"
            path={{ fill: 'white', stroke: 'white' }}
            style={{ height: '60px', width: '60px', textAlign: 'center' }}
          />
          Marketing Materials
        </Link>
      </span>
      {/* <img  

            src={qrIcon}
            alt=""
            style={{ height: '75px', width: '75px', textAlign: 'center' }}
          /> */}
    </div>
  )
}

export default React.memo(MarketingToolbar)
