import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function ConsumerFinancialPrivacyPolicy() {
  return (
    <div className="page-container">
      <div className="fact-table-header">
        <div className="left-box">
          <h3>FACTS</h3>
        </div>
        <div className="right-box">
          <h3>
            WHAT DOES ALPHAEON <br />
            DO WITH YOUR PERSONAL INFORMATION?
          </h3>
        </div>
      </div>

      <div className="fact-table-1">
        <div className="fact-table-row">
          <div className="left-box">
            <h3>Why?</h3>
          </div>
          <div className="right-box">
            <p className="paragraph-basic">
              Financial companies choose how they share your personal
              information. Federal law gives consumers the right to limit some
              but not all sharing. Federal law also requires us to tell you how
              we collect, share, and protect your personal information. Please
              read this notice carefully to understand what we do.
            </p>
          </div>
        </div>

        <div className="fact-table-row">
          <div className="left-box">
            <h3>What?</h3>
          </div>
          <div className="right-box">
            <p className="paragraph-basic">
              The types of personal information we collect and share depend on
              the product or service you have with us. This information can
              include:
            </p>
            <ul className="paragraph-list terms-list">
              <li>Social Security number and transaction history</li>
              <li>account balances and payment history</li>
              <li>account transactions and income</li>
            </ul>
          </div>
        </div>

        <div className="fact-table-row">
          <div className="left-box">
            <h3>How?</h3>
          </div>
          <div className="right-box">
            <p className="paragraph-basic">
              All financial companies need to share customers' personal
              information to run their everyday business. In the section below,
              we list the reasons financial companies can share their customers'
              personal information; the reasons Alphaeon chooses to share; and
              whether you can limit this sharing.
            </p>
          </div>
        </div>
      </div>

      <div className="fact-table-2">
        <table>
          <thead>
            <tr>
              <th>Reasons we can share your personal information</th>
              <th>Does Alphaeon share?</th>
              <th>Can you limit this sharing?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>For our everyday business purposes —</strong>
                such as to process your transactions, maintain your account(s),
                respond to court orders and legal investigations; or report to
                credit bureaus
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <strong>For our marketing purposes —</strong>
                to offer our products and services to you
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <strong>
                  For joint marketing with other financial companies
                </strong>
              </td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <strong>
                  For our affiliates' everyday business purposes —
                </strong>
                information about your transactions and experiences
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <strong>
                  For our affiliates' everyday business purposes —{' '}
                </strong>
                information about your creditworthiness
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <strong>For our affiliates to market to you</strong>
              </td>
              <td>No</td>
              <td>We don't share</td>
            </tr>
            <tr>
              <td>
                <strong>For nonaffiliates to market to you</strong>
              </td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="fact-table-4">
        <div className="left-box">
          <h3>To Limit Our Sharing</h3>
        </div>
        <div className="right-box">
          <p className="paragraph-basic">
            Call (800) 754-4013 or email us at privacy@alphaeoncredit.com.
          </p>
          <p className="paragraph-basic">
            <strong>Please note: </strong>
            If you are a new customer, we can begin sharing your information 30
            days from the date we sent this notice. When you are no longer our
            customer, we continue to share your information as described in this
            notice.
          </p>
          <p className="paragraph-basic">
            However, you can contact us at any time to limit our sharing.
          </p>
        </div>
      </div>

      <div className="fact-table-4">
        <div className="left-box">
          <h3>Questions?</h3>
        </div>
        <div className="right-box">
          <p className="paragraph-basic">
            Call (800) 754-4013 or email us at privacy@alphaeoncredit.com.
          </p>
        </div>
      </div>

      <div className="fact-table-3">
        <div className="table-3-header">
          <h3>Who we are</h3>
        </div>
        <div className="table-3-body">
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>Who is providing this notice?</h4>
            </div>
            <div className="table-3-right">
              <p>Alphaeon Credit, Inc.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="fact-table-3">
        <div className="table-3-header">
          <h3>What we do</h3>
        </div>
        <div className="table-3-body">
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>How does Alphaeon protect my personal information?</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                To protect your personal information from unauthorized access
                and use, we use security measures that comply with federal law.
                These measures include computer safeguards and secured files and
                buildings. We also take measures to limit personal information
                access to only employees, agents and representatives that need
                to know.
              </p>
            </div>
          </div>
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>How does Alphaeon collect my personal information?</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                We collect your personal information, for example, when you:
              </p>
              <ul className="paragraph-list terms-list">
                <li>Open an account or provide account information</li>
                <li>Apply for a loan or use your credit or debit card</li>
                <li>Give us your contact information</li>
              </ul>
              <p className="paragraph-basic">
                We also collect your personal information from others, such as
                creditbureaus, affiliates, or other companies.
              </p>
            </div>
          </div>
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>Why can't I limit all sharing?</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                Federal law gives you the right to limit only:
              </p>
              <ul className="paragraph-list terms-list">
                <li>
                  sharing for affiliates' everyday business purposes—information
                  about your creditworthiness
                </li>
                <li>affiliates from using your information to market to you</li>
                <li>sharing for nonaffiliates to market to you</li>
              </ul>
              <p className="paragraph-basic">
                State laws and individual companies may give you additional
                rights to limit sharing. See below for more on your rights under
                state law.
              </p>
            </div>
          </div>
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>
                What happens when I limit sharing for an account I hold jointly
                with someone else?
              </h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                Your choices will apply to everyone on your account.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fact-table-3">
        <div className="table-3-header">
          <h3>Definitions</h3>
        </div>
        <div className="table-3-body">
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>Affiliates</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                Companies related by common ownership or control. They can be
                financial and nonfinancial companies.
              </p>
              <ul className="paragraph-list terms-list">
                <li>Alphaeon has no affiliates.</li>
              </ul>
            </div>
          </div>
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>Nonaffiliates</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                Companies not related by common ownership or control. They can
                be financial and nonfinancial companies.
              </p>
              <ul className="paragraph-list terms-list">
                <li>
                  Nonaffiliates we share with can include the medical practices
                  or retailers that referred you to us.
                </li>
              </ul>
            </div>
          </div>
          <div className="table-3-row">
            <div className="table-3-left">
              <h4>Joint marketing</h4>
            </div>
            <div className="table-3-right">
              <p className="paragraph-basic">
                A formal agreement between nonaffiliated financial companies
                that together market financial products or services to you.
              </p>
              <ul className="paragraph-list terms-list">
                <li>
                  Our joint marketing partners include companies such as
                  lenders.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="fact-table-3">
        <div className="table-3-header">
          <h3>Other Important Information</h3>
        </div>
        <div className="table-3-body">
          <div className="table-3-row table-3-info">
            <p className="paragraph-basic">
              <strong>California Residents:</strong> For more information,
              please refer to our Privacy Policy or the California privacy
              notice that will be provided separately entitled, “Important
              Privacy Choices for Consumers.”
            </p>
            <p className="paragraph-basic">
              <strong>Vermont Customers:</strong> We will not disclose
              information about your creditworthiness to our affiliates and will
              not disclose your personal information, financial information,
              credit report, or health information to nonaffiliated third
              parties to market to you, other than as permitted by Vermont law,
              unless you authorize us to make those disclosures. Additional
              information concerning our privacy policies can be found by email
              at <span className="contact">privacy@alphaeoncredit.com</span> or
              call (800) 754-4013.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

ConsumerFinancialPrivacyPolicy.propTypes = exact(propTypes)
ConsumerFinancialPrivacyPolicy.defaultProps = defaultProps

export default memo(ConsumerFinancialPrivacyPolicy)
