import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AccountNumber } from 'components'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { AuthorizedButton } from 'merchant-portal-components'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'
import { formatCurrency, formatISODateStringAsUSMonthDayYear } from 'utils'
import { VOID_SALE_URL, ACCOUNT_DETAIL_URL, PERMISSION } from 'config'

const propTypes = {
  refundedTransaction: PropTypes.object,
}
const defaultProps = {}

function RefundComplete({ refundedTransaction }) {
  const location = useLocation()
  const history = useHistory()

  const { alphaeonAccountNumber, creditLimit, name, transaction } =
    location.state

  if (!refundedTransaction) {
    return <Redirect to={`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`} />
  }

  const { planName, locationName, createdAt } = transaction
  const { amount } = refundedTransaction

  const onCardClick = () => {
    history.push({
      pathname: `${VOID_SALE_URL}${alphaeonAccountNumber}`,
      state: {
        alphaeonAccountNumber,
        creditLimit,
        name,
        transaction: refundedTransaction,
      },
    })
  }
  return (
    <>
      <div className="account-lookup m-t-30">
        <div className="account-summary summary refund-complete">
          <h1 className="text-center">Refund Complete</h1>
          <p className="summary__info m-t-20 text-center">
            Your refund has successfully been completed.
          </p>
          <div className="summary__card card m-t-30">
            <div className="card-content">
              <div className="summary__account-info">
                <p className="card-title">
                  {' '}
                  {name.firstName} {name.lastName}
                </p>
                <div className="info-container sub-title">
                  <p className="card-area">
                    <AccountNumber
                      accountNumberString={alphaeonAccountNumber}
                    />
                  </p>
                </div>

                <div className="card-main">
                  <div className="card-main__left">
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Plan</strong> {planName}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Location</strong> {locationName}
                      </p>
                    </div>
                    <div className="info-container ">
                      <p className="card-area">
                        <strong>Date Submitted</strong>{' '}
                        {formatISODateStringAsUSMonthDayYear(createdAt)}
                      </p>
                    </div>
                  </div>

                  <div className="card-main__right">
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Refund Amount</strong>{' '}
                        {formatCurrency(amount, 2)}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Authorization Code</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <AuthorizedButton
                  className="button-warn-outline sale"
                  onClick={onCardClick}
                  requiredPermission={PERMISSION.PROCESS_VOID}
                >
                  VOID
                </AuthorizedButton>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center voidable-transactions__void-helper m-t-5">
          Void available up until 11:59 EST the day of a transaction.
          <br />
          Funds will be released back to the account owner's Alphaeon Card.
        </p>
      </div>
    </>
  )
}

RefundComplete.propTypes = propTypes

RefundComplete.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    refundedTransaction: selectors.refundedTransaction(state),
  }
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RefundComplete
)
