import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { isNil } from 'lodash'
import { Table, TableColumn as Column } from 'lp-components'

import { Button } from 'components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { ACCESS_LOAN_URL, CREDIT_PORTAL_URL, WIZARD_URL } from 'config'
import { NameType, PrequalificationType } from 'types'
import { formatISODateStringAsUSMonthDayYear, getFullName } from 'utils'

const propTypes = {
  personalInfo: NameType.isRequired,
  prequalifications: PropTypes.arrayOf(PrequalificationType).isRequired,
  setLenderReferenceId: PropTypes.func.isRequired,
  setPractice: PropTypes.func.isRequired,
  setPrequalification: PropTypes.func.isRequired,
}
const defaultProps = {}

function ApplicationFound({
  personalInfo,
  prequalifications,
  setLenderReferenceId,
  setPractice,
  setPrequalification,
}) {
  if (isNil(prequalifications)) return <Redirect to={CREDIT_PORTAL_URL} />

  const history = useHistory()
  const { firstName, lastName } = personalInfo
  const totalPrequalifications = prequalifications.length
  const totalPrequalificationsPlural = totalPrequalifications > 1 ? 's' : ''

  const navigateToNextView = (prequalification) => {
    const {
      applicationStatus,
      lenderNextStepUrl,
      lenderRequestReferenceId,
      location,
    } = prequalification

    setPrequalification(prequalification)
    setPractice(location)
    setLenderReferenceId(lenderRequestReferenceId)

    if (applicationStatus === 'prequalified') {
      history.push({
        pathname: WIZARD_URL,
        state: {
          step: 'PRE-QUALIFICATION',
          fromfindMyApp: true,
        },
      })
    } else if (applicationStatus === 'pending_approval') {
      // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
      //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."

      history.push({
        pathname: ACCESS_LOAN_URL,
        state: {
          lenderNextStepUrl,
          modalType: 'no-modal',
          fromfindMyApp: true,
        },
      })
    } else {
      throw new Error(
        `ERROR: unknown API application status: ${applicationStatus}`
      )
    }
  }

  return (
    <div className="action-result-container">
      <div className="main-content">
        <header>
          <h1>
            {totalPrequalifications} Application
            {totalPrequalificationsPlural} Found
          </h1>
        </header>
        <p>
          We have found the following existing application
          {totalPrequalificationsPlural} for {firstName} {lastName}:
        </p>
        <AllCreditApplications
          onSelect={navigateToNextView}
          applicant={personalInfo}
          prequalifications={prequalifications}
        />
        <p>Please click "COMPLETE APPLICATION" to complete your application.</p>
      </div>
    </div>
  )
}

ApplicationFound.propTypes = propTypes
ApplicationFound.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    personalInfo: selectors.personalInfo(state),
    prequalifications: selectors.prequalifications(state),
  }
}

const mapDispatchToProps = {
  setLenderReferenceId: actions.setLenderReferenceId,
  setPractice: actions.setPractice,
  setPrequalification: actions.setPrequalification,
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplicationFound
)

function AllCreditApplications({ applicant, onSelect, prequalifications }) {
  const ApplyButton = ({ data }) => (
    <td className="action">
      <Button className="button-warn-outline" onClick={() => onSelect(data)}>
        COMPLETE APPLICATION
      </Button>
    </td>
  )

  return (
    <Table className="result-table" data={prequalifications}>
      <Column
        name="name"
        label="Applicant"
        valueGetter={() => getFullName(applicant)}
      />
      <Column
        name="location"
        label="Location"
        valueGetter={({ location }) => location.name}
      />
      <Column
        name="applicationOpenedAt"
        label="Application Date"
        format={(applicationOpenedAt) =>
          formatISODateStringAsUSMonthDayYear(applicationOpenedAt)
        }
      />
      <Column name="action" label="" component={ApplyButton} />
    </Table>
  )
}
