import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Modal } from 'lp-components'

import { Button } from 'components'
import { AccountDetailType } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import actions from 'redux-form/lib/actions'

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  accountDetail: AccountDetailType,
}
const defaultProps = {}

function SaleInitiatorModal({ onConfirm, onClose, accountDetail }) {
  const [selectedMemberAccountId, setSelectedMemberAccountId] = useState(
    accountDetail.accountMemberId
  )

  const handleOptionChange = (event) => {
    setSelectedMemberAccountId(parseInt(event.target.value))
  }

  const onCloseClick = () => {
    onClose(false)
  }

  const onConfirmClick = () => {
    onConfirm(selectedMemberAccountId)
  }

  return (
    <Modal
      onClose={onCloseClick}
      overlayClassName=""
      className="modal-inner sale-initiator-modal modal"
    >
      <div>
        <div className="sale-initiator-modal__container">
          <div className="title">
            PLEASE CHOOSE THE PATIENT FOR THIS TRANSACTION
          </div>

          <div className="borrower-container">
            <div className="primary-borrower">
              <div className="select-title">Select Primary Borrower</div>
              <div className="radio-group">
                <div className="radio-wrapper">
                  <input
                    type="radio"
                    id={accountDetail.accountMemberId}
                    name="primaryBorrower"
                    value={accountDetail.accountMemberId}
                    checked={
                      selectedMemberAccountId == accountDetail.accountMemberId
                    }
                    onChange={handleOptionChange}
                  />
                  <label htmlFor={accountDetail.accountMemberId}>
                    {accountDetail.name.firstName +
                      ' ' +
                      accountDetail.name.lastName}
                  </label>
                </div>
              </div>
            </div>

            <div className="separator">
              <div className="or">OR</div>
            </div>

            <div className="authorized-buyer">
              <div className="select-title">Select Authorized Buyer</div>

              <div className="radio-group">
                {accountDetail.authorizedBuyers.map((buyer, index) => {
                  if (buyer.enabled == true) {
                    return (
                      <React.Fragment key={index}>
                        <div className="radio-wrapper">
                          <input
                            type="radio"
                            id={buyer.accountMemberId}
                            name="authBuyer"
                            value={buyer.accountMemberId}
                            checked={
                              selectedMemberAccountId == buyer.accountMemberId
                            }
                            onChange={handleOptionChange}
                          />
                          <label htmlFor={buyer.accountMemberId}>
                            {buyer.firstName + ' ' + buyer.lastName}
                          </label>
                        </div>
                      </React.Fragment>
                    )
                  }
                })}
              </div>
            </div>
          </div>

          <div className="button-block">
            <Button className="button-warn" onClick={onConfirmClick}>
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

SaleInitiatorModal.propTypes = propTypes
SaleInitiatorModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    accountDetail: selectors.accountDetail(state),
  }
}

const mapDispatchToProps = {
  setAccountData: actions.setAccountData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SaleInitiatorModal
)
