import React /*, { useEffect } */ from 'react'
// import PropTypes from 'prop-types'
// import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
// import { selectors } from '../reducer'
// import * as actions from '../actions'
// import * as apiActions from 'api-actions'
import ExternalLink from '../../../components/ExternalLink'
import { Link } from 'react-router-dom'
import { makePath, PATH } from '../../../config'

const propTypes = {
  // legals: PropTypes.arrayOf(Types.legal),
  // fetchLegals: PropTypes.func.isRequired,
}

const defaultProps = {}

function Legals() {
  return (
    <div className="page-container">
      <ExternalLink
        styleAsAnchor={true}
        className=""
        href="https://c.comenity.net/legaldocs/alphaeoncosmetic/legal-documents/cca"
      >
        ADS Credit Card Act
      </ExternalLink>
      <br />
      <ExternalLink
        styleAsAnchor={true}
        className=""
        href="https://d.comenity.net/legaldocs/alphaeoncosmetic/legal-documents/patriot-act"
      >
        ADS Patriot Act
      </ExternalLink>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.CA_PRIVACY_NOTICE)}>
        CA Privacy Notice
      </Link>
      <br />
      <Link
        to={makePath(PATH.LEGAL, PATH.COMENITY_CAPITAL_PREQUALIFICATION_TERMS)}
      >
        Comenity Capital Prequalification Terms
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.CONSENT_AUTODIALED)}>
        Consent Autodialed
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.CONSENT_ELECTRONIC_DISCLOSURES)}>
        Consent Electronic Disclosures
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.CONSUMER_FINANCIAL_PRIVACY_POLICY)}>
        Consumer Financial Privacy Policy
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.CREDIT_REPORT_AUTHORIZATION)}>
        Credit Report Authorization
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.PREQUALIFICATION_TERMS)}>
        Prequalification Terms
      </Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.PRIVACY_POLICY)}>Privacy Policy</Link>
      <br />
      <Link to={makePath(PATH.LEGAL, PATH.WEBSITE_TERMS_OF_USE)}>
        Website Terms Of Use
      </Link>
    </div>
  )
}

Legals.propTypes = propTypes

Legals.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {
    // legals: selectors.legals(state),
  }
}

const mapDispatchToProps = {
  // fetchLegals: apiActions.fetchLegals,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Legals)
