import React from 'react'
import Routes from './Routes'
import history from 'browser-history'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  PATH,
  REACT_APP_ALPHAEON_MERCHANT_MUX_API,
  REACT_APP_ALPHAEON_MERCHANT_AUTH0_CLIENTID,
  REACT_APP_AUTH0_DOMAIN,
  APPLICATION_ROOT_URL,
  makePath,
  MERCHANT_PORTAL_URL,
  getTestEnv,
} from 'config'

import { VersionChangeReporter, TestEnvironmentBanner } from 'components'

function App() {
  const testEnv = getTestEnv()

  return (
    <>
      <Auth0Provider
        audience={REACT_APP_ALPHAEON_MERCHANT_MUX_API}
        clientId={REACT_APP_ALPHAEON_MERCHANT_AUTH0_CLIENTID}
        domain={REACT_APP_AUTH0_DOMAIN}
        redirectUri={makePath(APPLICATION_ROOT_URL, PATH.MERCHANT_PORTAL)}
        onRedirectCallback={onRedirectCallback}
        leeway={3600}
      >
        {testEnv && <TestEnvironmentBanner env={testEnv} />}
        <Routes />
        <VersionChangeReporter redirectURL={MERCHANT_PORTAL_URL} />
      </Auth0Provider>
    </>
  )
}

export default App

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname, appState)
}
