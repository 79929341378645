import React from 'react'
import exact from 'prop-types-exact'

const propTypes = {}

function PreQualificationInstallment() {
  return (
    <>
      <div className="pre-qualification-installment">
        <p className="first-title">
          You have been pre-qualified for our <br />
          Alphaeon Installment Product for $5,000
        </p>
        <div className="pre-qualification-installment-header">
          Installment Offer
        </div>

        <div className="installment-summary">
          <div className="summary">
            <div className="summary__title ">ACCOUNT SUMMARY</div>
            <div className="summary__root">
              <div className="summary__body">
                <div className="summary__body--data">
                  <span>APR </span>14.99%
                </div>
                <div className="summary__body--data">
                  <span>AMOUNT FINANCED </span>$5,000
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="summary">
            <div className="summary__title">PAYMENT SCHEDULE</div>
            <div className="summary__root">
              <div className="summary__body">
                <div className="summary__body--data">
                  <span>Number of Payments</span> 12
                </div>
                <div className="summary__body--data">
                  <span>Estimated Total Cost </span>$5,497.92
                </div>
              </div>
              <div className="summary__body">
                <div className="summary__body--data">
                  <span>Amount of Payments </span>$458.16
                </div>
                <div className="summary__body--data">
                  <span>First Payment Date</span> MM/DD/YYY
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="first-subtitle">
          Although we were unable to pre-qualify you for out Alphaeon credit
          card product, we were able to pre-qualify you for our Alphaeon
          Installment Product.
        </p>
      </div>
    </>
  )
}

PreQualificationInstallment.propTypes = exact(propTypes)

export default PreQualificationInstallment
