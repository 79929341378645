import { handleActions } from 'redux-actions'
// import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
// import * as apiActions from 'api-actions'

const reducerKey = 'legal'
// const slice = 'root.legal'

const initialState = {}

const reducer = handleActions(
  {
    // [apiActions.fetchLegals]: setOnSuccess('legals'),
    // [apiActions.fetchLegal]: setOnSuccess('legal'),
    // [actions.clearLegal]: unsetState('legal'),
  },
  initialState
)

// const select = selectorForSlice(slice)

const selectors = {
  // legals: select('legals'),
  // legal: select('legal'),
}

export { reducer, selectors, reducerKey }
