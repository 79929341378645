import Retool from 'react-retool'
import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  makeApplicationURL,
  PATH,
  REACT_APP_ALPHAEON_RETOOL_EMBED_URL,
} from 'config'
import { LinkButton } from 'components'

export default function AdminPortal() {
  const { logout, getAccessTokenSilently } = useAuth0()
  const [loaded, setLoaded] = useState(false)
  const [token, setToken] = useState({ token: '' })

  // Fetch token and make available to Retool
  useEffect(() => {
    getAccessTokenSilently().then((result) => {
      setToken({ token: result })
    })
  }, [])

  // Do not allow render until token is available
  useEffect(() => {
    setLoaded(!!token['token'])
  }, [token])

  if (loaded) {
    return (
      <React.Fragment>
        <LinkButton
          className="button-warn-outline link-button-internal-logout"
          onClick={() => logout({ returnTo: makeApplicationURL(PATH.ADMIN) })}
        >
          Sign Out
        </LinkButton>
        <Retool url={REACT_APP_ALPHAEON_RETOOL_EMBED_URL} data={token} />
      </React.Fragment>
    )
  } else {
    return <div>Loading...</div>
  }
}
