import {
  APPLICATION_TRACKING,
  SALES,
  BANK_RECONCILIATION,
  REPORT_TYPES,
  PERMISSION,
} from 'config'
import { operationPermitted } from 'utils'

const getAuthorizedReportTypes = (permissions) => {
  let report_types = []

  if (
    operationPermitted(permissions, PERMISSION.VIEW_APPLICATION_TRACKING_REPORT)
  ) {
    report_types.push(APPLICATION_TRACKING)
  }
  if (
    operationPermitted(permissions, PERMISSION.VIEW_BANK_RECONCILIATION_REPORT)
  ) {
    report_types.push(BANK_RECONCILIATION)
  }
  if (operationPermitted(permissions, PERMISSION.VIEW_SALE_REPORT)) {
    report_types.push(SALES)
  }

  return REPORT_TYPES.filter((reportType) =>
    report_types.includes(reportType.value)
  )
}
export default getAuthorizedReportTypes
