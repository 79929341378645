import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Button } from 'lp-components'
import maskNumber from 'utils/maskNumber'

const defaultProps = {
  accountNumberString: '',
}

const propTypes = {
  accountNumberString: PropTypes.string,
}

function AccountNumber({ accountNumberString }) {
  const [visible, setVisible] = useState(false)
  const [formattedNumber, setFormattedNumber] = useState(
    maskNumber(accountNumberString)
  )

  const handleVisibility = () => {
    setVisible(!visible)
    setFormattedNumber(
      !visible ? accountNumberString : maskNumber(accountNumberString)
    )
  }

  return (
    <div className="account-number">
      <div className="number">{formattedNumber}</div>
      <Button
        type="button"
        onClick={handleVisibility}
        className="account-number__visibility-button"
      >
        {!visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </Button>
    </div>
  )
}

AccountNumber.propTypes = propTypes
AccountNumber.defaultProps = defaultProps

export default React.memo(AccountNumber)
