import React, { useState, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Table, TableColumn as Column } from 'lp-components'
import { ApplicationActionCell } from 'components'
import ReportDetailsContainer from './ReportDetailsContainer'
import { selectors as merchantPortalSelectors } from 'merchant-portal-reducer'
import {
  ACCOUNT_DETAIL_URL,
  APPLICATION_REPORT_SUMMARY_HEADERS,
  EMPTY_APPLICATION_REPORT_SUMMARY,
  APPLICATION_REPORT_DETAIL_HEADER,
} from 'config'
import { ApplicationReportType } from 'types'
import {
  formatCurrency,
  formatFixedNumber,
  formatISODateStringAsUSMonthDayYear,
  formatEmailAddress,
  formatLocation,
  formatProductType,
} from 'utils'

const propTypes = {
  applicationReports: ApplicationReportType.isRequired,
}
const defaultProps = {}

function ApplicationReportDetails({ applicationReports }) {
  const reportRef = useRef(null)
  const [summaryDetail, setSummaryDetail] = useState([])
  const [locationData, setLocationData] = useState([])
  const [allSelected, setAllSelected] = useState(false)

  const { summary, locationSummaries, applications } = applicationReports

  const summaryWithSummaryLabel = {
    ...summary,
    locationName: 'SUMMARY',
    locationNumber: undefined,
    checked: false,
  }

  useEffect(() => {
    const locationsSummary = locationSummaries.map((location) => ({
      ...location,
      checked: false,
    }))
    setLocationData([summaryWithSummaryLabel, ...locationsSummary])
  }, [locationSummaries])

  const setSummaryDetailLocation = (data) => {
    const value = locationData.findIndex(
      (val) => val.locationName === data.locationName
    )
    locationData[value] = {
      ...locationData[value],
      checked: data.checked,
    }

    setLocationData([...locationData])
    const locationNamesArr = locationData.map(
      (location) => location.checked && location.locationName
    )
    setSummaryDetail(
      applications.filter((application) =>
        locationNamesArr.includes(application.locationName)
      )
    )
  }

  const getDetailSummaryData = () => {
    return summaryDetail.map((summary) => ({
      column01: formatLocation(summary.locationName, summary.locationNumber),
      column02: formatISODateStringAsUSMonthDayYear(summary.createdAt),
      column03: summary.applicationIdentifier,
      column04: summary.customerName,
      column05: formatProductType(summary.productType),
      column06: summary.applicationStatus,
      column07: formatCurrency(summary.creditLimit, 2),
      column08: summary.accountNumber?.slice(-4),
      column09: formatEmailAddress(summary.associate),
    }))
  }

  const getLocationSummary = () => {
    return locationData.map((location) => ({
      column01: location.locationName,
      column02: location.totalApplications,
      column03: location.approvedApplications,
      column04: location.declinedApplications,
      column05: location.pendingApplications,
      column06: location.duplicateApplications,
      column07: location.netApplications,
      column08: location.netApprovalRate,
      column09: '',
    }))
  }

  const getCSVData = () => {
    const locationData = getLocationSummary()
    const summaryData = getDetailSummaryData()
    return [
      ...locationData,
      ...new Array(5).fill(EMPTY_APPLICATION_REPORT_SUMMARY),
      APPLICATION_REPORT_DETAIL_HEADER,
      ...summaryData,
    ]
  }

  const selectAllLocation = (selected) => {
    setAllSelected(selected)

    if (selected) {
      setSummaryDetail(applications)
      setLocationData(locationData.map((row) => ({ ...row, checked: true })))
    } else {
      setSummaryDetail([])
      setLocationData(locationData.map((row) => ({ ...row, checked: false })))
    }
  }

  return (
    <ReportDetailsContainer
      csvHeaders={APPLICATION_REPORT_SUMMARY_HEADERS}
      csvFilename="Application_Tracking_Report.csv"
      csvData={getCSVData()}
      reportRef={reportRef}
    >
      <div id="report-container" ref={reportRef}>
        <div className="report-detail__headers m-t-20">
          <div className="report-detail--title title ">
            Application Tracking Results
          </div>
        </div>

        <div className="report-detail__summary m-t-50">
          {locationSummaries.length > 0 ? (
            <Table data={locationData}>
              <Column
                name="checked"
                label="Show Details"
                className="show-details"
                component={({ data }) => (
                  <ApplicationActionCell
                    data={data}
                    setData={setSummaryDetailLocation}
                    selectAllLocation={selectAllLocation}
                    allSelected={allSelected}
                  />
                )}
              />
              <Column name="locationName" label="Location" />
              <Column name="totalApplications" label="Total Apps" />
              <Column name="approvedApplications" label="Approvals" />
              <Column name="declinedApplications" label="Declines" />
              <Column name="pendingApplications" label="Pending" />
              <Column name="duplicateApplications" label="Duplicates" />
              <Column name="netApplications" label="Net App Volume" />
              <Column
                name="netApprovalRate"
                label="Net Approval Rate"
                format={(data) =>
                  data ? `${formatFixedNumber(data * 100, 2)}%` : ''
                }
              />
            </Table>
          ) : (
            <div className="empty-state-container">
              <p>No matching results</p>
            </div>
          )}
        </div>

        <div className="report-detail--sub-title title m-t-50">Details</div>
        <div className="report-detail__summary-detail m-t-50">
          {summaryDetail.length > 0 ? (
            <>
              <Table data={summaryDetail}>
                <Column
                  name="location"
                  label="Location"
                  valueGetter={({ locationName, locationNumber }) =>
                    formatLocation(locationName, locationNumber)
                  }
                />
                <Column
                  name="createdAt"
                  label="App Date"
                  format={(createdAt) =>
                    formatISODateStringAsUSMonthDayYear(createdAt)
                  }
                />
                <Column name="applicationIdentifier" label="App ID" />
                <Column name="customerName" label="Customer" />
                <Column
                  name="productType"
                  label="Product Type"
                  format={formatProductType}
                />
                <Column name="applicationStatus" label="Status" />
                <Column
                  name="creditLimit"
                  label="Credit Limit"
                  format={(creditLimit) => formatCurrency(creditLimit, 2)}
                />
                <Column
                  name="accountNumber"
                  headerComponent={() => (
                    <th>
                      Account/
                      <br />
                      Loan #<br />
                      (Last 4)
                    </th>
                  )}
                  format={(value) => {
                    const displayValue = value?.slice(-4)
                    return value ? (
                      <Link to={`${ACCOUNT_DETAIL_URL}${value}`}>
                        {displayValue}
                      </Link>
                    ) : (
                      '-'
                    )
                  }}
                />
                <Column
                  name="associate"
                  label="Associate"
                  format={formatEmailAddress}
                />
              </Table>
            </>
          ) : (
            <>Select Summary above to show Details</>
          )}
        </div>
      </div>
    </ReportDetailsContainer>
  )
}

ApplicationReportDetails.propTypes = propTypes
ApplicationReportDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    applicationReports: merchantPortalSelectors.applicationReports(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplicationReportDetails
)
