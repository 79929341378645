import { flashErrorMessage } from 'redux-flash'

export const serverErrorLoggingMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      action &&
      action.type &&
      action.type.includes('@@lp-redux-api') &&
      action.payload.status === 'failure'
    ) {
      const errorStatus = action.payload.data.status
      const serverErrors = errorStatus >= 500 && errorStatus <= 599 //503 wont be passed here, will already be rerouted
      if (serverErrors) {
        dispatch(flashErrorMessage(`Server Error: ${errorStatus}`))
      }
    }
    return next(action)
  }
