import { isEmpty } from 'lodash'
import formatISODate from './formatISODate'
import parseUSDayMonthYearDate from './parseUSDayMonthYearDate'
import { DATE_STRING_LENGTH } from 'config'

const formatISODateString = (dateString) => {
  if (isEmpty(dateString)) return dateString
  if (dateString.length !== DATE_STRING_LENGTH) return dateString

  return formatISODate(parseUSDayMonthYearDate(dateString))
}

export default formatISODateString
