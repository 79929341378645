import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  formValues,
  propTypes as formPropTypes,
  Field,
  SubmissionError,
} from 'redux-form'
import { isEmpty, omit } from 'lodash'
import { lpForm } from 'lp-form'
import { Input, SubmitButton, Button } from 'lp-components'

import { ContactPatientRadioGroup } from 'merchant-portal-components'

const propTypes = {
  ...formPropTypes,
  patientContactOptions: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  withReference: PropTypes.bool,
}
const defaultProps = {
  withReference: false,
}

function ContactPatientForm({
  handleSubmit,
  handleCancel,
  patientContactOptions,
  withReference,
  submitting,
}) {
  return (
    <form
      noValidate
      name="contactPatientForm"
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
    >
      {withReference && (
        <Field
          name="userReferenceId"
          component={Input}
          label="Reference ID (Optional)"
          placeholder="Reference ID"
        />
      )}

      <div className="radio-group">
        <div className="info-contain">
          Please select the patient's preferred method of contact, and confirm
          the contact information.
        </div>
        <div className="radio-group__share m-t-30">
          <Field
            component={ContactPatientRadioGroup}
            name="patientContactOptions"
            label={false}
            options={allPatientContactOptions(patientContactOptions)}
          />
        </div>
      </div>

      <div className="button-block">
        <SubmitButton
          disabled={submitting}
          submitting={submitting}
          className="button-warn m-t-30"
        >
          Send To Patient
        </SubmitButton>
        <Button
          className="button-warn-outline m-t-30"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

ContactPatientForm.propTypes = propTypes
ContactPatientForm.defaultProps = defaultProps

function beforeSubmit(formValues) {
  const { patientContactOptions } = formValues

  if (isEmpty(patientContactOptions)) {
    throw new SubmissionError({
      _error: 'Selected contact option must have a value specified',
    })
  }

  const contactMethod = /^\d+$/.test(patientContactOptions) ? 'sms' : 'email'

  return {
    ...omit(formValues, 'patientContactOptions'),
    [contactMethod]: patientContactOptions,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'contactPatientForm',
  }),
  formValues('patientContactOptions')
)(ContactPatientForm)

function allPatientContactOptions(patientContactOptionValue) {
  let sms, email

  if (/^\d+$/.test(patientContactOptionValue)) {
    // eslint-disable-next-line no-unused-vars
    sms = patientContactOptionValue
  } else {
    email = patientContactOptionValue
  }

  return {
    email,
  }
}
