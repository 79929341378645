import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { SubmitButton } from 'lp-components'
import { propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function PlanSelectionForm({ handleSubmit, submitting }) {
  return (
    <div className="plan-selection__form">
      <form noValidate onSubmit={handleSubmit}>
        <SubmitButton
          invalid={submitting}
          className="button-warn m-t-30"
          submitting={submitting}
        >
          COMPLETE SALE
        </SubmitButton>
      </form>
    </div>
  )
}

PlanSelectionForm.propTypes = propTypes

PlanSelectionForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'planSelectionForm',
    constraints: {},
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(PlanSelectionForm)
