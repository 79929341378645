import React from 'react'
import { compose } from 'redux'
import {
  Field,
  FormSection,
  propTypes as formPropTypes,
  formValues,
} from 'redux-form'
import { lpForm } from 'lp-form'
import {
  Checkbox,
  SubmitButton,
  Input,
  Select,
  MaskedInput,
  RadioGroup,
} from 'lp-components'
import PropTypes from 'prop-types'
import { InputTip, ExternalLink, LinkButton } from 'components'
import {
  URL,
  PRINT_URL,
  RELATIONSHIPS,
  SHARE_WITH_FAMILY_OPTIONS,
} from 'config'
import { formatISODateString } from 'utils'
import { ApplicationEnvironmentType } from 'types'

const propTypes = {
  signedAgreed: PropTypes.bool,
  shareWithFamily: PropTypes.string,
  applicationEnvironment: ApplicationEnvironmentType,
  ...formPropTypes,
}

function PreQualificationForm({
  handleSubmit,
  signedAgreed,
  shareWithFamily,
  submitting,
  applicationEnvironment,
}) {
  return (
    <form noValidate onSubmit={handleSubmit} className="forms-container">
      {applicationEnvironment?.authorizedBuyersEnabled === true && (
        <div className="preQualification-form radio-group">
          <div className="info-contain">
            Want to share your Alphaeon Credit Card with family or friends?
          </div>
          <div className="radio-group__share m-t-30">
            <Field
              component={RadioGroup}
              name="shareWithFamily"
              label={false}
              options={SHARE_WITH_FAMILY_OPTIONS}
            />
          </div>
          <FormSection name="authorizedBuyer">
            {shareWithFamily === 'yes' && (
              <div className="m-t-30">
                <Field
                  name="firstName"
                  component={Input}
                  placeholder="First Name"
                  label="First Name"
                  required
                  requiredIndicator=" *"
                />

                <Field
                  name="lastName"
                  component={Input}
                  placeholder="Last Name"
                  required
                  requiredIndicator=" *"
                />

                <Field
                  name="relationship"
                  component={Select}
                  options={RELATIONSHIPS}
                  label={'Relationship To Applicant'}
                  placeholder="Relationship To Applicant"
                  required
                  requiredIndicator=" *"
                />

                <div className="date-of-birth">
                  <Field
                    component={InputTip}
                    inputType={MaskedInput}
                    name="dateOfBirth"
                    placeholder="MM/DD/YYYY"
                    required
                    requiredIndicator=" *"
                    maskOptions={{
                      date: true,
                      datePattern: ['m', 'd', 'Y'],
                    }}
                  />
                </div>
                <div className="authorized-buyer-text">
                  An authorized buyer is an individual you give permission to
                  make purchases on your account. As the primary account holder,
                  you are responsible for paying for all purchases made on this
                  account, including any made by an authorized buyer.
                </div>
              </div>
            )}
          </FormSection>
        </div>
      )}
      <div className="stepper-card">
        <div className="stepper-card-header">
          Consent to Account Terms and Conditions
        </div>
        <div className="stepper-card-body terms-frame">
          <iframe id="legal-doc" src={URL.PATRIOT_ACT} />
        </div>
      </div>

      <LinkButton
        to={`${PRINT_URL}/PATRIOT_ACT`}
        target="_blank"
        className="print-button"
      >
        PRINT
      </LinkButton>
      <div className="stepper-card">
        <div className="stepper-card-header">
          Consent to Financial Terms of the Account
        </div>
        <div className="stepper-card-body terms-frame">
          <iframe id="financial-doc" src={URL.CCA} />
        </div>
      </div>
      <LinkButton
        to={`${PRINT_URL}/CCA`}
        target="_blank"
        className="print-button"
      >
        PRINT
      </LinkButton>
      <div className="stepper-checkbox pre-qualification m-t-30">
        <div>
          <p className="must-read">
            <strong>
              You must read the disclosures presented in the Electronic Consent
              section of the Terms and Conditions box above prior to checking
              the consent box.
            </strong>{' '}
            Please also read the Credit Card Agreement, Privacy Statement and
            other information presented in the Terms and Conditions box prior to
            submitting this application and print a copy for your records.
          </p>
        </div>
      </div>
      <div className="stepper-checkbox pre-qualification">
        <div className="checkbox-container">
          <Field name="signedAgreed" component={Checkbox} label={false} />

          <div className="checkbox-right terms-and-condition">
            <p>
              <strong>
                By signing or otherwise submitting this
                application/solicitation, each applicant (“I,” “me” or “my”
                below) agrees and certifies that:
              </strong>{' '}
            </p>
            <ul type="1">
              <li>
                (1) I have read and agree to the disclosures provided on or with
                this application/solicitation,
              </li>
              <li>(2) the information I have supplied is true and correct,</li>
              <li>
                (3) I am applying to Comenity Capital Bank,P.O. Box 183003,
                Columbus, OH 43218-3003 (“Bank”) for an Alphaeon Credit Card
                Account,
              </li>
              <li>
                (4) I authorize the Bank to obtain credit reports on me, which
                may result in a hard inquiry.
              </li>
              <li>
                (5) if approved, my account will be governed by the Credit Card
                Agreement,
              </li>
              <li>
                (6) I understand that I may pay all of my account balance at any
                time without penalty and
              </li>
              <li>
                (7) this application/solicitation, any information I submitted
                to the Bank, and the Bank’s final decision on my
                application/solicitation may be shared with and retaind by
                Alphaeon.
              </li>
              <li>
                (8) By providing your contact information, including any mobile
                or other phone numbers, you agree to be contacted regarding any
                of your Comenity Bank or Comenity Capital Bank accounts via
                calls to cell phones, text messages or telephone calls,
                including the use of artificial or pre-recorded message calls,
                as well as calls made via automatic telephone dialing systems or
                via email.
              </li>
              <li>
                (9) I understand that by checking this box and clicking “Accept
                offer and Submit Application” I am applying for credit with
                Comenity Capital Bank, agreeing to Comenity's Terms and
                Conditions and{' '}
                <ExternalLink
                  className="agree-link"
                  href={URL.FINANCIAL_PRIVACY}
                  target="_blank"
                  rel="noreferrer"
                >
                  Financial Privacy Policy
                </ExternalLink>
                , and Online and{' '}
                <ExternalLink
                  className="agree-link"
                  href={URL.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                >
                  California Privacy Policy
                </ExternalLink>
                , Consenting to electronically receiving documents and am
                electronically signing this application/solicitation.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <SubmitButton
          disabled={!signedAgreed || submitting}
          submitting={submitting}
          className={`button-warn ${!signedAgreed ? `warn-disabled` : ``}`}
        >
          ACCEPT OFFER AND SUBMIT APPLICATION
        </SubmitButton>
      </div>
    </form>
  )
}

PreQualificationForm.propTypes = propTypes

function beforeSubmit(formValues) {
  const { authorizedBuyer } = formValues

  if (authorizedBuyer) {
    const isoAuthorizedBuyerDateOfBirth = formatISODateString(
      authorizedBuyer.dateOfBirth
    )
    authorizedBuyer.dateOfBirth = isoAuthorizedBuyerDateOfBirth
  }

  return {
    ...formValues,
    authorizedBuyer,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'preQualificaitonForm',
    constraints: {
      firstName: {
        presence: true,
      },
      lastName: {
        presence: true,
      },
      relationship: {
        presence: true,
      },
      dateOfBirth: {
        presence: true,
        length: { is: 10 },
      },
      'authorizedBuyer.firstName': { presence: true },
      'authorizedBuyer.lastName': { presence: true },
      'authorizedBuyer.relationship': { presence: true },
      'authorizedBuyer.dateOfBirth': { presence: true, length: { is: 10 } },
    },
    submitFilters: {
      reject: ['shareWithFamily', 'signedAgreed'],
    },
    initialValues: {
      shareWithFamily: 'no',
    },
  }),
  formValues({
    shareWithFamily: 'shareWithFamily',
    signedAgreed: 'signedAgreed',
  })
)(PreQualificationForm)
