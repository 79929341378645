//TO-DO: Delete this file when typescript fully added

import PropTypes from 'prop-types'

export const StylesheetType = PropTypes.shape({
  logo_url: PropTypes.string,
  primary_color: PropTypes.string,
  secondary_color: PropTypes.string,
})

export const TermContentType = PropTypes.shape({
  term_content_id: PropTypes.number,
  language: PropTypes.string,
  text: PropTypes.string,
})

export const ApplicationEnvironmentType = PropTypes.shape({
  location_name: PropTypes.string,
  merchant_name: PropTypes.string,
  stylesheet: StylesheetType,
  alphaeon_terms: TermContentType,
  lender_terms: TermContentType,
  prequalification_url: PropTypes.string,
  authorizedBuyersEnabled: PropTypes.bool,
})

export const AddressType = PropTypes.shape({
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const PracticeType = PropTypes.shape({
  achid: PropTypes.number,
  address: AddressType,
  adsStoreNumber: PropTypes.number,
  email: PropTypes.string,
  fax: PropTypes.string,
  locationId: PropTypes.number,
  name: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
})

export const AccountType = PropTypes.shape({
  name: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleInitial: PropTypes.string,
  }),
  address: AddressType,
  paymentDueDate: PropTypes.string,
  creditLimit: PropTypes.number,
  availableCreditLimit: PropTypes.number,
  alphaeonAccountNumber: PropTypes.string,
  accountOpenDate: PropTypes.string,
  consumerCreditInstrumentType: PropTypes.string,
})

export const AccountsList = PropTypes.shape({
  accounts: PropTypes.arrayOf(AccountType),
  accountsLookupStatus: PropTypes.string,
})

export const AcceptedTermsType = PropTypes.shape({
  acceptedOn: PropTypes.string,
  termContentId: PropTypes.number,
})

export const PersonalInfoType = PropTypes.shape({
  alternatePhone: PropTypes.string,
  annualIncome: PropTypes.number,
  city: PropTypes.string,
  dateOfBirth: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  prerequisiteTermsAccepted: PropTypes.arrayOf(AcceptedTermsType),
  primaryPhone: PropTypes.string,
  purchaseAmount: PropTypes.number,
  specialty: PropTypes.string,
  ssn: PropTypes.string,
  state: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  zip: PropTypes.string,
})

export const OfferType = PropTypes.shape({
  amount: PropTypes.number,
  expirationDate: PropTypes.string,
  offerId: PropTypes.number,
  terms: PropTypes.arrayOf(TermContentType),
})

export const ServiceProviderPriorityType = PropTypes.shape({
  productType: PropTypes.string,
  priority: PropTypes.number,
})

export const CreditApplicationType = PropTypes.shape({
  application_id: PropTypes.string,
  application_status: PropTypes.string,
  application_service_provider_priority: ServiceProviderPriorityType,
  location_service_provider_priorities: PropTypes.shape({
    customer: PropTypes.arrayOf(ServiceProviderPriorityType),
    manager: PropTypes.arrayOf(ServiceProviderPriorityType),
  }),
})

export const IdentityVerificationType = PropTypes.shape({
  shareableUrl: PropTypes.string,
  status: PropTypes.string,
  clientUserId: PropTypes.string,
  phone: PropTypes.string,
  smsSent: PropTypes.bool,
  verificationIdentifier: PropTypes.string,
  linkToken: PropTypes.string,
})

export const PrequalificationType = PropTypes.shape({
  applicationId: PropTypes.string,
  applicationStatus: PropTypes.string,
  applicationOpenedAt: PropTypes.string,
  applicationServiceProviderPriority: ServiceProviderPriorityType,
  locationServiceProviderPriorities: PropTypes.shape({
    customer: PropTypes.arrayOf(ServiceProviderPriorityType),
    manager: PropTypes.arrayOf(ServiceProviderPriorityType),
  }),
  lenderRequestReferenceId: PropTypes.string,
  lenderNextStepUrl: PropTypes.string,
  offers: PropTypes.arrayOf(OfferType),
})

export const AuthorizedBuyers = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  relationship: PropTypes.string,
  authorizedBuyerAddedDate: PropTypes.string,
})

export const ApprovedApplicationType = PropTypes.shape({
  alphaeonAccountNumber: PropTypes.string,
  alphaeonIdentifier: PropTypes.string,
  applicationStatus: PropTypes.string,
  creditLimit: PropTypes.number,
  authorizedBuyers: PropTypes.any,
  applicationId: PropTypes.string,
})

export const ApplicationInfoType = PropTypes.shape({
  locationId: PropTypes.number,
  purchaseAmount: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  primaryPhone: PropTypes.string,
  state: PropTypes.string,
  street1: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  dateOfBirth: PropTypes.string,
  ssn: PropTypes.string,
  annualIncome: PropTypes.number,
  prerequisiteTermsAccepted: PropTypes.arrayOf(AcceptedTermsType),
})

export const NameType = PropTypes.shape({
  firstName: PropTypes.string,
  middleInitial: PropTypes.string,
  lastName: PropTypes.string,
})

export const TransactionDisplayType = PropTypes.shape({
  amount: PropTypes.number,
  comment: PropTypes.any,
  effectiveAt: PropTypes.any,
  locationName: PropTypes.string,
  planIdentifier: PropTypes.string,
  planName: PropTypes.string,
  status: PropTypes.string,
  transactionType: PropTypes.string,
})

export const TransactionType = PropTypes.shape({
  accountMemberId: PropTypes.number,
  amount: PropTypes.number,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  consumerCreditInstrumentId: PropTypes.number,
  invoiceNumber: PropTypes.string,
  locationId: PropTypes.number,
  locationName: PropTypes.string,
  refundedTransactionId: PropTypes.number,
  status: PropTypes.string,
  transactionId: PropTypes.number,
  transactionType: PropTypes.string,
})

export const AccountDetailType = PropTypes.shape({
  accountMemberId: PropTypes.number,
  address: AddressType,
  alphaeonAccountNumber: PropTypes.string,
  alphaeonAccountIdentifier: PropTypes.string,
  alternatePhone: PropTypes.string,
  annualIncome: PropTypes.number,
  availableCreditLimit: PropTypes.number,
  authorizedBuyers: PropTypes.arrayOf(AuthorizedBuyers),
  consumerCreditInstrumentId: PropTypes.number,
  consumerCreditInstrumentType: PropTypes.string,
  creditLimit: PropTypes.number,
  dateOfBirth: PropTypes.string,
  derogatoryInd: PropTypes.string,
  division: PropTypes.string,
  email: PropTypes.string,
  end_at: PropTypes.string,
  internalRiskReason: PropTypes.string,
  lenderSpecificAccountAttributes: PropTypes.object,
  name: NameType,
  primaryPhone: PropTypes.string,
  ssn_last_4: PropTypes.string,
  transactions: PropTypes.arrayOf(TransactionDisplayType),
})

export const PermissionsType = PropTypes.shape({
  canAccessAccountLookup: PropTypes.bool,
  canAccessApplicationActivityTable: PropTypes.bool,
  canAccessCalculator: PropTypes.bool,
  canManageUsers: PropTypes.bool,
  canProcessRefund: PropTypes.bool,
  canProcessSale: PropTypes.bool,
  canProcessVoid: PropTypes.bool,
  canSubmitPushApplications: PropTypes.bool,
  canSubmitDashboardApplications: PropTypes.bool,
  canViewApplicationTrackingReport: PropTypes.bool,
  canViewBankReconciliationReport: PropTypes.bool,
  canViewCreditAvailableReport: PropTypes.bool,
  canViewSaleReport: PropTypes.bool,
})

export const PricingTierType = PropTypes.shape({
  id: PropTypes.number,
  division: PropTypes.string,
  notes: PropTypes.string,
})

export const LocationType = PropTypes.shape({
  locationId: PropTypes.number,
  name: PropTypes.string,
  achid: PropTypes.number,
  adsStoreNumber: PropTypes.number,
  email: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  address: AddressType,
  permissions: PermissionsType,
  pricingTier: PricingTierType,
  supplementalFundingEnabled: PropTypes.bool,
  pushApplicationEnabled: PropTypes.bool,
})

export const LocationNotification = PropTypes.shape({
  id: PropTypes.number,
  content: PropTypes.string,
})

export const IdentificationConfirmationType = PropTypes.shape({
  type: PropTypes.string,
  state: PropTypes.string,
})

export const PaymentDetailsType = PropTypes.shape({
  name: NameType,
  alphaeonAccountNumber: PropTypes.string,
  availableCreditLimit: PropTypes.number,
  address: AddressType,
  ssn: PropTypes.string,
  creditLimit: PropTypes.number,
  amount: PropTypes.number,
  authorizationCode: PropTypes.string,
  referenceId: PropTypes.string,
  consumerCreditInstrumentId: PropTypes.number,
  accountMemberId: PropTypes.number,
  identificationConfirmation: IdentificationConfirmationType,
})

export const PlansType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  division: PropTypes.string,
  service_provider_id: PropTypes.number,
  start_on: PropTypes.string,
  end_on: PropTypes.string,
  external_lender_identifier: PropTypes.string,
  down_payment: PropTypes.string,
  min_credit_limit: PropTypes.string,
  max_credit_limit: PropTypes.string,
  min_customer_apr: PropTypes.string,
  max_customer_apr: PropTypes.string,
  min_purchase_amount: PropTypes.string,
  max_purchase_amount: PropTypes.string,
  min_term_months: PropTypes.string,
  max_term_months: PropTypes.string,
  merchant_discount_rate: PropTypes.string,
  terms: PropTypes.arrayOf(TermContentType),
  estimatedMonthlyPayment: PropTypes.number,
  estimatedTotalCost: PropTypes.number,
})

export const LocationPlansType = PropTypes.shape({
  min_purchase_amount: PropTypes.string,
  dashboardDisplayEnabled: PropTypes.bool,
  payMyProviderEnabled: PropTypes.bool,
  isEnabled: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
})

export const ReconciliationSummaryType = PropTypes.shape({
  achEffectiveDate: PropTypes.string,
  locationName: PropTypes.string,
  locationNumber: PropTypes.number,
  totalCreditAdjustments: PropTypes.number,
  totalDebtAdjustments: PropTypes.number,
  totalMdf: PropTypes.number,
  totalRefunds: PropTypes.number,
  totalSales: PropTypes.number,
  totalSettlement: PropTypes.number,
  transactionDate: PropTypes.string,
})

export const ReconciliationReportType = PropTypes.shape({
  summary: ReconciliationSummaryType,
  locationSummaries: PropTypes.arrayOf(ReconciliationSummaryType),
  bankReconciliations: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumber: PropTypes.string,
      achEffectiveDate: PropTypes.string,
      amount: PropTypes.number,
      authCode: PropTypes.string,
      creditTerm: PropTypes.number,
      customerName: PropTypes.string,
      locationName: PropTypes.string,
      locationNumber: PropTypes.number,
      mdfFee: PropTypes.number,
      mdfRate1: PropTypes.number,
      mdfRate2: PropTypes.number,
      mdfRate3: PropTypes.number,
      originCode: PropTypes.number,
      planName: PropTypes.string,
      planNumber: PropTypes.string,
      postDate: PropTypes.string,
      settlementAmount: PropTypes.number,
      transactionDate: PropTypes.string,
      transactionType: PropTypes.string,
    })
  ),
})

export const ApplicationReportStatisticsType = PropTypes.shape({
  totalApplications: PropTypes.number,
  approvedApplications: PropTypes.number,
  declinedApplications: PropTypes.number,
  pendingApplications: PropTypes.number,
  duplicateApplications: PropTypes.number,
  netApplications: PropTypes.number,
  netApprovalRate: PropTypes.number,
})

export const ApplicationReportLocationStatisticsType = PropTypes.shape({
  locationName: PropTypes.string,
  totalApplications: PropTypes.number,
  approvedApplications: PropTypes.number,
  declinedApplications: PropTypes.number,
  pendingApplications: PropTypes.number,
  duplicateApplications: PropTypes.number,
  netApplications: PropTypes.number,
  netApprovalRate: PropTypes.number,
})

export const ApplicationReportType = PropTypes.shape({
  summary: ApplicationReportStatisticsType,
  locationSummaries: PropTypes.arrayOf(ApplicationReportLocationStatisticsType),
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      locationName: PropTypes.string,
      locationId: PropTypes.number,
      locationNumber: PropTypes.number,
      createdAt: PropTypes.string,
      applicationIdentifier: PropTypes.string,
      customerName: PropTypes.string,
      prequalStatus: PropTypes.string,
      applicationStatus: PropTypes.string,
      creditLimit: PropTypes.number,
      accountNumber: PropTypes.string,
      associate: PropTypes.string,
    })
  ),
})

export const SalesReportStatisticsType = PropTypes.shape({
  locationId: PropTypes.number,
  locationName: PropTypes.string,
  totalSales: PropTypes.number,
  totalRefunds: PropTypes.number,
})

export const SalesReportType = PropTypes.shape({
  summary: SalesReportStatisticsType,
  locationSummaries: PropTypes.arrayOf(SalesReportStatisticsType),
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      adsStoreNumber: PropTypes.number,
      alphaeonAccountNumber: PropTypes.string,
      amount: PropTypes.number,
      associate: PropTypes.string,
      authCode: PropTypes.string,
      createdAt: PropTypes.string,
      creatorId: PropTypes.string,
      effectiveAt: PropTypes.string,
      fundingDate: PropTypes.string,
      id: PropTypes.number,
      installmentServiceDate: PropTypes.string,
      locationId: PropTypes.number,
      locationName: PropTypes.string,
      merchantDiscountRate: PropTypes.number,
      name: NameType,
      planIdentifier: PropTypes.string,
      planName: PropTypes.string,
      referenceId: PropTypes.string,
      status: PropTypes.string,
      transactionType: PropTypes.string,
      voiderId: PropTypes.string,
      userReferenceId: PropTypes.string,
    })
  ),
})

export const ManagerType = PropTypes.shape({
  createdAt: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleInitial: PropTypes.string,
  email: PropTypes.string,
  managerId: PropTypes.number,
  updatedAt: PropTypes.string,
  suffix: PropTypes.string,
  status: PropTypes.string,
})

export const MangerDetailType = PropTypes.shape({
  ...ManagerType,
  assignedLocationIds: PropTypes.arrayOf(PropTypes.number),
  locationPermissions: PropTypes.arrayOf(PermissionsType),
})

export const Options = PropTypes.shape({
  label: PropTypes.string,
  key: PropTypes.string,
})

export const AccountVerificationType = PropTypes.shape({
  shareableUrl: PropTypes.string,
  status: PropTypes.string,
  phone: PropTypes.string,
  smsSent: PropTypes.bool,
  verificationIdentifier: PropTypes.string,
  isWhitelisted: PropTypes.bool,
})
