import { isNil } from 'lodash'
import { useEffect } from 'react'

export default function useInterval(
  callback,
  { interval, invokeFirst = false }
) {
  useEffect(() => {
    if (invokeFirst) callback()

    if (!isNil(interval) && interval > 0) {
      const intervalId = setInterval(callback, interval)

      return () => clearInterval(intervalId)
    }
  }, [callback, interval])
}
