export default function matchingDetailCriteria(
  achEffectiveDate,
  locationNumber,
  productType,
  postDate
) {
  return function comparator(location) {
    return (
      location.achEffectiveDate === achEffectiveDate &&
      location.locationNumber === locationNumber &&
      location.productType === productType &&
      location.postDate === postDate
    )
  }
}
