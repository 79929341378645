import { isEmpty } from 'lodash'

const capitalizeFirstLetterInEachWord = (str) => {
  if (isEmpty(str)) return str

  return str
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

export default capitalizeFirstLetterInEachWord
