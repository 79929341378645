import React, { useEffect } from 'react'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import exact from 'prop-types-exact'
import { Button } from 'components'
import { useAuth0 } from '@auth0/auth0-react'
import { PATH, makeApplicationURL, REACT_APP_API_URL } from 'config'

const propTypes = {}

const defaultProps = {}
function ExpirationTimeError() {
  const { logout } = useAuth0()

  useEffect(() => {
    fetch(`${REACT_APP_API_URL}heartbeat`, {
      method: 'HEAD',
      mode: 'cors',
    }).then(() => {})
  }, [])

  const currentDate = new Date()

  return (
    <div className="page-container expiration-error-container">
      <div className="error-card-container">
        <div className="error-section">
          <ErrorIcon style={{ fontSize: 100 }} color="error" />
          <h1>Oops! You can't log in because your device time is incorrect.</h1>
        </div>

        <div className="error-section date-sync m-t-20 m-b-20 second-header">
          Your local system time is {`${currentDate}`} which is NOT in sync with
          the server time.
        </div>
        <br />
        <Button
          onClick={() =>
            logout({ returnTo: makeApplicationURL(PATH.MERCHANT_PORTAL) })
          }
          className="button-warn-outline"
        >
          Go Back
        </Button>
        <div className="error-section">
          <h2>For Windows</h2>
          <h3>See instructions below on how to fix it.</h3>
          <p>Start &gt; Settings &gt; Time & Language &gt; Date & Time</p>
          <p>Under Synchronize your clock, click on Sync now.</p>
        </div>
        <div className="error-section last-section">
          <h2>For Mac</h2>
          <h3>See instructions below on how to fix it.</h3>
          <p>From the Apple menu, select System Preferences.</p>
          <p>Click Date & Time.</p>
          <p>Click the Lock Icon.</p>
          <p>Select the Set data and time automatically checkbox.</p>
          <h3 className="second-header">
            Please contact us at 1-920-306-1794 if you experience any problems.
          </h3>
        </div>
      </div>
    </div>
  )
}

ExpirationTimeError.propTypes = exact(propTypes)
ExpirationTimeError.defaultProps = defaultProps

export default React.memo(ExpirationTimeError)
