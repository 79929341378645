import React from 'react'
import PropTypes from 'prop-types'
import { ExportCSV } from 'components'
import { Options } from 'types'

const propTypes = {
  csvData: PropTypes.any,
  csvHeaders: PropTypes.arrayOf(Options),
  handlePrint: PropTypes.func,
  fileName: PropTypes.string,
}

function ExportFiles({ csvHeaders, handlePrint, csvData, fileName }) {
  return (
    <div className="actions">
      <style>{`@page { margin: 40px !important; }`}</style>
      <>
        <ExportCSV
          csvHeaders={csvHeaders}
          title={'Export to CSV'}
          csvData={csvData}
          fileName={fileName}
        />

        <button onClick={handlePrint} className="export-to-pdf m-l-20">
          Print to PDF
        </button>
      </>
    </div>
  )
}

ExportFiles.propTypes = propTypes

export default ExportFiles
