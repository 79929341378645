import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { flashErrorMessage } from 'redux-flash'
import { isNil } from 'lodash'

import { Spinner } from 'components'
import ApplicantShow from './ApplicantShow'
import { useIovation } from 'hooks'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { APPLICANTS_URL, PRODUCT_TYPE } from 'config'
import {
  approveTerms,
  flashSubmitFailure,
  determinePrequalificationResponse,
} from 'utils'
import {
  ApplicationEnvironmentType,
  CreditApplicationType,
  PracticeType,
} from 'types'
import * as apiActions from 'api-actions'

const propTypes = {
  applicationEnvironment: ApplicationEnvironmentType,
  clearApplicant: PropTypes.func.isRequired,
  clearApplicationEnvironment: PropTypes.func.isRequired,
  creditApplication: CreditApplicationType.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  newCreditApplication: PropTypes.func.isRequired,
  patchApplicant: PropTypes.func.isRequired,
  practice: PracticeType.isRequired,
  prequalifyCreditApplication: PropTypes.func.isRequired,
}
const defaultProps = {}

function NewAccessLoan({
  applicationEnvironment,
  clearApplicant,
  clearApplicationEnvironment,
  creditApplication,
  flashErrorMessage,
  newCreditApplication,
  patchApplicant,
  practice,
  prequalifyCreditApplication,
}) {
  const { search } = useLocation()

  if (isNil(creditApplication)) {
    return (
      <Redirect
        to={{
          pathname: APPLICANTS_URL,
          search: search,
        }}
      />
    )
  }

  useEffect(() => {
    const { locationId } = practice

    newCreditApplication(locationId)

    return () => {
      clearApplicant()
      clearApplicationEnvironment()
    }
  }, [])

  const { applicationId, locationServiceProviderPriorities } = creditApplication
  const history = useHistory()
  const [getBlackboxData] = useIovation()

  const handleSubmit = (prequalificationFormValues) => {
    const { alphaeonTerms, lenderTerms } = applicationEnvironment

    return patchApplicant(applicationId, prequalificationFormValues).then(
      () => {
        const { consumer } = locationServiceProviderPriorities
        const accessLoanPriority = consumer.find(
          ({ productType }) => productType === PRODUCT_TYPE.ACCESS_LOAN
        )

        if (isNil(accessLoanPriority)) {
          return flashErrorMessage(
            `No consumer-accessible access loan plans configured for location [${practice.name}]`
          )
        }

        const prequalificationRequest = {
          priority: accessLoanPriority.priority,
          iovationBlackBox: getBlackboxData().blackbox,
          prerequisiteTermsAccepted: approveTerms(alphaeonTerms, lenderTerms),
        }

        return prequalifyCreditApplication(
          applicationId,
          prequalificationRequest
        )
      }
    )
  }
  const handleSubmitSuccess = (response) => {
    const nextStep = determinePrequalificationResponse(response, 'no-modal')

    history.push(nextStep)
    return
  }

  if (!applicationEnvironment) return <Spinner />

  return (
    <ApplicantShow
      practice={practice}
      handleSubmit={handleSubmit}
      handleSubmitSuccess={handleSubmitSuccess}
      handleSubmitFailure={flashSubmitFailure}
    />
  )
}

NewAccessLoan.propTypes = propTypes
NewAccessLoan.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    applicationEnvironment: selectors.applicationEnvironment(state),
    creditApplication: selectors.creditApplication(state),
    personalInfo: selectors.personalInfo(state),
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {
  clearApplicant: actions.clearApplicant,
  clearApplicationEnvironment: actions.clearApplicationEnvironment,
  flashErrorMessage: flashErrorMessage,
  newCreditApplication: apiActions.newCreditApplication,
  patchApplicant: apiActions.patchApplicant,
  prequalifyCreditApplication: apiActions.prequalifyCreditApplication,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewAccessLoan
)
