import { isEmpty } from 'lodash'
import { API_ERROR_MAP } from 'config'

export default function apiErrorToErrorMessage(errorCode, message) {
  if (isEmpty(errorCode)) return API_ERROR_MAP.UNKNOWN_ERROR

  const errorMessage =
    API_ERROR_MAP[errorCode] ?? message ?? API_ERROR_MAP.UNKNOWN_ERROR

  return `${errorMessage}`
}
