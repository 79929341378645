import React, { useEffect, useState } from 'react'
import { Paginator } from 'lp-components'
import { PAGINATION_PAGE_SIZE } from 'config'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  maxPageNumber: PropTypes.number,
}
function Pagination({ handlePageChange, data, maxPageNumber }) {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const indexOfLastData = currentPage * PAGINATION_PAGE_SIZE
    const indexOfFirstData = indexOfLastData - PAGINATION_PAGE_SIZE
    const paginatedData = data.slice(indexOfFirstData, indexOfLastData)
    handlePageChange(paginatedData)
  }, [currentPage, data])

  useEffect(() => {
    if (maxPageNumber) {
      setCurrentPage(1)
    }
  }, [maxPageNumber])

  const max = maxPageNumber
    ? maxPageNumber
    : isNil(data)
    ? 1
    : Math.ceil(data.length / PAGINATION_PAGE_SIZE)

  return (
    <>
      <Paginator
        alwaysShow
        value={currentPage}
        max={max}
        onChange={(page) => setCurrentPage(page)}
      />
    </>
  )
}

Pagination.propTypes = propTypes

export default Pagination
