import React from 'react'
const propTypes = {}
const defaultProps = {}

//PRE_QUALIFICATION_ACCEPT_DECLINED_URL

function PreQualificationAcceptDeclinedPage() {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Credit Application</h1>
        <p className="first-subtitle">Thank you for your interest.</p>
        <p>We were unable to prequalify you.</p>
      </div>
    </div>
  )
}

PreQualificationAcceptDeclinedPage.propTypes = propTypes
PreQualificationAcceptDeclinedPage.defaultProps = defaultProps

export default PreQualificationAcceptDeclinedPage
