// [ New Credit Application ]--------------------------------------------------
export const newCreditApplication = {
  locationName: 'Test Location 1',
  merchantName: 'Test Merchant',
  stylesheet: {
    logoUrl: 'https://loremflickr.com/300/300',
    primaryColor: 'rgb(255,0,0)',
    secondaryColor: 'rgb(0,0,255)',
  },
  alphaeonTerms: {
    termContentId: 2,
    language: 'en',
    text: 'Nemo sed esse. Maxime officiis consequatur. Facere non sapiente. Ab quibusdam omnis. Soluta ea eos. Est harum rerum. Non incidunt nostrum. Sit sed in. Distinctio praesentium nostrum. Doloribus ad molestiae.',
  },
  lenderTerms: {
    termContentId: 1,
    language: 'en',
    text: 'Facilis quo quia. Sed qui similique. Iure in quam. Impedit beatae sunt. Aut optio incidunt. Suscipit accusamus officia. Sapiente ut et. Suscipit qui minima. Natus magnam aspernatur. Qui itaque ducimus.',
  },
}

export const practiceData = [
  {
    locationId: 1,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: false,
  },
  {
    locationId: 2,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: false,
  },
  {
    locationId: 3,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: true,
  },
  {
    locationId: 4,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: true,
  },
  {
    locationId: 5,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
  },
  {
    locationId: 6,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: true,
  },
  {
    locationId: 7,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: true,
  },
  {
    locationId: 8,
    title: 'BOCA DENTAL & BRACES',
    address: '123 Street Name, City, State Zip Code',
    phone: '(123) 456-7890',
    link: 'www.practicewebsite.com',
    value: 'dentistry',
    subprimeEnrolled: true,
  },
]

export const accountData = [
  {
    name: 'MINGO FERNANDEZ',
    accountNumber: '7788402064987028',
    authorizedBuyer: 'Alpert Fernandez - Brother Added 9/28/2021',
    speciality: 'Dental',
    creditLimit: '7300',
  },
  {
    name: 'MINGO FERNANDEZ',
    accountNumber: '7788402064987028',
    authorizedBuyer: 'Alpert Fernandez - Brother Added 9/28/2021',
    speciality: 'Dental',
    creditLimit: '7300',
  },
  {
    name: 'MINGO FERNANDEZ',
    accountNumber: '7788402064987028',
    authorizedBuyer: 'Alpert Fernandez - Brother Added 9/28/2021',
    speciality: 'Dental',
    creditLimit: '7300',
  },
  {
    name: 'MINGO FERNANDEZ',
    accountNumber: '7788402064987028',
    authorizedBuyer: 'Alpert Fernandez - Brother Added 9/28/2021',
    speciality: 'Dental',
    creditLimit: '7300',
  },
]
// [ Prequalification and Prequalification Accept ]-----------------------------
export const applicationApproval = {
  applicationStatus: 'approved',
  alphaeonIdentifier: '88115617ac85e70afc3c',
  alphaeonAccountNumber: '5555555555555555500',
  creditLimit: 35000,
}

export const prequalificationSuccess = {
  applicationStatus: 'prequalified',
  applicationId: 2,
  offers: [
    {
      offerId: 2,
      expirationDate: '2022-04-19T21:06:27.839Z',
      amount: 35406,
      terms: [
        {
          termContentId: 1,
          language: 'en',
          text: 'Magnam corporis sed. Et quia consequatur. Quidem error exercitationem. Fugiat consequatur qui. Magnam recusandae ratione. Ratione dolores corrupti. Facere et adipisci. Id dicta mollitia. Nisi nulla nihil. Magni et occaecati.',
        },
      ],
    },
  ],
}

export const allLocations = [
  {
    locationId: 1,
    name: 'Dentistry.com',
    achid: 45663,
    adsStoreNumber: 12344,
    email: 'support@location.com',
    website: 'www.example.com',
    phone: '123-456-7890',
    fax: '555-555-5555',
    address: {
      street1: '100 Main Street',
      street2: 'Apt. 1',
      city: 'Anytown',
      state: 'NY',
      zip: '02122',
    },
  },
  {
    locationId: 2,
    name: 'SONO BELLO',
    achid: 45663,
    adsStoreNumber: 1234,
    email: 'support@sonobello.com',
    website: 'www.example.com',
    phone: '123-456-7890',
    fax: '555-555-5555',
    address: {
      street1: '100 68th Street',
      street2: '',
      city: 'New York',
      state: 'NY',
      zip: '02125',
    },
  },
  {
    locationId: 3,
    name: 'OstoichOptical.com',
    achid: 444,
    adsStoreNumber: 124,
    email: 'support@oistoichoptical.com',
    website: 'www.example.com',
    phone: '123-456-7890',
    fax: '555-555-5555',
    address: {
      street1: '100 78th Street',
      street2: '',
      city: 'New York',
      state: 'NY',
      zip: '02123',
    },
  },
]

export const transaction = {
  id: '1',
  customer: 'LastName,First',
  submitted: 'MM/DD/YY',
  transtype: 'SALE',
  amount: '$6379.00',
}

export const activity = {
  id: '1',
  customer: 'LastName,First',
  submitted: 'MM/DD/YY',
  status: 'APPROVED',
}

export const reconciliationReport = {
  summary: {
    totalSales: 55757.62,
    totalRefunds: 2345.62,
    totalCreditAdjustments: 2342.3,
    totalDebtAdjustments: 23423,
    totalMdf: 12312.23,
    totalSettlement: 23234.2,
    locationName: null,
    locationNumber: null,
    transactionDate: '2022-06-13',
    achEffectiveDate: '2022-06-13',
  },
  locationSummaries: [
    {
      totalSales: 55757.62,
      totalRefunds: 2345.62,
      totalCreditAdjustments: 2342.3,
      totalDebtAdjustments: 23423,
      totalMdf: 12312.23,
      totalSettlement: 23234.2,
      locationName: 'SONO BELLO',
      locationNumber: 10,
      transactionDate: '2022-06-13',
      achEffectiveDate: '2022-06-13',
    },
    {
      totalSales: 1000.62,
      totalRefunds: 1234.62,
      totalCreditAdjustments: 34.3,
      totalDebtAdjustments: 323,
      totalMdf: 129.23,
      totalSettlement: 3334.2,
      locationName: 'Luxxery Boutique',
      locationNumber: 20,
      transactionDate: '2022-06-13',
      achEffectiveDate: '2022-06-13',
    },
  ],
  bankReconciliations: [
    {
      locationName: 'SONO BELLO',
      locationNumber: 10,
      transactionDate: '2022-06-13',
      customerName: 'Jane Smith',
      accountNumber: '9999999999999995',
      postDate: '2022-06-13',
      authCode: '2342',
      planName: '60 MONTHS LOW APR  EQUAL PAYME',
      planNumber: '234',
      creditTerm: 1,
      originCode: 440,
      transactionType: 'Sale',
      amount: 4425.31,
      mdfFee: -225.69,
      mdfRate1: 0.051,
      mdfRate2: 0.051,
      mdfRate3: 0.051,
      settlementAmount: 6263.4,
      achEffectiveDate: '2022-06-13',
    },
    {
      locationName: 'SONO BELLO',
      locationNumber: 10,
      transactionDate: '2022-06-13',
      customerName: 'Jan Brady',
      accountNumber: '9999999999999996',
      postDate: '2022-06-13',
      authCode: '2342',
      planName: '60 MONTHS LOW APR  EQUAL PAYME',
      planNumber: '234',
      creditTerm: 1,
      originCode: 440,
      transactionType: 'Sale',
      amount: 4425.31,
      mdfFee: -225.69,
      mdfRate1: 0.051,
      mdfRate2: 0.051,
      mdfRate3: 0.051,
      settlementAmount: 6263.4,
      achEffectiveDate: '2022-06-13',
    },
    {
      locationName: 'SONO BELLO',
      locationNumber: 10,
      transactionDate: '2022-06-13',
      customerName: 'Harvey Klinger',
      accountNumber: '9999999999999997',
      postDate: '2022-06-13',
      authCode: '2342',
      planName: '60 MONTHS LOW APR  EQUAL PAYME',
      planNumber: '234',
      creditTerm: 1,
      originCode: 440,
      transactionType: 'Sale',
      amount: 4425.31,
      mdfFee: -225.69,
      mdfRate1: 0.051,
      mdfRate2: 0.051,
      mdfRate3: 0.051,
      settlementAmount: 6263.4,
      achEffectiveDate: '2022-06-13',
    },
    {
      locationName: 'Luxxery Boutique',
      locationNumber: 20,
      transactionDate: '2022-06-13',
      customerName: 'Shirley Partridge',
      accountNumber: '9999999999999998',
      postDate: '2022-06-13',
      authCode: '2342',
      planName: '60 MONTHS LOW APR  EQUAL PAYME',
      planNumber: '234',
      creditTerm: 1,
      originCode: 440,
      transactionType: 'Sale',
      amount: 4425.31,
      mdfFee: -225.69,
      mdfRate1: 0.051,
      mdfRate2: 0.051,
      mdfRate3: 0.051,
      settlementAmount: 6263.4,
      achEffectiveDate: '2022-06-13',
    },
  ],
}

export const plans = [
  {
    id: 61,
    name: '36 MONTHS WAIVED INTEREST, EQUAL PAYMENT',
    division: 'Vision',
    serviceProviderId: 1,
    startOn: '2022-05-20T00:00:00.000Z',
    endOn: null,
    externalLenderIdentifier: 836,
    downPayment: 0,
    minCreditLimit: 0,
    maxCreditLimit: 25000,
    minCustomerApr: 0,
    maxCustomerApr: 0,
    minPurchaseAmount: 1000,
    maxPurchaseAmount: 35000,
    minTermMonths: 0,
    maxTermMonths: 36,
    merchantDiscountRate: 0.1299,
    estimatedMonthlyPayment: 41.67,
    estimatedTotalCost: 1500,
    terms: [
      {
        termContentId: 76,
        language: 'en',
        text: '12 MONTHS DEFERRED INTEREST, PAYMENT REQUIRED: No Interest if paid in full within 12 months. Interest will be charged to your Account from the purchase date if the promotional plan balance is not paid in full within the promotional period. Minimum payments are required for each Credit Plan as disclosed in your Credit Card Agreement. Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period. You may have to pay more than the minimum payment to avoid accrued interest charges.  Valid for single transaction only. As of (insert date), terms for new Accounts: Purchase APR of 28.99%. Minimum interest charge is $2 per Credit Plan in any billing period in which interest is due . Existing Cardholders: see your credit card agreement for Account terms.Credit card offers are subject to credit approval.Alphaeon Credit Card Accounts are issued by Comenity Capital Bank.',
        contentType: 'text',
      },
    ],
  },
]

export const existingCreditApplication = {
  application_status: 'prequalified',
  application_service_provider_priority: {
    product_type: 'line_of_credit',
    priority: 1,
  },
  location_service_provider_priorities: {
    consumer: [
      {
        product_type: 'line_of_credit',
        priority: 1,
      },
      {
        product_type: 'installment',
        priority: 2,
      },
    ],
    practice_manager: [
      {
        product_type: 'line_of_credit',
        priority: 1,
      },
    ],
  },
  application_id: '23d7e730-761a-4f29-9660-fbe9fb3098c7',
  lender_request_reference_id: '15 CHAR prequalificationId STRING',
  lender_next_step_url: null,
  offers: [
    {
      offer_id: 13,
      expiration_date: '2023-01-10T20:27:54.419Z',
      amount: 24964,
      terms: [
        {
          term_content_id: 1,
          language: 'en',
          text: 'Lorem ipsum dolor sit amet, an expetenda dignissim conclusionemque his, ei quot propriae noluisse pri. Mei an ornatus definitiones, an eum esse scripta. Ad tamquam nonumes mentitum cum, vis facer sanctus ea, cu probo ridens sententiae qui. Ridens scripta intellegam eu sea. Ex exerci aperiri copiosae quo, vis aperiam reprehendunt in. Odio oblique vulputate ad per, essent alterum detracto te vim.\n\nHabeo viderer appetere est at, pro brute iuvaret legendos ex. An omittam ancillae persecuti nam, stet cetero eripuit nam eu. Discere pertinacia ea eam. Per eu maiorum similique, evertitur percipitur definitiones sit te. Ex vim nemore epicuri voluptua, cum no utroque necessitatibus. Est sententiae posidonium ex, ex vidit idque facer vix. Vix ei quod movet omittam, quo ex dolores senserit similique.\n',
          content_type: 'text',
        },
      ],
    },
  ],
}

export const prequalifiedCreditApplications = [
  {
    prequalifications: [
      {
        application_id: '1bb6f744-e802-4357-b4e3-5cef2501608e',
        application_status: 'prequalified',
        application_service_provider_priority: {
          product_type: 'line_of_credit',
          priority: 1,
        },
        location_service_provider_priorities: {
          consumer: [
            {
              product_type: 'line_of_credit',
              priority: 1,
            },
          ],
          practice_manager: [
            {
              product_type: 'line_of_credit',
              priority: 1,
            },
          ],
        },
        lender_request_reference_id: '3ef0452c-bc78-50b4-850e-324d2e0daa8c',
        lender_next_step_url:
          'https =>//alphaeonCredit.apply.coveredcare.io/campaign/1058_AlphaeonCredit/step2_amount. tGuid=44818AEF-FDA9-4C47-99C9-E7633F89B39E&cGuid=10CB392C-1080-4D18-B37A-2CBC2DB73D3D',
        location: {
          location_id: 346672,
          name: 'Dentistry.com',
          ads_store_number: 12344,
        },
        offers: [
          {
            offer_id: 346672,
            expiration_date: '2022-01-10',
            amount: 10000.5,
            terms: [
              {
                term_content_id: 123,
                language: 'en',
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\ntempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\nquis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo\nconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse\ncillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\nproident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n',
                content_type: 'string',
              },
            ],
          },
        ],
      },
    ],
    applicant: {
      first_name: 'George',
      middle_initial: 'A',
      last_name: 'Glass',
      date_of_birth: '1970-01-01',
      annual_income: 50000,
      email: 'george.a.glass@gmail.com',
      street1: '100 Main Street',
      street2: 'Apt. 1',
      city: 'Anytown',
      state: 'NY',
      zip: '02122',
      country: 'US',
      primary_phone: '8005551212',
      alternate_phone: '8005551111',
      purchase_amount: 10000.5,
    },
  },
]
