import React, { useCallback } from 'react'
import { mapValues } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'

function withApiAuth(actionCreatorMap) {
  return (Component) => {
    function WrappedComponent({ ...rest }) {
      const dispatch = useDispatch()
      const { getAccessTokenSilently } = useAuth0()
      const actionCreatorWithAuth = useCallback(
        async (actionCreator, ...args) => {
          const accessToken = await getAccessTokenSilently()

          return await dispatch(actionCreator(...args, accessToken))
        }
      )
      const mappedActionCreators = mapValues(
        actionCreatorMap,
        (actionValue) =>
          (...args) =>
            actionCreatorWithAuth(actionValue, ...args)
      )

      return <Component {...{ ...mappedActionCreators, ...rest }} />
    }

    WrappedComponent.displayName = `withApiAuth(${
      Component.displayName || Component.name
    })`

    return WrappedComponent
  }
}

export default withApiAuth
