import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  FormSection,
  Field,
  SubmissionError,
  propTypes as formPropTypes,
  formValues,
} from 'redux-form'
import { lpForm } from 'lp-form'
import {
  Input,
  Checkbox,
  SubmitButton,
  MaskedInput,
  Select,
  RadioGroup,
} from 'lp-components'

import { InputTip, SSNInput, FormSpy } from 'components'
import PropTypes from 'prop-types'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {
  formatISODate,
  formatISODateString,
  parseUSDayMonthYearDate,
  removeNonDigits,
  toNumber,
  validateAddress,
  validDateOfBirth,
} from 'utils'
import {
  STATES,
  RELATIONSHIPS,
  SHARE_WITH_FAMILY_OPTIONS,
  MIN_ANNUAL_INCOME,
} from 'config'
import { ApplicationEnvironmentType } from 'types'

const propTypes = {
  ...formPropTypes,
  shareWithFamily: PropTypes.string,
  applicationEnvironment: ApplicationEnvironmentType,
}

let warnMode = false

function FullApplicationForm({
  handleSubmit,
  shareWithFamily,
  submitting,
  applicationEnvironment,
}) {
  return (
    <div className="forms-container full-application__form">
      <form noValidate onSubmit={handleSubmit}>
        <Field
          component={InputTip}
          inputType={MaskedInput}
          name="purchaseAmount"
          label="Estimated Procedure Amount"
          placeholder="Estimated Procedure Amount"
          required
          requiredIndicator=" *"
          parse={toNumber}
          maskOptions={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }}
        />
        <Field
          name="email"
          component={Input}
          placeholder="Email Address (Optional)"
          type="email"
        />

        <Field
          name="firstName"
          component={Input}
          placeholder="First Name"
          label="First Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="lastName"
          component={Input}
          placeholder="Last Name"
          required
          requiredIndicator=" *"
        />
        <div className="stepper-checkbox full-application__form--mid-content  m-b-30">
          <p>
            <strong>
              Please read the following sentence to your customer:
            </strong>
          </p>
          <div className="m-t-10 psa">
            By providing your phone number, Alphaeon Credit and their service
            providers may contact you at any number you provide, including by
            autodialer, recorded or artificial voice, and text. Message
            frequency varies. Message and data rates may apply. For further
            details, refer to our Consent to Autodialed Calls/Texts below.
          </div>
        </div>
        <Field
          name="primaryPhone"
          placeholder="Mobile Number"
          label="Mobile Number"
          component={InputTip}
          inputType={MaskedInput}
          required
          requiredIndicator=" *"
          parse={removeNonDigits}
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 3, 4],
            delimiters: ['(', ') ', '-'],
          }}
          tooltips={() => (
            <div className="input-tips mobile-number">
              <ul>
                <li>
                  Please confirm that this mobile number is ready and accessible
                  by the patient as it may be used for authentication.
                </li>
              </ul>
            </div>
          )}
        />
        <Field
          name="street1"
          component={Input}
          validate={validateAddress}
          placeholder="Address Line 1"
          required
          requiredIndicator=" *"
        />
        <Field
          name="apt"
          component={Input}
          placeholder="Apt, Unit, Suite, etc.(optional)"
        />
        <Field
          name="city"
          component={Input}
          placeholder="City"
          required
          requiredIndicator=" *"
        />
        <Field
          label={'State'}
          name="state"
          component={Select}
          placeholder="State"
          options={STATES}
          required
          requiredIndicator=" *"
        />
        <div className="date-birth">
          <Field
            name="zip"
            component={Input}
            placeholder="Zip"
            required
            maxLength={5}
            requiredIndicator=" *"
          />
        </div>
        <div className="date-birth">
          <Field
            component={InputTip}
            inputType={MaskedInput}
            name="dateOfBirth"
            placeholder="MM/DD/YYYY"
            required
            requiredIndicator=" *"
            maskOptions={{
              date: true,
              datePattern: ['m', 'd', 'Y'],
            }}
          />
        </div>
        <Field
          component={SSNInput}
          inputType={MaskedInput}
          required
          requiredIndicator=" *"
          label="Social Security Number"
          name="ssn"
          maskOptions={{
            delimiter: '-',
            blocks: [3, 2, 4],
          }}
          parse={removeNonDigits}
          tooltips={() => (
            <div className="input-tips social-security">
              <LockOutlinedIcon className="gray-icon" />
              <p>
                &nbsp;We use your social security number to validate your
                information and check your credit. Your information is
                transmitted securely.
              </p>
            </div>
          )}
          placeholder="Social Security Number"
        />
        <Field
          component={InputTip}
          inputType={MaskedInput}
          name="annualIncome"
          placeholder="Annual Income"
          required
          requiredIndicator=" *"
          maskOptions={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }}
          parse={toNumber}
          tooltips={() => (
            <div className="input-tips">
              <p>
                If you do not wish to have alimony, child support and/or
                separate maintenance income considered as a basis for repaying
                this obligation, please do not include it in your Annual Income
                amount.
              </p>
              <FormSpy name="state">
                {(state) => (
                  <>
                    {state.includes('WI') && (
                      <p>
                        <strong>Married WI Residents Only:</strong> if you are
                        applying for an individual account and your spouse is
                        also a WI resident, combine you and your spouse's
                        financial information.
                      </p>
                    )}
                  </>
                )}
              </FormSpy>
            </div>
          )}
        />

        {applicationEnvironment?.authorizedBuyersEnabled === true && (
          <div className="radio-group">
            <div className="info-contain">
              Want to share your Alphaeon Credit Card with family or friends?
            </div>
            <div className="radio-group__share m-t-30">
              <Field
                component={RadioGroup}
                name="shareWithFamily"
                label={false}
                options={SHARE_WITH_FAMILY_OPTIONS}
              />
            </div>

            <FormSection name="authorizedBuyer">
              {shareWithFamily === 'yes' && (
                <div className="m-t-30">
                  <Field
                    name="firstName"
                    component={Input}
                    placeholder="First Name"
                    label="First Name"
                    required
                    requiredIndicator=" *"
                  />

                  <Field
                    name="lastName"
                    component={Input}
                    placeholder="Last Name"
                    required
                    requiredIndicator=" *"
                  />

                  <Field
                    name="relationship"
                    component={Select}
                    options={RELATIONSHIPS}
                    label={'Relationship To Applicant'}
                    placeholder="Relationship To Applicant"
                    required
                    requiredIndicator=" *"
                  />

                  <div className="date-of-birth">
                    <Field
                      component={InputTip}
                      inputType={MaskedInput}
                      name="dateOfBirth"
                      placeholder="MM/DD/YYYY"
                      required
                      requiredIndicator=" *"
                      maskOptions={{
                        date: true,
                        datePattern: ['m', 'd', 'Y'],
                      }}
                    />
                  </div>
                  <div className="authorized-buyer-text">
                    An authorized buyer is an individual you give permission to
                    make purchases on your account. As the primary
                    accountholder, you are I responsible for paying for all
                    purchases made on this account, including any made by an
                    authorized buyer.
                  </div>
                </div>
              )}
            </FormSection>
          </div>
        )}

        <div className="stepper-checkbox">
          {applicationEnvironment?.authorizedBuyersEnabled === true && (
            <div className="psa m-b-30">
              An authorized buyer is an individual you give permission to make
              purchases on your account. As the primary accountholder, you are
              responsible for paying for all purchases made on this account,
              including any made by an authorized buyer.
            </div>
          )}
          <div className="checkbox-container">
            <Field name="agreed" component={Checkbox} label={false} />

            <div className="checkbox-right">
              <p>
                <strong>
                  By clicking the Submit Request button below, you acknowledge
                  that you have received, read, and agreed to all of the
                  following:
                </strong>
              </p>
              <div className="privacy-content">
                <ol>
                  <li>
                    I confirm that the applicant is 21 years of age or over OR
                    that any applicant under the age of 21, I have received a
                    written application and the applicant agrees I will submit
                    the application electronically on their behalf.
                  </li>
                  <li>
                    {' '}
                    I acknowledge receipt of the Terms and Conditions and
                    Privacy Notice, and I am electronically signing and
                    submitting this application/solicitation for the Alphaeon
                    Credit Card.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <FormSpy name="agreed">
          {(agreed) => (
            <SubmitButton
              disabled={!agreed || submitting}
              className={`button-warn ${!agreed ? `warn-disabled` : ``}`}
              submitting={submitting}
            >
              SUBMIT REQUEST
            </SubmitButton>
          )}
        </FormSpy>
      </form>
    </div>
  )
}

FullApplicationForm.propTypes = propTypes

function beforeSubmit(formValues) {
  const { authorizedBuyer } = formValues

  if (authorizedBuyer) {
    const isoAuthorizedBuyerDateOfBirth = formatISODateString(
      authorizedBuyer.dateOfBirth
    )
    authorizedBuyer.dateOfBirth = isoAuthorizedBuyerDateOfBirth
  }

  const dateOfBirth = parseUSDayMonthYearDate(formValues.dateOfBirth)
  const annualIncome = formValues.annualIncome

  if (!validDateOfBirth(dateOfBirth)) {
    throw new SubmissionError({
      dateOfBirth:
        'Date of birth year must be after 1900 and applicant must be at least 18 years old',
    })
  }

  if (annualIncome < MIN_ANNUAL_INCOME && !warnMode) {
    warnMode = true
    throw new SubmissionError({
      _error: `Please confirm that your annual income is $${annualIncome}.`,
    })
  }

  return {
    ...formValues,
    dateOfBirth: formatISODate(dateOfBirth),
    authorizedBuyer,
  }
}

function mapStateToProps() {
  return {}
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'fullApplicationForm',
    constraints: {
      purchaseAmount: {
        presence: { message: "^Estimated Procedure Amount can't be blank" },
      },
      firstName: { presence: true },
      lastName: { presence: true },
      primaryPhone: { presence: { message: "^Mobile Number can't be blank" } },
      state: { presence: true },
      street1: { presence: true },
      city: { presence: true },
      zip: {
        numericality: { onlyInteger: true },
        presence: true,
        length: { is: 5 },
      },
      dateOfBirth: { presence: true, length: { is: 10 } },
      ssn: { presence: true },
      annualIncome: { presence: true },
      'authorizedBuyer.firstName': { presence: true },
      'authorizedBuyer.lastName': { presence: true },
      'authorizedBuyer.relationship': { presence: true },
      'authorizedBuyer.dateOfBirth': { presence: true, length: { is: 10 } },
    },
    initialValues: {
      shareWithFamily: 'no',
    },
    submitFilters: { reject: ['agreed', 'shareWithFamily'] },
  }),
  formValues('shareWithFamily'),
  connect(mapStateToProps)
)(FullApplicationForm)
