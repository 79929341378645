import React, { useState, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Table, TableColumn as Column } from 'lp-components'
import { ApplicationActionCell } from 'components'
import ReportDetailsContainer from './ReportDetailsContainer'
import { selectors as merchantPortalSelectors } from 'merchant-portal-reducer'
import {
  ACCOUNT_DETAIL_URL,
  SALES_REPORT_SUMMARY_HEADERS,
  EMPTY_SALES_REPORT_SUMMARY,
  SALES_REPORT_DETAIL_HEADER,
} from 'config'
import { SalesReportType } from 'types'
import {
  formatCurrency,
  formatEmailAddress,
  formatISODateStringAsUSMonthDayYear,
  formatLocation,
  formatUpperCase,
  mapTransactionTypeToProductType,
  parseAndCompareDatesAsc,
} from 'utils'

const propTypes = {
  salesReports: SalesReportType.isRequired,
}
const defaultProps = {}

function SalesReportDetails({ salesReports }) {
  const reportRef = useRef(null)
  const [summaryDetail, setSummaryDetail] = useState([])
  const [locationData, setLocationData] = useState([])
  const [allSelected, setAllSelected] = useState(false)

  const { summary, locationSummaries, transactions } = salesReports

  const summaryWithSummaryLabel = {
    ...summary,
    locationName: 'SUMMARY',
    locationNumber: undefined,
    checked: false,
  }

  useEffect(() => {
    const locationsSummary = locationSummaries.map((location) => ({
      ...location,
      checked: false,
    }))
    setLocationData([summaryWithSummaryLabel, ...locationsSummary])
  }, [locationSummaries])

  const setSummaryDetailLocation = (data) => {
    const value = locationData.findIndex(
      (val) => val.locationName === data.locationName
    )
    locationData[value] = {
      ...locationData[value],
      checked: data.checked,
    }
    setLocationData([...locationData])

    const locationNamesArr = locationData.map(
      (location) => location.checked && location.locationName
    )
    setSummaryDetail(
      transactions
        .filter((transaction) =>
          locationNamesArr.includes(transaction.locationName)
        )
        .sort(compareTransactionsAsc)
    )
  }

  const getSummaryDetailData = () => {
    return summaryDetail.map((summary) => ({
      column01: formatLocation(summary.locationName, summary.locationId),
      column02: formatISODateStringAsUSMonthDayYear(summary.createdAt),
      column03: summary.name.firstName + ' ' + summary.name.lastName,
      column04: summary.alphaeonAccountNumber?.slice(-4),
      column05: summary.authCode,
      column06: summary.planName,
      column07: summary.planIdentifier,
      column08: mapTransactionTypeToProductType(summary.transactionType),
      column09: summary.transactionType.toUpperCase(),
      column10: formatCurrency(summary.amount, 2),
      column11: summary.status.toUpperCase(),
      column12: formatEmailAddress(summary.associate),
    }))
  }

  const getLocationSummary = () => {
    return locationData.map((location) => ({
      column01: location.locationName,
      column02: location.totalRefunds,
      column03: location.totalSales,
    }))
  }

  const getCSVData = () => {
    const summaryDetailData = getSummaryDetailData()
    const locationSummary = getLocationSummary()
    return [
      ...locationSummary,
      ...new Array(5).fill(EMPTY_SALES_REPORT_SUMMARY),
      SALES_REPORT_DETAIL_HEADER,
      ...summaryDetailData,
    ]
  }

  const selectAllLocation = (selected) => {
    setAllSelected(selected)

    if (selected) {
      setSummaryDetail(transactions.sort(compareTransactionsAsc))
      setLocationData(locationData.map((row) => ({ ...row, checked: true })))
    } else {
      setSummaryDetail([])
      setLocationData(locationData.map((row) => ({ ...row, checked: false })))
    }
  }

  return (
    <ReportDetailsContainer
      csvHeaders={SALES_REPORT_SUMMARY_HEADERS}
      csvFilename="SalesReport.csv"
      csvData={getCSVData()}
      reportRef={reportRef}
    >
      <div id="report-container" ref={reportRef}>
        <div className="report-detail__headers m-t-20">
          <div className="report-detail--title title ">Sales Results</div>
        </div>

        <div className="report-detail__summary m-t-50">
          {locationSummaries.length > 0 ? (
            <Table data={locationData}>
              <Column
                name="checked"
                label="Show Details"
                className="show-details"
                component={({ data }) => (
                  <ApplicationActionCell
                    data={data}
                    setData={setSummaryDetailLocation}
                    selectAllLocation={selectAllLocation}
                    allSelected={allSelected}
                  />
                )}
              />
              <Column name="locationName" label="Location" />
              <Column
                name="totalRefunds"
                label="Total Refunds"
                component={({ data }) => {
                  return <td>{formatCurrency(data.totalRefunds, 2)}</td>
                }}
              />
              <Column
                name="totalSales"
                label="Total Sales"
                component={({ data }) => {
                  return <td>{formatCurrency(data.totalSales, 2)}</td>
                }}
              />
            </Table>
          ) : (
            <div className="empty-state-container">
              <p>No matching results</p>
            </div>
          )}
        </div>

        <div className="report-detail--sub-title title m-t-50">Details</div>
        <div className="report-detail__summary-detail sales-report m-t-50">
          {summaryDetail.length > 0 ? (
            <Table className="detail-table" data={summaryDetail}>
              <Column
                name="location"
                label="Location"
                valueGetter={({ locationName, locationId }) =>
                  formatLocation(locationName, locationId)
                }
              />
              <Column
                name="createdAt"
                label="Tran Date"
                format={(createdAt) =>
                  formatISODateStringAsUSMonthDayYear(createdAt)
                }
              />
              <Column
                name="name"
                label="Customer"
                component={({ value }) => (
                  <td>{`${value.firstName} ${
                    value.middleInitial ? value.middleInitial : ''
                  } ${value.lastName}`}</td>
                )}
              />
              <Column
                name="alphaeonAccountNumber"
                headerComponent={() => (
                  <th>
                    Account/
                    <br />
                    Loan #<br />
                    (Last 4)
                  </th>
                )}
                format={(value) => {
                  const displayValue = value?.slice(-4)
                  return value ? (
                    <Link to={`${ACCOUNT_DETAIL_URL}${value}`}>
                      {displayValue}
                    </Link>
                  ) : (
                    '-'
                  )
                }}
              />
              <Column
                name="authCode"
                label="Auth #"
                style={{ textAlign: 'center ' }}
              />
              <Column name="planName" label="Plan" />
              <Column name="planIdentifier" label="Plan #" />
              <Column
                name="productType"
                label="Product Type"
                valueGetter={({ transactionType }) =>
                  mapTransactionTypeToProductType(transactionType)
                }
              />
              <Column
                name="transactionType"
                label="Tran Type"
                format={formatUpperCase}
              />
              <Column
                name="amount"
                label="Tran $"
                format={(amount) => formatCurrency(amount, 2)}
              />

              <Column name="status" label="Status" format={formatUpperCase} />

              <Column name="associate" label="Associate" />
            </Table>
          ) : (
            <>Select Summary above to show Details</>
          )}
        </div>
      </div>
    </ReportDetailsContainer>
  )
}

SalesReportDetails.propTypes = propTypes
SalesReportDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    salesReports: merchantPortalSelectors.salesReports(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SalesReportDetails
)

function compareTransactionsAsc(t1, t2) {
  const locationId1 = t1.locationId
  const locationId2 = t2.locationId
  const transactionDate1 = t1.createdAt
  const transactionDate2 = t2.createdAt

  return (
    parseAndCompareDatesAsc(transactionDate1, transactionDate2) ||
    locationId1 - locationId2
  )
}
