import React, { useState } from 'react'
import exact from 'prop-types-exact'
import { SketchPicker } from 'react-color'
import { Switch } from 'lp-components'
import Select from 'react-select'
import PropTypes from 'prop-types'

const propTypes = {
  storeNumber: PropTypes.number.isRequired,
  initialSize: PropTypes.number.isRequired,
  initialColor: PropTypes.string.isRequired,
  initialLogo: PropTypes.bool.isRequired,
  sizeOptions: PropTypes.array.isRequired,
}

const defaultProps = {
  initialSize: 680,
  initialColor: '9B1B3C',
  initialLogo: true,
  sizeOptions: [
    { label: 'S', value: 170 },
    { label: 'M', value: 340 },
    { label: 'L', value: 510 },
    { label: 'XL', value: 680 },
  ],
}

function QrCode({
  storeNumber,
  initialSize,
  initialColor,
  initialLogo,
  sizeOptions,
}) {
  const [color, setColor] = useState(initialColor)
  const [size, setSize] = useState(initialSize)
  const [logo, setLogo] = useState(initialLogo)

  const handleColorChangeComplete = (color) => {
    setColor(color.hex)
  }

  const tabletUrl = `${process.env.REACT_APP_CONSUMER_APP_URL}/practice-application/${storeNumber}`
  const storeUrl = `${process.env.REACT_APP_CONSUMER_APP_URL}/credit-portal/store/${storeNumber}`

  return (
    <>
      <div className="qr-code-customizer-component">
        <div className="custom-application-link">
          Custom Application Link: <a href={storeUrl}>{storeUrl}</a>
        </div>
        <div className="custom-application-link">
          Tablet Link: <a href={tabletUrl}>{tabletUrl}</a>
        </div>

        <div className="qr-code-column qr-code-result">
          <span className="helper"></span>
          <img
            style={{ width: 'fit-content' }}
            src={`${
              process.env.REACT_APP_API_URL
            }/qr_code/store/${storeNumber}?size=${size}&color=${encodeURIComponent(
              color
            )}&logo=${logo}`}
          />
        </div>
        <div className="qr-code-column qr-code-selection">
          <div className="qr-code-selection-option">
            <label>Size</label>
            <Select
              name="size"
              className="selector"
              component={Select}
              options={sizeOptions}
              label="Size"
              placeholder="size"
              defaultValue={sizeOptions[3]}
              onChange={(newSize) => setSize(newSize.value)}
            />
          </div>
          <div className="qr-code-selection-option">
            <label>Logo</label>
            <Switch
              label={false}
              className="selector"
              uncheckedIcon={
                <div
                  style={{
                    color: 'white',
                    paddingLeft: '4px',
                    paddingTop: '8px',
                    fontFamily: 'inherit',
                    fontSize: '11px',
                  }}
                >
                  OFF
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    color: 'white',
                    paddingLeft: '7px',
                    paddingTop: '8px',
                    fontFamily: 'inherit',
                    fontSize: '11px',
                  }}
                >
                  ON
                </div>
              }
              input={{
                name: 'logo',
                value: logo,
              }}
              onChange={() => setLogo(!logo)}
              meta={{}}
            />
          </div>
          <div className="qr-code-selection-option">
            <label>Color</label>
            <SketchPicker
              color={color}
              className="selector"
              onChangeComplete={handleColorChangeComplete}
            />
          </div>
        </div>
        <div className="qr-code-column info">
          <label>
            To download, right click the image above and select 'Save image
            As...'
          </label>
        </div>
      </div>
    </>
  )
}

QrCode.propTypes = exact(propTypes)
QrCode.defaultProps = defaultProps

export default QrCode
