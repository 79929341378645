import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LocationType } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Spinner } from 'components'
import SiteInitializationLayout from './SiteInitializationLayout'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { setLocation, getLocation } from 'local-storage'
import * as apiActions from 'api-actions'
import { NO_MANAGER_ACCESS_URL } from 'config'
import { withApiAuth } from 'utils'

const propTypes = {
  allLocations: PropTypes.arrayOf(LocationType),
  children: PropTypes.node.isRequired,
  clearAllLocations: PropTypes.func.isRequired,
  fetchAllLocationsForManager: PropTypes.func.isRequired,
  currentLocation: LocationType,
}
const defaultProps = {}

function AllLocationsProvider({
  allLocations,
  children,
  clearAllLocations,
  fetchAllLocationsForManager,
  fetchLocationDetails,
  currentLocation,
}) {
  const [managerAccessError, setManagerAccessError] = useState(false)

  useEffect(() => {
    fetchAllLocationsForManager().catch((error) => {
      if (error?.response?.errorCode === 'manager_not_found') {
        setManagerAccessError(true)
      }
    })

    return () => clearAllLocations()
  }, [])

  if (managerAccessError) {
    return <Redirect to={NO_MANAGER_ACCESS_URL} />
  }

  if (!allLocations) return <SiteInitializing />

  if (
    !currentLocation ||
    !currentLocation.locationId ||
    !currentLocation.permissions
  ) {
    const persistedLocation = getLocation()
    const defaultLocation =
      persistedLocation &&
      allLocations.some(
        (location) => location.locationId === persistedLocation.locationId
      )
        ? persistedLocation
        : allLocations[0]
    fetchLocationDetails(defaultLocation.locationId).then((res) =>
      setLocation(res)
    )
    return <SiteInitializing />
  }

  return children
}

AllLocationsProvider.propTypes = propTypes
AllLocationsProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allLocations: selectors.allLocations(state),
    currentLocation: selectors.currentLocation(state),
  }
}

const mapDispatchToProps = {
  clearAllLocations: actions.clearAllLocations,
}

const mapApiAuthToProps = {
  fetchAllLocationsForManager: apiActions.fetchAllLocationsForManager,
  fetchLocationDetails: apiActions.fetchLocationDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AllLocationsProvider)

function SiteInitializing() {
  return (
    <SiteInitializationLayout>
      <Spinner />
    </SiteInitializationLayout>
  )
}
