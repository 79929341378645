import { isNil } from 'lodash'

export default function formatEmailAddress(email) {
  if (isNil(email)) return ''
  if (!email.includes('@')) return email

  const [user] = email.split('@')

  return `${user}@`
}
