import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, SubmitButton } from 'lp-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectors } from '../reducer'
//import * as apiActions from 'api-actions'
import {
  PRE_QUALIFICATION_DECLINED_URL,
  PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
  WIZARD_URL,
  ACCESS_LOAN_URL,
} from 'config'
import { PracticeType } from 'types'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.any,
  practice: PracticeType,
  //requestPrequalification: PropTypes.func.isRequired,
}
const defaultProps = {}

function PrequalAcceptDeclineModal({
  onClose,
  payload,
  practice,
  //requestPrequalification,
}) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  // Keeping this for later.
  // eslint-disable-next-line no-unused-vars
  const requestPrequalification = () => {
    //empty for now.
  }

  // Keeping this for later.
  // eslint-disable-next-line no-unused-vars
  const reApplyForPreQualification = () => {
    setLoading(true)
    return requestPrequalification(payload).then((prequalification) => {
      const { applicationStatus, lenderNextStepUrl } = prequalification
      setLoading(false)

      if (applicationStatus === 'prequalified') {
        history.push({
          pathname: WIZARD_URL,
          state: {
            step: 'PRE-QUALIFICATION',
          },
        })
      } else if (applicationStatus === 'prequalified_account_exists') {
        history.push(PRE_QUALIFICATION_ACCOUNT_EXISTS_URL)
      } else if (applicationStatus === 'pending_approval') {
        // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
        //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."

        history.push({
          pathname: ACCESS_LOAN_URL,
          state: {
            lenderNextStepUrl,
          },
        })
      } else {
        history.push(PRE_QUALIFICATION_DECLINED_URL)
      }
    })
  }
  return (
    <Modal
      onClose={onClose}
      overlayClassName="modal-fade-screen prequal-decline-modal-overlay"
      className="modal-inner prequal-decline-modal modal"
    >
      <div>
        <div className="prequal-decline-modal__container">
          <div className="details">
            We were unable to approve you for an Alphaeon Credit Card. You will
            receive an explanation letter in 7-10 business days.
            <br />
            <br />
            However, we were able to pre-qualify you for our Access Loan
            {practice && ` at ${practice.name}`}.
          </div>
          <div className="button-block">
            <SubmitButton
              className="button-warn-outline"
              disabled={loading}
              submitting={loading}
              onClick={() => onClose()}
            >
              Continue
            </SubmitButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

PrequalAcceptDeclineModal.propTypes = propTypes
PrequalAcceptDeclineModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    personalInfo: selectors.personalInfo(state),
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {
  //requestPrequalification: apiActions.requestPrequalification,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrequalAcceptDeclineModal
)
