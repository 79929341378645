import React from 'react'
import { Modal } from 'lp-components'
import PropTypes from 'prop-types'
import { AccountDetailType } from 'types'
import { formatCurrency } from 'utils'
import { format } from 'date-fns'
import LoanAdjustmentConfirmationForm from '../forms/ConfirmationForm'

const propTypes = {
  adjustmentData: PropTypes.any,
  accountDetail: AccountDetailType.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleSubmitSuccess: PropTypes.func.isRequired,
  handleSubmitFailure: PropTypes.func.isRequired,
}

function LoanAdjustConfirmationModal({
  adjustmentData,
  handleClose,
  accountDetail,
  handleConfirm,
  handleSubmitSuccess,
  handleSubmitFailure,
}) {
  const { serviceDate, creditLimit } = accountDetail

  return (
    <Modal
      onClose={() => {
        handleClose()
      }}
      hideCloseButton
      className="modal-inner prequal-decline-modal modal"
    >
      <div className="sales-confirmation">
        <div className="sales-confirmation__container">
          <div className="title">Confirm Loan Adjust Details</div>
          <table className="details m-t-20">
            {adjustmentData?.serviceDate && (
              <tr>
                <td className="">
                  Please confirm the service date adjustment from{' '}
                  {format(new Date(serviceDate), 'MM/dd/yyyy')} to{' '}
                  {format(new Date(adjustmentData?.serviceDate), 'MM/dd/yyyy')}
                </td>
              </tr>
            )}
            {adjustmentData?.amount && (
              <tr>
                <td className="">
                  Please confirm the adjusted amount from{' '}
                  {formatCurrency(creditLimit, 2)} to{' '}
                  {formatCurrency(adjustmentData?.amount, 2)} is accurate as it
                  cannot be increased after the update.
                </td>
              </tr>
            )}
          </table>
        </div>
        <LoanAdjustmentConfirmationForm
          onSubmit={handleConfirm}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFailure}
          handleClose={handleClose}
        />
      </div>
    </Modal>
  )
}

LoanAdjustConfirmationModal.propTypes = propTypes

export default LoanAdjustConfirmationModal
