import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

const propTypes = {
  authBuyers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      authorizedBuyerAddedDate: PropTypes.string,
    })
  ),
}

function AuthorizedBuyers({ authBuyers }) {
  return (
    <>
      {authBuyers &&
        authBuyers.map((buyer) => {
          const {
            firstName,
            lastName,
            relationship,
            authorizedBuyerAddedDate,
          } = buyer
          return (
            <span key={authorizedBuyerAddedDate + firstName}>
              {firstName} {lastName}{' '}
              <span className="capitalize">
                {relationship && relationship !== 'N/A' && (
                  <>- {relationship} </>
                )}
              </span>
              {authorizedBuyerAddedDate && (
                <>
                  - Added -{' '}
                  {authorizedBuyerAddedDate == '9999-12-12'
                    ? 'N/A'
                    : format(new Date(authorizedBuyerAddedDate), 'MM/dd/yyyy')}
                </>
              )}
              <br />
            </span>
          )
        })}
    </>
  )
}

AuthorizedBuyers.propTypes = propTypes

export default AuthorizedBuyers
