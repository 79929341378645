import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { isNil } from 'lodash'
import { ERROR_STATUS } from 'config'
import { handleClientError, handleServerError } from 'utils'

// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before() {
  return {}
}

function onSuccess(res) {
  return res
}

function onFailure(httpError) {
  const clientErrors = [401, 403, 404]

  // An undefined httpError.status indicates a failure to send an API request, likely due
  // to the connection with the backend API not being available.
  const status = httpError?.status

  if (isNil(status)) return handleClientError(ERROR_STATUS.API_DOWN)
  if (clientErrors.includes(status)) return handleClientError(status)

  if (status === 503) return handleServerError()

  return httpError
}

// Global handler to trigger when API receives a response with a 401 code.
// This is invoked _in addition to_ the failureAction option.
function onUnauthorized() {
  return function (/* dispatch */) {
    // dispatch(sessionsActions.logout())
    window.location.reload()
  }
}

export const config = {
  root: process.env.REACT_APP_API_URL,
  before,
  onSuccess,
  onFailure,
  onUnauthorized,
  mode: 'cors',
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)
