import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import exact from 'prop-types-exact'
import { isNil } from 'lodash'

import { Modal } from 'lp-components'
import { Button } from 'components'
import * as applicationServerApiActions from 'application-server-api-actions'
import { selectors } from 'global-reducer'
import { REACT_APP_BUILDID_FETCH_INTERVAL } from 'config'
import { useInterval } from 'hooks'
import { fetchApplicationBuildId, forceRedirect } from 'utils'

const propTypes = {
  applicationBuildId: PropTypes.string,
  getApplicationBuildId: PropTypes.func.isRequired,
  redirectURL: PropTypes.string.isRequired,
}
const defaultProps = {}

function VersionChangeReporter({
  applicationBuildId,
  redirectURL,
  getApplicationBuildId,
}) {
  const [displayVersionModal, setDisplayVersionModal] = useState(false)

  const reloadApplication = () => {
    forceRedirect({ to: redirectURL })
  }

  const determineIfBuildIdChanged = useCallback(async () => {
    if (!applicationBuildId) return

    const latestBuildId = await fetchApplicationBuildId()

    if (!isNil(latestBuildId) && latestBuildId !== applicationBuildId) {
      setDisplayVersionModal(true)
    }
  }, [applicationBuildId])

  useEffect(() => {
    getApplicationBuildId()
  }, [])

  useInterval(determineIfBuildIdChanged, {
    interval: REACT_APP_BUILDID_FETCH_INTERVAL,
  })

  return (
    displayVersionModal && (
      <Modal className="alert-modal" onClose={reloadApplication} preventClose>
        <>
          <header>
            <h1>System Upgrade/Updated</h1>
          </header>
          <p>
            We apologize for the inconvenience, but we just released some
            updates to the dashboard that will require a page reload to take
            effect. Please click below to reload your dashboard.
          </p>
          <Button
            className="button-primary-outline"
            onClick={reloadApplication}
          >
            Continue
          </Button>
        </>
      </Modal>
    )
  )
}

VersionChangeReporter.propTypes = exact(propTypes)
VersionChangeReporter.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    applicationBuildId: selectors.applicationBuildId(state),
  }
}

const mapDispatchToProps = {
  getApplicationBuildId: applicationServerApiActions.getApplicationBuildId,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  VersionChangeReporter
)
