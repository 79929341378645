import React from 'react'
import exact from 'prop-types-exact'

import { ALPHAEON_CONTACT_EMAIL, ALPHAEON_CONTACT_PHONE } from 'config'

const propTypes = {}
const defaultProps = {}

function ContactUs() {
  return (
    <div className="page-container contact-us-container">
      <h1>Alphaeon Credit Practice Support</h1>
      <div className="content">
        <p className="contact-info">
          <span>
            Phone:{' '}
            <a href={`tel:${ALPHAEON_CONTACT_PHONE}`}>
              {ALPHAEON_CONTACT_PHONE}
            </a>
          </span>
        </p>
        <p className="contact-info">
          <span>
            Email:{' '}
            <a href={`mailto:${ALPHAEON_CONTACT_EMAIL}`}>
              {ALPHAEON_CONTACT_EMAIL}
            </a>
          </span>
        </p>
        <p>
          The practice hotline can assist with enrollments, general questions,
          and requests, however they do not have access to specific details
          concerning individual patient accounts.
        </p>
      </div>
    </div>
  )
}

ContactUs.propTypes = exact(propTypes)
ContactUs.defaultProps = defaultProps

export default React.memo(ContactUs)
