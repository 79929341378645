import {
  APPLICATION_ROOT_URL,
  REACT_APP_BUILDID_FETCH_INTERVAL,
  isProduction,
} from 'config'

export default async function getApplicationBuildID() {
  if (!isProduction() || REACT_APP_BUILDID_FETCH_INTERVAL === '0') {
    return Promise.resolve('DEFAULT APPLICATION VERSION')
  }

  return await fetch(`${APPLICATION_ROOT_URL}/buildid`)
}
