import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}
const defaultProps = {}

function SectionSeparator() {
  return (
    <div className="section-separator">
      <hr />
    </div>
  )
}

SectionSeparator.propTypes = exact(propTypes)
SectionSeparator.defaultProps = defaultProps

export default React.memo(SectionSeparator)
