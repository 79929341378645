import { isNil } from 'lodash'

export default function formatErrorMessage(error_message) {
  if (isNil(error_message)) return error_message

  const mapping = {
    purchase_amount: 'Purchase Amount',
  }

  Object.keys(mapping).forEach((key) => {
    error_message = error_message.replaceAll(key, mapping[key])
  })

  return error_message
}
