import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button } from 'lp-components'
import { flashErrorMessage } from 'redux-flash'

import { PracticeSearchInput } from '../components'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { APPLICATION_FINDER_URL, QUERY_KEY, WIZARD_URL } from 'config'
import { PracticeType } from 'types'
import { apiErrorToErrorMessage, isPresent, parseQuery } from 'utils'

const propTypes = {
  clearPractice: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  practice: PracticeType,
}
const defaultProps = {}

function Home({ clearPractice, flashErrorMessage, practice }) {
  const history = useHistory()
  const { search } = useLocation()
  const query = parseQuery(search)

  const handleClick = (url) => {
    history.push(url)
  }

  useEffect(() => {
    const initialError = query[QUERY_KEY.ERROR]

    if (initialError) flashErrorMessage(apiErrorToErrorMessage(initialError))
    if (isPresent(practice)) clearPractice()
  }, [])

  return (
    <>
      <div className="home-content">
        <div className="home-container">
          <div className="home-left">
            <p className="practice">Select Your Practice</p>
            <div className="simple-form">
              <PracticeSearchInput
                searchPromptText="CONTINUE WITH PRACTICE"
                className="w-70"
              />
            </div>
            <Link to={APPLICATION_FINDER_URL}>
              Application already started?
            </Link>
          </div>
          <div className="separator">
            <div className="or">OR</div>
          </div>
          <div className="home-right">
            <p className="practice">Haven't chosen a doctor yet?</p>
            <Button
              onClick={() => handleClick(WIZARD_URL)}
              className="button-warn-outline qualify"
            >
              PRE-QUALIFY
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {
  clearPractice: actions.clearPractice,
  flashErrorMessage: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
