import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { useLocation } from 'react-router-dom'

import {
  ApplicationReportDetails,
  ReconciliationReport,
  SalesReportDetails,
} from '../components'
import { ReportsForm } from '../forms'
import { selectors as merchantPortalSelectors } from 'merchant-portal-reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import {
  APPLICATION_TRACKING,
  BANK_RECONCILIATION,
  DATE_SELECTION,
  SALES,
  QUERY_KEY,
} from 'config'
import { LocationType, PermissionsType } from 'types'
import { withApiAuth, getReportDates, parseQuery } from 'utils'

const propTypes = {
  clearAllReports: PropTypes.func.isRequired,
  currentPermissions: PermissionsType,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  getApplicationsReport: PropTypes.func.isRequired,
  getReconciliationReport: PropTypes.func.isRequired,
  getSalesReport: PropTypes.func.isRequired,
  reportType: PropTypes.object,
}
const defaultProps = {}

function Reports({
  allLocations,
  clearAllReports,
  currentPermissions,
  getApplicationsReport,
  getReconciliationReport,
  getSalesReport,
  reportType: selectedReportType,
}) {
  const [ReportDetailView, setReportDetailView] = useState(null)

  const { search } = useLocation()
  const query = parseQuery(search)
  const type = query[QUERY_KEY.TYPE]
  const reportType = selectedReportType?.value
  const { startDate: readOnlyStartDate, endDate: readOnlyEndDate } =
    getReportDates({ dateFilterType: DATE_SELECTION.MONTH_TO_DATE })

  useEffect(() => {
    return () => clearAllReports()
  }, [])

  const handleSubmit = ({
    dateFilterType,
    endDate,
    locationIds,
    startDate,
  }) => {
    setReportDetailView(null)

    const reportDates = getReportDates({
      dateFilterType,
      fromDate: startDate,
      toDate: endDate,
    })
    const selectedLocationIds = locationIds.map(({ value }) => value)
    let reportDataGetter

    switch (reportType) {
      case APPLICATION_TRACKING:
        reportDataGetter = getApplicationsReport
        break
      case BANK_RECONCILIATION:
        reportDataGetter = getReconciliationReport
        break
      case SALES:
        reportDataGetter = getSalesReport
        break

      default:
        throw new Error(`Unknown report type [${reportType}]`)
    }

    return reportDataGetter({
      ...reportDates,
      locationIds: selectedLocationIds,
    })
  }

  const handleSubmitSuccess = () => {
    switch (reportType) {
      case SALES:
        setReportDetailView(() => SalesReportDetails)
        break
      case APPLICATION_TRACKING:
        setReportDetailView(() => ApplicationReportDetails)
        break
      case BANK_RECONCILIATION:
        setReportDetailView(() => ReconciliationReport)
        break
      default:
        throw new Error(`Unhandled report type [${reportType}]`)
    }
  }

  return (
    <div className="report-page">
      <h1>Reports</h1>

      <div className="report-page__container">
        <ReportsForm
          allLocations={allLocations}
          initialValues={{
            dateFilterType: DATE_SELECTION.MONTH_TO_DATE,
            locationIds: [],
            reportType: type,
            readOnlyStartDate,
            readOnlyEndDate,
          }}
          permissions={currentPermissions}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
        />
        {ReportDetailView && <ReportDetailView />}
      </div>
    </div>
  )
}

const reportFormSelector = formValueSelector('reportForm')

Reports.propTypes = propTypes
Reports.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPermissions: merchantPortalSelectors.currentPermissions(state),
    allLocations: merchantPortalSelectors.allLocations(state),
    reportType: reportFormSelector(state, 'reportType'),
  }
}

const mapDispatchToProps = {
  clearAllReports: actions.clearAllReports,
}

const mapApiAuthToProps = {
  getApplicationsReport: apiActions.getApplicationsReport,
  getReconciliationReport: apiActions.getReconciliationReport,
  getSalesReport: apiActions.getSalesReport,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Reports)
