import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { isSubmitting } from 'redux-form'
import { Spinner } from 'components'
import PracticeSearchForm from '../forms/PracticeSearchForm'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { PRACTICE_URL, makeStoreUrl } from 'config'
import { PracticeType } from 'types'

const propTypes = {
  className: PropTypes.string,
  isSubmitting: PropTypes.bool,
  setPracticesList: PropTypes.func.isRequired,
  practicesList: PracticeType,
  searchPromptText: PropTypes.string,
  withBackButton: PropTypes.bool,
  setPractice: PropTypes.func.isRequired,
}

const defaultProps = {
  withBackButton: false,
}

function PracticeSearchInput({
  setPracticesList,
  //searchForPractices,
  className,
  isSubmitting,
  practicesList,
  searchPromptText,
  withBackButton,
  setPractice,
}) {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const store_number = query.get('store_number')

  useEffect(() => {
    if (store_number) {
      handleSubmit({ adsStoreNumber: store_number })
    }
  }, [store_number])

  const handleSubmit = (item) => {
    if (item && item.locationId) {
      setPractice(item)
      history.push({
        pathname: makeStoreUrl(item.adsStoreNumber),
        state: {
          practice: item,
        },
      })
    } else {
      item.preventDefault()
      return false
    }
  }

  const handleSubmitSuccess = () => {
    history.push(PRACTICE_URL)
  }

  const handleSubmitFailure = () => {
    setPracticesList(null)
    history.push(PRACTICE_URL)
  }

  return (
    <div className={`practice-search-input ${className} `}>
      <PracticeSearchForm
        initialValues={{
          adsStoreNumber: store_number,
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFailure}
        practicesList={practicesList}
        searchPromptText={searchPromptText}
        withBackButton={withBackButton}
        handleSubmit={handleSubmit}
      />
      <div className="mt-5 spinner">{isSubmitting && <Spinner />}</div>
    </div>
  )
}

PracticeSearchInput.propTypes = propTypes
PracticeSearchInput.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isSubmitting: isSubmitting('practiceSearchForm')(state),
    practicesList: selectors.practicesList(state),
  }
}
const mapDispatchToProps = {
  setPracticesList: actions.setPracticesList,
  setPractice: actions.setPractice,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PracticeSearchInput
)
