import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { SubmitButton } from 'lp-components'

import { AccountNumber } from 'components'
import { formatCurrency } from 'utils'

const propTypes = {}
const defaultProps = {}

function SignReceipt() {
  const [signature, setSignature] = useState('')
  const [isSigned, setisSigned] = useState(false)
  const [procedureDate, setProcedureDate] = useState('')
  const [ssn, setSsn] = useState('')

  //dummy info
  const ssnLast4 = '1111'
  const name = {
    first: 'Mary',
    last: 'Jones',
  }
  const buyerName = {
    first: 'Buyer',
    last: 'Name',
  }
  const responsibleParty = {
    first: 'Responsible',
    last: 'Party',
  }
  const accountNumber = '5555555555555555'
  const totalSalesAmount = 500
  const authorizationCode = 3437
  const referenceId = 1234
  const creditLimit = 200
  const location = {
    name: 'Sono Bello',
    city: 'Atlanta',
  }
  const planSelected = 'Plan A'
  const amountFinanced = 600

  const handleClickToSign = () => {
    if (!signature) {
      setSignature(`${name.first} ${name.last}`)
      setisSigned(true)
    }
  }
  const handleProcedureDateInput = (event) => {
    setProcedureDate(event.target.value)
  }
  const handleSSN = (event) => {
    setSsn(event.target.value)
  }

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Patient Receipt</h1>
        <>
          <div className="account-summary summary refund-complete">
            {/* {buyer && (
              <AuthBuyerSaleCard
                paymentDetails={{ ...paymentDetails }}
                currentLocation={currentLocation}
              />
            )} */}
            {
              <div className="summary__card card m-t-30">
                <div className="card-content">
                  <div className="summary__account-info">
                    <p className="card-title">
                      {name.first} {name.last}
                    </p>
                    <div className="info-container sub-title">
                      <p className="card-area">Account Number: </p>
                      <AccountNumber accountNumberString={accountNumber} />
                    </div>

                    <div className="card-main">
                      <div className="card-main__left">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Available Credit</strong>{' '}
                            {formatCurrency(200, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Social Security Number</strong>
                            {ssnLast4 ? `XXX-XX-${1234}` : 'N/A'}
                          </p>
                        </div>
                      </div>

                      <div className="card-main__right">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Credit Limit</strong>{' '}
                            {formatCurrency(creditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Location </strong>
                            {location.name} - {location.city}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="summary__card--bottom card">
              <div className="card-content">
                <div className="summary__account-info">
                  <div className="card-main">
                    <div className="card-main__left">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong>Total Sales Amount</strong>{' '}
                          {formatCurrency(totalSalesAmount, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Authorization Code</strong>{' '}
                          {authorizationCode}
                        </p>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Reference ID</strong> {referenceId}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sales-complete-container" id="print-container">
              <div className="refund-complete__receipt m-t-50">
                <div className="refund-complete__receipt--header">
                  Sales Receipt
                </div>
                <div className="refund-complete__receipt--body">
                  <div className="refund-complete__receipt--info-container">
                    <div className="refund-complete__receipt--info">
                      <div className="key">Date</div>
                      <div className="value">
                        {format(new Date(), 'MM/dd/yyyy')}
                      </div>
                    </div>

                    {
                      <>
                        <div className="refund-complete__receipt--info">
                          <div className="key">Authorized Buyer</div>
                          <div className="value">
                            {` ${buyerName.first} ${buyerName.last}`}
                          </div>
                        </div>
                      </>
                    }

                    <div className="refund-complete__receipt--info">
                      <div className="key">{'Responsible Party'}</div>
                      <div className="value">
                        {' '}
                        {`${responsibleParty.first} ${responsibleParty.last}`}
                      </div>
                    </div>

                    <div className="refund-complete__receipt--info">
                      <div className="key">Location</div>
                      <div className="value">
                        {location.name} - {location.city}
                      </div>
                    </div>

                    <div className="refund-complete__receipt--info">
                      <div className="key">Plan Selected</div>
                      <div className="value">{planSelected}</div>
                    </div>
                    <div className="disclaimer"></div>
                  </div>

                  <div className="seperator"></div>

                  <div className="refund-complete__receipt--info-container">
                    <div className="refund-complete__receipt--info">
                      <div className="key">Authorization#</div>
                      <div className="value">{authorizationCode}</div>
                    </div>
                    <div className="refund-complete__receipt--info">
                      <div className="key">Account#</div>
                      <div className="value">
                        {' '}
                        <AccountNumber
                          accountNumberString={'7788000000024479'}
                        />
                      </div>
                    </div>
                    <div className="refund-complete__receipt--info">
                      <div className="key">Amount Financed</div>
                      <div className="value">
                        {formatCurrency(amountFinanced, 2)}
                      </div>
                    </div>

                    <div className="disclaimer">
                      <p>
                        I agree to pay for these goods or services according to
                        the terms of my ALPHAEON Credit Card Agreement and if
                        applicable, any Promotional Credit Plan, which may apply
                        to this transaction. If any purchase is not
                        satisfactory, it will be subject to provider's refund
                        policy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="esign-inputs-wrapper">
              <div className="esign-ssn-date-wrapper">
                <div className="esign-input">
                  <strong>SSN # (Last 4 digits)</strong>
                  <input
                    placeholder="XXXX"
                    onChange={handleSSN}
                    value={ssn}
                  ></input>
                </div>
                <div className="esign-input">
                  <strong>Procedure Date</strong>
                  <input
                    placeholder="XX/XX/XXXX"
                    onChange={handleProcedureDateInput}
                    value={procedureDate}
                  ></input>
                </div>
              </div>
              <div className="esign-input--signature">
                <strong>Signature</strong>
                <input
                  onClick={handleClickToSign}
                  value={signature}
                  placeholder="Click to Sign"
                  readOnly
                ></input>
              </div>
              <SubmitButton
                invalid={!ssn || !procedureDate || !isSigned}
                type="button"
                className="button-warn"
              >
                Submit
              </SubmitButton>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

SignReceipt.propTypes = propTypes
SignReceipt.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SignReceipt
)
