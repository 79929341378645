import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Routes as CreditApplicationRoutes } from './credit_application'
import { Routes as ApplicantsRoutes } from './applicants'
import { PATH, makePath } from 'config'
import Layout from './Layout'
import StoreLookup from './credit_application/views/StoreLookup'
import * as Views from './views'
import SignReceipt from './esign/views/SignReceipt'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      {process.env.REACT_APP_CONSUMER_DOWN_MESSAGE_ENABLED === true && (
        <Views.DownPage />
      )}

      {process.env.REACT_APP_CONSUMER_DOWN_MESSAGE_ENABLED !== true && (
        <Switch>
          <Route path={makePath(path, PATH.ESIGN)}>
            <SignReceipt />
          </Route>

          <Route path={makePath(path, PATH.CREDIT_APPLICATION)}>
            <CreditApplicationRoutes />
          </Route>

          <Route path={makePath(path, PATH.APPLICANTS)}>
            <ApplicantsRoutes />
          </Route>

          <Route path={makePath(path, '/store/:id')} exact={true}>
            <StoreLookup />
          </Route>

          <Redirect path="*" to={makePath(path, PATH.CREDIT_APPLICATION)} />
        </Switch>
      )}
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
