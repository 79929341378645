import React, { useState } from 'react'
import { FormSpy } from 'components'
import { MaskedInput } from 'lp-components'

export const MaskedInputWithToolTip = (props) => {
  const [focus, setFocus] = useState(false)

  return (
    <>
      <MaskedInput
        onFocus={() => {
          setFocus(true)
        }}
        {...props}
      />
      <FormSpy name={props.input.name} focused={focus}>
        {(val) => {
          if ((val || focus) && props.tooltips) {
            return <props.tooltips />
          }
          return null
        }}
      </FormSpy>
    </>
  )
}

// Keeping this for reference.
// export const SSNInputField = (props) => {
//   const [focus, setFocus] = useState(false)
//   const [visible, setVisible] = useState(false)
//   const [rawSSN, setRawSSN] = useState('')
//   const {
//     input: { onChange },
//   } = props

//   const mask = (v) => {
//     const masked = v.slice(0, 6).replace(/[0-9]/g, 'X')
//     const unmaskedValue = v.length > 6 && v.slice(6, 11)
//     return unmaskedValue ? `${masked}${unmaskedValue}` : masked
//   }

//   const handleChange = (e) => {
//     const { value } = e.target

//     setRawSSN(value)
//     onChange(value)
//   }

//   const handleVisibility = () => {
//     setVisible(!visible)
//     onChange(rawSSN)
//   }

//   useEffect(() => {
//     // setVisible(true)
//     // setRawSSN('123-44-5667')
//   }, [])

//   return (
//     <div>
//       <div className="ssn-input d-flex-center">
//         <div style={{ display: 'none' }}>
//           <props.inputType name={props.input.name} {...props} />
//         </div>

//         <props.inputType
//           name={'ssn' + 'proxy'}
//           value={visible ? rawSSN : mask(rawSSN)}
//           onFocus={() => {
//             setFocus(true)
//           }}
//           onChange={handleChange}
//           {...props}
//         >
//           <div
//             onClick={handleVisibility}
//             style={{ cursor: 'pointer', color: 'gray' }}
//           >
//             {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
//           </div>
//         </props.inputType>
//       </div>
//       <FormSpy name={props.input.name} focused={focus}>
//         {(val) => {
//           if ((val || focus) && props.tooltips) {
//             return <props.tooltips />
//           }
//           return null
//         }}
//       </FormSpy>
//     </div>
//   )
// }
export default { MaskedInputWithToolTip }
