import { handleActions } from 'redux-actions'
// import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
// import * as apiActions from 'api-actions'

const reducerKey = 'site-initialization'
// const slice = 'root.site-initialization'
const initialState = {}
const reducer = handleActions(
  {
    // [apiActions.fetchSiteInitializations]: setOnSuccess('siteInitializations'),
    // [apiActions.fetchSiteInitialization]: setOnSuccess('siteInitialization'),
    // [actions.clearSiteInitialization]: unsetState('siteInitialization'),
  },
  initialState
)
// const select = selectorForSlice(slice)
const selectors = {
  // siteInitializations: select('siteInitializations'),
  // siteInitialization: select('siteInitialization'),
}

export { reducer, selectors, reducerKey }
