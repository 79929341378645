import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import * as Views from './views'
import { PATH, QUERY_KEY, ERROR_EXPIRATION_ERROR_URL } from 'config'
import { AuthenticatedRoutes, Spinner } from 'components'
import { forceRedirect, makeQueryPath } from 'utils'
import { useAuth0 } from '@auth0/auth0-react'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { isLoading, error } = useAuth0()
  const location = useLocation()

  if (error) {
    const { pathname: applicationPath } = location

    const errorStr = error.toString()
    const isErrorExpiration = errorStr.toLowerCase().includes('expiration time')

    if (isErrorExpiration) {
      return forceRedirect({ to: ERROR_EXPIRATION_ERROR_URL })
    }

    const authorizationErrorPath = makeQueryPath(PATH.AUTHORIZATION_ERROR, {
      ...error,
      [QUERY_KEY.APPLICATION_PATH]: applicationPath,
    })

    return forceRedirect({ to: authorizationErrorPath })
  }
  if (isLoading) return <Spinner />

  return (
    <AuthenticatedRoutes>
      <Route exact path={PATH.ADMIN}>
        <Views.AdminPortal />
      </Route>
    </AuthenticatedRoutes>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
