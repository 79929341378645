import operationPermitted from './operationPermitted'

export default function operationNotPermitted(
  currentPermissions,
  requiredPermission,
  selectionCriteria
) {
  return !operationPermitted(
    currentPermissions,
    requiredPermission,
    selectionCriteria
  )
}
