import React from 'react'
import Routes from './Routes'

import TagManager from 'react-gtm-module'

import { VersionChangeReporter, TestEnvironmentBanner } from 'components'
import { CREDIT_PORTAL_URL, getTestEnv } from 'config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function App() {
  if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      events: {
        conversion: 'conversion',
      },
    })
  }

  const testEnv = getTestEnv()

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        useRecaptchaNet
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        {testEnv && <TestEnvironmentBanner env={testEnv} />}
        <Routes />
        <VersionChangeReporter redirectURL={CREDIT_PORTAL_URL} />
      </GoogleReCaptchaProvider>
    </>
  )
}

export default App
