import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { makePath, SALES_PORTAL_PATHS } from 'config'

const propTypes = {}

const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, SALES_PORTAL_PATHS.PAYMENT_DETAILS)}>
          <Views.PaymentDetails />
        </Route>

        <Route
          exact
          path={makePath(path, SALES_PORTAL_PATHS.PAYMENT_DETAILS_AUTH_BUYER)}
        >
          <Views.PaymentDetails />
        </Route>

        <Route exact path={makePath(path, SALES_PORTAL_PATHS.PLAN_SELECTION)}>
          <Views.PlanSelection />
        </Route>

        <Route exact path={makePath(path, SALES_PORTAL_PATHS.SIGN_RECEIPT)}>
          <Views.SignReceipt />
        </Route>

        <Route
          exact
          path={makePath(path, SALES_PORTAL_PATHS.ACCOUNT_VERIFICATION)}
        >
          <Views.AccountVerification />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
