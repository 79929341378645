import { isNil, isEmpty } from 'lodash'

export default function checkForNull(value) {
  if (isNil(value)) {
    return
  }
  if (!isEmpty(value) && value.toLowerCase() !== 'null') {
    return value
  }
  return
}
