import React from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { makePath, MERCHANT_PORTAL_PATHS, PATH } from 'config'

const propTypes = {}

const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, MERCHANT_PORTAL_PATHS.ROOT)}>
          <Views.AccountLookup />
        </Route>

        <Route exact path={makePath(path, MERCHANT_PORTAL_PATHS.RESULT)}>
          <Views.AccountLookUpResult />
        </Route>

        <Route exact path={makePath(path, MERCHANT_PORTAL_PATHS.DETAIL)}>
          <Views.AccountDetail />
        </Route>

        <Route path={makePath(path, PATH.LOAN_ADJUSTMENT)}>
          <Views.LoanAdjustment />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
