import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useLocation, Redirect } from 'react-router-dom'
import { isNil, omitBy } from 'lodash'
import InfoIcon from '@material-ui/icons/Info'
import { Tooltip, IconButton } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

import { ServiceProviderDisclosures } from 'credit-portal-components'
import { PersonalInfoForm } from '../../forms'
import { selectors } from '../reducer'
import { APPLICANTS_URL } from 'config'
import { formatISODateStringAsUSMonthDayYear } from 'utils'
import { PersonalInfoType, PracticeType } from 'types'

const propTypes = {
  followOnOperation: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitFailure: PropTypes.func.isRequired,
  handleSubmitSuccess: PropTypes.func.isRequired,
  personalInfo: PersonalInfoType.isRequired,
  practice: PracticeType.isRequired,
}
const defaultProps = {}

function ApplicantShow({
  followOnOperation,
  handleSubmit,
  handleSubmitFailure,
  handleSubmitSuccess,
  personalInfo,
  practice,
}) {
  const { search } = useLocation()

  if (isNil(personalInfo)) {
    return (
      <Redirect
        to={{
          pathname: APPLICANTS_URL,
          search: search,
        }}
      />
    )
  }

  return (
    <div className="applicant-personal-info">
      <div className="application-instructions">
        <div className="info-header">
          <p>
            Find out now if you <span>pre-qualify</span> for our Access Loan
            product* without impacting your credit.
          </p>
          <p>It's quick, easy, and secure with instant results.</p>
        </div>
        <ul className="info-content">
          {practice && practice.name && practice.locationId && (
            <li className="info">
              You've selected practice <b>{practice.name}</b>.
            </li>
          )}
          <li>
            If your credit is locked or frozen, you'll need to remove the freeze
            before applying.
            <Tooltip
              placement="left"
              title={
                <p style={{ fontSize: '14px', color: 'white' }}>
                  If you placed a freeze on your credit file, please contact
                  each credit reporting agency directly to remove the credit
                  freeze before going through the apply process.
                </p>
              }
            >
              <IconButton className="icon-button">
                <InfoIcon style={{ color: blue[800] }} />
              </IconButton>
            </Tooltip>
          </li>
        </ul>

        <PersonalInfoForm
          initialValues={formatInitialValues(personalInfo)}
          followOnOperation={followOnOperation}
          practice={practice}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFailure}
        />
        <ServiceProviderDisclosures />
      </div>
    </div>
  )
}

ApplicantShow.propTypes = propTypes
ApplicantShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    personalInfo: selectors.personalInfo(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplicantShow
)

function formatInitialValues(initialValues) {
  if (isNil(initialValues)) return {}

  const compactedInitialValues = omitBy(initialValues, (value) => isNil(value))
  const { alternatePhone, dateOfBirth } = compactedInitialValues
  const usDateOfBirth = formatISODateStringAsUSMonthDayYear(dateOfBirth)
  const homeNumberCheck = !isNil(alternatePhone)

  return {
    ...compactedInitialValues,
    dateOfBirth: usDateOfBirth,
    homeNumberCheck,
  }
}
