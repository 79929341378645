import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LinkButton } from 'components'
import { AuthorizationTooltip } from 'merchant-portal-components'
import PropTypes from 'prop-types'
import { selectors as merchantSelectors } from '../reducer'
import { PermissionsType } from 'types'
import { operationNotPermitted } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentPermissions: PermissionsType.isRequired,
  className: PropTypes.string,
  invalid: PropTypes.bool,
  requiredPermission: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
const defaultProps = {
  className: 'button-secondary',
  invalid: false,
}

function AuthorizedLinkButton({
  children,
  className,
  currentPermissions,
  invalid,
  requiredPermission,
  to,
  ...rest
}) {
  const unauthorized =
    operationNotPermitted(currentPermissions, requiredPermission) || invalid

  return (
    <AuthorizationTooltip displayed={unauthorized}>
      <LinkButton
        className={className}
        invalid={unauthorized}
        to={to}
        {...rest}
      >
        {children}
      </LinkButton>
    </AuthorizationTooltip>
  )
}

AuthorizedLinkButton.propTypes = propTypes
AuthorizedLinkButton.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPermissions: merchantSelectors.currentPermissions(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthorizedLinkButton
)
