import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'

import { selectorForSlice } from 'lp-redux-utils'

const reducerKey = 'programManagement'
const slice = 'root.merchantPortal.programManagement'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchPlansByLocationId]: setOnSuccess('plans'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  plans: select('plans'),
}

export { reducer, selectors, reducerKey }
