import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { ApplicationFinderForm } from '../../forms'
import * as apiActions from 'api-actions'
import {
  ACTION,
  CONTINUE_APPLICATION_URL,
  NEW_ACCESS_LOAN_URL,
  QUERY_KEY,
} from 'config'
import { flashSubmitFailure, makeQueryPath, parseQuery } from 'utils'

const propTypes = {
  getCreditApplicationByToken: PropTypes.func.isRequired,
}
const defaultProps = {}

function Applicants({ getCreditApplicationByToken }) {
  const history = useHistory()
  const { search } = useLocation()
  const query = parseQuery(search)
  const token = query[QUERY_KEY.TOKEN]

  const handleSubmit = (formValues) => {
    return getCreditApplicationByToken(formValues, token)
  }
  const handleSubmitSuccess = () => {
    let nextActionURL

    switch (query[QUERY_KEY.ACTION]) {
      case ACTION.CONTINUE:
        nextActionURL = CONTINUE_APPLICATION_URL
        break

      case ACTION.NEW_ACCESS_LOAN:
        nextActionURL = NEW_ACCESS_LOAN_URL
        break

      default:
        throw new Error(`Unknown applicant action [${query[QUERY_KEY.ACTION]}]`)
    }

    history.push(makeQueryPath(nextActionURL, query))
  }

  return (
    <section className="applicant-container">
      <header>
        <h1>Identity Verification</h1>
        <p>
          Please provide the following information so we can confirm your
          identity before moving forward.
        </p>
      </header>
      <ApplicationFinderForm
        className="multi-column"
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={flashSubmitFailure}
        searchButtonLabel="Submit"
      />
    </section>
  )
}

Applicants.propTypes = propTypes
Applicants.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  getCreditApplicationByToken: apiActions.getCreditApplicationByToken,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Applicants)
