import React, { useState } from 'react'
import { Spinner } from 'components'
import PrequalDeclineModal from '../components/PrequalDeclineModal'
import PrequalAcceptDeclineModal from '../components/PrequalAcceptDeclineModal'
import { useLocation } from 'react-router-dom'

const propTypes = {}
const defaultProps = {}

function CoveredIframePage() {
  const location = useLocation()
  const { lenderNextStepUrl, modalType } = location.state

  const [loading, setLoading] = useState(true)
  const [modalShown, setModalShown] = useState(modalType !== 'no-modal')

  return (
    <div className="access-loans">
      {loading && <Spinner />}

      <iframe
        className="access-loans--iframe"
        onLoad={() => {
          setLoading(false)
        }}
        src={lenderNextStepUrl}
      />

      {modalShown && modalType == 'prequal' && (
        <PrequalDeclineModal onContinue={() => setModalShown(false)} />
      )}
      {modalShown && modalType == 'credit' && (
        <PrequalAcceptDeclineModal onClose={() => setModalShown(false)} />
      )}
    </div>
  )
}

CoveredIframePage.propTypes = propTypes
CoveredIframePage.defaultProps = defaultProps

export default CoveredIframePage
