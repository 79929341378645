import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import BackIcon from '@material-ui/icons/ArrowBackIos'

function BackButton() {
  const history = useHistory()

  const handleBack = () => {
    history.goBack()
  }
  return (
    <Button
      className="button-primary-outline d-flex-center back-button"
      onClick={handleBack}
    >
      <BackIcon height={'18px'} />
      Back to Search
    </Button>
  )
}

export default BackButton
