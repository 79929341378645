import React from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { makePath, PATH } from 'config'

// const propTypes = {}

const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()
  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, PATH.ROOT)}>
          <Views.Legals />
        </Route>
        <Route exact path={makePath(path, PATH.CA_PRIVACY_NOTICE)}>
          <Views.CAPrivacyNotice />
        </Route>
        <Route
          exact
          path={makePath(path, PATH.COMENITY_CAPITAL_PREQUALIFICATION_TERMS)}
        >
          <Views.ComenityCapitalPrequalificationTerms />
        </Route>
        <Route exact path={makePath(path, PATH.CONSENT_AUTODIALED)}>
          <Views.ConsentAutodialed />
        </Route>
        <Route exact path={makePath(path, PATH.CONSENT_ELECTRONIC_DISCLOSURES)}>
          <Views.ConsentElectronicDisclosures />
        </Route>
        <Route
          exact
          path={makePath(path, PATH.CONSUMER_FINANCIAL_PRIVACY_POLICY)}
        >
          <Views.ConsumerFinancialPrivacyPolicy />
        </Route>
        <Route exact path={makePath(path, PATH.CREDIT_REPORT_AUTHORIZATION)}>
          <Views.CreditReportAuthorization />
        </Route>
        <Route exact path={makePath(path, PATH.PREQUALIFICATION_TERMS)}>
          <Views.PrequalificationTerms />
        </Route>
        <Route exact path={makePath(path, PATH.PRIVACY_POLICY)}>
          <Views.PrivacyPolicy />
        </Route>
        <Route exact path={makePath(path, PATH.WEBSITE_TERMS_OF_USE)}>
          <Views.WebsiteTermsOfUse />
        </Route>
      </Switch>
    </Layout>
  )
}

// Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
