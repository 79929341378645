import { differenceInYears, getYear } from 'date-fns'

export default function validDateOfBirth(dateOfBirth) {
  return (
    // Date of birth must be after 1900
    getYear(dateOfBirth) >= 1900 &&
    // Applicant must be 18 years or older
    differenceInYears(new Date(), dateOfBirth) >= 18
  )
}
