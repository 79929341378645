import React from 'react'
import exact from 'prop-types-exact'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

import { ButtonLink } from 'components'
import logo from 'images/Alphaeon_Credit_Logo.png'
import { INITIALIZATION_CONTACT_US_URL, PATH, makeApplicationURL } from 'config'

const propTypes = {}
const defaultProps = {}

function SiteInitializationHeader() {
  const { user, logout } = useAuth0()

  return (
    <>
      <div className="header-login">
        <div className="header-login__content">
          <div>{user.email}</div>
          <ul className="nav">
            <li className="navlink">
              <Link to={INITIALIZATION_CONTACT_US_URL}>Contact Us</Link>
            </li>
            <li className="navlink">
              <ButtonLink
                onClick={() =>
                  logout({
                    returnTo: makeApplicationURL(PATH.MERCHANT_PORTAL),
                  })
                }
                className="button-link"
              >
                Sign out
              </ButtonLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="header">
        <div className="header__content">
          <div>
            <img src={logo} className="logo" alt="Logo" />
          </div>
        </div>
      </div>
    </>
  )
}

SiteInitializationHeader.propTypes = exact(propTypes)
SiteInitializationHeader.defaultProps = defaultProps

export default React.memo(SiteInitializationHeader)
