import React from 'react'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import {
  propTypes as formPropTypes,
  Field,
  SubmissionError,
  formValues,
} from 'redux-form'
import { SubmitButton, DateInput, MaskedInput, Input } from 'lp-components'
import { ButtonLink } from 'components'
import { toAmount } from 'utils'
import { connect } from 'react-redux'

const propTypes = {
  ...formPropTypes,
}
const defaultProps = {}

function LoanAdjustmentForm({ submitting, handleSubmit, handleCancelEdit }) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="loan-adjustment-form">
        <Field
          className="loan-amount-input"
          component={Input}
          inputType={MaskedInput}
          name="amount"
          placeholder="Loan Amount"
          parse={toAmount}
          maskOptions={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }}
        />
        <div className="date-selection">
          <Field
            component={DateInput}
            name="serviceDate"
            label="Service Date"
            autoComplete="off"
          />
        </div>
        <Field
          name="userReferenceId"
          component={Input}
          label="Reference ID (Optional)"
          placeholder="Reference ID"
        />
      </div>

      <SubmitButton
        submitting={submitting}
        disabled={submitting}
        className={`button-warn-outline`}
      >
        SAVE INFORMATION
      </SubmitButton>
      <ButtonLink onClick={handleCancelEdit} className="select-all button-link">
        CANCEL EDIT
      </ButtonLink>
    </form>
  )
}

function beforeSubmit(formValues) {
  const newDate = new Date()
  const today = new Date(newDate)
  today.setDate(newDate.getDate() - 1)

  const { serviceDate } = formValues

  if (serviceDate < today) {
    throw new SubmissionError({
      serviceDate: 'Service Date should be today at the earliest',
    })
  }

  return formValues
}

LoanAdjustmentForm.propTypes = propTypes
LoanAdjustmentForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'loanAdjustmentForm',
    constraints: {
      amount: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 500,
        },
      },
    },
  }),
  formValues('amount', 'serviceDate'),
  connect(mapStateToProps)
)(LoanAdjustmentForm)
