import { usStates } from './usstates'
import ApplyIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import TransactIcon from '@material-ui/icons/MonetizationOnOutlined'
import CalculatorIcon from '@material-ui/icons/AppsOutlined'

export const isProduction = () => process.env.NODE_ENV === 'production'
export const getTestEnv = () =>
  process.env.REACT_APP_BUILD_ENV !== 'production'
    ? process.env.REACT_APP_BUILD_ENV
    : ''
export const usingTestApi = () => process.env.REACT_APP_USE_TEST_API === 'true'

export const ACTION = {
  CONTINUE: 'continue',
  NEW_ACCESS_LOAN: 'new_access_loan',
}

export const ALL_LOCATIONS = '**-ALL_LOCATIONS-**'
export const ALPHAEON_CONTACT_PHONE = '1-920-306-1794'
export const ALPHAEON_CONTACT_EMAIL = 'teamcredit@alphaeon.com'
export const INSTALLMENT = 'installment'

export const MIN_ANNUAL_INCOME = 10000

// Note that the keys here, with the exception of the UNKNOWN_ERROR key, must
// match the "error_code" values returned by the Consumer Application and
// Merchant Management APIs.
export const API_ERROR_MAP = {
  application_not_declined: `Your application is not in the correct state for prequalification. Please contact us at ${ALPHAEON_CONTACT_PHONE}.`,
  lender_refused_void: 'The lender declined the void request',
  location_unconfigured_service_provider_profile: `This location does not have a valid service provider profile configuration. Please contact us at ${ALPHAEON_CONTACT_PHONE}.`,
  new_application_required:
    'There was an issue with your application. Please resubmit your prequalification.',
  no_credit_application_found:
    'Link expired or no active credit application found. Please contact the selected practice for further assistance.',
  no_plans_for_location: `There are no plans for this location. Please contact us at ${ALPHAEON_CONTACT_PHONE}`,
  prequalify_success_without_offers: `No offers have been returned for this application. Please contact us at ${ALPHAEON_CONTACT_PHONE}`,
  UNKNOWN_ERROR: `Received an unknown error code. Please call ${ALPHAEON_CONTACT_PHONE} if the situation persists`,
  no_credit_application_found_one_time: 'The link is either invalid or expired',
}

export const APPLICATION = {
  CREDIT_PORTAL: 'credit-portal',
  MERCHANT_PORTAL: 'merchant',
}

export const APPLICATION_ROOT_URL = window.location.origin

export const APPLICATION_SEARCH_CRITERION = {
  STATUS: 'status',
  TOKEN: 'token',
}

export const DATE_STRING_LENGTH = 10

export const ERROR_STATUS = {
  API_DOWN: 'api-down',
  FORBIDDEN: '403',
  NETWORK_DOWN: 'network-down',
  NOT_FOUND: '404',
  UNAUTHORIZED: '401',
}

export const ERROR_STATUS_NAME = {
  UNAUTHORIZED: 'unauthorized',
}

export const PATH = {
  // Admin
  ADMIN: '/admin',

  // Application
  AUTHORIZATION_ERROR: '/error/authorization_error',
  CLIENT_ERROR: '/error/client_error',
  ESIGN: '/esign',
  ERROR: '/error',
  EXPIRATION_TIME_ERROR: '/error/expiration_time_error',
  SERVER_ERROR: '/error/server_error',

  // Credit Portal
  ACCOUNT_EXISTS: '/account-exists',
  APPLICANT: '/applicant',
  APPLICANTS: '/applicants',
  APPLICATION_FINDER: '/find-application',
  APPLICATION_FOUND: '/application-found',
  CONTINUE_APPLICATION: '/continue-application',
  CREDIT_APPLICATION: '/apply',
  CREDIT_DECLINED: '/credit-declined',
  CREDIT_PORTAL: '/credit-portal',
  DECLINED: '/declined',
  NEW_ACCESS_LOAN: '/new-access-loan',
  NO_APPLICATIONS: '/no-applications-found',
  PRACTICE: '/practice',
  PRE_QUALIFICATION_ACCEPT_DECLINED: '/pre-qualification-accept-declined',
  PRE_QUALIFICATION_ACCOUNT_EXISTS: '/pre-qualification-account-exists',
  PRE_QUALIFICATION_DECLINED_ADS_ONLY: '/pre-qualification-declined-card',
  PRE_QUALIFICATION_DECLINED: '/pre-qualification-declined',
  PRE_QUALIFICATION_INFORMATION_REQUIRED:
    '/pre-qualification-information-required',
  PRINT: '/print/:terms',
  PUSH_APPLICATION: '/push-application',
  ROOT: '/',
  WIZARD: '/wizard',

  // Styleguide
  STYLEGUIDE: '/styleguide',

  // Legal
  CA_PRIVACY_NOTICE: '/ca-privacy-notice',
  COMENITY_CAPITAL_PREQUALIFICATION_TERMS:
    '/comenity-capital-prequalification-terms',
  CONSENT_AUTODIALED: '/consent-autodialed',
  CONSENT_ELECTRONIC_DISCLOSURES: '/consent-electronic-disclosures',
  CONSUMER_FINANCIAL_PRIVACY_POLICY: '/consumer-financial-privacy-policy',
  CREDIT_REPORT_AUTHORIZATION: '/credit-report-authorization',
  LEGAL: '/legal',
  PREQUALIFICATION_TERMS: '/prequalification-terms',
  PRIVACY_POLICY: '/privacy-policy',
  WEBSITE_TERMS_OF_USE: '/website-terms-of-use',

  //Merchant Portal
  ACCESS_LOANS: '/access-loans',
  ACCOUNT_CREATED: '/account/created',
  ACCOUNT_LOOKUP_RESULT: '/account-lookup-result',
  ACCOUNT_LOOKUP_SUMMARY: '/account-lookup/',
  ACCOUNT_LOOKUP: '/account-lookup',
  ACCOUNT_START_APPLICATION_BEGIN: '/application/begin',
  ACCOUNT_START_APPLICATION_CONFIRMATION: '/application/confirm',
  APPLICATION_ACCOUNT_EXISTS: '/application-account-exists',
  APPLICATION_DECLINED: '/application-declined',
  APPLICATION_PENDING: '/application-pending',
  APPLICATION_REPORT_DETAILS: '/application-reports',
  CALCULATOR: '/calculator',
  CONTACT_US: '/contact-us',
  DECLINED_ACCESS_LOAN_AVAILABLE: '/application-declined/access-loan-available',
  NO_MANAGER_ACCESS: '/no-manager-access',
  MARKETING: '/marketing',
  QR_CODE: '/qr-code',
  MERCHANT_PORTAL: '/merchant',
  NEW: '/new',
  PAYMENT_DETAILS: '/payment-details',
  PROGRAM_MANAGEMENT: '/program-management',
  RECONCILIATION_REPORT: '/reconciliation-report',
  REFUND_COMPLETE: '/account/refund/:id/complete',
  REFUND_REQUEST: '/account/refund/:id',
  REPORTS: '/reports',
  SALES_REPORT_DETAILS: '/sales-reports',
  SALES: '/sales',
  SITE_INITIALIZATION: '/i',
  STORE: '/store/',
  SUPPLEMENTAL_FUNDING: '/supplemental-funding',
  USER: '/users',
  VOID_SALE: '/account/void-sale/:id',
  ACCOUNT_VERIFICATION: '/account-verification',
}

export const URL = {
  PRACTICE_SUPPORT: 'https://www.myalphaeoncredit.com/practice-support',
  PATIENT_SUPPORT: 'https://www.myalphaeoncredit.com/patient-support',
  PATRIOT_ACT:
    'https://d.comenity.net/legaldocs/alphaeoncosmetic/legal-documents/patriot-act',
  CCA: 'https://c.comenity.net/legaldocs/alphaeoncosmetic/legal-documents/cca',

  FINANCIAL_PRIVACY: 'https://comenity.com/financial-privacy',
  PRIVACY_POLICY: 'https://comenity.com/privacy-policy',

  LINKEDIN: 'https://www.linkedin.com/',
  INSTAGARAM: 'https://www.instagram.com/',
  FACEBOOK: 'https://www.facebook.com/',
  GOOGLE: 'https://www.google.com/',
  IOVATION_JS: isProduction()
    ? '/iojs/snare.js'
    : 'https://go.dev.alphaeontest.com/iojs/snare.js',

  //merchant portal
  ALPHAEON_COSMETIC: 'http://www.comenity.net/alphaeoncosmetic',
  ALPHAEON: 'https://goalphaeon.com/',

  // Internal portal
  INTERNAL_PORTAL:
    getTestEnv() === ''
      ? 'https://internal.alphaeon.com'
      : 'https://internal.sandbox.alphaeontest.com',
}

export const VOID_SALE = '/merchant/account/void-sale'
export const MERCHANT_SALES_PORTAL = '/merchant/sales'
export const MERCHANT_USER_PORTAL = '/merchant/users'

export const MERCHANT_PORTAL_PATHS = {
  ROOT: '/',
  RESULT: '/result',
  DETAIL: '/:id',
  LOAN_ADJUSTMENT: '/loan-adjustment',
  VOID_SALE: '/account/void-sale/',
  REFUND_REQUEST: '/account/refund/',
  REFUND_COMPLETE: '/account/refund/complete',
}

export const SALES_PORTAL_PATHS = {
  //Sales
  PAYMENT_DETAILS: '/payment-details/:id',
  PLAN_SELECTION: '/plan-selection',
  SIGN_RECEIPT: '/sign-receipt',
  PAYMENT_DETAILS_AUTH_BUYER: '/payment-details/:id/buyer/:buyerId',
  ACCOUNT_VERIFICATION: '/account-verification',
}

export const USER_PORTAL_PATHS = {
  LIST: '/',
  ADD: '/add',
  UPDATE: '/update/:id',
  UPDATE_URL: '/update/',
}

export const STATES = usStates

export const verification_methods = [
  { key: "Driver's license", value: 'drivers-license' },
  { key: 'State ID', value: 'state-id' },
  {
    key: 'Federal or Government Photo ID',
    value: 'federal-or-government-photo-id',
  },
  { key: 'Military Photo ID', value: 'military-photo-id' },
  { key: 'Passport', value: 'passport' },
  { key: 'Border Crossing Card', value: 'border-crossing-card' },
  { key: 'US Legal Resident Alien ID', value: 'us-legal-resident-alien-id' },
  { key: 'Travel Visa with a Photo', value: 'travel-visa-with-a-photo' },
]

export const DOCTOR_SPECIALTIES = [
  { key: 'Dentistry', value: 'dentistry' },
  { key: 'Dermatology', value: 'dermatology' },
  { key: 'Ophthalmology/ Optometry', value: 'ophthalmology_optometry' },
  { key: 'Plastic Surgery', value: 'plastic_surgery' },
]
// Path helpers...
export const makePath = (...args) => args.join('')

export const makeStoreUrl = (store_id) => `${STORE_URL}${store_id}`

export const makeCreditPortalPath = (...args) =>
  makePath(PATH.CREDIT_PORTAL, ...args)

export const makeMerchantPortalPath = (...args) =>
  makePath(PATH.MERCHANT_PORTAL, ...args)
export const makeApplicationURL = (...args) =>
  makePath(APPLICATION_ROOT_URL, ...args)

export const PERMISSION = {
  ACCOUNT_LOOKUP: 'canAccessAccountLookup',
  APPLICATION_ACTIVITY_TABLE: 'canAccessApplicationActivityTable',
  CALCULATOR: 'canAccessCalculator',
  MANAGE_PROGRAMS: 'canManagePrograms',
  MANAGE_USERS: 'canManageUsers',
  PROCESS_REFUND: 'canProcessRefund',
  PROCESS_SALE: 'canProcessSale',
  PROCESS_VOID: 'canProcessVoid',
  SUBMIT_PUSH_APPLICATIONS: 'canSubmitPushApplications',
  SUBMIT_DASHBOARD_APPLICATIONS: 'canSubmitDashboardApplications',
  VIEW_APPLICATION_TRACKING_REPORT: 'canViewApplicationTrackingReport',
  VIEW_BANK_RECONCILIATION_REPORT: 'canViewBankReconciliationReport',
  VIEW_CREDIT_AVAILABLE_REPORT: 'canViewCreditAvailableReport',
  VIEW_SALE_REPORT: 'canViewSaleReport',
}
export const ALL_PERMISSIONS = [
  PERMISSION.ACCOUNT_LOOKUP,
  PERMISSION.APPLICATION_ACTIVITY_TABLE,
  PERMISSION.CALCULATOR,
  PERMISSION.MANAGE_USERS,
  PERMISSION.MANAGE_PROGRAMS,
  PERMISSION.PROCESS_REFUND,
  PERMISSION.PROCESS_SALE,
  PERMISSION.PROCESS_VOID,
  PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS,
  PERMISSION.SUBMIT_PUSH_APPLICATIONS,
  PERMISSION.VIEW_APPLICATION_TRACKING_REPORT,
  PERMISSION.VIEW_BANK_RECONCILIATION_REPORT,
  PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT,
  PERMISSION.VIEW_SALE_REPORT,
]
export const ADMIN_PERMISSION_KEYS = [
  PERMISSION.MANAGE_USERS,
  PERMISSION.MANAGE_PROGRAMS,
]
export const DEFAULT_PERMISSIONS = {
  [PERMISSION.ACCOUNT_LOOKUP]: true,
  [PERMISSION.APPLICATION_ACTIVITY_TABLE]: true,
  [PERMISSION.CALCULATOR]: true,
  [PERMISSION.MANAGE_PROGRAMS]: false,
  [PERMISSION.MANAGE_USERS]: false,
  [PERMISSION.PROCESS_REFUND]: true,
  [PERMISSION.PROCESS_SALE]: true,
  [PERMISSION.PROCESS_VOID]: true,
  [PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS]: true,
  [PERMISSION.SUBMIT_PUSH_APPLICATIONS]: true,
  [PERMISSION.VIEW_APPLICATION_TRACKING_REPORT]: true,
  [PERMISSION.VIEW_BANK_RECONCILIATION_REPORT]: true,
  [PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT]: true,
  [PERMISSION.VIEW_SALE_REPORT]: true,
}
export const DEFAULT_PERMISSIONS_KEYS = [
  PERMISSION.ACCOUNT_LOOKUP,
  PERMISSION.APPLICATION_ACTIVITY_TABLE,
  PERMISSION.CALCULATOR,
  PERMISSION.PROCESS_REFUND,
  PERMISSION.PROCESS_SALE,
  PERMISSION.PROCESS_VOID,
  PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS,
  PERMISSION.SUBMIT_PUSH_APPLICATIONS,
  PERMISSION.VIEW_APPLICATION_TRACKING_REPORT,
  PERMISSION.VIEW_BANK_RECONCILIATION_REPORT,
  PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT,
  PERMISSION.VIEW_SALE_REPORT,
]

export const QUERY_KEY = {
  ACTION: 'action',
  APPLICATION: 'app',
  APPLICATION_PATH: 'applicationPath',
  BACK: 'back',
  ERROR: 'error',
  ERROR_DESCRIPTION: 'errorDescription',
  LOCATION_ID: 'locationId',
  LOCATION_NAME: 'locationName',
  PRIORITY: 'priority',
  RETURN_TO: 'returnTo',
  STATUS: 'status',
  SUPPLEMENTED_CCI_ID: 'supplementedConsumerCreditInstrumentId',
  TOKEN: 'token',
  TYPE: 'type',
}

// Credit Portal
export const CREDIT_PORTAL_URL = makeCreditPortalPath(PATH.CREDIT_APPLICATION)

export const CONTINUE_APPLICATION_URL = makeCreditPortalPath(
  PATH.APPLICANTS,
  PATH.CONTINUE_APPLICATION
)

export const NEW_ACCESS_LOAN_URL = makeCreditPortalPath(
  PATH.APPLICANTS,
  PATH.NEW_ACCESS_LOAN
)
export const APPLICANTS_URL = makeCreditPortalPath(PATH.APPLICANTS)

export const PRACTICE_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRACTICE
)
export const DECLINED_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.DECLINED
)

export const ACCOUNT_EXISTS_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.ACCOUNT_EXISTS
)

export const APPLICATION_FINDER_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.APPLICATION_FINDER
)

export const APPLICATION_FOUND_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.APPLICATION_FOUND
)

export const NO_APPLICATIONS_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.NO_APPLICATIONS
)

export const PRE_QUALIFICATION_ACCOUNT_EXISTS_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRE_QUALIFICATION_ACCOUNT_EXISTS
)
export const PRE_QUALIFICATION_DECLINED_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRE_QUALIFICATION_DECLINED
)
export const PRE_QUALIFICATION_INFORMATION_REQUIRED_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRE_QUALIFICATION_INFORMATION_REQUIRED
)

export const PRE_QUALIFICATION_ACCEPT_DECLINED_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRE_QUALIFICATION_ACCEPT_DECLINED
)

export const PRE_QUALIFICATION_DECLINED_ADS_ONLY_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.PRE_QUALIFICATION_DECLINED_ADS_ONLY
)

export const WIZARD_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.WIZARD
)

export const STORE_URL = makeCreditPortalPath(PATH.STORE)
export const ACCESS_LOAN_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.ACCESS_LOANS
)

export const CREDIT_DECLINED_URL = makeCreditPortalPath(
  PATH.CREDIT_APPLICATION,
  PATH.CREDIT_DECLINED
)

export const PRINT_URL = '/print'

export const WIZARD_STEP = {
  COLLECT_PERSONAL_INFO: 0,
  PERFORM_PREQUALIFICATION: 1,
  APPROVAL: 2,
}

// Merchant Portal
export const MERCHANT_PORTAL_URL = makePath(PATH.MERCHANT_PORTAL, PATH.ROOT)
export const USER_PORTAL_URL = makePath(PATH.MERCHANT_PORTAL, PATH.USER)

export const ACCOUNT_LOOKUP_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_LOOKUP
)

export const CALCULATOR_URL = makePath(PATH.MERCHANT_PORTAL, PATH.CALCULATOR)

export const ACCOUNT_LOOKUP = '/merchant/account-lookup'

export const REPORT_URL = makePath(PATH.MERCHANT_PORTAL, PATH.REPORTS)

export const START_APPLICATION_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_START_APPLICATION_CONFIRMATION
)
export const ACCOUNT_FULL_APPLICATION_CONFIRM_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_START_APPLICATION_CONFIRMATION
)

export const ACCOUNT_FULL_APPLICATION_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_START_APPLICATION_BEGIN
)

export const MERCHANT_ACCOUNT_EXISTS_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.APPLICATION_ACCOUNT_EXISTS
)

export const MERCHANT_DECLINED_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.APPLICATION_DECLINED
)

export const MERCHANT_DECLINED_ACCESS_LOAN_AVAILABLE = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.DECLINED_ACCESS_LOAN_AVAILABLE
)

export const MERCHANT_PENDING_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.APPLICATION_PENDING
)

export const QR_CODE_URL = makePath(PATH.MERCHANT_PORTAL, PATH.QR_CODE)
export const MARKETING_MATERIALS = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.MARKETING
)

export const ACCOUNT_CREATED_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_CREATED
)

export const ACCOUNT_LOOK_RESULT_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_LOOKUP_RESULT
)

export const ACCOUNT_DETAIL_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.ACCOUNT_LOOKUP_SUMMARY
)

export const LOAN_ADJUSTMENT_URL = makePath(
  MERCHANT_PORTAL_PATHS.LOAN_ADJUSTMENT
)

export const ACCOUNT_LOOK_UP_RESULT_URL = makePath(
  PATH.MERCHANT_PORTAL + PATH.ACCOUNT_LOOKUP,
  MERCHANT_PORTAL_PATHS.RESULT
)

export const SUPPLEMENTAL_FUNDING_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.SUPPLEMENTAL_FUNDING
)

export const VOID_SALE_URL = makePath(
  PATH.MERCHANT_PORTAL,
  MERCHANT_PORTAL_PATHS.VOID_SALE
)

export const USER_URL = makePath(MERCHANT_USER_PORTAL, '/')
export const USER_UPDATE_URL = makePath(
  MERCHANT_USER_PORTAL,
  USER_PORTAL_PATHS.UPDATE_URL
)

export const REFUND_REQUEST_URL = makePath(
  PATH.MERCHANT_PORTAL,
  MERCHANT_PORTAL_PATHS.REFUND_REQUEST
)

export const SALES_URL = makePath(MERCHANT_SALES_PORTAL, PATH.PAYMENT_DETAILS)

export const PLAN_SELECTION_URL = makePath(
  MERCHANT_SALES_PORTAL,
  SALES_PORTAL_PATHS.PLAN_SELECTION
)

export const SIGN_RECEIPT_URL = makePath(
  MERCHANT_SALES_PORTAL,
  SALES_PORTAL_PATHS.SIGN_RECEIPT
)

export const USER_ADD_URL = makePath(
  MERCHANT_USER_PORTAL,
  USER_PORTAL_PATHS.ADD
)

export const PROGRAM_MANAGEMENT_URL = makePath(
  PATH.MERCHANT_PORTAL,
  PATH.PROGRAM_MANAGEMENT
)

export const ACCOUNT_VERIFICATION_URL = makePath(
  MERCHANT_SALES_PORTAL,
  SALES_PORTAL_PATHS.ACCOUNT_VERIFICATION
)

export const PRIVACY_URL = makePath(PATH.LEGAL, PATH.WEBSITE_TERMS_OF_USE)

export const ERROR_EXPIRATION_ERROR_URL = PATH.EXPIRATION_TIME_ERROR

export const INITIALIZATION_CONTACT_US_URL = makeMerchantPortalPath(
  PATH.SITE_INITIALIZATION,
  PATH.CONTACT_US
)

export const NO_MANAGER_ACCESS_URL = makeMerchantPortalPath(
  PATH.SITE_INITIALIZATION,
  PATH.NO_MANAGER_ACCESS
)

export const DASHBOARD_ACTIONS = [
  {
    id: 'apply',
    title: 'Apply',
    description: 'Start a new application',
    icon: ApplyIcon,
    link: ACCOUNT_FULL_APPLICATION_CONFIRM_URL,
    requiredPermission: PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS,
  },
  {
    id: 'transact',
    title: 'Transact',
    description: 'Process a sale or refund',
    icon: TransactIcon,
    link: ACCOUNT_LOOKUP_URL,
    requiredPermission: PERMISSION.ACCOUNT_LOOKUP,
  },
  {
    id: 'calculator',
    title: 'Calculator',
    description: 'Estimate payments',
    icon: CalculatorIcon,
    link: CALCULATOR_URL,
    requiredPermission: PERMISSION.CALCULATOR,
  },
]

// Auth0 Credentials
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const REACT_APP_ALPHAEON_MERCHANT_AUTH0_CLIENTID =
  process.env.REACT_APP_ALPHAEON_MERCHANT_AUTH0_CLIENTID
export const REACT_APP_AUTH0_ADMIN_CLIENTID =
  process.env.REACT_APP_AUTH0_ADMIN_CLIENTID
export const REACT_APP_ALPHAEON_MERCHANT_MUX_API =
  process.env.REACT_APP_ALPHAEON_MERCHANT_MUX_API
export const REACT_APP_BUILDID_FETCH_INTERVAL = Number(
  process.env.REACT_APP_BUILDID_FETCH_INTERVAL ?? 60000
) // milliseconds

// Retool Parameters
export const REACT_APP_ALPHAEON_RETOOL_EMBED_URL =
  process.env.REACT_APP_ALPHAEON_RETOOL_EMBED_URL

// CoveredCare-specific environment
export const REACT_APP_COVERED_CARE_DOMAIN =
  process.env.REACT_APP_COVERED_CARE_DOMAIN ??
  'https://alphaeoncredit.apply.coveredcare.com' // Note that this default is the non-production value

export const RELATIONSHIPS = [
  {
    key: 'Brother',
    value: 'brother',
  },
  {
    key: 'Cousin',
    value: 'cousin',
  },
  {
    key: 'Daughter',
    value: 'daughter',
  },
  {
    key: 'Father',
    value: 'father',
  },
  {
    key: 'Grand Parent',
    value: 'grandparent',
  },
  {
    key: 'Husband',
    value: 'husband',
  },
  {
    key: 'Mother',
    value: 'mother',
  },
  {
    key: 'Other',
    value: 'other',
  },
  {
    key: 'Sister',
    value: 'sister',
  },
  {
    key: 'Son',
    value: 'son',
  },
  {
    key: 'Aunt',
    value: 'aunt',
  },
  {
    key: 'Uncle',
    value: 'uncle',
  },
  {
    key: 'Wife',
    value: 'wife',
  },
]

export const CONTACT_NUMBER = '1-855-497-8176'
export const TDD_TTY_NUMBER = '1-888-819-1918'
export const RISK_NUMBER = '1-920-333-8481'
export const SHARE_WITH_FAMILY_OPTIONS = [
  { key: 'Yes', value: 'yes' },
  { key: 'No', value: 'no' },
]

export const REPORT_EMAIL = 'teamcredit@alphaeon.com'

export const RISK_MESSAGE = `This account is under review. \
The account holder has been sent a text message and needs to verify information. \
Please check back once the account holder has completed the verification or call ${RISK_NUMBER} with any questions.`

// Transaction status and allowed operations...
export const TRANSACTION_STATUS = {
  APPROVED: 'approved',
  ENTERED: 'entered',
  FAILED: 'failed',
  SENT_FOR_SETTLEMENT: 'sent_for_settlement',
  SETTLED: 'settled',
  SETTLEMENT_ISSUE: 'settlement_issue',
  VOIDED: 'voided',
}
export const REFUNDABLE_STATES = [
  TRANSACTION_STATUS.SENT_FOR_SETTLEMENT,
  TRANSACTION_STATUS.SETTLED,
]

export const VOIDABLE_STATES = [TRANSACTION_STATUS.APPROVED]

export const TRANSACTION_TYPE = {
  REFUND: 'refund',
  SALE: 'sale',
  LOAN: 'loan',
}

export const DATE_SELECTION = {
  DATE_RANGE: 'date-range',
  MONTH_TO_DATE: 'month-to-date',
  PRIOR_MONTH: 'prior-month',
  YEAR_TO_DATE: 'year-to-date',
}

export const APPLICATION_TRACKING = 'application-tracking'
export const BANK_RECONCILIATION = 'bank-reconciliation'
export const CREDIT_AVAILABLE = 'credit-available'
export const SALES = 'sales'

export const DATE_FILTER_TYPE = [
  { key: 'MTD', value: DATE_SELECTION.MONTH_TO_DATE },
  { key: 'YTD', value: DATE_SELECTION.YEAR_TO_DATE },
  { key: 'Prior Month', value: DATE_SELECTION.PRIOR_MONTH },
  { key: 'Date Range', value: DATE_SELECTION.DATE_RANGE },
]

export const REPORTS_START_OFFSET_DAYS = 30
export const REPORT_TYPES = [
  {
    label: 'Application Tracking',
    value: APPLICATION_TRACKING,
  },
  {
    label: 'Bank Reconciliation',
    value: BANK_RECONCILIATION,
  },
  {
    label: 'Sales',
    value: SALES,
  },
]

export const PAGINATION_PAGE_SIZE = 10

export const ADMIN_PERMISSION_OPTIONS = [
  { key: 'MAY MANAGE USERS', value: PERMISSION.MANAGE_USERS },
  { key: 'MAY MANAGE PROGRAMS', value: PERMISSION.MANAGE_PROGRAMS },
]

export const PERMISSION_OPTIONS = [
  {
    key: 'MAY SUBMIT DASHBOARD APPLICATIONS',
    value: PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS,
  },
  {
    key: 'MAY SUBMIT ONE-TIME APPLICATIONS',
    value: PERMISSION.SUBMIT_PUSH_APPLICATIONS,
  },
  { key: 'MAY PROCESS A SALE', value: PERMISSION.PROCESS_SALE },
  { key: 'MAY PROCESS A REFUND', value: PERMISSION.PROCESS_REFUND },
  { key: 'MAY PROCESS A VOID', value: PERMISSION.PROCESS_VOID },

  {
    key: 'MAY VIEW APPLICATION TRACKING REPORT',
    value: PERMISSION.VIEW_APPLICATION_TRACKING_REPORT,
  },
  {
    key: 'MAY VIEW BANK RECONCILIATION REPORT',
    value: PERMISSION.VIEW_BANK_RECONCILIATION_REPORT,
  },
  {
    key: 'MAY VIEW CREDIT AVAILABLE REPORT',
    value: PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT,
  },
  {
    key: 'MAY VIEW SALE REPORT',
    value: PERMISSION.VIEW_SALE_REPORT,
  },
  {
    key: 'MAY ACCESS CALCULATOR',
    value: PERMISSION.CALCULATOR,
  },
  {
    key: 'MAY ACCESS APPLICATION ACTIVITY TABLE',
    value: PERMISSION.APPLICATION_ACTIVITY_TABLE,
  },
]
1
export const USER_STATUS = [
  { key: 'Active (Can Login)', value: 'active' },
  { key: 'Inactive (Cannot Login)', value: 'inactive' },
]

export const USER_ACTIVE_STATUS = [
  { key: 'Active', value: 'active' },
  { key: 'Inactive', value: 'inactive' },
]

// [Report Constants]------------------------------------------------------------
// NOTE: for each report, the number of columns must be the same between the summary
// and detail reports. Unneded columns are filled with empty ('') data.

export const SALES_REPORT_SUMMARY_HEADERS = [
  { label: 'Location', key: 'column01' },
  { label: 'Total Refunds', key: 'column02' },
  { label: 'Total Sales', key: 'column03' },
  { label: '', key: 'column04' },
  { label: '', key: 'column05' },
  { label: '', key: 'column06' },
  { label: '', key: 'column07' },
  { label: '', key: 'column08' },
  { label: '', key: 'column09' },
  { label: '', key: 'column10' },
  { label: '', key: 'column11' },
  { label: '', key: 'column12' },
]

export const EMPTY_SALES_REPORT_SUMMARY = {
  column01: '',
  column02: '',
  column03: '',
  column04: '',
  column05: '',
  column06: '',
  column07: '',
  column08: '',
  column09: '',
  column10: '',
  column11: '',
  column12: '',
}

export const SALES_REPORT_DETAIL_HEADER = {
  column01: 'Location',
  column02: 'Tran Date ',
  column03: 'Customer',
  column04: 'Account/ Loan # (Last 4)',
  column05: 'Auth Code',
  column06: 'Plan Name',
  column07: 'Plan No.',
  column08: 'Product Type',
  column09: 'Transaction Type',
  column10: 'Transaction Amount',
  column11: 'Status',
  column12: 'Associate',
}

export const APPLICATION_REPORT_SUMMARY_HEADERS = [
  { label: 'Location', key: 'column01' },
  { label: 'Total Apps', key: 'column02' },
  { label: 'Approvals', key: 'column03' },
  { label: 'Declines', key: 'column04' },
  { label: 'Pending', key: 'column05' },
  { label: 'Duplicates', key: 'column06' },
  { label: 'Net App Volume', key: 'column07' },
  { label: 'Net Approval Rate', key: 'column08' },
  { label: '', key: 'column09' },
]

export const EMPTY_APPLICATION_REPORT_SUMMARY = {
  column01: '',
  column02: '',
  column03: '',
  column04: '',
  column05: '',
  column06: '',
  column07: '',
  column08: '',
  column09: '',
}

export const APPLICATION_REPORT_DETAIL_HEADER = {
  column01: 'Location',
  column02: 'Application Date',
  column03: 'Application ID',
  column04: 'Customer Name',
  column05: 'Product Type',
  column06: 'Status',
  column07: 'Credit Limit',
  column08: 'Account/ Loan # (Last 4)',
  column09: 'Associate',
}

export const RECONCILIATION_REPORT_SUMMARY_HEADERS = [
  { label: 'Location', key: 'column01' },
  { label: 'Product Type', key: 'column02' },
  { label: 'Total Sales', key: 'column03' },
  { label: 'Total Refunds', key: 'column04' },
  { label: 'Total Credit Adjustments', key: 'column05' },
  { label: 'Total Debit Adjustments', key: 'column06' },
  { label: 'Total MDF ', key: 'column07' },
  { label: 'Total Settlement', key: 'column08' },
  { label: 'Post Date', key: 'column09' },
  { label: 'ACH Eff Date', key: 'column10' },
  { label: '', key: 'column11' },
  { label: '', key: 'column12' },
  { label: '', key: 'column13' },
  { label: '', key: 'column14' },
  { label: '', key: 'column15' },
  { label: '', key: 'column16' },
  { label: '', key: 'column17' },
  { label: '', key: 'column18' },
]

export const EMPTY_RECONCILIATION_REPORT_SUMMARY_DETAIL = {
  column01: '',
  column02: '',
  column03: '',
  column04: '',
  column05: '',
  column06: '',
  column07: '',
  column08: '',
  column09: '',
  column10: '',
  column11: '',
  column12: '',
  column13: '',
  column14: '',
  column15: '',
  column16: '',
  column17: '',
  column18: '',
}

export const RECONCILIATION_REPORT_DATA_HEADERS = {
  column01: 'Location',
  column02: 'Product Type',
  column03: 'Trans Date',
  column04: 'Customer',
  column05: 'Account/ Loan # (Last 4)',
  column06: 'Post Date',
  column07: 'Auth #',
  column08: 'Plan',
  column09: 'Plan #',
  column10: 'Credit Term',
  column11: 'Origin Code',
  column12: 'Trans Type',
  column13: 'Trans $',
  column14: 'MDF Fee',
  column15: 'MDF Rate 1',
  column16: 'MDF Rate 2',
  column17: 'Settlement $',
  column18: 'ACH Eff Date',
}

// ----------------------------------------------------------------------------

export const MAX_DISPLAYED_SELECT_ITEMS = 6
export const MENU_HEIGHT = 35

export const PRODUCT_TYPE = {
  ACCESS_LOAN: 'installment',
  AC_PRIME: 'line_of_credit',
}

export const PRODUCT_TYPE_MAP = {
  installment: 'ACCESS LOAN',
  line_of_credit: 'AC CARD',
}

export const TRANSACTION_TYPE_TO_PRODUCT_TYPE_MAP = {
  loan: PRODUCT_TYPE_MAP.installment,
  loan_refund: PRODUCT_TYPE_MAP.installment,
  refund: PRODUCT_TYPE_MAP.line_of_credit,
  sale: PRODUCT_TYPE_MAP.line_of_credit,
}

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export const CONSUMER_APP_URL = process.env.REACT_APP_CONSUMER_APP_URL

export const TRANSACTION_FILTERS = {
  CUSTOMER_NAME: 'customer-name',
  ACCOUNT_NUMBER: 'account-number',
}
export const FILTER_OPTIONS = [
  { key: 'Customer Name', value: TRANSACTION_FILTERS.CUSTOMER_NAME },
  { key: 'Account/Loan Number', value: TRANSACTION_FILTERS.ACCOUNT_NUMBER },
]

export const APPLICATION_STATUS_OPTIONS = [
  { key: 'Pre-approved', value: 'Pre-approved' },
  { key: 'Approved', value: 'Approved' },
  { key: 'Contract Sign', value: 'Contract Sign' },
  { key: 'Down Payment', value: 'Down Payment' },
  { key: 'Declined', value: 'Declined' },
  { key: 'All', value: 'All' },
]

export const POBOX_VARIATIONS = [
  'pobox',
  'Po Box',
  'Po box',
  'po box',
  'PO Box',
  'P.O Box',
  'P.O. Box',
  'Post Office Box',
]

export const EST_TIMEZONE = 'America/New_York'
export const PST_TIMEZONE = 'America/Los_Angeles'
export const ACCESS_LOAN_DEFAULT_LIST = [
  {
    id: null,
    name: 'Plan A',
    description: 'Silver',
    locationPlanSettings: {
      isActive: false,
    },
  },
  {
    id: null,
    name: 'Plan B',
    description: 'Gold',
    locationPlanSettings: {
      isActive: false,
    },
  },
]

export const ACCESS_LOAN_DISALLOWED_STATES = [
  'LA',
  'MA',
  'NE',
  'NH',
  'ND',
  'OR',
  'RI',
  'VE',
]

export const DEFAULT_MAX_VALUE_FOR_PLANS = 10000000

export const PUSH_APPLICATION_SMS_TEMPLATE_NAME = 'push_prequalification'
