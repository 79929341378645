import React, { useEffect, useState, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AuthorizedLinkButton } from 'merchant-portal-components'
import { Pagination, Spinner } from 'components'
import { PERMISSION, USER_ADD_URL, USER_UPDATE_URL } from 'config'
import { Table, TableColumn as Column } from 'lp-components'
import { UserFilterForm } from '../forms'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { ManagerType } from 'types'
import { formatISODateStringAsUSMonthDayYear, withApiAuth } from 'utils'
import { formValueSelector } from 'redux-form'
import { isEmpty, isUndefined } from 'lodash'
import { clearMangers } from '../actions'

const propTypes = {
  getManagers: PropTypes.func.isRequired,
  managers: PropTypes.arrayOf(ManagerType),
  userStatus: PropTypes.arrayOf(PropTypes.string),
  searchQuery: PropTypes.string,
}
const defaultProps = {}

function UserList({ userStatus, getManagers, managers, searchQuery }) {
  const [managerPaginatedData, setManagerPaginatedData] = useState([])

  useEffect(() => {
    getManagers()
    return () => clearMangers()
  }, [])

  const managerData = useMemo(() => {
    let filteredData = userStatus
      ? managers?.filter((user) => userStatus?.includes(user.status))
      : managers

    if (searchQuery) {
      filteredData = filteredData.filter((fd) => {
        const name = `${fd.firstName} ${fd.lastName}`.toLowerCase()
        const email = fd.email
        return (
          name?.includes(searchQuery.toLowerCase()) ||
          email?.includes(searchQuery.toLowerCase())
        )
      })
    }

    return filteredData
  }, [userStatus, searchQuery, managers])

  const handlePageChange = (paginatedData) => {
    setManagerPaginatedData(paginatedData)
  }

  if (isEmpty(managers) || isUndefined(managers)) {
    return <Spinner />
  }

  return (
    <>
      <div className="merchant dashboard users">
        <div className="dashboard--header">Manage Users</div>

        <div className="users__add-user m-t-30">
          {' '}
          <AuthorizedLinkButton
            to={USER_ADD_URL}
            className="button-primary button-warn-outline"
            requiredPermission={PERMISSION.MANAGE_USERS}
            size="large"
          >
            Add User
          </AuthorizedLinkButton>
        </div>

        <div className="dashboard__activities--table m-t-30 users__table">
          <UserFilterForm
            onSubmit={(e) => {
              e.preventDefault()
            }}
            initialValues={{
              searchQuery: '',
              userStatus: ['active'],
            }}
          />

          {managerData.length > 0 ? (
            <>
              <Table data={managerPaginatedData}>
                <Column
                  label="USER"
                  name="firstName"
                  component={({ data }) => (
                    <td
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        to={{
                          pathname: `${USER_UPDATE_URL}${data.managerId}`,
                        }}
                      >
                        {data.firstName} {data.lastName}
                      </Link>
                    </td>
                  )}
                />
                <Column
                  name="email"
                  label="EMAIL"
                  component={({ data }) => (
                    <td className="login">{data.email}</td>
                  )}
                />
                <Column
                  name="status"
                  label="STATUS"
                  component={StatusHighlightCell}
                />
                <Column
                  name="createdAt"
                  label="DATE CREATED"
                  format={formatISODateStringAsUSMonthDayYear}
                />
                <Column
                  name="updatedAt"
                  label="LAST UPDATED"
                  format={formatISODateStringAsUSMonthDayYear}
                />
              </Table>
              <div className="m-b-20">
                <Pagination
                  handlePageChange={handlePageChange}
                  data={managerData}
                />
              </div>
            </>
          ) : (
            <div className="empty-state-container">
              <p>No Users</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const StatusHighlightCell = ({ value }) => {
  return (
    <td
      style={{
        color: value === 'inactive' ? 'red' : value === 'active' ? 'green' : '',
        textTransform: 'uppercase',
        fontWeight: 800,
      }}
    >
      {value}
    </td>
  )
}

UserList.propTypes = propTypes

UserList.defaultProps = defaultProps

const selector = formValueSelector('userFilterForm')

function mapStateToProps(state) {
  return {
    managers: selectors.managers(state),
    userStatus: selector(state, 'userStatus'),
    searchQuery: selector(state, 'searchQuery'),
  }
}
const mapDispatchToProps = {}

const mapApiAuthToProps = {
  getManagers: apiActions.getManagers,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(UserList)
