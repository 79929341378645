import React from 'react'
import { useLocation } from 'react-router-dom'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import { ExternalLink } from 'components'
import {
  CONTACT_NUMBER,
  TDD_TTY_NUMBER,
  CONSUMER_APP_URL,
  PATH,
  makePath,
} from 'config'
import { getLocation } from 'local-storage'

const propTypes = {}
const defaultProps = {}

function Declined() {
  const location = useLocation()

  const { showConsumerPortalLink } = location.state
  const persistedLocation = getLocation()

  const consumerPortalURL = makePath(
    CONSUMER_APP_URL,
    PATH.CREDIT_PORTAL,
    PATH.STORE,
    persistedLocation?.storeNumber
  )

  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Application Declined</h1>
        <p className="first-subtitle">Thank you for your interest.</p>

        {showConsumerPortalLink && (
          <p>
            If the patient would like to apply for an Alphaeon Access Loan,
            please provide them the following link:
            <br />
            <ExternalLink href={consumerPortalURL}>
              {consumerPortalURL}
            </ExternalLink>
          </p>
        )}

        <p>
          We are reviewing the information you provided for the Alphaeon Credit
          Card and will contact you by mail within 10 business days.
        </p>
        <p>
          If you have any questions regarding your application with the Alphaeon
          Credit Card, contact our Customer Care department.
        </p>

        <div className="info-container">
          <LocalPhoneIcon />{' '}
          <div className="number-container">
            <div>
              <a href={`tel:${CONTACT_NUMBER}`}>{CONTACT_NUMBER}</a>
            </div>{' '}
            <div>
              <a href={`tel:${TDD_TTY_NUMBER}`}>(TDD/TTY {TDD_TTY_NUMBER})</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Declined.propTypes = propTypes
Declined.defaultProps = defaultProps

export default Declined
