import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Tooltip } from '@material-ui/core'

const propTypes = {
  children: PropTypes.node.isRequired,
  displayed: PropTypes.bool.isRequired,
  message: PropTypes.string,
}
const defaultProps = {}

function AuthorizationTooltip({ children, displayed, message, ...rest }) {
  return (
    <Tooltip
      arrow
      placement="top"
      title={
        message ? (
          <p style={{ fontSize: '14px', color: 'white' }}>{message}</p>
        ) : displayed ? (
          <span style={{ fontSize: '1.4em' }}>
            Permission for this functionality is disabled.
            <br />
            Please contact your administrator to enable.
          </span>
        ) : (
          ''
        )
      }
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

AuthorizationTooltip.propTypes = exact(propTypes)
AuthorizationTooltip.defaultProps = defaultProps

export default React.memo(AuthorizationTooltip)
