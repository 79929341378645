import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Routes as ErrorRoutes } from './error'
import { Routes as LegalRoutes } from './legal'
import { App as CreditPortalApp } from './credit-portal'
import { App as MerchantPortalApp } from './merchant-portal'
import { Routes as StyleguideRoutes } from './styleguide'
import { App as AdminPortalApp } from './admin-portal'
import { isUndefined } from 'lodash'
import PrintPage from './PrintPage'
import { PATH } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const portalValExists = isUndefined(process.env.REACT_APP_PORTAL)

  const loadConsumerPortal =
    portalValExists || process.env.REACT_APP_PORTAL === 'consumer'

  const loadMerchantPortal =
    portalValExists || process.env.REACT_APP_PORTAL === 'merchant'

  const redirectUrl = loadConsumerPortal
    ? PATH.CREDIT_PORTAL
    : PATH.MERCHANT_PORTAL

  return (
    <Switch>
      {loadConsumerPortal && (
        <Route path={PATH.CREDIT_PORTAL}>
          <CreditPortalApp />
        </Route>
      )}

      {loadMerchantPortal && (
        <Route path={PATH.ADMIN}>
          <AdminPortalApp />
        </Route>
      )}

      {loadMerchantPortal && (
        <Route path={PATH.MERCHANT_PORTAL}>
          <MerchantPortalApp />
        </Route>
      )}

      <Route path={PATH.ERROR}>
        <ErrorRoutes />
      </Route>
      <Route path={PATH.LEGAL}>
        <LegalRoutes />
      </Route>

      <Route path={PATH.PRINT} exact={true}>
        <PrintPage />
      </Route>

      <Route path={PATH.STYLEGUIDE}>
        <StyleguideRoutes />
      </Route>
      <Redirect path="*" to={redirectUrl} />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
