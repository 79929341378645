import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'

const reducerKey = 'accountLookup'
const slice = 'root.merchantPortal.accountLookup'

const initialState = {}

const reducer = handleActions(
  {
    [actions.clearMerchantPortalAccountLookup]: unsetState('accountsList'),
    [actions.clearAccountDetail]: unsetState('accountDetail'),

    [apiActions.searchForAccounts]: setOnSuccess('accountsList'),
    [apiActions.getAccountDetails]: setOnSuccess('accountDetail'),
    [apiActions.getAccountVerification]: setOnSuccess('accountVerification'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  accountsList: select('accountsList'),
  accountDetail: select('accountDetail'),
  accountVerification: select('accountVerification'),
}
export { reducer, selectors, reducerKey }
