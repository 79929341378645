import React from 'react'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import { CONTACT_NUMBER, TDD_TTY_NUMBER } from 'config'

const propTypes = {}
const defaultProps = {}

function DeclinedPage() {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Credit Application</h1>
        <p className="first-subtitle">
          Thank you for your interest in the Alphaeon Credit Card.
        </p>

        <p>
          We’re reviewing the information you provided and will contact you by
          mail within 10 business days.
        </p>
        <p>
          If you have any questions or issues, please do not hesitate to contact
          our Customer Care department.
        </p>

        <div className="info-container">
          <LocalPhoneIcon />{' '}
          <div className="number-container">
            <div>
              <a href={`tel:${CONTACT_NUMBER}`}>{CONTACT_NUMBER}</a>
            </div>{' '}
            <div>
              <a href={`tel:${TDD_TTY_NUMBER}`}>(TDD/TTY {TDD_TTY_NUMBER})</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DeclinedPage.propTypes = propTypes
DeclinedPage.defaultProps = defaultProps

export default DeclinedPage
