import React, { useState } from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { Button } from 'lp-components'
import { TransactionDisplayType } from 'types'
import { formatCurrency, isRevolving } from 'utils'

const propTypes = {
  onCardClick: PropTypes.func,
  plan: TransactionDisplayType.isRequired,
  isSaleTransaction: PropTypes.bool.isRequired,
  handleSelectForPrint: PropTypes.func.isRequired,
  forPrint: PropTypes.bool,
}

const defaultProps = {
  forPrint: false,
}

function PlanCard({
  plan,
  onCardClick,
  isSaleTransaction,
  handleSelectForPrint,
  forPrint,
}) {
  const [showBottomCard, setShowBottomCard] = useState(false)

  const { id, name, estimatedMonthlyPayment, estimatedTotalCost } = plan

  const handleCheckboxChange = (e) => {
    setShowBottomCard(!showBottomCard)
    handleSelectForPrint(e)
  }

  return (
    <>
      <div className="summary__card card m-t-30">
        <div className="card-content">
          <div
            className={`summary__account-info${!forPrint ? '__for-view' : ''}`}
          >
            <p className="card-title">{name}</p>

            {!isRevolving(name) && (
              <div className="card-main">
                <div className="card-main__left">
                  <div className="info-container">
                    <p className="card-area">
                      <strong>
                        {estimatedMonthlyPayment
                          ? `${formatCurrency(
                              estimatedMonthlyPayment,
                              2
                            )}/month`
                          : 'N/A'}{' '}
                      </strong>{' '}
                      Estimated Monthly Payment
                    </p>
                  </div>
                  <div className="info-container">
                    <p className="card-area">
                      <strong>
                        {estimatedTotalCost
                          ? `${formatCurrency(estimatedTotalCost, 2)}`
                          : 'N/A'}
                      </strong>{' '}
                      Total Estimated Cost
                    </p>
                  </div>
                  <div>
                    {plan.terms &&
                      forPrint &&
                      plan.terms.map((term) => (
                        <p className="termText" key={term.term_content_id}>
                          {term.text}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {showBottomCard && (
              <div>
                {plan.terms &&
                  plan.terms.map((term) => (
                    <p className="termText" key={term.term_content_id}>
                      {term.text}
                    </p>
                  ))}

                <div className="m-b-20 m-t-20">
                  <strong>Please read the program details</strong>
                </div>
                {isSaleTransaction && (
                  <Button
                    className="button-warn m-t-30"
                    onClick={() => onCardClick(plan)}
                  >
                    PROCESS SALE TRANSACTION
                  </Button>
                )}
              </div>
            )}
          </div>
          {isSaleTransaction && (
            <Button
              className="button-warn-outline sale"
              onClick={() => setShowBottomCard(!showBottomCard)}
            >
              Choose
            </Button>
          )}
          {!isSaleTransaction && !forPrint && !isRevolving(name) && (
            <div className="select-for-print-wrap">
              <input
                type="checkbox"
                value={id}
                className="select-for-print"
                onChange={handleCheckboxChange}
              />
              <label>Select to View/Print</label>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

PlanCard.propTypes = exact(propTypes)
PlanCard.defaultProps = defaultProps

export default PlanCard
