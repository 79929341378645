import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { URL } from 'config'
import { Spinner } from 'components'

const propTypes = {}

function PrintPage() {
  const [loading, setLoading] = useState(true)
  const { terms } = useParams()

  const printPage = () => {
    setTimeout(() => {
      setLoading(false)
      window.print()
    }, 3000)
  }

  const SRC = terms === 'CCA' ? URL.CCA : URL.PATRIOT_ACT

  return (
    <>
      <div className="print-page">
        {loading && <Spinner />}
        <iframe className="proxy-iframe" id="iframe" src={SRC}></iframe>
        <iframe
          className={`iframe ${
            terms === 'CCA' ? 'financial-terms' : 'patriot-terms'
          }`}
          id="iframe"
          onLoad={printPage}
          src={SRC}
        ></iframe>
      </div>
    </>
  )
}
PrintPage.propTypes = propTypes

export default PrintPage
