import React from 'react'
import { Button } from 'components'

function ExternalLinkButton(
  { children, className, href, invalid, ...rest },
  ref
) {
  // Ensure that all web addresses begin with a protocol (the expectation). If
  // they don't, assume the current protocol (typically https) by prepending
  // "//" to the URL.
  const normalizedUrl = href.startsWith('http') ? href : `//${href}`

  return (
    <>
      <a
        className="external-link"
        target="_blank"
        rel="noopener noreferrer"
        href={normalizedUrl}
        {...rest}
      >
        <Button
          className={`${className} external-link-button`}
          invalid={invalid}
          ref={ref}
        >
          {children}
        </Button>
      </a>
      <span hidden>Opens in a new window</span>
    </>
  )
}

export default React.forwardRef(ExternalLinkButton)
