import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { Spinner } from 'components'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors } from '../reducer'
import { selectors as merchantSelectors } from '../../reducer'
import { LocationType, AccountDetailType } from 'types'
import { withApiAuth, flashSubmitFailure } from 'utils'
import { ACCOUNT_DETAIL_URL } from 'config'
import {
  LoanCard,
  LoanAdjustConfirmationModal,
  LoanCancelConfirmationModal,
} from 'merchant-portal-components'
import LoanAdjustmentForm from '../../forms/LoanAdjustmentForm'
import { flashSuccessMessage } from 'redux-flash'

const propTypes = {
  currentLocation: LocationType.isRequired,
  loading: PropTypes.bool.isRequired,
  getAccountDetails: PropTypes.func.isRequired,
  accountDetail: AccountDetailType,
  clearAccountDetail: PropTypes.func.isRequired,
  adjustLoan: PropTypes.func.isRequired,
  cancelLoan: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function LoanAdjustment({
  accountDetail,
  currentLocation,
  loading,
  adjustLoan,
  cancelLoan,
  getAccountDetails,
  clearAccountDetail,
  flashSuccessMessage,
}) {
  const { locationId } = currentLocation

  const location = useLocation()
  const history = useHistory()
  const [showAdjustConfirm, setShowAdjustConfirm] = useState(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState(false)

  const [adjustmentData, setAdjustmentData] = useState(null)

  useEffect(() => {
    getAccountDetails(location.state.id, locationId, null)

    return () => {
      clearAccountDetail()
    }
  }, [location.state.id, currentLocation])

  if (!accountDetail) {
    return <Spinner />
  }

  const { alphaeonAccountNumber } = accountDetail

  const handleSubmit = (data) => {
    setAdjustmentData(data)
    setShowAdjustConfirm(true)
  }

  const handleLoanAdjustment = () => {
    return adjustLoan(alphaeonAccountNumber, { ...adjustmentData }, locationId)
  }

  const handleLoanCancel = () => {
    return cancelLoan(alphaeonAccountNumber, locationId)
  }

  const handleLoanCancelSuccess = () => {
    flashSuccessMessage('Loan Cancelled.')
    history.push(`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`)
  }
  const handleCancel = () => {
    setShowCancelConfirm(true)
  }

  const handleSubmitSuccess = () => {
    flashSuccessMessage('Loan Adjusted Successfully.')
    history.push(`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`)
  }

  return (
    <div className="account-lookup">
      <h1>Loan Adjustment</h1>
      <div className="transaction-summary summary w-80 m-b-40 ">
        <LoanCard
          transaction={location.state.transaction}
          accountDetail={accountDetail}
          withCancel={true}
          handleCancelOrRefund={handleCancel}
        />
      </div>
      <div className="edit-information">
        <span>
          Edit Information{' '}
          <span className="additional-info">
            (You can enter information to one or both of the fields below)
          </span>
        </span>
      </div>

      <LoanAdjustmentForm
        alphaeonAccountNumber={alphaeonAccountNumber}
        permissions={'currentPermissions'}
        onSubmit={handleSubmit}
        onSubmitFail={flashSubmitFailure}
        handleCancelEdit={() =>
          history.push(`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`)
        }
      />
      {loading && <Spinner />}

      {showAdjustConfirm && (
        <LoanAdjustConfirmationModal
          adjustmentData={adjustmentData}
          accountDetail={accountDetail}
          handleConfirm={handleLoanAdjustment}
          handleSubmitSuccess={handleSubmitSuccess}
          handleSubmitFailure={flashSubmitFailure}
          handleClose={() => setShowAdjustConfirm(false)}
        />
      )}
      {showCancelConfirm && (
        <LoanCancelConfirmationModal
          handleConfirm={handleLoanCancel}
          handleSubmitSuccess={handleLoanCancelSuccess}
          handleSubmitFailure={flashSubmitFailure}
          handleClose={() => setShowCancelConfirm(false)}
        />
      )}
    </div>
  )
}

LoanAdjustment.propTypes = propTypes

LoanAdjustment.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    accountDetail: selectors.accountDetail(state),
    currentLocation: merchantSelectors.currentLocation(state),
    loading: apiSelectors.isLoading(state, 'ACCOUNT_DATA'),
  }
}

const mapDispatchToProps = {
  clearAccountDetail: actions.clearAccountDetail,
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  getAccountDetails: apiActions.getAccountDetails,
  adjustLoan: apiActions.adjustLoan,
  cancelLoan: apiActions.cancelLoan,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(LoanAdjustment)
