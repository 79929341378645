import { useEffect } from 'react'
import { URL } from 'config'

const useIovation = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = URL.IOVATION_JS
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const getBlackboxData = () => {
    let blackboxData
    try {
      // eslint-disable-next-line no-undef
      blackboxData = ioGetBlackbox()
    } catch (e) {
      alert('Unable to get blackbox. ' + e)
    }
    return blackboxData
  }

  return [getBlackboxData]
}

export default useIovation
