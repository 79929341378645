import React from 'react'
import Routes from './Routes'
import '../../scss/application.scss'
import history from 'browser-history'
import store from 'store'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  )
}

export default App
