import { handleActions } from 'redux-actions'
import { compose } from 'redux'
import { set, unset } from 'lodash/fp'
import { handleSuccess, setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'

import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'applicants'
const slice = 'root.creditPortal.applicants'
const initialState = {}
const reducer = handleActions(
  {
    [actions.clearApplicationEnvironment]: unsetState('applicationEnvironment'),
    [actions.clearApplicant]: (state) => {
      return compose(
        unset('creditApplication'),
        unset('personalInfo'),
        unset('practice'),
        unset('prequalifications')
      )(state)
    },
    [apiActions.getCreditApplicationByToken]: handleSuccess(
      (state, { payload: { data } }) => {
        return compose(
          set('creditApplication', data.creditApplication),
          set('practice', data.location),
          set('personalInfo', data.applicant)
        )(state)
      }
    ),
    [apiActions.getCreditApplicationByTokenValidate]: handleSuccess(
      (state, { payload: { data } }) => {
        return compose(
          set('creditApplication', data.creditApplication),
          set('practice', data.location),
          set('personalInfo', data.applicant)
        )(state)
      }
    ),
    [apiActions.newCreditApplication]: setOnSuccess('applicationEnvironment'),
    [apiActions.createCreditApplicationIdentityVerification]: setOnSuccess(
      'identityVerification'
    ),
  },
  initialState
)
const select = selectorForSlice(slice)
const selectors = {
  applicationEnvironment: select('applicationEnvironment'),
  creditApplication: select('creditApplication'),
  personalInfo: select('personalInfo'),
  practice: select('practice'),
  prequalifications: select('prequalifications'),
  identityVerification: select('identityVerification'),
}

export { reducer, selectors, reducerKey }
