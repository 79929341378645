import React, { useState, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { pullAt } from 'lodash'

import { Table, TableColumn as Column } from 'lp-components'
import { ReconciliationReportActionCell } from 'merchant-portal-components'
import ReportDetailsContainer from './ReportDetailsContainer'
import { selectors } from '../reducer'
import {
  ACCOUNT_DETAIL_URL,
  RECONCILIATION_REPORT_DATA_HEADERS,
  RECONCILIATION_REPORT_SUMMARY_HEADERS,
  EMPTY_RECONCILIATION_REPORT_SUMMARY_DETAIL,
} from 'config'
import { ReconciliationReportType } from 'types'
import {
  formatCurrency,
  formatFixedNumber,
  formatISODateStringAsUSMonthDayYear,
  formatLocation,
  formatProductType,
  matchingDetailCriteria,
} from 'utils'

const propTypes = {
  reconciliationReport: ReconciliationReportType.isRequired,
}
const defaultProps = {}

function ReconciliationReport({ reconciliationReport }) {
  const reportRef = useRef(null)
  const [detailedLocations, setDetailedLocations] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [displayedLocationDetail, setDisplayedLocationDetail] = useState([])
  const { bankReconciliations, locationSummaries, summary } =
    reconciliationReport
  const summaryWithSummaryLabel = {
    ...summary,
    locationName: 'SUMMARY',
    locationNumber: undefined,
  }
  const allSummaries = [summaryWithSummaryLabel, ...locationSummaries]

  const toggleLocation = (
    achEffectiveDate,
    locationNumber,
    productType,
    postDate
  ) => {
    const detailCriteriaIndex = detailedLocations.findIndex(
      matchingDetailCriteria(
        achEffectiveDate,
        locationNumber,
        productType,
        postDate
      )
    )
    let newDetailedLocations = []

    // Toggle whether or not a detail records for a particular achEffectiveDate/
    // locationNumber/productType/postDate are displayed by examining the detailedLocations
    // array. If the criteria are present remove them from the array (no longer
    // display the detail). If the criteria are not present (findIndex() returns
    // -1), add them (and display the detail)...
    if (detailCriteriaIndex === -1) {
      newDetailedLocations = [
        ...detailedLocations,
        { achEffectiveDate, locationNumber, productType, postDate },
      ]
    } else {
      newDetailedLocations = [...detailedLocations]
      pullAt(newDetailedLocations, detailCriteriaIndex)
    }

    const newDisplayedLocationDetail = bankReconciliations.filter(
      ({ achEffectiveDate, locationNumber, productType, postDate }) =>
        newDetailedLocations.some(
          matchingDetailCriteria(
            achEffectiveDate,
            locationNumber,
            productType,
            postDate
          )
        )
    )

    setDetailedLocations(newDetailedLocations)
    setDisplayedLocationDetail(newDisplayedLocationDetail)
  }

  const getReconciliationData = () => {
    return displayedLocationDetail.map((data) => ({
      column01: formatLocation(data.locationName, data.locationNumber),
      column02: formatProductType(data.productType),
      column03: formatISODateStringAsUSMonthDayYear(data.transactionDate),
      column04: data.customerName,
      column05: data.accountNumber,
      column06: data.postDate,
      column07: data.authCode,
      column08: data.planName,
      column09: data.planNumber,
      column10: data.creditTerm,
      column11: data.originCode,
      column12: data.transactionType,
      column13: formatCurrencyWithCents(data.amount),
      column14: formatCurrencyWithCents(data.mdfFee),
      column15: formatCurrencyWithCents(data.mdfRate1),
      column16: formatCurrencyWithCents(data.mdfRate2),
      column17: formatCurrencyWithCents(data.settlementAmount),
      column18: formatISODateStringAsUSMonthDayYear(data.achEffectiveDate),
    }))
  }

  const getReconciliationSummary = () => {
    return allSummaries.map((data) => ({
      column01: formatLocation(data.locationName, data.locationNumber),
      column02: formatProductType(data.productType),
      column03: formatCurrencyWithCents(data.totalSales),
      column04: formatCurrencyWithCents(data.totalRefunds),
      column05: formatCurrencyWithCents(data.totalCreditAdjustments),
      column06: formatCurrencyWithCents(data.totalDebtAdjustments),
      column07: formatCurrencyWithCents(data.totalSettlement),
      column08: formatCurrencyWithCents(data.totalMdf),
      column09: data.postDate,
      column10: data.achEffectiveDate,
      column11: '',
      column12: '',
      column13: '',
      column14: '',
      column15: '',
      column16: '',
      column17: '',
      column18: '',
    }))
  }

  const getCSVData = () => {
    const summaryData = getReconciliationSummary()
    const summaryDetail = getReconciliationData()

    return [
      ...summaryData,
      ...new Array(5).fill(EMPTY_RECONCILIATION_REPORT_SUMMARY_DETAIL),
      RECONCILIATION_REPORT_DATA_HEADERS,
      ...summaryDetail,
    ]
  }

  const selectAllLocation = (selected) => {
    setAllSelected(selected)
    if (selected) {
      setDisplayedLocationDetail(bankReconciliations)
      setDetailedLocations([
        ...detailedLocations,
        ...bankReconciliations.map(
          ({ achEffectiveDate, locationNumber, productType, postDate }) => ({
            achEffectiveDate,
            locationNumber,
            productType,
            postDate,
          })
        ),
      ])
    } else {
      setDisplayedLocationDetail([])
      setDetailedLocations([])
    }
  }

  return (
    <ReportDetailsContainer
      csvHeaders={RECONCILIATION_REPORT_SUMMARY_HEADERS}
      csvFilename="BankReconciliationReport.csv"
      csvData={getCSVData()}
      reportRef={reportRef}
    >
      <div id="report-container" ref={reportRef}>
        <div className="report-detail__headers m-t-20">
          <div className="report-detail--title title ">
            Bank Reconciliation Results
          </div>
        </div>

        <div className="report-detail__summary m-t-50">
          <div className="report-detail m-t-5">
            {locationSummaries.length > 0 ? (
              <Table data={allSummaries}>
                <Column
                  name="action"
                  label="Show Details"
                  component={({ data }) => (
                    <ReconciliationReportActionCell
                      data={data}
                      detailedLocations={detailedLocations}
                      toggleLocation={toggleLocation}
                      selectAllLocation={selectAllLocation}
                      allSelected={allSelected}
                    />
                  )}
                />
                <Column
                  name="location"
                  label="Location"
                  valueGetter={({ locationName, locationNumber }) =>
                    formatLocation(locationName, locationNumber)
                  }
                />
                <Column
                  name="productType"
                  label="Product Type"
                  format={formatProductType}
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total Sales"
                  name="totalSales"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total Refunds"
                  name="totalRefunds"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total Credit Adjustments"
                  name="totalCreditAdjustments"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total Debit Adjustments"
                  name="totalDebtAdjustments"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total MDF"
                  name="totalMdf"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Total Settlement"
                  name="totalSettlement"
                />
                <Column
                  format={formatISODateStringAsUSMonthDayYear}
                  label="Post Date"
                  name="postDate"
                />
                <Column
                  format={formatISODateStringAsUSMonthDayYear}
                  label="ACH Eff Date"
                  name="achEffectiveDate"
                />
              </Table>
            ) : (
              <div className="empty-state-container">
                <p>No matching results</p>
              </div>
            )}
          </div>
        </div>

        <div className="report-detail--sub-title title m-t-50">Details</div>
        <div className="report-detail__summary-detail m-t-50">
          {displayedLocationDetail.length > 0 ? (
            <>
              <Table className="detail-table" data={displayedLocationDetail}>
                <Column
                  name="location"
                  label="Location"
                  valueGetter={({ locationName, locationNumber }) =>
                    formatLocation(locationName, locationNumber)
                  }
                />
                <Column
                  name="productType"
                  label="Product Type"
                  format={formatProductType}
                />
                <Column
                  format={formatISODateStringAsUSMonthDayYear}
                  label="Trans Date"
                  name="transactionDate"
                />
                <Column label="Customer" name="customerName" />
                <Column
                  name="accountNumber"
                  headerComponent={() => (
                    <th>
                      Account/
                      <br />
                      Loan #<br />
                      (Last 4)
                    </th>
                  )}
                  format={(value) => {
                    const displayValue = value?.slice(-4)
                    return value ? (
                      <Link to={`${ACCOUNT_DETAIL_URL}${value}`}>
                        {displayValue}
                      </Link>
                    ) : (
                      '-'
                    )
                  }}
                />
                <Column
                  format={formatISODateStringAsUSMonthDayYear}
                  label="Post Date"
                  name="postDate"
                />
                <Column label="Auth #" name="authCode" />
                <Column label="Plan" name="planName" />
                <Column label="Plan #" name="planNumber" />
                <Column label="Credit Term" name="creditTerm" />
                <Column label="Origin Code" name="originCode" />
                <Column label="Trans Type" name="transactionType" />
                <Column
                  format={formatCurrencyWithCents}
                  label="Trans $"
                  name="amount"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="MDF Fee"
                  name="mdfFee"
                />
                <Column
                  format={formatPercentage}
                  label="MDF Rate 1"
                  name="mdfRate1"
                />
                <Column
                  format={formatPercentage}
                  label="MDF Rate 2"
                  name="mdfRate2"
                />
                <Column
                  format={formatCurrencyWithCents}
                  label="Settlement $"
                  name="settlementAmount"
                />
                <Column
                  format={formatISODateStringAsUSMonthDayYear}
                  label="ACH Eff Date"
                  name="achEffectiveDate"
                />
              </Table>
            </>
          ) : (
            <>Select Summary above to show Details</>
          )}
        </div>
      </div>
    </ReportDetailsContainer>
  )
}

ReconciliationReport.propTypes = propTypes
ReconciliationReport.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    reconciliationReport: selectors.reconciliationReport(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReconciliationReport
)

function formatCurrencyWithCents(value) {
  return formatCurrency(value, 2)
}

function formatPercentage(value) {
  return `${formatFixedNumber(value, 2)}%`
}
