import React from 'react'

const propTypes = {}
const defaultProps = {}

function PreQualificationDeclinedPage() {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Pre-qualification</h1>
        <p className="first-subtitle">Thank you for your interest.</p>
        <p>We were unable to pre-qualify you. </p>
      </div>
    </div>
  )
}

PreQualificationDeclinedPage.propTypes = propTypes
PreQualificationDeclinedPage.defaultProps = defaultProps

export default PreQualificationDeclinedPage
