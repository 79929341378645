import React from 'react'
import exact from 'prop-types-exact'

import { ContactUs } from '../../views'

const propTypes = {}
const defaultProps = {}

function SiteInitializationContactUs() {
  return <ContactUs />
}

SiteInitializationContactUs.propTypes = exact(propTypes)
SiteInitializationContactUs.defaultProps = defaultProps

export default React.memo(SiteInitializationContactUs)
