import { isNil } from 'lodash'
import formatFixedNumber from './formatFixedNumber'

const formatCurrency = (amount, decimals = 0) => {
  if (isNil(amount)) return 'N/A'
  if (typeof amount === 'string') amount = Number(amount)

  const valueAsCurrency = `$${formatFixedNumber(Math.abs(amount), decimals)}`

  if (valueAsCurrency === 'N/A') return valueAsCurrency

  return amount < 0 ? `-${valueAsCurrency}` : valueAsCurrency
}

export default formatCurrency
