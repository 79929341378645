import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Input, MaskedInput, SubmitButton } from 'lp-components'
import { InputTip } from 'components'
import { formatISODateString } from 'utils'

const propTypes = {
  className: PropTypes.string,
  searchButtonLabel: PropTypes.string,
  ...formPropTypes,
}
const defaultProps = {
  searchButtonLabel: 'Find My Application',
}

function ApplicationFinderForm({
  className,
  handleSubmit,
  submitting,
  searchButtonLabel,
}) {
  return (
    <form className={className} onSubmit={handleSubmit} noValidate>
      <div className="form-fields">
        <Field
          name="ssnLastFour"
          component={Input}
          label="Last 4 of Social Security Number"
          required
          requiredIndicator="*"
        />
        <Field
          component={InputTip}
          inputType={MaskedInput}
          label="Date of Birth"
          name="dateOfBirth"
          placeholder="MM/DD/YYYY"
          required
          requiredIndicator=" *"
          maskOptions={{
            date: true,
            datePattern: ['m', 'd', 'Y'],
          }}
        />
        <Field
          label="Zip Code"
          name="zip"
          component={Input}
          placeholder="Zip Code"
          maxLength={5}
          required
          requiredIndicator=" *"
        />
      </div>

      <SubmitButton className="button-warn" submitting={submitting}>
        {searchButtonLabel}
      </SubmitButton>
    </form>
  )
}

ApplicationFinderForm.propTypes = propTypes
ApplicationFinderForm.defaultProps = defaultProps

function beforeSubmit(formValues) {
  const isoDateOfBirth = formatISODateString(formValues.dateOfBirth)

  return {
    ...formValues,
    dateOfBirth: isoDateOfBirth,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'ApplicationFinderForm',
    constraints: {
      dateOfBirth: {
        presence: true,
        length: { is: 10 },
      },
      ssnLastFour: {
        length: { is: 4 },
        numericality: { onlyInteger: true },
        presence: true,
      },
      zip: {
        length: { is: 5 },
        numericality: { onlyInteger: true },
        presence: true,
      },
    },
  })
)(ApplicationFinderForm)
