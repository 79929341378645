import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Button } from 'lp-components'
import { useHistory, Link } from 'react-router-dom'
import { AccountNumber } from 'components'
import { AuthorizedButton } from 'merchant-portal-components'
import { selectors } from '../reducer'
import { ApprovedApplicationType, ApplicationInfoType } from 'types'
import { formatCurrency } from 'utils'
import {
  START_APPLICATION_URL,
  MERCHANT_PORTAL_URL,
  PERMISSION,
  SALES_URL,
} from 'config'

const propTypes = {
  applicationInfo: ApplicationInfoType.isRequired,
  approvedCreditApplication: ApprovedApplicationType.isRequired,
}

function AccountCreated({ applicationInfo, approvedCreditApplication }) {
  const history = useHistory()

  if (!applicationInfo) {
    history.push(START_APPLICATION_URL)
    return <></>
  }

  const { creditLimit, alphaeonAccountNumber } = approvedCreditApplication

  const { firstName, lastName, ssnLast4 } = applicationInfo

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Account Created</h1>
        <div className="account-summary summary">
          <div className="summary__info m-t-20">
            A new credit account has been completed. Please tell the customer
            they will receive their welcome letter in the mail within 10-14
            days.
          </div>
          <div className="summary__card card m-t-30">
            <div className="card-content">
              <div className="summary__account-info">
                <p className="card-title">
                  {firstName} {lastName}
                </p>
                <div className="info-container sub-title">
                  <div className="card-area">Account Number: </div>
                  <AccountNumber accountNumberString={alphaeonAccountNumber} />
                </div>

                <div className="card-main">
                  <div className="card-main__left">
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Social Security Number</strong>{' '}
                        {ssnLast4 ? `XXX-XX-${ssnLast4}` : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="card-main__right">
                    <div className="info-container">
                      <p className="card-area">
                        <strong>Credit Limit</strong>{' '}
                        {formatCurrency(creditLimit, 2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button className="button-warn-outline sale">PRINT INFO</Button>
              </div>
            </div>
          </div>

          <div className="note m-t-30">
            Note: Account paperwork will be sent to the customer's mailing
            address
          </div>

          <div className="summary__actions m-t-50">
            <div className="submit">
              <AuthorizedButton
                className={`button-warn`}
                requiredPermission={PERMISSION.PROCESS_SALE}
                onClick={() => {
                  history.push({
                    pathname: `${SALES_URL}/${alphaeonAccountNumber}`,
                  })
                }}
              >
                PROCESS NEW SALE
              </AuthorizedButton>
            </div>
            <div className="cancel">
              <div>
                <Link to={MERCHANT_PORTAL_URL}>RETURN TO DASHBOARD</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AccountCreated.propTypes = propTypes

function mapStateToProps(state) {
  return {
    approvedCreditApplication: selectors.approvedCreditApplication(state),
    applicationInfo: selectors.applicationInfo(state),
  }
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountCreated
)
