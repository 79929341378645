import React from 'react'
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import {
  makePath,
  PATH,
  ACCOUNT_LOOKUP_URL,
  QUERY_KEY,
  ERROR_EXPIRATION_ERROR_URL,
} from 'config'
import AccountLookUpApp from './account_lookup/App'
import SalesApp from './sales/App'
import UserApp from './user/App'
import { AllLocationsProvider } from './components'
import { AuthenticatedRoutes, Spinner } from 'components'
import { Routes as InitializationRoutes } from './site-initialization'
import { Routes as ProgramManagementRoutes } from './program-management'
import { Routes as ReportsRoutes } from './reports'
import { forceRedirect, makeQueryPath } from 'utils'
import { useAuth0 } from '@auth0/auth0-react'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()
  const location = useLocation()
  const { isLoading, error } = useAuth0()

  if (error) {
    const { pathname: applicationPath } = location

    const errorStr = error.toString()
    const isErrorExpiration = errorStr.toLowerCase().includes('expiration time')

    if (isErrorExpiration) {
      return forceRedirect({ to: ERROR_EXPIRATION_ERROR_URL })
    }

    const authorizationErrorPath = makeQueryPath(PATH.AUTHORIZATION_ERROR, {
      ...error,
      [QUERY_KEY.APPLICATION_PATH]: applicationPath,
    })

    return forceRedirect({ to: authorizationErrorPath })
  }
  if (isLoading) return <Spinner />

  return (
    <AuthenticatedRoutes>
      <Switch>
        {/* Routes required before location information is available */}
        <Route path={makePath(path, PATH.SITE_INITIALIZATION)}>
          <InitializationRoutes />
        </Route>

        <AllLocationsProvider>
          <Layout>
            <Switch>
              <Route exact path={makePath(path, PATH.ROOT)}>
                <Views.Home />
              </Route>

              <Route path={makePath(path, PATH.CONTACT_US)}>
                <Views.ContactUs />
              </Route>

              <Route path={makePath(path, PATH.ACCOUNT_LOOKUP)}>
                <AccountLookUpApp />
              </Route>

              <Route path={makePath(path, PATH.SALES)}>
                <SalesApp />
              </Route>

              <Route path={makePath(path, PATH.USER)}>
                <UserApp />
              </Route>

              <Route
                exact
                path={makePath(
                  path,
                  PATH.ACCOUNT_START_APPLICATION_CONFIRMATION
                )}
              >
                <Views.StartApplication />
              </Route>

              <Route
                exact
                path={makePath(path, PATH.ACCOUNT_START_APPLICATION_BEGIN)}
              >
                <Views.Application />
              </Route>

              <Route exact path={makePath(path, PATH.ACCOUNT_CREATED)}>
                <Views.AccountCreated />
              </Route>

              <Route exact path={makePath(path, PATH.MARKETING)}>
                <Views.ShopifyStore />
              </Route>

              <Route exact path={makePath(path, PATH.QR_CODE)}>
                <Views.QrCodeCustomizer />
              </Route>

              <Route exact path={makePath(path, PATH.CALCULATOR)}>
                <Views.Calculator />
              </Route>

              <Route
                exact
                path={makePath(path, PATH.APPLICATION_ACCOUNT_EXISTS)}
              >
                <Views.AccountExists />
              </Route>

              <Route exact path={makePath(path, PATH.APPLICATION_DECLINED)}>
                <Views.DeclinedPage />
              </Route>

              <Route
                exact
                path={makePath(path, PATH.DECLINED_ACCESS_LOAN_AVAILABLE)}
              >
                <Views.DeclinedAccessLoanAvailable />
              </Route>

              <Route exact path={makePath(path, PATH.APPLICATION_PENDING)}>
                <Views.ApplicationPending />
              </Route>

              <Route exact path={makePath(path, PATH.VOID_SALE)}>
                {location.state ? (
                  <Views.VoidSale />
                ) : (
                  <Redirect to={ACCOUNT_LOOKUP_URL} />
                )}
              </Route>

              <Route exact path={makePath(path, PATH.REFUND_REQUEST)}>
                {location.state ? (
                  <Views.RefundRequest />
                ) : (
                  <Redirect to={ACCOUNT_LOOKUP_URL} />
                )}
              </Route>

              <Route exact path={makePath(path, PATH.REFUND_COMPLETE)}>
                {location.state ? (
                  <Views.RefundComplete />
                ) : (
                  <Redirect to={ACCOUNT_LOOKUP_URL} />
                )}
              </Route>

              <Route exact path={makePath(path, PATH.REPORTS)}>
                <ReportsRoutes />
              </Route>

              <Route exact path={makePath(path, PATH.SUPPLEMENTAL_FUNDING)}>
                <Views.SupplementalFunding />
              </Route>

              <Route path={makePath(path, PATH.PROGRAM_MANAGEMENT)}>
                <ProgramManagementRoutes />
              </Route>
            </Switch>
          </Layout>
        </AllLocationsProvider>
      </Switch>
    </AuthenticatedRoutes>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
