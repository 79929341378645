import React, { useEffect, useState, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
  Table,
  TableColumn as Column,
  SubmitButton,
  Modal,
  Switch,
} from 'lp-components'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import {
  apiErrorToErrorMessage,
  withApiAuth,
  isRevolving,
  toAmount,
  operationNotPermitted,
} from 'utils'
import { flashErrorMessage, flashSuccessMessage } from 'redux-flash'
import { isEqual, cloneDeep } from 'lodash'
import { PricingTierCard, EditableInput } from 'merchant-portal-components'
import { LocationType, LocationPlansType, PermissionsType } from 'types'
import { selectors as merchantSelectors } from '../../reducer'
import {
  PRODUCT_TYPE,
  ACCESS_LOAN_DEFAULT_LIST,
  DEFAULT_MAX_VALUE_FOR_PLANS,
  PROGRAM_MANAGEMENT_URL,
  PERMISSION,
  MERCHANT_PORTAL_URL,
  ACCESS_LOAN_DISALLOWED_STATES,
} from 'config'
import { Button, Spinner } from 'components'

const propTypes = {
  currentLocation: LocationType,
  fetchPlansByLocationId: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(LocationPlansType),
  updateLocationPlans: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  currentPermissions: PermissionsType.isRequired,
}
const defaultProps = {}

function ProgramManagement({
  currentLocation,
  fetchPlansByLocationId,
  plans,
  updateLocationPlans,
  flashSuccessMessage,
  flashErrorMessage,
  currentPermissions,
}) {
  if (operationNotPermitted(currentPermissions, PERMISSION.MANAGE_PROGRAMS))
    return <Redirect to={MERCHANT_PORTAL_URL} />

  const [initialAccessLoanPlanSettings, setInitialAccessLoanPlanSettings] =
    useState([])
  const [initialLocPlanSettings, setInitialLocPlanSettings] = useState([])

  const [accessLoanPlanSettings, setAccessLoanPlanSettings] = useState([])
  const [locPlanSettings, setLocPlanSettings] = useState([])

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [updatingLocationPlans, setUpdatingLocationPlans] = useState(false)

  const [locPlanDiff, setLocPlanDiff] = useState([])
  const [accessLoanPlanDiff, setAccessLoanPlanDiff] = useState([])

  const [changesMadeForAccessLoans, setChangesMadeForAccessLoans] =
    useState(false)
  const [changesMadeForLoc, setChangesMadeForLoc] = useState(false)

  const [anyAccessLoanPlansEnabled, setAnyAccessLoanPlansEnabled] =
    useState(false)

  let accessLoansDefault = cloneDeep(ACCESS_LOAN_DEFAULT_LIST)

  useEffect(() => {
    fetchPlansByLocationId(
      currentLocation.locationId,
      DEFAULT_MAX_VALUE_FOR_PLANS,
      false
    )
  }, [currentLocation])

  let accessLoanPlans = useMemo(() => {
    if (!plans) return []
    if (
      !currentLocation.address ||
      ACCESS_LOAN_DISALLOWED_STATES.includes(currentLocation.address.state)
    ) {
      return []
    }

    let accessLoanPlansFound = plans.plans.filter(
      (plan) => plan.productType === PRODUCT_TYPE.ACCESS_LOAN
    )
    if (accessLoanPlansFound && accessLoanPlansFound.length > 0) {
      accessLoanPlansFound.forEach((accessLoanPlanFound) => {
        const isEnabled = accessLoanPlanFound.locationPlanSettings?.isActive
        if (accessLoanPlanFound.name == 'Plan A') {
          accessLoansDefault[0].locationPlanSettings.isActive = isEnabled
          accessLoansDefault[0]['id'] = accessLoanPlanFound.id
        } else if (accessLoanPlanFound.name == 'Plan B') {
          accessLoansDefault[1].locationPlanSettings.isActive = isEnabled
          accessLoansDefault[1]['id'] = accessLoanPlanFound.id
        }
      })
      setAnyAccessLoanPlansEnabled(true)
    } else {
      accessLoansDefault = ACCESS_LOAN_DEFAULT_LIST
      setAnyAccessLoanPlansEnabled(false)
    }
    setInitialAccessLoanPlanSettings(accessLoansDefault)
    setAccessLoanPlanSettings(cloneDeep(accessLoansDefault))
    return accessLoansDefault
  }, [plans])

  const locPlans = useMemo(() => {
    if (!plans) return []
    return plans.plans.filter(
      (plan) =>
        plan.productType === PRODUCT_TYPE.AC_PRIME &&
        plan.locationPlanSettings?.isActive
    )
  }, [plans])

  const locPlansNonRevolving = useMemo(() => {
    if (!locPlans) return []

    return locPlans.filter((plan) => !isRevolving(plan.name))
  }, [locPlans])

  const locPlansRevolving = useMemo(() => {
    if (!locPlans) return []
    return locPlans.filter((plan) => isRevolving(plan.name))
  }, [locPlans])

  //const [accessLoanPlanDiff, setAccessLoanPlanDiff] = useState([])

  const enabledLocationKeys = [
    'payMyProviderEnabled',
    'dashboardDisplayEnabled',
    'isActive',
    'minPurchaseAmount',
  ]

  const FIELD_TO_TEXT = {
    minPurchaseAmount: 'Minimum Purchase Amount',
    dashboardDisplayEnabled: 'Dashboard / Calulator Display',
    payMyProviderEnabled: 'Pay my provider',
  }

  useEffect(() => {
    setInitialAccessLoanPlanSettings(accessLoanPlans)
    setAccessLoanPlanSettings(cloneDeep(accessLoanPlans))
  }, [accessLoanPlans])

  useEffect(() => {
    setInitialLocPlanSettings(locPlansNonRevolving.concat(locPlansRevolving))
    setLocPlanSettings(
      cloneDeep(locPlansNonRevolving.concat(locPlansRevolving))
    )
  }, [locPlansNonRevolving, locPlansRevolving])

  const planSettingsChange = (value, id, key, settings) => {
    const newArr = settings.map((obj) => {
      if (obj.id === id) {
        obj['locationPlanSettings'][key] = value
        return { ...obj }
      }

      return obj
    })
    return newArr
  }

  const locPlanSettingsChange = (value, id, key) => {
    const newArr = planSettingsChange(value, id, key, locPlanSettings)
    setChangesMadeForLoc(
      JSON.stringify(initialLocPlanSettings) != JSON.stringify(newArr)
    )
    setLocPlanSettings(newArr)
  }

  const accessLoanPlanSettingsChange = (value, id, key) => {
    const newArr = accessLoanPlanSettings.map((obj) => {
      if (obj.id === id) {
        obj['locationPlanSettings'][key] = value
        return { ...obj }
      } else {
        obj['locationPlanSettings'][key] = !value
      }

      return obj
    })
    setChangesMadeForAccessLoans(
      JSON.stringify(initialAccessLoanPlanSettings) != JSON.stringify(newArr)
    )
    setAccessLoanPlanSettings(newArr)
  }

  // const closeConfirmationModal =>{

  // }

  const determineLocPlanDiff = () => {
    return determinePlanDiff(initialLocPlanSettings, locPlanSettings)
  }

  const determinePlanDiff = (initial, current) => {
    let planDiff = []
    for (var i = 0; i < initial.length; i++) {
      if (!isEqual(current[i], initial[i])) {
        const initialLocationPlanSettings = initial[i].locationPlanSettings
        const newLocationPlanSettings = current[i].locationPlanSettings

        enabledLocationKeys.forEach((key) => {
          const initialValue = initialLocationPlanSettings[key]
          const newValue = newLocationPlanSettings[key]
          if (initialValue != newValue) {
            planDiff.push({
              name: initial[i].name,
              id: initial[i].id,
              field: key,
              from: initialValue,
              to: newLocationPlanSettings[key],
            })
          }
        })
      }
    }
    return planDiff
  }

  const determinePlanDiffForUpdate = (
    initialPlanSettings,
    currentPlanSettings
  ) => {
    let planDifForUpdate = []
    for (var i = 0; i < initialPlanSettings.length; i++) {
      if (!isEqual(currentPlanSettings[i], initialPlanSettings[i])) {
        const initialLocationPlanSettings =
          initialPlanSettings[i].locationPlanSettings
        const newLocationPlanSettings =
          currentPlanSettings[i].locationPlanSettings
        let planUpdate = { plan_id: initialPlanSettings[i].id }

        enabledLocationKeys.forEach((key) => {
          const initialValue = initialLocationPlanSettings[key]
          const newValue = newLocationPlanSettings[key]
          if (initialValue != newValue) {
            planUpdate[key] = newLocationPlanSettings[key]
          }
        })
        planDifForUpdate.push(planUpdate)
      }
    }
    return planDifForUpdate
  }

  const determineLocPlanDiffForUpdate = () => {
    return determinePlanDiffForUpdate(initialLocPlanSettings, locPlanSettings)
  }

  const determineAccessPlanDiff = () => {
    return determinePlanDiff(
      initialAccessLoanPlanSettings,
      accessLoanPlanSettings
    )
  }

  const determineAccessPlanDiffForUpdate = () => {
    return determinePlanDiffForUpdate(
      initialAccessLoanPlanSettings,
      accessLoanPlanSettings
    )
  }

  const submitChanges = () => {
    setUpdatingLocationPlans(true)
    const locationPlanUpdates = determineLocPlanDiffForUpdate().concat(
      determineAccessPlanDiffForUpdate()
    )

    const updateBody = { plans: locationPlanUpdates }
    updateLocationPlans(currentLocation.locationId, updateBody)
      .then(() => {
        setShowConfirmationModal(false)
        fetchPlansByLocationId(currentLocation.locationId, 10000000, false)
        setUpdatingLocationPlans(false)
        setChangesMadeForAccessLoans(false)
        setChangesMadeForLoc(false)
        flashSuccessMessage('Program Settings Updated Successfully')
      })
      .catch((error) => {
        flashErrorMessage(
          apiErrorToErrorMessage(
            error?.response?.errorCode,
            error?.response?.message
          )
        )
      })
  }

  const determineChanges = () => {
    const locChanges = determineLocPlanDiff()
    const accessLoanChanges = determineAccessPlanDiff()
    setLocPlanDiff(locChanges)
    setAccessLoanPlanDiff(accessLoanChanges)
    if (locChanges.length > 0 || accessLoanChanges.length > 0) {
      setShowConfirmationModal(true)
    } else {
      //do something to prompt the user for changes
    }
  }

  const accessLoanHtml = () => {
    let html = ''
    accessLoanPlanDiff.forEach((accessLoanChange) => {
      if (accessLoanChange.name == 'Plan A') {
        if (accessLoanChange.to === true) {
          html = (
            <>
              <li key={accessLoanChange.id}>
                Access Loan <b>Plan A "Silver" Enabled</b>
              </li>
            </>
          )
        } else {
          html = (
            <>
              <li key={accessLoanChange.id}>
                Access Loan <b>Plan B "Gold" Enabled</b>
              </li>
            </>
          )
        }
      }
    })
    return html
  }

  const formatToFixedTwoDecimals = (number) => {
    if (typeof number == 'number') {
      return number
    } else if (typeof number == 'string' && number == '') {
      return 0
    } else if (typeof number == 'string') {
      return toAmount(number)
    } else {
      return null
    }
  }

  const validateMinAmountValue = (number) => {
    let numberString = number
    if (typeof number == 'number') {
      numberString = toString(number)
    }

    return numberString == '' || /^\d+$/.test(numberString)
  }

  return (
    <>
      <div
        className="merchant dashboard program-management"
        style={{ display: 'block' }}
      >
        <div className="dashboard dashboard--header">Program Management</div>
        <div className="dashboard dashboard--header2">Alphaeon Credit Card</div>

        <div style={{ display: 'block' }}>
          <PricingTierCard currentLocation={currentLocation} />
        </div>

        <div className="dashboard dashboard--header3">Promotional Plan</div>

        <div className="program-management__settings--table m-t-50">
          {locPlanSettings && (
            <Table data={locPlanSettings}>
              <Column
                name="name"
                label="Plan Term"
                className="planName"
                style={{ width: '100%' }}
                component={({ data }) => {
                  if (data.name == 'REVOLVING') {
                    return <td>Revolving Plan</td>
                  } else {
                    return (
                      <td>
                        {data.name.trim()},{' '}
                        {(data.merchantDiscountRate * 100).toFixed(2)}% MDF
                      </td>
                    )
                  }
                }}
              />
              <Column
                name="dashboardDisplayEnabled"
                label="Dashboard / Calculator"
                component={({ data }) => {
                  if (data.name != 'REVOLVING') {
                    return (
                      <>
                        <td>
                          {' '}
                          <Switch
                            label={false}
                            uncheckedIcon={
                              <div
                                style={{
                                  color: 'white',
                                  paddingLeft: '4px',
                                  paddingTop: '8px',
                                  fontFamily: 'inherit',
                                  fontSize: '11px',
                                }}
                              >
                                OFF
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  color: 'white',
                                  paddingLeft: '7px',
                                  paddingTop: '8px',
                                  fontFamily: 'inherit',
                                  fontSize: '11px',
                                }}
                              >
                                ON
                              </div>
                            }
                            input={{
                              name: 'dashboardDisplayEnabled',
                              value:
                                data.locationPlanSettings
                                  .dashboardDisplayEnabled,
                            }}
                            onChange={(value) =>
                              locPlanSettingsChange(
                                value,
                                data.id,
                                'dashboardDisplayEnabled'
                              )
                            }
                            meta={{}}
                          />
                        </td>
                      </>
                    )
                  } else {
                    return <td>DEFAULT PLAN</td>
                  }
                }}
              />
              <Column
                name="minPurchaseAmount"
                label="Minimum Amount"
                component={({ data }) => {
                  if (data.name != 'REVOLVING') {
                    return (
                      <EditableInput
                        name="editMinPurchaseAmount"
                        initialValue={
                          data.locationPlanSettings.minPurchaseAmount
                        }
                        onSave={(value) =>
                          locPlanSettingsChange(
                            value,
                            data.id,
                            'minPurchaseAmount'
                          )
                        }
                        prefix="$"
                        inputType="text"
                        inputValidator={validateMinAmountValue}
                        inputFormatter={formatToFixedTwoDecimals}
                      ></EditableInput>
                    )
                  } else {
                    return <td></td>
                  }
                }}
              />

              <Column
                name="maxPurchaseAmount"
                label="Maximum Amount"
                component={({ data }) => {
                  if (data.name != 'REVOLVING') {
                    return (
                      <>
                        <td>${data.locationPlanSettings.maxPurchaseAmount}</td>
                      </>
                    )
                  } else {
                    return <td></td>
                  }
                }}
              />
              {/* <Column 
                  component={({data}) => {
                    return <td> <Switch label={false} input={{name: "payMyProviderEnabled", value: data.payMyProviderEnabled}} onChange={(value) => locPlanSettingsChange(value, data.id, "payMyProviderEnabled")} meta ={{}}/></td>
                  }}
                  /> */}
            </Table>
          )}
        </div>

        {accessLoanPlans.length > 0 && (
          <>
            <div className="dashboard dashboard--header2">
              Alphaeon Access Loan
            </div>

            {!anyAccessLoanPlansEnabled && (
              <div
                className="dashboard dashboard--header3"
                style={{ textAlign: 'center' }}
              >
                Access loans will provide you an opportunity for increased
                revenue by providing customers another avenue beyond the
                Alphaeon Credit Card. For additional details click{' '}
                <a
                  className="descriptionLink"
                  style={{ display: 'contents' }}
                  href="https://www.myalphaeoncredit.com/access"
                >
                  here
                </a>
                .
              </div>
            )}

            <div className="program-management__settings--table m-t-50">
              <Table data={accessLoanPlanSettings}>
                <Column name="name" label="Plan Term" />

                <Column name="description" label="Description" />

                <Column
                  name="isActive"
                  label="Enable/Disable"
                  component={({ data }) => {
                    return (
                      <>
                        <td>
                          <Switch
                            label={false}
                            input={{
                              name: 'isActive',
                              value: data.locationPlanSettings.isActive,
                            }}
                            disabled={!anyAccessLoanPlansEnabled}
                            uncheckedIcon={
                              <div
                                style={{
                                  color: 'white',
                                  paddingLeft: '4px',
                                  paddingTop: '8px',
                                  fontFamily: 'inherit',
                                  fontSize: '11px',
                                }}
                              >
                                OFF
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  color: 'white',
                                  paddingLeft: '7px',
                                  paddingTop: '8px',
                                  fontFamily: 'inherit',
                                  fontSize: '11px',
                                }}
                              >
                                ON
                              </div>
                            }
                            onChange={(value) =>
                              accessLoanPlanSettingsChange(
                                value,
                                data.id,
                                'isActive'
                              )
                            }
                            meta={{}}
                          ></Switch>
                        </td>
                      </>
                    )
                  }}
                />
              </Table>
            </div>
          </>
        )}

        {/* <div className="submit"> */}

        <div className="button-block">
          <SubmitButton
            onClick={() => determineChanges()}
            className={`button-warn-outline ${
              !changesMadeForLoc && !changesMadeForAccessLoans
                ? `warn-disabled`
                : ``
            }`}
            disabled={!changesMadeForLoc && !changesMadeForAccessLoans}
          >
            SAVE
          </SubmitButton>
          <Link
            to={PROGRAM_MANAGEMENT_URL}
            onClick={(e) => {
              e.preventDefault()
              window.location.reload()
            }}
          >
            CANCEL
          </Link>
        </div>
        {showConfirmationModal && (
          <Modal
            onClose={() => setShowConfirmationModal(false)}
            className="modal-inner program-management-confirm-modal modal"
          >
            <div className="program-management-confirm-modal__container">
              <div className="title dashboard--header">Summary of Changes</div>
              <div className="details">
                <>
                  <ul>
                    {locPlanDiff.length > 0 &&
                      locPlanDiff.map((locChange) => {
                        let to = locChange.to
                        let from = locChange.from

                        if (locChange.field.includes('Amount')) {
                          from = '$' + locChange.from.toString()
                          to = '$' + locChange.to.toString()
                        }
                        let changeHtml = (
                          <>
                            {locChange.name} changed from{' '}
                            <b>
                              {from.toString()} to {to.toString()} for{' '}
                              {FIELD_TO_TEXT[locChange.field]}
                            </b>
                          </>
                        )

                        if (typeof to == 'boolean') {
                          const changeVerb = to ? 'enabled' : 'disabled'
                          changeHtml = (
                            <>
                              {locChange.name}{' '}
                              <b>
                                {' '}
                                {changeVerb} for{' '}
                                {FIELD_TO_TEXT[locChange.field]}
                              </b>
                            </>
                          )
                        }
                        return <li key={locChange.id}>{changeHtml}</li>
                      })}
                    {accessLoanPlanDiff.length > 0 && accessLoanHtml()}
                  </ul>
                </>
                <br />
                <br />
              </div>
              <div className="button-block">
                <Button
                  className="button-warn-outline"
                  onClick={() => submitChanges()}
                >
                  Confirm
                </Button>
                <Link onClick={() => setShowConfirmationModal(false)}>
                  CANCEL
                </Link>
              </div>
            </div>
            <div className="mt-5 spinner">
              {updatingLocationPlans && <Spinner />}
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

ProgramManagement.propTypes = propTypes

ProgramManagement.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
    plans: selectors.plans(state),
    currentPermissions: merchantSelectors.currentPermissions(state),
  }
}
const mapApiAuthToProps = {
  fetchPlansByLocationId: apiActions.fetchPlansByLocationId,
  updateLocationPlans: apiActions.updateLocationPlans,
}

const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
  flashErrorMessage: flashErrorMessage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(ProgramManagement)
