import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { format } from 'date-fns'

import { AccountNumber } from 'components'
import SignReceiptForm from '../forms/SignReceiptForm'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as merchantSelectors } from '../../reducer'
import { ACCOUNT_LOOKUP_URL, VOID_SALE_URL } from 'config'
import {
  flashSubmitFailure,
  formatCurrency,
  formatISODateString,
  getIdentityTypeName,
  withApiAuth,
} from 'utils'
import {
  PaymentDetailsType,
  PlansType,
  LocationType,
  TransactionType,
} from 'types'
import { isNil } from 'lodash'
import AuthBuyerSaleCard from '../components/AuthBuyerSaleCard'

const propTypes = {
  currentLocation: LocationType.isRequired,
  getSalesReceipt: PropTypes.func.isRequired,
  paymentDetails: PaymentDetailsType.isRequired,
  processedSale: TransactionType.isRequired,
  selectedPlan: PlansType.isRequired,
  signReceipt: PropTypes.func.isRequired,
}
const defaultProps = {}

function SignReceipt({
  currentLocation,
  getSalesReceipt,
  paymentDetails,
  processedSale,
  selectedPlan,
  signReceipt,
}) {
  const history = useHistory()

  if (!paymentDetails) {
    return <Redirect to={ACCOUNT_LOOKUP_URL} />
  }

  const {
    name,
    alphaeonAccountNumber,
    availableCreditLimit,
    ssnLast4,
    creditLimit,
    amount,
    authorizationCode,
    referenceId,
    identificationConfirmation,
    buyer,
  } = paymentDetails
  const { name: selectedPlanName, terms } = selectedPlan
  const { createdAt, locationName, transactionId } = processedSale

  const handleSubmit = (formValues) => {
    const signReceiptRequest = { transactionId: transactionId }
    const { idExpiration } = formValues

    if (idExpiration) {
      const idExpiration = formatISODateString(formValues.idExpiration)
      signReceiptRequest.idExpirationDate = idExpiration
    }

    return signReceipt(signReceiptRequest).then(() => {
      return getSalesReceipt(transactionId)
    })
  }

  const handleSubmitSuccess = (pdfWindowURL) => {
    const pdfWindow = window.open(pdfWindowURL)
    pdfWindow.print()
  }

  const handleVoidSales = () => {
    history.push({
      pathname: `${VOID_SALE_URL}${alphaeonAccountNumber}`,
      state: {
        transaction: {
          amount,
          createdAt,
          locationName,
          planName: selectedPlanName,
          transactionId,
        },
        alphaeonAccountNumber,
        creditLimit,
        name,
        referenceId,
        buyer,
      },
    })
  }

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Sign Receipt, Step 3 of 3</h1>
        <>
          <div className="account-summary summary refund-complete">
            {buyer && (
              <AuthBuyerSaleCard
                paymentDetails={{ ...paymentDetails }}
                currentLocation={currentLocation}
              />
            )}
            {!buyer && (
              <div className="summary__card card m-t-30">
                <div className="card-content">
                  <div className="summary__account-info">
                    <p className="card-title">
                      {name.firstName} {name.lastName}
                    </p>
                    <div className="info-container sub-title">
                      <p className="card-area">Account Number: </p>
                      <AccountNumber
                        accountNumberString={alphaeonAccountNumber}
                      />
                    </div>

                    <div className="card-main">
                      <div className="card-main__left">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Available Credit</strong>{' '}
                            {formatCurrency(availableCreditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Social Security Number</strong>
                            {ssnLast4 ? `XXX-XX-${ssnLast4}` : 'N/A'}
                          </p>
                        </div>
                      </div>

                      <div className="card-main__right">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Credit Limit</strong>{' '}
                            {formatCurrency(creditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Location </strong>
                            {currentLocation.name} -{' '}
                            {currentLocation.adsStoreNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="summary__card--bottom card">
              <div className="card-content">
                <div className="summary__account-info">
                  <div className="card-main">
                    <div className="card-main__left">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong>Total Sales Amount</strong>{' '}
                          {formatCurrency(amount, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Authorization Code</strong>{' '}
                          {authorizationCode}
                        </p>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Reference ID</strong> {referenceId}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sales-complete-container" id="print-container">
              <div className="refund-complete__receipt m-t-50">
                <div className="refund-complete__receipt--header">
                  Sales Receipt
                </div>
                <div className="refund-complete__receipt--body">
                  <div className="refund-complete__receipt--info-container">
                    <div className="refund-complete__receipt--info">
                      <div className="key">Date</div>
                      <div className="value">
                        {format(new Date(), 'MM/dd/yyyy')}
                      </div>
                    </div>

                    {!isNil(buyer) && (
                      <>
                        <div className="refund-complete__receipt--info">
                          <div className="key">Authorized Buyer</div>
                          <div className="value">
                            {` ${buyer.firstName} ${buyer.lastName}`}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="refund-complete__receipt--info">
                      <div className="key">
                        {isNil(buyer) ? `Account Holder` : 'Responsible Party'}
                      </div>
                      <div className="value">
                        {' '}
                        {`${name.firstName} ${name.lastName}`}
                      </div>
                    </div>

                    <div className="refund-complete__receipt--info">
                      <div className="key">Location</div>
                      <div className="value">
                        {currentLocation.name} -{' '}
                        {currentLocation.adsStoreNumber}
                      </div>
                    </div>

                    <div className="refund-complete__receipt--info">
                      <div className="key">Plan Selected</div>
                      <div className="value">{selectedPlanName}</div>
                    </div>
                    <div className="disclaimer">
                      {terms.map(({ termContentId, text }) => (
                        <p key={termContentId}>{text}</p>
                      ))}
                    </div>
                  </div>

                  <div className="seperator"></div>

                  <div className="refund-complete__receipt--info-container">
                    <div className="refund-complete__receipt--info">
                      <div className="key">Authorization#</div>
                      <div className="value">{authorizationCode}</div>
                    </div>
                    <div className="refund-complete__receipt--info">
                      <div className="key">Account#</div>
                      <div className="value">
                        {' '}
                        <AccountNumber
                          accountNumberString={alphaeonAccountNumber}
                        />
                      </div>
                    </div>
                    <div className="refund-complete__receipt--info">
                      <div className="key">Amount Financed</div>
                      <div className="value">{formatCurrency(amount, 2)}</div>
                    </div>

                    <div className="disclaimer">
                      <p>
                        I agree to pay for these goods or services according to
                        the terms of my ALPHAEON Credit Card Agreement and if
                        applicable, any Promotional Credit Plan, which may apply
                        to this transaction. If any purchase is not
                        satisfactory, it will be subject to provider's refund
                        policy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <SignReceiptForm
                voidSale={handleVoidSales}
                initialValues={{
                  type: getIdentityTypeName(identificationConfirmation.type),
                  state: identificationConfirmation.state,
                }}
                onSubmit={handleSubmit}
                onSubmitSuccess={handleSubmitSuccess}
                onSubmitFail={flashSubmitFailure}
              />
            </div>
          </div>
        </>
      </div>
    </>
  )
}

SignReceipt.propTypes = propTypes
SignReceipt.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    paymentDetails: selectors.paymentDetails(state),
    processedSale: selectors.processedSale(state),
    selectedPlan: selectors.selectedPlan(state),
    currentLocation: merchantSelectors.currentLocation(state),
  }
}
const mapDispatchToProps = {}

const mapApiAuthToProps = {
  getSalesReceipt: apiActions.getSalesReceipt,
  searchForAccounts: apiActions.searchForAccounts,
  signReceipt: apiActions.signReceipt,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SignReceipt)
