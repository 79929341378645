import React from 'react'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Input, RadioGroup } from 'lp-components'
import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
import PropTypes from 'prop-types'
import { FILTER_OPTIONS, TRANSACTION_FILTERS } from 'config'

const propTypes = {
  filterOption: PropTypes.string,
  searchField: PropTypes.string,
  ...formPropTypes,
}

const defaultProps = {}

function TransactionFilterForm({ onSubmit, filterOption, searchField }) {
  return (
    <div className="start-application__form">
      <form noValidate onSubmit={onSubmit}>
        <div className="transaction-filter">
          <div className="checkbox-group transaction-filter--item">
            <Field
              name="filterOption"
              label={false}
              component={RadioGroup}
              options={FILTER_OPTIONS}
              onChange={(event) => {
                onSubmit({
                  searchField,
                  filterOption: event,
                })
              }}
            />
          </div>
          <div className="transaction-filter--item">
            <Field
              label={false}
              component={Input}
              name="searchField"
              onChange={(_event, newSearchField) => {
                onSubmit({
                  searchField: newSearchField,
                  filterOption,
                })
              }}
              placeholder={
                filterOption === TRANSACTION_FILTERS.CUSTOMER_NAME
                  ? 'First and Last Name'
                  : 'Account/Loan Number'
              }
            />
          </div>
        </div>
      </form>
    </div>
  )
}

TransactionFilterForm.propTypes = propTypes

TransactionFilterForm.defaultProps = defaultProps

export default compose(
  lpForm({
    constraints: {},
  }),
  formValues('filterOption')
)(TransactionFilterForm)
