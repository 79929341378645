import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'

import { useIovation } from 'hooks'
import ApplicantShow from './ApplicantShow'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { APPLICANTS_URL, CREDIT_PORTAL_URL, QUERY_KEY } from 'config'
import {
  flashSubmitFailure,
  determinePrequalificationResponse,
  makeQueryPath,
} from 'utils'
import { CreditApplicationType, PracticeType } from 'types'

const propTypes = {
  clearApplicant: PropTypes.func.isRequired,
  creditApplication: CreditApplicationType.isRequired,
  patchApplicant: PropTypes.func.isRequired,
  practice: PracticeType.isRequired,
  prequalifyWithNextLender: PropTypes.func.isRequired,
}
const defaultProps = {}

function ContinuedCreditApplication({
  clearApplicant,
  creditApplication,
  patchApplicant,
  practice,
  prequalifyWithNextLender,
}) {
  const { search } = useLocation()

  if (isNil(creditApplication)) {
    return (
      <Redirect
        to={{
          pathname: APPLICANTS_URL,
          search: search,
        }}
      />
    )
  }

  useEffect(() => {
    return () => {
      clearApplicant()
    }
  }, [])

  const { applicationId } = creditApplication
  const history = useHistory()
  const [getBlackboxData] = useIovation()

  const handleSubmit = (formValues) => {
    return patchApplicant(applicationId, formValues).then(() => {
      return prequalifyWithNextLender(applicationId, {
        iovationBlackBox: getBlackboxData().blackbox,
      })
    })
  }
  const handleSubmitSuccess = (response) => {
    const nextStep = determinePrequalificationResponse(response, 'no-modal')

    history.push(nextStep)
    return
  }
  const handleSubmitFailure = (errors, dispatch, submitError) => {
    const apiErrorCode = submitError?.meta?.error?.response?.errorCode

    if (apiErrorCode === 'new_application_required') {
      history.push(
        makeQueryPath(CREDIT_PORTAL_URL, { [QUERY_KEY.ERROR]: apiErrorCode })
      )
    } else {
      flashSubmitFailure(errors, dispatch, submitError)
    }
  }

  return (
    <ApplicantShow
      practice={practice}
      handleSubmit={handleSubmit}
      handleSubmitSuccess={handleSubmitSuccess}
      handleSubmitFailure={handleSubmitFailure}
    />
  )
}

ContinuedCreditApplication.propTypes = propTypes
ContinuedCreditApplication.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    creditApplication: selectors.creditApplication(state),
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {
  clearApplicant: actions.clearApplicant,
  patchApplicant: apiActions.patchApplicant,
  prequalifyWithNextLender: apiActions.prequalifyWithNextLender,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContinuedCreditApplication
)
