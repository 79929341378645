import React, { useEffect, useState } from 'react'
import DoneIcon from '@material-ui/icons/Done'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { first, isNil } from 'lodash'
import { selectors } from '../reducer'
import { flashErrorMessage } from 'redux-flash'
import PreQualificationForm from '../forms/PreQualificationForm'
import PreQualificationInstallment from './PreQualificationInstallment'
import * as apiActions from 'api-actions'
import {
  PersonalInfoType,
  PracticeType,
  PrequalificationType,
  ApplicationEnvironmentType,
} from 'types'
import {
  apiErrorToErrorMessage,
  approveTerms,
  flashSubmitFailure,
  formatCurrency,
  determinePrequalificationError,
  determinePrequalificationResponse,
  parseQuery,
} from 'utils'
import { useIovation } from 'hooks'
import {
  CREDIT_PORTAL_URL,
  ACCOUNT_EXISTS_URL,
  PRE_QUALIFICATION_INFORMATION_REQUIRED_URL,
  PRE_QUALIFICATION_ACCEPT_DECLINED_URL,
  DECLINED_URL,
  QUERY_KEY,
} from 'config'
import TagManager from 'react-gtm-module'
import { PrequalAcceptDeclineModal } from '../components'
import * as actions from '../actions'

const propTypes = {
  payload: PropTypes.object,
  applyAfterPrequalification: PropTypes.func.isRequired,
  practice: PracticeType,
  onStepComplete: PropTypes.func.isRequired,
  personalInfo: PersonalInfoType.isRequired,
  prequalification: PrequalificationType,
  prequalifyWithNextLender: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  setLenderReferenceId: PropTypes.func.isRequired,
  applicationEnvironment: ApplicationEnvironmentType,
}

function PreQualification({
  payload,
  applyAfterPrequalification,
  flashErrorMessage,
  prequalifyWithNextLender,
  practice,
  personalInfo,
  prequalification,
  onStepComplete,
  setLenderReferenceId,
  applicationEnvironment,
}) {
  const [preQualDeclineModalShow, setPreQualDeclineModalShow] = useState(false)
  const [declinedPayload, setDeclinedPayload] = useState(null)
  const [getBlackboxData] = useIovation()
  const history = useHistory()
  const { search } = useLocation()
  const query = parseQuery(search)
  const token = query[QUERY_KEY.TOKEN]

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'conversion',
      },
    })
  }, [])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP' && !isNil(token)) {
        // One Time Apps back button -> no action
        // Return true to allow transition, false to block
        return false
      }
    })

    return () => {
      unblock()
    }
  }, [])

  if (isNil(prequalification)) return <Redirect to={CREDIT_PORTAL_URL} />

  const handleSubmit = (authorizedBuyer) => {
    // TBD: if the user will be prequalified for more than one offer there will
    // need to be a means of the user selecting the offer to be accepted. For now,
    // simply accept the terms of the first offer provided in the
    // prequalification.
    const { applicationId, offers } = prequalification

    const acceptedOffer = first(offers)
    const { offerId: acceptedOfferId, terms: acceptedOfferTerms } =
      acceptedOffer

    const blackboxData = getBlackboxData()
    const approvedTerms = approveTerms(...acceptedOfferTerms)
    const application = {
      applicationId,
      acceptedOfferId,
      iovationBlackBox: blackboxData.blackbox,
      prerequisiteTermsAccepted: approvedTerms,
      ...authorizedBuyer,
    }

    return applyAfterPrequalification(application)
  }
  const handleDecline = (applicationServiceProviderPriority) => {
    if (applicationServiceProviderPriority.productType === 'installment') {
      history.push(PRE_QUALIFICATION_ACCEPT_DECLINED_URL)
      //declined after prequal for both
    } else {
      //declined after prequal for bread
      history.push(DECLINED_URL)
    }
  }

  const handleSubmitSuccess = (data) => {
    const {
      applicationStatus,
      locationServiceProviderPriorities,
      applicationServiceProviderPriority,
      applicationId,
      lenderRequestReferenceId,
    } = data

    setLenderReferenceId(lenderRequestReferenceId)

    setDeclinedPayload({
      ...payload,
      applicationId,
    })

    if (applicationStatus === 'approved') {
      onStepComplete()
    } else if (applicationStatus === 'account_exists') {
      history.push(ACCOUNT_EXISTS_URL)
    } else if (applicationStatus === 'pending') {
      history.push(PRE_QUALIFICATION_INFORMATION_REQUIRED_URL)
    } else if (applicationStatus === 'declined') {
      const nextLender = locationServiceProviderPriorities.consumer.some(
        ({ priority }) => priority > applicationServiceProviderPriority.priority
      )

      if (nextLender) {
        const blackboxData = getBlackboxData()

        return prequalifyWithNextLender(applicationId, {
          iovationBlackBox: blackboxData.blackbox,
        })
          .then((response) => {
            const nextStep = determinePrequalificationResponse(
              response,
              'credit'
            )

            history.push(nextStep)
            return
          })
          .catch((error) => {
            const nextStep = determinePrequalificationError(error)

            if (nextStep) {
              history.push(nextStep)
              return
            }

            flashErrorMessage(
              apiErrorToErrorMessage(
                error?.response?.errorCode,
                error?.response?.message
              )
            )
          })
      } else {
        handleDecline(applicationServiceProviderPriority)
        return
      }
    } else {
      throw new Error('Not Handled')
    }
  }

  const { firstName, lastName } = personalInfo
  const { offers } = prequalification
  const { amount: prequalifiedCreditLimit } = first(offers)
  const applyForCredit = isNil(practice) || !practice.subprimeEnrolled

  return (
    <>
      {applyForCredit ? (
        <div className="preQualifictaion">
          <p className="first-title">
            Congratulations, {firstName} {lastName}
          </p>
          <p className="first-subtitle">
            You're pre-qualified for the Alphaeon Credit Card
          </p>
          <div className="first-card">
            <div className="first-card-header">
              <p className="first-limit">PRE-QUALIFIED CREDIT LIMIT + </p>
              <p className="first-price">
                {formatCurrency(prequalifiedCreditLimit, 2)}
              </p>
              <div className="first-detail">
                + The credit limit provided is based on your pre-qualification
                only, and upon submission of your full credit application, may
                be increased or decreased.
              </div>
            </div>

            <div className="first-card-body">
              <p className="first-card-body-header">Cardmember Benefits:</p>
              <p className="first-card-body-sub-header bold">
                Patient Financing offered by Dentists, Dermatologists, <br />
                Plastic Surgeons, and Ophthalmologists
              </p>
              <div className="icon-container m-b-10 m-t-20">
                <DoneIcon className="first_tick" />
                <p>Special financing options* for any purchase over $250</p>
              </div>
              <div className="icon-container m-b-10">
                <DoneIcon className="first_tick" />
                <p>
                  Ability to reuse your card again and again with Dentists,{' '}
                  <br /> Dermatologists, Ophthalmologists, and Plastic Surgeons
                  nationwide at participating locations.
                </p>
              </div>
              <div className="italic">
                <p>
                  Alphaeon Credit Card Accounts are issued by Comenity Capital
                  Bank.
                  <br />
                  Credit card offers are subject to credit approval.
                  <br />
                  "Minimum payments are required for each Credit Plan. Valid for
                  single transaction only.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PreQualificationInstallment />
      )}

      <PreQualificationForm
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={flashSubmitFailure}
        practice={practice}
        applicationEnvironment={applicationEnvironment}
      />

      {preQualDeclineModalShow && (
        <PrequalAcceptDeclineModal
          payload={declinedPayload}
          onClose={() => setPreQualDeclineModalShow(false)}
        />
      )}
      <div className="stepper-end">
        <p>
          The credit limit provided is based on your pre-qualification only, and
          upon submission of your full credit application, may be increased or
          decreased.
        </p>
      </div>
    </>
  )
}

PreQualification.propTypes = propTypes

function mapStateToProps(state) {
  return {
    personalInfo: selectors.personalInfo(state),
    practice: selectors.practice(state),
    prequalification: selectors.prequalification(state),
  }
}

const mapDispatchToProps = {
  applyAfterPrequalification: apiActions.applyAfterPrequalification,
  flashErrorMessage: flashErrorMessage,
  prequalifyWithNextLender: apiActions.prequalifyWithNextLender,
  setLenderReferenceId: actions.setLenderReferenceId,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PreQualification
)
