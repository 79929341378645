import { isNil, isArray } from 'lodash'

export default function operationPermitted(
  currentPermissions,
  requiredPermission,
  selectionCriteria = 'ALL'
) {
  if (isNil(currentPermissions)) return false

  const checkPermissions = (permission) => {
    const isPermitted = currentPermissions[permission]
    return isNil(isPermitted) ? false : isPermitted
  }

  if (isArray(requiredPermission)) {
    if (selectionCriteria === 'OR') {
      return requiredPermission.some((permission) =>
        checkPermissions(permission)
      )
    }
    return requiredPermission.every((permission) =>
      checkPermissions(permission)
    )
  }

  return checkPermissions(requiredPermission)
}
