import React from 'react'
const propTypes = {}
const defaultProps = {}

function DownPage() {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Service Interruption</h1>
        <p>
          We are currently experiencing a service outage. We apologize for the
          inconvenience. Please check again in a few minutes to see if we are
          back.
        </p>
      </div>
    </div>
  )
}

DownPage.propTypes = propTypes
DownPage.defaultProps = defaultProps

export default DownPage
