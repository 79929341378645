import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function CAPrivacyNotice() {
  return (
    <div className="page-container">
      <h3 className="heavy page-title">
        Important Privacy Choices for Consumers
      </h3>
      <h4 className="heavy">
        You have the right to control whether we share some of your personal
        information.Please read the following information carefully before you
        make your choices below.
      </h4>

      <h4 className="section-title title-underline">Your Rights</h4>
      <p>
        You have the following rights to restrict the sharing of personal and
        financial information with our affiliates (companies we own or control)
        and outside companies that we do business with. Nothing in this form
        prohibits the sharing of information necessary for us to follow the law,
        as permitted by law, or to give you the best service on your accounts
        with us. This includes sending you information about some other products
        or services.
      </p>

      <h4 className="section-title title-underline">Your Choices</h4>
      <p>
        <strong>
          Restrict Information Sharing With Other Companies We Do Business With
          To Provide Financial Products And Services:
        </strong>
        Unless you say “No,” we may share personal and financial information
        about you with outside companies we contract with to provide financial
        products and services to you.
      </p>
      <p>
        (_) NO, please do not share personal and financial information with
        outside companies you contract with to provide financial products and
        services.
      </p>

      <h4 className="section-title title-underline">Time Sensitive Reply</h4>
      <p>
        You may make your privacy choice at any time. Your choice marked here
        will remain unless you state otherwise. However, if we do not hear from
        you we may share some of your information with affiliated companies and
        other companies with whom we have contracts to provide products and
        services. <br />
        Name: __________________ <br />
        Account Number(s): __________________ <br />
        Signature: ______________________ <br />
      </p>
      <div className="footer-info">
        <p>To exercise your choice, do one of the following:</p>
        <ol>
          <li>Call this toll-free number (800) 754 4013; or</li>
          <li>
            Reply electronically by contacting us through the following Internet
            option: privacy@alphaeoncredit.com.
          </li>
        </ol>
      </div>
    </div>
  )
}

CAPrivacyNotice.propTypes = exact(propTypes)
CAPrivacyNotice.defaultProps = defaultProps

export default memo(CAPrivacyNotice)
