import React from 'react'
import exact from 'prop-types-exact'
import { LocationType } from '../../types'

const propTypes = {
  currentLocation: LocationType.isRequired,
}

const defaultProps = {}

function PricingTierCard({ currentLocation }) {
  return (
    <>
      {currentLocation?.pricingTier && (
        <div className="summary__card card">
          <div className="card-section title">CURRENT PRICING TIER</div>
          <div className="card-section sub-title">
            {currentLocation?.pricingTier?.notes}
          </div>
        </div>
      )}
    </>
  )
}

PricingTierCard.propTypes = exact(propTypes)
PricingTierCard.defaultProps = defaultProps

export default React.memo(PricingTierCard)
