import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Checkbox, Input, SubmitButton, MaskedInput } from 'lp-components'
import {
  change,
  Field,
  propTypes as formPropTypes,
  formValues,
} from 'redux-form'
import { FormSpy, SSNInput, InputTip } from 'components'
import PropTypes from 'prop-types'
import { removeNonDigits, formatISODateString } from 'utils'

const propTypes = {
  ...formPropTypes,
  updateField: PropTypes.func.isRequired,
  ssn4ZipDateOfBirthSearch: PropTypes.bool,
  ssnZipSearch: PropTypes.bool,
  accountNumberSearch: PropTypes.bool,
}

const defaultProps = {}

function AccountLookupForm({
  submitting,
  handleSubmit,
  updateField,
  ssn4ZipDateOfBirthSearch,
  ssnZipSearch,
  accountNumberSearch,
  reset,
}) {
  useEffect(() => {
    if (ssn4ZipDateOfBirthSearch) {
      reset('accountLookupForm')
    }
  }, [ssn4ZipDateOfBirthSearch])

  useEffect(() => {
    if (ssnZipSearch) {
      reset('accountLookupForm')
      updateField('ssnZipSearch', true)
      updateField('ssn4ZipDateOfBirthSearch', false)
    }
  }, [ssnZipSearch])

  useEffect(() => {
    if (accountNumberSearch) {
      reset('accountLookupForm')
      updateField('accountNumberSearch', true)
      updateField('ssn4ZipDateOfBirthSearch', false)
    }
  }, [accountNumberSearch])

  return (
    <div className="account-lookup-form">
      <form noValidate onSubmit={handleSubmit}>
        <Field
          name="ssn4ZipDateOfBirthSearch"
          component={Checkbox}
          label={'Zip Code + Last 4 of Social Security Number + Date of Birth'}
        />
        <FormSpy name="ssn4ZipDateOfBirthSearch">
          {(ssn4ZipDateOfBirthSearch) => (
            <>
              {ssn4ZipDateOfBirthSearch && (
                <div className="accountSearchForm">
                  <div className="accountSearchForm__fields">
                    <Field
                      name="zip"
                      component={Input}
                      label={false}
                      parse={removeNonDigits}
                      autoComplete="off"
                      placeholder="Zip Code"
                      className="accountSearchForm__input"
                      maxLength={5}
                    />
                    <Field
                      name="ssn_last_4"
                      component={Input}
                      inputType={MaskedInput}
                      parse={removeNonDigits}
                      label={false}
                      placeholder="Last 4 of SSN"
                      className="accountSearchForm__input"
                      maxLength={4}
                    />
                    <Field
                      name="dateOfBirth"
                      component={InputTip}
                      inputType={MaskedInput}
                      label={false}
                      placeholder="Birth Day MM/DD/YYYY"
                      className="accountSearchForm__input"
                      maskOptions={{
                        date: true,
                        datePattern: ['m', 'd', 'Y'],
                      }}
                    />
                  </div>

                  <SubmitButton
                    className={`button-warn-outline`}
                    disabled={submitting}
                    submitting={submitting}
                  >
                    Search For Account
                  </SubmitButton>
                </div>
              )}
            </>
          )}
        </FormSpy>
        <Field
          name="ssnZipSearch"
          component={Checkbox}
          label={'Social Security Number + Zip Code'}
        />
        <FormSpy name="ssnZipSearch">
          {(ssnZipSearch) => (
            <>
              {ssnZipSearch && (
                <div className="accountSearchForm ssn-zip">
                  <div className="accountSearchForm__fields">
                    <Field
                      component={SSNInput}
                      inputType={MaskedInput}
                      label={false}
                      name="ssn"
                      maskOptions={{
                        delimiter: '-',
                        blocks: [3, 2, 4],
                      }}
                      parse={removeNonDigits}
                      className="accountSearchForm__input"
                      placeholder="Social Security Number"
                    />
                    <Field
                      name="zip"
                      component={Input}
                      label={false}
                      placeholder="Zip Code"
                      className="accountSearchForm__input"
                      maxLength={5}
                    />
                  </div>
                  <SubmitButton
                    className={`button-warn-outline`}
                    disabled={submitting}
                    submitting={submitting}
                  >
                    Search For Account
                  </SubmitButton>
                </div>
              )}
            </>
          )}
        </FormSpy>
        <Field
          name="accountNumberSearch"
          component={Checkbox}
          label={'Account/ Loan Number'}
        />
        <FormSpy name="accountNumberSearch">
          {(accountNumberSearch) => (
            <>
              {accountNumberSearch && (
                <div className="accountSearchForm">
                  <div className="accountSearchForm__fields">
                    <Field
                      name="alphaeonAccountNumber"
                      component={Input}
                      label={false}
                      placeholder="Account/ Loan Number"
                      className="accountSearchForm__number"
                    />
                  </div>
                  <SubmitButton
                    disabled={submitting}
                    submitting={submitting}
                    className={`button-warn-outline`}
                  >
                    Search For Account
                  </SubmitButton>
                </div>
              )}
            </>
          )}
        </FormSpy>
      </form>
    </div>
  )
}

AccountLookupForm.propTypes = propTypes

AccountLookupForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateField: (field, data) => change('accountLookupForm', field, data),
}

function beforeSubmit(formValues) {
  const isoDateOfBirth = formatISODateString(formValues.dateOfBirth)

  return {
    ...formValues,
    dateOfBirth: isoDateOfBirth,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'accountLookupForm',
    constraints: {
      zip: {
        numericality: { onlyInteger: true },
        presence: true,
        length: { is: 5 },
      },
      ssn_last_4: {
        numericality: { onlyInteger: true },
        length: { is: 4 },
        presence: true,
      },
      ssn: {
        presence: true,
      },
      dateOfBirth: {
        presence: true,
        length: { is: 10 },
      },
      alphaeonAccountNumber: {
        presence: true,
      },
    },
    submitFilters: {
      reject: [
        'ssn4ZipDateOfBirthSearch',
        'ssnZipSearch',
        'accountNumberSearch',
      ],
    },

    initialValues: {
      ssn4ZipDateOfBirthSearch: true,
    },
  }),
  formValues('ssn4ZipDateOfBirthSearch', 'ssnZipSearch', 'accountNumberSearch'),
  connect(mapStateToProps, mapDispatchToProps)
)(AccountLookupForm)
