import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import * as actions from './global-actions'

const reducerKey = 'global'
const slice = 'root.global'

const initialState = {}
const reducer = handleActions(
  {
    [actions.recordApplicationBuildId]: setState('applicationBuildId'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  applicationBuildId: select('applicationBuildId'),
}

export { reducer, selectors, reducerKey }
