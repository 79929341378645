import { isEmpty } from 'lodash'
import { parse } from 'date-fns'
import { DATE_STRING_LENGTH } from 'config'

const parseUSDayMonthYearDate = (dateString) => {
  if (isEmpty(dateString)) return
  if (dateString.length !== DATE_STRING_LENGTH) return
  if (dateString.includes('-')) {
    return parse(dateString, 'yyyy-MM-dd', new Date())
  }

  return parse(dateString, 'MM/dd/yyyy', new Date())
}

export default parseUSDayMonthYearDate
