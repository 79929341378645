import { createAction } from 'redux-actions'

export const clearApplicationEnvironment = createAction(
  'CLEAR_APPLICATION_ENVIRONMENT'
)
export const setPractice = createAction('CREDIT_APPLICATION/SET_PRACTICE')

export const setPracticesList = createAction(
  'CREDIT_APPLICATION/SET_PRACTICE_LIST'
)

export const setPersonalInfo = createAction(
  'CREDIT_APPLICATION/SET_PERSONAL_INFO'
)
export const setPracticeLocations = createAction('PRACTICE/SEARCH')
export const clearPracticeLocations = createAction('CLEAR_PRACTICE_LOCATIONS')

export const setLenderReferenceId = createAction(
  'CREDIT_APPLICATION/SET_LENDER_REFERENCE_ID'
)

export const setPrequalification = createAction(
  'CREDIT_APPLICATION/SET_PREQUALIFICATION'
)

export const clearPractice = createAction('CREDIT_APPLICATION/CLEAR_PRACTICE')

export const setCreditApplication = createAction(
  'CREDIT_APPLICATION/SET_CREDIT_APPLICATION'
)
