import React /*, { useEffect } */ from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Button } from 'components'
import { PATH, makeApplicationURL } from 'config'

const propTypes = {}
const defaultProps = {}

function NoManagerAccess() {
  const { logout, user } = useAuth0()
  const { email } = user

  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">Account Has No Configured Locations</h1>
        <p className="first-subtitle">
          The account, {email}, does not have access to any location.
        </p>
        <p>Please contact your local administrator to obtain access.</p>
        <Button
          className="button-warn-outline"
          onClick={() =>
            logout({ returnTo: makeApplicationURL(PATH.MERCHANT_PORTAL) })
          }
        >
          Sign Out
        </Button>
      </div>
    </div>
  )
}

NoManagerAccess.propTypes = propTypes
NoManagerAccess.defaultProps = defaultProps

export default React.memo(NoManagerAccess)
