import { makeQueryPath } from 'utils'
import {
  createGetRequest,
  createPostRequest,
  createPatchRequest,
} from 'lp-redux-api'
import { isNil } from 'lodash'

export const getCreditApplicationsByStatus = createGetRequest(
  'GET_CREDIT_APPLICATIONS_BY_STATUS',
  (applicationSearchCriteria, status) => ({
    url: `/consumer_applications/v1/credit_applications/status/${status}`,
    query: applicationSearchCriteria,
  })
)

export const getCreditApplicationByToken = createGetRequest(
  'GET_CREDIT_APPLICATION_BY_TOKEN',
  (applicationSearchCriteria, token) => ({
    url: `/consumer_applications/v1/credit_applications/token/${token}`,
    query: applicationSearchCriteria,
  })
)

export const getCreditApplicationByTokenValidate = createGetRequest(
  'GET_CREDIT_APPLICATION_BY_TOKEN_VALIDATE',
  (token) => ({
    url: `/consumer_applications/v1/credit_applications/token/${token}/validate`,
  })
)

export const applyAfterPrequalification = createPostRequest(
  'APPLY_AFTER_PREQUALIFICATION',
  (application) => ({
    url: '/consumer_applications/v1/credit_applications/apply_after_prequalification',
    body: application,
  })
)

export const newCreditApplication = createGetRequest(
  'NEW_CREDIT_APPLICATION',
  (location_id, language = 'en') => ({
    url: `/consumer_applications/v1/prequalifications/new`,
    query: {
      location_id,
      language,
    },
  })
)

export const requestPrequalification = createPostRequest(
  'REQUEST_PREQUALIFICATION',
  (prequalificationData) => ({
    url: '/consumer_applications/v1/prequalifications',
    body: prequalificationData,
  })
)

export const searchForPractices = createGetRequest(
  'PRACTICE_DATA',
  (ads_store_number, language = 'en') => ({
    url: `/consumer_applications/v1/locations/search`,
    query: {
      ads_store_number: ads_store_number,
      language,
    },
  })
)

export const patchApplicant = createPatchRequest(
  'PATCH_APPLICANT',
  (applicattionId, updatedApplicant) => ({
    url: `/consumer_applications/v1/credit_applications/${applicattionId}`,
    body: updatedApplicant,
  })
)

export const prequalifyWithNextLender = createPostRequest(
  'PREQUALIFY_WITH_NEXT_LENDER',
  (applicationId, prequalificationData) => ({
    url: `/consumer_applications/v1/prequalifications/${applicationId}/next_lender`,
    body: prequalificationData,
  })
)

export const prequalifyCreditApplication = createPostRequest(
  'PREQUALIFY_CREDIT_APPLICATION',
  (applicationId, prequalification) => ({
    url: `/consumer_applications/v1/credit_applications/${applicationId}/prequalify`,
    body: prequalification,
  })
)

export const createCreditApplicationIdentityVerification = createPostRequest(
  'CREATE_CREDIT_APPLICATION_IDENTITY_VERIFICATION',
  (creditApplicationId, creditApplicationData) => ({
    url: `/consumer_applications/v1/credit_applications/${creditApplicationId}/identity_verification`,
    body: creditApplicationData,
  })
)

export const getCreditApplicationIdentityVerification = createGetRequest(
  'GET_CREDIT_APPLICATION_IDENTITY_VERIFICATION',
  (creditApplicationId) => ({
    url: `/consumer_applications/v1/credit_applications/${creditApplicationId}/identity_verification`,
  })
)

//Merchant APIs
export const newMerchantCreditApplication = createGetRequest(
  'MERCHANT_NEW_CREDIT_APPLICATION',
  (location_id, language, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications/new',
    query: {
      location_id,
      language,
    },
  })
)

export const applyForCredit = createPostRequest(
  'APPLY_FOR_CREDIT',
  (creditApplicationData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications/apply',
    body: creditApplicationData,
  })
)

export const searchForAccounts = createPostRequest(
  'ACCOUNT_LIST',
  (createPostRequest, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/lookups/all_ads_accounts',
    body: createPostRequest,
  })
)

export const getAccountDetails = createGetRequest(
  'ACCOUNT_DETAIL',
  (alphaeonAccountNumber, locationId, buyerId, bearerToken) => ({
    bearerToken,
    url:
      isNil(buyerId) || buyerId === 0 || buyerId === ''
        ? `/merchant_management/v1/lookups/accounts/${alphaeonAccountNumber}?location_id=${locationId}`
        : `/merchant_management/v1/lookups/accounts/${alphaeonAccountNumber}?location_id=${locationId}?buyer_id=${buyerId}`,
  })
)

export const fetchAllLocationsForManager = createGetRequest(
  'FETCH_ALL_LOCATIONS_FOR_MANAGER',
  (bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/locations',
  })
)

export const fetchLocationDetails = createGetRequest(
  'FETCH_LOCATION_DETAILS',
  (locationId, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/locations/${locationId}`,
  })
)

export const salesRequest = createPostRequest(
  'SALES_REQUEST',
  (salesRequestData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/transactions/sale',
    body: salesRequestData,
  })
)

export const fetchPlansByLocationId = createGetRequest(
  'FETCH_PLANS_BY_LOCATION_ID',
  (locationId, amount, activeOnly = true, bearerToken) => ({
    bearerToken,
    url: makeQueryPath(
      `/merchant_management/v1/locations/${locationId}/plans`,
      { amount, activeOnly }
    ),
  })
)

export const voidSale = createPatchRequest(
  'VOID_TRANSACTION',
  (voidTransactionData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/transactions/void`,
    body: voidTransactionData,
  })
)

export const refundRequest = createPostRequest(
  'REFUND_REQUEST',
  (refundRequestData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/transactions/refund',
    body: refundRequestData,
  })
)

export const getApplicationsReport = createPostRequest(
  'GET_APPLICATIONS_REPORT',
  (reportData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/applications`,
    body: reportData,
  })
)

export const getSalesReport = createPostRequest(
  'GET_SALES_REPORT',
  (reportData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/sales`,
    body: reportData,
  })
)

export const getReconciliationReport = createPostRequest(
  'GET_RECONCILIATION_REPORT',
  (reconciliationReportRequest, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/reports/bank_reconciliation`,
    body: reconciliationReportRequest,
  })
)

export const getManagers = createGetRequest('GET_MANAGERS', (bearerToken) => ({
  bearerToken,
  url: '/manager_administration/v1/managers/all',
}))

export const newManager = createPostRequest(
  'CREATE_NEW_MANAGER',
  (managerData, bearerToken) => ({
    bearerToken,
    url: '/manager_administration/v1/managers',
    body: managerData,
  })
)

export const updateManager = createPatchRequest(
  'UPDATE_MANAGER',
  (managerData, bearerToken) => ({
    bearerToken,
    url: '/manager_administration/v1/managers',
    body: managerData,
  })
)

export const getManagerById = createGetRequest(
  'GET_MANAGER_BY_ID',
  (id, bearerToken) => ({
    bearerToken,
    url: `/manager_administration/v1/managers/${id}`,
  })
)

export const adjustLoan = createPatchRequest(
  'ADJUST_LOAN',
  (alphaeonAccountNumber, loanData, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/${alphaeonAccountNumber}`,
    body: loanData,
    query: {
      location_id,
    },
  })
)

export const cancelLoan = createPostRequest(
  'CANCEL_LOAN',
  (alphaeonAccountNumber, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/cancel/${alphaeonAccountNumber}`,
    query: {
      location_id,
    },
  })
)

export const refundLoan = createPostRequest(
  'REFUND_LOAN',
  (alphaeonAccountNumber, amount, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/access_loans/refund/${alphaeonAccountNumber}`,
    body: { amount: amount },
    query: {
      location_id,
    },
  })
)

export const getPracticeLocations = createGetRequest(
  'GET_LOCATIONS',
  (fields_to_search, search_criteria, limit = 5) => ({
    url: '/consumer_applications/v1/locations/search_full',
    query: {
      search_criteria: search_criteria,
      fields_to_search: fields_to_search,
      limit: limit,
    },
  })
)

export const getLocationNotifications = createGetRequest(
  'GET_LOCATION_NOTIFICATIONS',
  (locationId, bearerToken) => ({
    bearerToken,
    url: makeQueryPath(
      `/merchant_management/v1/locations/${locationId}/notifications`
    ),
  })
)

export const updateLocationPlans = createPatchRequest(
  'PATCH_LOCATION_PLANS',
  (locationId, locationPlanChanges, bearerToken) => ({
    bearerToken,
    url: makeQueryPath(`/merchant_management/v1/locations/${locationId}/plans`),
    body: locationPlanChanges,
  })
)

export const signReceipt = createPatchRequest(
  'PATCH_SIGN_RECEIPT',
  (transactionData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/transactions/receipt/sign`,
    body: transactionData,
  })
)

export const sendExternalReferencesCommunication = createPostRequest(
  'POST_EXTERNAL_REFERENCES',
  (creditApplicationId, requestData, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/credit_applications/${creditApplicationId}/external_references`,
    body: requestData,
  })
)

export const createNewCreditApplication = createPostRequest(
  'CREATE_NEW_CREDIT_APPLICATION',
  (creditApplication, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications',
    body: creditApplication,
  })
)

export function getSalesReceipt(transactionId, bearerToken) {
  return async function () {
    const receiptPdf = await fetch(
      `${process.env.REACT_APP_API_URL}/merchant_management/v1/transactions/receipt`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify({ transaction_id: transactionId }),
      }
    )
    const receiptBlob = await receiptPdf.blob()

    return window.URL.createObjectURL(receiptBlob)
  }
}

export const createPushApplication = createPostRequest(
  'CREATE_PUSH_APPLICATION',
  (pushApplicationData, bearerToken) => ({
    bearerToken,
    url: '/merchant_management/v1/credit_applications',
    body: pushApplicationData,
  })
)

export const requestAccountVerification = createPostRequest(
  'REQUEST_ACCOUNT_VERIFICATION',
  (alphaeonAccountIdentifier, location_id, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/accounts/${alphaeonAccountIdentifier}/identity_verification`,
    body: { send_verification: true, location_id: location_id },
  })
)

export const getAccountVerification = createGetRequest(
  'GET_ACCOUNT_VERIFICATION',
  (alphaeonAccountIdentifier, bearerToken) => ({
    bearerToken,
    url: `/merchant_management/v1/accounts/${alphaeonAccountIdentifier}/identity_verification`,
  })
)
