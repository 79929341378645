import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function PrequalificationTerms() {
  return (
    <div className="page-container">
      <h4 className="page-title heavy title-underline">
        Alphaeon's Prequalification Terms
      </h4>
      <p className="paragraph-basic">
        Alphaeon's Prequalification Terms (the “Terms”) outline the relationship
        between you and Alphaeon Credit. As used in these Terms, “we,” “our,”
        “us” and “Company” refers to Alphaeon Credit, and "you" and "your" mean
        the consumer electronically agreeing to these Terms. For the purpose of
        the Arbitration Agreement, “we,” “our,” “us” and “Company” also refers
        to Alphaeon's parents, subsidiaries, affiliates, employees, agents,
        officers, directors, shareholders, successors, and assigns.
      </p>
      <p className="paragraph-basic">
        The Terms only apply to the relationship between you and Company, and do
        not apply to any other relationships you may have with third parties as
        a result of your relationship with Company.{' '}
        <strong>
          THESE TERMS INCLUDE AN ARBITRATION AGREEMENT WITH A CLASS ACTION
          WAIVER AND A JURY TRIAL WAIVER. THIS ARBITRATION AGREEMENT DOES NOT
          APPLY TO YOU IF YOU ARE A COVERED BORROWER UNDER THE MILITARY LENDING
          ACT.
        </strong>
      </p>

      <h4 className="section-title">
        <strong>GENERAL TERMS AND CONDITIONS.</strong>
      </h4>

      <ol className="paragraph-list terms-list">
        <li className="decimal">
          <strong>Company's Service.</strong> Company is providing you with a
          platform (the “Platform”) through which you may submit
          prequalification inquiries for review by Comenity Capital Bank and/or
          Covered Care. You direct us to send your information to Comenity
          Capital Bank and/or Covered Care, as we may choose, to determine if
          Comenity Capital Bank or Covered Care want to prequalify you for
          credit, and you direct each of them to notify us regarding any offer
          for which you may prequalify. The address of Comenity Capital Bank is
          Comenity Capital Bank PO Box 183003 Columbus, OH 43218-3003, and the
          address of Covered Care is 5050 Quorum Dr Suite 250 Dallas, TX 75254.
          You authorize Alphaeon, Comenity Capital Bank and Covered Care to
          share application, consumer report and other information about you
          among themselves in order to determine if you prequalify.
        </li>
        <li className="decimal">
          <strong>Company is Not a Creditor.</strong> Company is not a creditor.
          The prequalification inquiries are being submitted through Company's
          Platform to third-party creditors for consideration. Company has no
          input into the third-party creditor's prequalification criteria, nor
          does it have any decision-making authority for the prequalification
          decision. Company is not liable for any damages that may result from
          interactions or dealings with any third-party creditors.
        </li>
        <li className="decimal">
          <strong>
            Prequalification Inquiries are Not Credit Applications.
          </strong>{' '}
          The prequalification inquiries are not credit applications. Instead,
          they are an opportunity for participating creditors to provide
          information to you so you can decide whether to apply for credit from
          such creditors.
        </li>
        <li className="decimal">
          <strong>Use of the Platform.</strong> The Company makes no
          representations or guarantees that use of the Platform will result in
          an offer of credit. The Platform is a tool through which you may
          submit prequalification inquiries. It is in the sole discretion of any
          third-party creditor to extend credit.
        </li>
        <li className="decimal">
          <strong>Your Representations.</strong> By saving your information with
          and/or submitting a prequalification inquiry through Company, you
          represent that the information you have provided is accurate,
          complete, and up-to-date. You agree that only you will use any account
          set up in your name, and that you will not share any log-in
          credentials with third parties or otherwise facilitate unauthorized
          access to your account. You agree that you are responsible for all
          activities that occur in connection with your account. If you believe
          that your account has been compromised or is no longer secure, you
          agree to immediately notify Company.
        </li>
      </ol>

      <h4 className="section-title">
        <strong>ARBITRATION AGREEMENT.</strong>
      </h4>

      <p className="paragraph-basic">
        In consideration for the mutual promises to arbitrate “Disputes,” as
        defined below, for your access to and use of the Platform provided by
        Company, and for other valuable consideration, you agree to the specific
        provisions below (unless you are a covered borrower under the Military
        Lending Act).
      </p>

      <h4 className="section-title centered">Background and Scope</h4>
      <table className="prequal-table">
        <tbody>
          <tr>
            <td className="row-title">
              <strong>What is arbitration?</strong>
            </td>
            <td>
              <strong>An alternative to court</strong>
            </td>
            <td>
              In arbitration, a third party (“Arbiter”) solves Disputes in a
              hearing (“hearing”). You, related third parties, and we, waive the
              right to go to court. Such “parties” forgo jury trials.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Is it different from court and jury trials?</strong>
            </td>
            <td>
              <strong>Yes.</strong>
            </td>
            <td>
              The hearing is private and less formal than court. Arbiters may
              limit pre-hearing fact finding, called “discovery.” The decision
              is final. Courts rarely overturn Arbiters.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Who does the Clause cover?</strong>
            </td>
            <td>
              <strong>You, Us, andOthers.</strong>
            </td>
            <td>
              This Clause governs the parties, their heirs, successors, assigns,
              and third parties related to any Dispute.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Which Disputes are covered?</strong>
            </td>
            <td>
              <strong>All Disputes.</strong>
            </td>
            <td>
              In this Clause, the word “Disputes” has the broadest possible
              meaning. This Clause governs all “Disputes” involving the parties.
              This includes all claims even indirectly related to your
              application, relationship or agreement with us. It includes claims
              related to setting aside this Clause. It includes claims about the
              Clause's validity and scope. It even includes threshold claims
              about whether a particular Dispute is subject to this Clause and
              whether to arbitrate.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Are you waiving rights?</strong>
            </td>
            <td>
              <strong>Yes</strong>
            </td>
            <td>
              You waive your rights to:
              <ol>
                <li>Have juries solve Disputes.</li>
                <li>
                  Have courts, other than small-claims courts, solve Disputes.
                </li>
                <li>
                  Serve as a private attorney general or in a representative
                  capacity.
                </li>
                <li>Be in a class action.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Are you waiving class action rights?</strong>
            </td>
            <td>
              <strong>Yes</strong>
            </td>
            <td>
              <strong>COURTS AND ARBITERS WON'T ALLOW CLASS ACTIONS.</strong>{' '}
              You waive your rights to be in a class action, as a representative
              and a member. Only individual arbitration, or small-claims courts,
              will solve Disputes. You waive your right to have representative
              claims. Unless reversed on appeal, if a court invalidates this
              waiver, the Clause will be void.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>What law applies?</strong>
            </td>
            <td>
              <strong>The Federal Arbitration Act (“FAA”).</strong>
            </td>
            <td>
              This transaction involves interstate commerce. Thus, the FAA
              governs. If a court finds the FAA doesn't apply, and the finding
              can't be appealed, then the state law where you were when you
              signed, governs. The Arbiter must apply substantive law consistent
              with the FAA. The Arbiter must follow statutes of limitation and
              privilege claims.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Can the parties try to solve Disputes first?</strong>
            </td>
            <td>
              <strong>Yes</strong>
            </td>
            <td>
              We can try to solve Disputes if you call us at 1 800 754 4013
              (Alphaeon Legal Phone number). If this doesn't solve the Dispute,
              mail us written notice, within 100 days of the Dispute date. In
              your notice, tell us the details and how you want to solve it. We
              will try to solve the Dispute. If we make a written offer
              (“Settlement Offer”), you can reject it and arbitrate. If we don't
              solve the Dispute, either party may start arbitration. To start
              arbitration, contact an Arbiter or arbitration group listed. No
              party will disclose settlement proposals to the Arbiter during
              arbitration.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>How should you contact us?</strong>
            </td>
            <td>
              <strong>By mail.</strong>
            </td>
            <td>
              Send mail to: <br />
              <br />
              4040 MacArthur Blvd #260 <br />
              Newport Beach, CA 92660. <br />
              <br />
              You can call us or use certified mail to confirm receipt.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Can small-claimscourt solve someDisputes?</strong>
            </td>
            <td>
              <strong>Yes</strong>
            </td>
            <td>
              Each party has the right to arbitrate, or to go to small-claims
              court if the small-claims court has the power to hear the Dispute.
              Arbitration will solve all disputes that the small-claims court
              does not have the power to hear. If there is an appeal from
              small-claims court, or if a Dispute changes so that the
              small-claims court loses the power to hear it, then the Dispute
              will only be heard by an Arbiter.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Do other options exist?</strong>
            </td>
            <td>
              <strong>Yes</strong>
            </td>
            <td>
              Both parties may go to court to use lawful self-help remedies.
              Both parties may seek remedies in court which don't claim money
              damages. This includes pre-judgment seizure, injunctions, or
              equitable relief.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Will this Clausecontinue to govern?</strong>
            </td>
            <td>
              <strong>Yes, unless otherwise agreed.</strong>
            </td>
            <td>
              The Clause stays effective, unless the parties sign an agreement
              stating it doesn't. The Clause governs if you rescind the
              transaction. It governs if you default, renew, prepay, or pay. It
              governs if you terminate the transaction. It governs if your
              transaction is impacted by bankruptcy. The Clause remains
              effective, despite a transaction's termination, amendment,
              expiration, or performance.
            </td>
          </tr>
        </tbody>
      </table>

      <h4 className="section-title centered">Process</h4>
      <table className="prequal-table">
        <tbody>
          <tr>
            <td className="row-title">
              <strong>How does arbitration start?</strong>
            </td>
            <td>
              <strong>Mailing a notice.</strong>
            </td>
            <td>
              Either party may mail the other a request to arbitrate, even if a
              lawsuit has been filed. The notice should describe the Dispute and
              relief sought. The receiving party must mail a response within 20
              days. If you mail the demand, you may choose the arbitration
              group. Or, your demand may state that you want the parties to
              choose a local Arbiter. If related third parties or we mail the
              demand, you must respond in 20 days. Your response must choose an
              arbitration group or propose a local Arbiter. If it doesn't, we
              may choose the group.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Who arbitrates?</strong>
            </td>
            <td>
              <strong>AAA, JAMS, or an agreed Arbiter.</strong>
            </td>
            <td>
              You may select the American Arbitration Association (“AAA”)
              (1-800-778-7879) http://www.adr.org or JAMS (1-800-352-5267)
              http://www.jamsadr.com. The parties may also agree in writing to a
              local attorney, retired judge, or Arbiter in good standing with an
              arbitration group. The Arbiter must arbitrate under AAA or JAMS
              consumer rules. You may get a copy of these rules from such group.
              Any rules that conflict with any of our agreements with you, don't
              apply. If these options aren't available, and the parties can't
              agree on another, a court may choose the group. The parties will
              then obtain an Arbiter under such group's rules. Such Arbiter must
              enforce your agreements with us, as they are written.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Will the hearing be held nearby?</strong>
            </td>
            <td>
              <strong>Yes.</strong>
            </td>
            <td>
              The Arbiter will order the hearing within 30 miles of your home or
              where the transaction occurred.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>What about appeals?</strong>
            </td>
            <td>
              <strong>Appeals are limited.</strong>
            </td>
            <td>
              The Arbiter's decision will be final. A party may file the
              Arbiter's award with the proper court. Arbitration will solve
              appeals of a small-claims court judgment. A party may appeal under
              the FAA. If the amount in controversy exceeds $1,500, a party may
              appeal the Arbiter's finding. Such appeal will be to a 3-Arbiter
              panel from the same arbitration group. The appeal will be de novo,
              and solved by majority vote.
            </td>
          </tr>
        </tbody>
      </table>

      <h4 className="section-title centered">Arbitration Fees and Awards</h4>
      <table className="prequal-table">
        <tbody>
          <tr>
            <td className="row-title">
              <strong>Will we advance Arbitration Fees?</strong>
            </td>
            <td>
              <strong>Yes, but you may pay costs.</strong>
            </td>
            <td>
              We advance your “Arbitration Fees” if you ask us to. This includes
              filing, administrative, hearing, and Arbiter's fees. You pay your
              attorney fees and other expenses.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Are damages and attorney fees possible?</strong>
            </td>
            <td>
              <strong>Yes, if allowed.</strong>
            </td>
            <td>
              The Arbiter may award the same damages as a court. Arbiters may
              award reasonable attorney fees, and expenses, if allowed by law.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Will you pay Arbitration Fees if you win?</strong>
            </td>
            <td>
              <strong>No.</strong>
            </td>
            <td>
              If the Arbiter awards you funds, you don't reimburse us the
              Arbitration Fees.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Will you ever pay Arbitration Fees?</strong>
            </td>
            <td>
              <strong>Yes/No.</strong>
            </td>
            <td>
              If the Arbiter doesn't award you funds, then you may repay the
              Arbitration Fees. The Arbiter will decide whether you'll pay. If
              you must pay Arbitration Fees, the amount won't exceed state court
              costs.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>What happens if you win?</strong>
            </td>
            <td>
              <strong>You could get more than the Arbiter awarded.</strong>
            </td>
            <td>
              If an Arbiter's award to you exceeds our last Settlement Offer, we
              will pay 3 amounts. We will pay the greater of the award amount or
              $500.00 (“bonus payment”). We will pay your attorney twice the
              attorney fees conferred (“attorney premium”). If the Arbiter
              orders, we will pay reasonable expert witness costs and other
              costs you incurred (“cost premium”). If we never made a Settlement
              Offer, we will pay the bonus payment, attorney premium, and any
              cost premium. The Arbiter may order the process for payment. If a
              law allows you more, this Clause won't prevent such award. We
              won't seek attorney fees and expenses.
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Can an award be explained?</strong>
            </td>
            <td>
              <strong>Yes.</strong>
            </td>
            <td>
              A party may request details from the Arbiter, within 14 days of
              the ruling. Upon such request, the Arbiter will explain the ruling
              in writing.
            </td>
          </tr>
        </tbody>
      </table>

      <h4 className="section-title centered">Other Options</h4>

      <table className="prequal-table">
        <tbody>
          <tr>
            <td className="row-title">
              <strong>
                If you don't wantto arbitrate, can you still get a transaction?
              </strong>
            </td>
            <td>
              <strong>
                Yes. You can get our services and decide not to arbitrate.
              </strong>
            </td>
            <td>
              Consider these choices:
              <ol>
                <li>
                  <strong>Informal Dispute Resolution.</strong> Contact us, and
                  attempt to settle any Disputes.
                </li>
                <li>
                  <strong>Small-claims Court.</strong> Seek to solve Disputes in
                  small-claims court, within state law limits.
                </li>
                <li>
                  <strong>Get an Agreement Without the Clause.</strong> Write to
                  us for an Agreement without the Clause.
                </li>
                <li>
                  <strong>Opt-Out of Arbitration.</strong> Sign and then timely
                  opt-out.
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td className="row-title">
              <strong>Can you opt-out of the Clause?</strong>
            </td>
            <td>
              <strong>Yes. Within 60 days.</strong>
            </td>
            <td>
              Write us at the address above within 60 calendar days of signing
              your agreement to opt-out of the Clause for that agreement.
              Provide your name, address, account number and date. State that
              you “opt out.” If you opt out, it will only apply to that
              agreement.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

PrequalificationTerms.propTypes = exact(propTypes)
PrequalificationTerms.defaultProps = defaultProps

export default memo(PrequalificationTerms)
