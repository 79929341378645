import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { DateInput, CheckboxGroup } from 'lp-components'
import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
// import PropTypes from 'prop-types'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function ActivityFilterForm({ onSubmit, startDate, endDate }) {
  const options = [
    { key: 'Approved', value: 'Approved' },
    { key: 'Declined', value: 'Declined' },
    { key: 'All', value: 'All' },
  ]

  return (
    <div className="start-application__form activity-filter-form">
      <form noValidate onSubmit={onSubmit}>
        <div className="activity-filter">
          <div className="activity-filter--item">
            <Field
              component={DateInput}
              name="startDate"
              onChange={(newStartDate) => {
                onSubmit({
                  startDate: newStartDate,
                  endDate,
                })
              }}
            />
          </div>
          <div className="activity-filter--item">
            <Field
              component={DateInput}
              name="endDate"
              onChange={(newEndDate) => {
                onSubmit({
                  startDate,
                  endDate: newEndDate,
                })
              }}
            />
          </div>
          <div className="checkbox-group activity-filter--item">
            <label className="label">Application Status</label>
            <Field
              label={false}
              component={CheckboxGroup}
              name="applicationStatus"
              options={options}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

ActivityFilterForm.propTypes = propTypes

ActivityFilterForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'activityFilterForm',
    constraints: {},
  }),
  formValues('startDate'),
  formValues('endDate'),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivityFilterForm)
