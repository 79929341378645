import React, { useState } from 'react'
import { SubmitButton } from 'lp-components'
import { useLocation, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import {
  PRE_QUALIFICATION_DECLINED_URL,
  PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
  WIZARD_URL,
  ACCESS_LOAN_URL,
} from 'config'

const propTypes = {
  requestPrequalification: PropTypes.func.isRequired,
}
const defaultProps = {}

function CreditDeclined({ requestPrequalification }) {
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const reApplyForPreQualification = () => {
    setLoading(true)
    return requestPrequalification(location.state).then((prequalification) => {
      const { applicationStatus, lenderNextStepUrl } = prequalification
      setLoading(false)

      if (applicationStatus === 'prequalified') {
        history.push({
          pathname: WIZARD_URL,
          state: {
            step: 'PRE-QUALIFICATION',
          },
        })
      } else if (applicationStatus === 'prequalified_account_exists') {
        history.push(PRE_QUALIFICATION_ACCOUNT_EXISTS_URL)
      } else if (applicationStatus === 'pending_approval') {
        // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
        //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."

        history.push({
          pathname: ACCESS_LOAN_URL,
          state: {
            lenderNextStepUrl,
          },
        })
      } else {
        history.push(PRE_QUALIFICATION_DECLINED_URL)
      }
    })
  }

  return (
    <div className="declined-container credit-declined">
      <div className="content">
        <h1 className="first-title">Credit Application</h1>
        <p className="first-subtitle">
          We were unable to approve you for an Alphaeon Credit Card. You will
          receive an explanation letter in 7-10 business days.
        </p>

        <p>
          However, YOU MAY QUALIFY for an Alphaeon Credit Access Loan. Select
          Continue to find out if you are pre-approved with no impact to your
          credit score.
        </p>
      </div>
      <div className="check-qualification">
        <SubmitButton
          className="button-warn"
          disabled={loading}
          submitting={loading}
          onClick={() => reApplyForPreQualification()}
        >
          Continue
        </SubmitButton>
      </div>
    </div>
  )
}

CreditDeclined.propTypes = propTypes
CreditDeclined.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    personalInfo: selectors.personalInfo(state),
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {
  requestPrequalification: apiActions.requestPrequalification,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreditDeclined
)
