import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FlashMessageContainer, NetworkErrorReporter } from 'components'
import WizardHeader from './WizardHeader'
import WizardFooter from './WizardFooter'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function WizardLayout({ children }) {
  return (
    <div>
      <WizardHeader />
      <FlashMessageContainer />
      {children}
      <NetworkErrorReporter />
      <WizardFooter />
    </div>
  )
}

WizardLayout.propTypes = propTypes
WizardLayout.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WizardLayout
)
