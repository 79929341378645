import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { flashSuccessMessage } from 'redux-flash'
import { isNil, omit, omitBy } from 'lodash'

import { ContactPatientForm } from '../forms'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import {
  ACCOUNT_LOOKUP_URL,
  ACTION,
  APPLICANTS_URL,
  CONSUMER_APP_URL,
  MERCHANT_PORTAL_URL,
  makePath,
} from 'config'
import {
  contactFormInitialValues,
  flashSubmitFailure,
  makeQueryPath,
  withApiAuth,
} from 'utils'
import { LocationType } from 'types'

const propTypes = {
  createNewCreditApplication: PropTypes.func.isRequired,
  currentLocation: LocationType.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  sendExternalReferencesCommunication: PropTypes.func.isRequired,
}
const defaultProps = {}

function SupplementalFunding({
  createNewCreditApplication,
  currentLocation,
  flashSuccessMessage,
  sendExternalReferencesCommunication,
}) {
  const { state } = useLocation()
  const history = useHistory()

  if (isNil(state)) return <Redirect to={ACCOUNT_LOOKUP_URL} />

  const { accountDetail } = state
  const { address, email, name, primaryPhone: sms } = accountDetail
  const applicationCallbackUrl = makeQueryPath(
    makePath(CONSUMER_APP_URL, APPLICANTS_URL),
    {
      action: ACTION.NEW_ACCESS_LOAN,
    }
  )
  const handleSubmit = (communicationPreference) => {
    const { userReferenceId } = communicationPreference
    const newCreditApplication = {
      location_id: currentLocation.locationId,
      firstName: name.firstName,
      lastName: name.lastName,
      middleInitial: name.middleInitial,
      ssn: accountDetail.ssn,
      ssnLast_4: accountDetail.ssnLast4,
      dateOfBirth: accountDetail.dateOfBirth,
      annualIncome: accountDetail.annualIncome,
      email,
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      primaryPhone: accountDetail.primaryPhone,
      supplementedConsumerCreditInstrumentId:
        accountDetail.consumerCreditInstrumentId,
      userReferenceId,
    }

    if (accountDetail.alternatePhone) {
      newCreditApplication.alternatePhone = accountDetail.alternatePhone
    }

    const normalizedCreditApplication = omitBy(newCreditApplication, isNil)

    return createNewCreditApplication(normalizedCreditApplication).then(
      (creditApplictionResponse) => {
        const {
          creditApplication: { applicationId },
        } = creditApplictionResponse
        const referenceData = {
          ...omit(communicationPreference, 'userReferenceId'),
          applicationCallbackUrl,
        }

        return sendExternalReferencesCommunication(applicationId, referenceData)
      }
    )
  }
  const handleSubmitSuccess = () => {
    flashSuccessMessage(
      'Successfully sent prequalification form link to customer.'
    )
    history.push(MERCHANT_PORTAL_URL)
  }
  const handleCancel = () => {
    history.push(MERCHANT_PORTAL_URL)
  }

  return (
    <div className="declined-container">
      <div className="content">
        <h1
          className="first-title"
          style={{ lineHeight: '38px', textAlign: 'center' }}
        >
          Access Loan Prequalification
        </h1>
        <p>
          Supplemental funding allows you to get additional funds in order to
          meet the procedure's transaction amount.
        </p>
        <p>
          In order to complete this request, the patient must complete an Access
          Loan prequalification. Please select the Patient's desired method of
          receiving the prequalification form.
        </p>

        <ContactPatientForm
          withReference
          initialValues={contactFormInitialValues({ email, sms })}
          onSubmit={handleSubmit}
          handleCancel={handleCancel}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={flashSubmitFailure}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentLocation: selectors.currentLocation(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  createNewCreditApplication: apiActions.createNewCreditApplication,
  sendExternalReferencesCommunication:
    apiActions.sendExternalReferencesCommunication,
}

SupplementalFunding.propTypes = propTypes
SupplementalFunding.defaultProps = defaultProps

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SupplementalFunding)
