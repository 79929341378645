import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Checkbox, SubmitButton } from 'lp-components'
import { Field, formValues, propTypes as formPropTypes } from 'redux-form'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MERCHANT_PORTAL_URL } from 'config'

const propTypes = {
  photoIdProvided: PropTypes.bool,
  disclosureProvided: PropTypes.bool,
  ...formPropTypes,
}

const defaultProps = {}

function StartApplicationForm({
  handleSubmit,
  photoIdProvided,
  disclosureProvided,
}) {
  const showSubmitButton = photoIdProvided && disclosureProvided

  return (
    <div className="start-application__form m-t-50">
      <form noValidate onSubmit={handleSubmit}>
        <div className="">
          <div className="checkbox-container">
            <Field
              name="disclosureProvided"
              component={Checkbox}
              label={false}
            />
            I confirm that I have provided the disclosures/agreement to the
            customer
          </div>
          <div className="checkbox-container">
            <Field name="photoIdProvided" component={Checkbox} label={false} />I
            confirm that I have seen the applicant’s valid photo ID prior to
            beginning the application
          </div>
        </div>
        <div className="start-application__form--actions m-t-50 m-b-50">
          <div className="submit">
            <SubmitButton
              disabled={!showSubmitButton}
              className={`button-warn ${
                !showSubmitButton ? `warn-disabled` : ``
              }`}
            >
              BEGIN REQUEST FOR CREDIT
            </SubmitButton>
          </div>
          <div className="cancel">
            <Link to={MERCHANT_PORTAL_URL}>CANCEL</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

StartApplicationForm.propTypes = propTypes

StartApplicationForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'startApplicationForm',
    constraints: {},
    submitFilters: {
      reject: ['disclosureProvided', 'photoIdProvided'],
    },
  }),
  formValues('disclosureProvided', 'photoIdProvided'),
  connect(mapStateToProps, mapDispatchToProps)
)(StartApplicationForm)
