import { handleActions } from 'redux-actions'
import { compose } from 'redux'
import { set, unset } from 'lodash/fp'
import { selectorForSlice } from 'lp-redux-utils'
import { handleSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'reports'
const slice = 'root.merchantPortal.reports'
const initialState = {}
const reducer = handleActions(
  {
    [actions.clearAllReports]: (state) => {
      return compose(unset('reconciliationReport'))(state)
    },
    [apiActions.getReconciliationReport]: handleSuccess(
      (state, { payload: { data: reconciliationReport } }) => {
        const { bankReconciliations, locationSummaries } = reconciliationReport
        const sortedBankReconciliations =
          bankReconciliations.sort(sortByPostDate)
        const sortedLocationSummaries = locationSummaries.sort(sortByPostDate)

        return set(
          'reconciliationReport',
          {
            ...reconciliationReport,
            bankReconciliations: sortedBankReconciliations,
            locationSummaries: sortedLocationSummaries,
          },
          state
        )
      }
    ),
  },
  initialState
)
const select = selectorForSlice(slice)
const selectors = {
  reconciliationReport: select('reconciliationReport'),
}

export { reducer, selectors, reducerKey }

function sortByPostDate(a, b) {
  return a.postDate.localeCompare(b.postDate)
}
