import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerSelectors from 'connected-react-router'
import {
  Header,
  FlashMessageContainer,
  Footer,
  NetworkErrorReporter,
  SkipNavLink,
} from 'components'
import { scrollToTop } from 'utils'
import { useLocation } from 'react-router-dom'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  const location = useLocation()

  // Scroll to top of page when route changes
  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  return (
    <div className="credit-portal">
      <FlashMessageContainer />
      <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>
      <Header />
      <main id="main-content">{children}</main>
      <NetworkErrorReporter />
      <Footer />
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.getLocation(state).pathname,
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
