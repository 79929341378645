import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import { selectors } from '../reducer'
import { CONTACT_NUMBER, TDD_TTY_NUMBER } from 'config'
import { PracticeType } from 'types'
import { formatPhoneNumber } from 'utils'

const propTypes = {
  applicationStep: PropTypes.string.isRequired,
  practice: PracticeType,
}
const defaultProps = {}

function AccountExistsPage({ applicationStep, practice }) {
  return (
    <div className="declined-container">
      <div className="content">
        <h1 className="first-title">{applicationStep}</h1>
        <p className="first-subtitle">
          Our records indicate that you already have an Alphaeon Credit Card
        </p>
        {practice && (
          <p>
            If you want to use your card with {practice.name} at{' '}
            {formatPhoneNumber(practice.phone)}, just tell them you want to pay
            with your Alphaeon Credit Card.
          </p>
        )}
        <p>
          If you have any questions or issues, please do not hesitate to contact
          our Customer Care department.
        </p>

        <div className="info-container">
          <LocalPhoneIcon />
          <div className="number-container">
            <div>
              <a href={`tel:${CONTACT_NUMBER}`}>{CONTACT_NUMBER}</a>
            </div>{' '}
            <div>
              <a href={`tel:${TDD_TTY_NUMBER}`}>(TDD/TTY {TDD_TTY_NUMBER})</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AccountExistsPage.propTypes = propTypes
AccountExistsPage.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountExistsPage
)
