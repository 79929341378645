import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Field,
  SubmissionError,
  propTypes as formPropTypes,
  formValues,
} from 'redux-form'
import { isNil } from 'lodash'
import { lpForm } from 'lp-form'
import {
  Input,
  Select,
  Checkbox,
  SubmitButton,
  MaskedInput,
} from 'lp-components'

import { InputTip, SSNInput, FormSpy } from 'components'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Link } from 'react-router-dom'

import { DOCTOR_SPECIALTIES, STATES, makePath, PATH } from 'config'
import { PracticeType } from 'types'
import {
  formatISODate,
  parseUSDayMonthYearDate,
  removeNonDigits,
  toNumber,
  validateAddress,
  validDateOfBirth,
} from 'utils'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const propTypes = {
  followOnOperation: PropTypes.bool,
  practice: PracticeType,
  ...formPropTypes,
}
const defaultProps = {
  followOnOperation: false,
}

const validatePhoneNumber = (value, allValues, _, name) => {
  const { homeNumberCheck } = allValues
  if (value) return
  if (homeNumberCheck && name === 'alternatePhone')
    return "Home Number can't be blank"
  if (!homeNumberCheck && name === 'primaryPhone')
    return "Mobile Number can't be blank"
}

let token = undefined

function PersonalInfoForm({
  followOnOperation,
  handleSubmit,
  practice,
  homeNumberCheck,
  submitting,
}) {
  const [initialFormCaptchaToken, setInitialFormCaptchaToken] = useState()
  const [refreshedCaptchaToken, setRefreshedCaptchaToken] = useState()
  const [formSubmitted, setFormSubmitted] = useState(0)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const requestInitialFormCaptcha = async (action) => {
    const recaptchaToken = await executeRecaptcha(action)
    setInitialFormCaptchaToken(recaptchaToken)
  }

  const refreshReCaptchaToken = async (action) => {
    const refreshedReCaptchaToken = await executeRecaptcha(action)
    setRefreshedCaptchaToken(refreshedReCaptchaToken)
  }

  const handleSubmitWithCaptcha = (e) => {
    e.preventDefault()
    setFormSubmitted((prevSubmitted) => prevSubmitted + 1)
  }

  useEffect(() => {
    if (!executeRecaptcha) {
      return
    }
    token = initialFormCaptchaToken
    requestInitialFormCaptcha('personal_form_load')

    if (formSubmitted && !refreshedCaptchaToken) {
      refreshReCaptchaToken('personal_form_submit')
    }

    if (formSubmitted > 0 && refreshedCaptchaToken) {
      token = refreshedCaptchaToken
      handleSubmit()
    }
  }, [executeRecaptcha, refreshedCaptchaToken, formSubmitted])

  return (
    <div className="forms-container">
      <form noValidate onSubmit={handleSubmitWithCaptcha}>
        <Field
          name="firstName"
          component={Input}
          placeholder="First Name"
          label="First Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="lastName"
          component={Input}
          placeholder="Last Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="street1"
          component={Input}
          placeholder="Address Line 1"
          validate={validateAddress}
          required
          requiredIndicator=" *"
        />
        <Field
          name="street2"
          component={Input}
          validate={validateAddress}
          placeholder="Apt, Unit, Suite, etc.(optional)"
        />
        <Field
          name="city"
          component={Input}
          placeholder="City"
          required
          requiredIndicator=" *"
        />
        <div className="one-line-text">
          <Field
            label={'State'}
            name="state"
            component={Select}
            placeholder="State"
            options={STATES}
            required
            requiredIndicator=" *"
          />
          <Field
            name="zip"
            component={Input}
            placeholder="Zip"
            maxLength={5}
            required
            requiredIndicator=" *"
          />
        </div>
        <div className="phone-number-helper">
          Please provide at least one phone number
        </div>
        <Field
          name="primaryPhone"
          placeholder="Mobile Number"
          label="Mobile Number"
          component={InputTip}
          inputType={MaskedInput}
          required={!homeNumberCheck}
          requiredIndicator=" *"
          validate={validatePhoneNumber}
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 3, 4],
            delimiters: ['(', ') ', '-'],
          }}
          parse={removeNonDigits}
          tooltips={() => (
            <div className="input-tips mobile-number">
              <ul>
                <li>
                  Please confirm that this mobile number is ready and accessible
                  as it may be used for authentication.{' '}
                </li>
                <li>
                  By providing your phone number, Alphaeon Credit and their
                  service providers may contact you at any number you provide,
                  including by autodialer, recorded or artificial voice, and
                  text. Message frequency varies. Message and data rates may
                  apply. For further details, refer to our Consent to Autodialed
                  Calls/Texts below.
                </li>
              </ul>
            </div>
          )}
        />
        <div className="home-number">
          <Field
            name="homeNumberCheck"
            placeholder="Number"
            label="I want to provide an alternate phone number (Optional)"
            component={Checkbox}
          />
          <FormSpy name="homeNumberCheck">
            {(homeNumberCheck) => (
              <>
                {homeNumberCheck && (
                  <Field
                    name="alternatePhone"
                    placeholder="Home Number"
                    label="Home Number"
                    validate={validatePhoneNumber}
                    component={InputTip}
                    inputType={MaskedInput}
                    required
                    requiredIndicator=" *"
                    maskOptions={{
                      numericOnly: true,
                      blocks: [0, 3, 3, 4],
                      delimiters: ['(', ') ', '-'],
                    }}
                    parse={removeNonDigits}
                  />
                )}
              </>
            )}
          </FormSpy>
        </div>
        <div className="date-birth">
          <Field
            component={InputTip}
            inputType={MaskedInput}
            name="dateOfBirth"
            placeholder="MM/DD/YYYY"
            required
            requiredIndicator=" *"
            maskOptions={{
              date: true,
              datePattern: ['m', 'd', 'Y'],
            }}
          />
        </div>
        <Field
          name="email"
          component={Input}
          label="Email Address"
          placeholder="Email Address"
          type="email"
          required
          requiredIndicator=" *"
        />
        <Field
          component={SSNInput}
          inputType={MaskedInput}
          required
          requiredIndicator=" *"
          label="Social Security Number"
          name="ssn"
          maskOptions={{
            delimiter: '-',
            blocks: [3, 2, 4],
          }}
          parse={removeNonDigits}
          tooltips={() => (
            <div className="input-tips social-security">
              <LockOutlinedIcon className="gray-icon" />
              <p>
                &nbsp;We use your social security number to validate your
                information and check your credit. Your information is
                transmitted securely.
              </p>
            </div>
          )}
          placeholder="Social Security Number"
        />
        <Field
          component={InputTip}
          inputType={MaskedInput}
          name="annualIncome"
          placeholder="Annual Income"
          required
          requiredIndicator=" *"
          maskOptions={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }}
          parse={toNumber}
          tooltips={() => (
            <div className="input-tips">
              <p>
                If you do not wish to have alimony, child support and/or
                separate maintenance income considered as a basis for repaying
                this obligation, please do not include it in your Annual Income
                amount.
                <br />
                <br />
              </p>
              <FormSpy name="state">
                {(state) => (
                  <>
                    {state.includes('WI') && (
                      <p>
                        <strong>Married WI Residents Only:</strong> if you are
                        applying for an individual account and your spouse is
                        also a WI resident, combine you and your spouse's
                        financial information.
                      </p>
                    )}
                  </>
                )}
              </FormSpy>
            </div>
          )}
        />
        <Field
          name="purchaseAmount"
          component={MaskedInput}
          maskOptions={{
            prefix: '$',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }}
          parse={toNumber}
          placeholder="Intended Purchase Amount (Optional)"
        />

        {(isNil(practice) || isNil(practice.locationId)) && (
          <Field
            name="specialty"
            required
            requiredIndicator=" *"
            component={Select}
            placeholder="Select Your Doctor's Specialty"
            options={DOCTOR_SPECIALTIES}
          />
        )}

        <div className="stepper-checkbox">
          <div className="checkbox-container">
            <Field name="agreed" component={Checkbox} label={false} />

            <div className="checkbox-right">
              <p>
                By clicking the Agree and Continue Prequalification button
                below, you acknowledge that you have received, read, and agreed
                to all of the following:
              </p>
              <div className="privacy-content">
                <ul>
                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(PATH.LEGAL, PATH.PREQUALIFICATION_TERMS)}
                      className="agree-link"
                    >
                      Alphaeon’s Prequalification Terms
                    </Link>
                    , which include an arbitration agreement that limits your
                    ability to sue us in court or in a class action.
                  </li>

                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(
                        PATH.LEGAL,
                        PATH.COMENITY_CAPITAL_PREQUALIFICATION_TERMS
                      )}
                      className="agree-link"
                    >
                      Comenity Capital Prequalification Terms
                    </Link>{' '}
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(
                        PATH.LEGAL,
                        PATH.CONSENT_ELECTRONIC_DISCLOSURES
                      )}
                      className="agree-link"
                    >
                      Consent to Electronic Disclosures
                    </Link>
                    ,which allows Alphaeon, Comenity Capital Bank and Covered
                    Care to provide you disclosures electronically.
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(
                        PATH.LEGAL,
                        PATH.CREDIT_REPORT_AUTHORIZATION
                      )}
                      className="agree-link"
                    >
                      Credit Report Authorization
                    </Link>
                    , which allows Alphaeon Credit, Comenity Capital Bank and
                    Covered Care to obtain credit and other types of reports on
                    you and to share such information.
                  </li>
                  <li>
                    <Link
                      to={makePath(PATH.LEGAL, PATH.CA_PRIVACY_NOTICE)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="agree-link"
                    >
                      Privacy notices from Alphaeon
                    </Link>
                    , Comenity Capital Bank and Covered Care
                  </li>
                  <li>
                    Information collected from your financing application will
                    be used in accordance to the{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(PATH.LEGAL, PATH.PRIVACY_POLICY)}
                      className="agree-link"
                    >
                      Alphaeon Credit Privacy Policy
                    </Link>{' '}
                    and California residents, please see your
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(PATH.LEGAL, PATH.CA_PRIVACY_NOTICE)}
                      className="agree-link"
                    >
                      Important Privacy Choices for Consumers
                    </Link>
                    .
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={makePath(PATH.LEGAL, PATH.CONSENT_AUTODIALED)}
                      className="agree-link"
                    >
                      Consent to Autodialed Calls/Texts
                    </Link>
                    , which allows Alphaeon to use an autodialer to call/text
                    you about your application.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <FormSpy name="agreed">
          {(agreed) => (
            <SubmitButton
              disabled={!agreed || submitting}
              submitting={submitting || followOnOperation}
              className={`button-warn ${!agreed ? `warn-disabled` : ``}`}
            >
              AGREE AND CONTINUE PREQUALIFICATION
            </SubmitButton>
          )}
        </FormSpy>
      </form>
    </div>
  )
}

PersonalInfoForm.propTypes = propTypes
PersonalInfoForm.defaultProps = defaultProps

function beforeSubmit(formValues) {
  let purchaseAmount = formValues.purchaseAmount
  if (isNil(formValues.purchaseAmount) || formValues.purchaseAmount === 0) {
    purchaseAmount = 10000
  }

  const dateOfBirth = parseUSDayMonthYearDate(formValues.dateOfBirth)

  if (token === '') {
    throw new SubmissionError({
      reCaptcha: 'Captcha Token is missing',
    })
  }

  if (!validDateOfBirth(dateOfBirth)) {
    throw new SubmissionError({
      dateOfBirth:
        'Date of birth year must be after 1900 and applicant must be at least 18 years old',
    })
  }

  return {
    ...formValues,
    dateOfBirth: formatISODate(dateOfBirth),
    purchaseAmount: purchaseAmount,
    recaptchaToken: token,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'personalInfoForm',
    constraints: {
      firstName: { presence: true },
      lastName: { presence: true },
      street1: { presence: true },
      city: { presence: true },
      state: { presence: true },
      zip: {
        numericality: { onlyInteger: true },
        presence: true,
        length: { is: 5 },
      },
      dateOfBirth: {
        presence: true,
        length: { is: 10 },
      },
      email: { presence: true, email: true },
      ssn: { presence: true },
      annualIncome: { presence: true },
      specialty: { presence: true },
      purchaseAmount: { presence: false },
      'g-recaptcha-response': { presence: false },
    },
    initialValuesFilters: { reject: ['id'] },
    submitFilters: { reject: ['agreed', 'homeNumberCheck'] },
  }),
  formValues('homeNumberCheck')
)(PersonalInfoForm)
