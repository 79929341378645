import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  QUERY_KEY,
  makeStoreUrl,
  CREDIT_PORTAL_URL,
  API_ERROR_MAP,
  APPLICATION_FINDER_URL,
} from 'config'
import { PropTypes } from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { parseQuery } from 'utils'
import * as apiActions from 'api-actions'
import { Spinner } from 'components'
import { CreditApplicationType, PracticeType, PersonalInfoType } from 'types'
import { selectors } from '../reducer'
import * as actions from '../actions'

import { flashErrorMessage } from 'redux-flash'

const propTypes = {
  getCreditApplicationByTokenValidate: PropTypes.func.isRequired,
  creditApplication: CreditApplicationType,
  setPersonalInfo: PropTypes.func.isRequired,
  setPractice: PropTypes.func.isRequired,
  practice: PracticeType,
  personalInfo: PersonalInfoType,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function PushApplication({
  getCreditApplicationByTokenValidate,
  creditApplication,
  setPersonalInfo,
  setPractice,
  practice,
  personalInfo,
  flashErrorMessage,
}) {
  const { search } = useLocation()
  const query = parseQuery(search)
  const token = query[QUERY_KEY.TOKEN]
  const history = useHistory()

  const redirectToStoreWizard = () => {
    history.push({
      pathname: makeStoreUrl(practice.adsStoreNumber), // + "?token=" +token,
      search: '?token=' + token,
      state: {
        practice: practice,
      },
    })
  }

  useEffect(() => {
    getCreditApplicationByTokenValidate(token).catch((error) => {
      if (error?.response?.errorCode === 'no_credit_application_found') {
        flashErrorMessage(API_ERROR_MAP.no_credit_application_found_one_time)
        history.push(CREDIT_PORTAL_URL)
      }
    })
  }, [])

  useEffect(() => {
    if (creditApplication && creditApplication.applicationId && practice) {
      setPractice(practice)
      setPersonalInfo(personalInfo)
      if (creditApplication.applicationStatus === 'created') {
        redirectToStoreWizard()
      } else {
        history.push(APPLICATION_FINDER_URL) // To  Do handle this
      }
    }
  }, [creditApplication, practice])

  return <Spinner />
}

PushApplication.propTypes = propTypes
PushApplication.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    creditApplication: selectors.creditApplication(state),
    practice: selectors.practice(state),
    personalInfo: selectors.personalInfo(state),
  }
}

const mapDispatchToProps = {
  getCreditApplicationByTokenValidate:
    apiActions.getCreditApplicationByTokenValidate,
  setPersonalInfo: actions.setPersonalInfo,
  setPractice: actions.setPractice,
  flashErrorMessage: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PushApplication
)
