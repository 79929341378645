import React /*, { useEffect } */ from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
// import { Spinner } from 'lp-components'
// import { connectParams } from 'lp-hoc'
// import { selectors } from '../reducer'
// import * as actions from '../actions'
// import * as apiActions from 'api-actions'
// import * as Types from 'types'

const propTypes = {
  // id: PropTypes.number.isRequired,
  // legal: Types.legal,
  // fetchLegal: PropTypes.func.isRequired,
  // clearLegal: PropTypes.func.isRequired,
}

const defaultProps = {}

function LegalShow() {
  // {
  //   id,
  //   legal,
  //   fetchLegal,
  //   clearLegal,
  // }
  // useEffect(() => {
  //  fetchLegal(id)
  //  // Cleanup
  //  return () => clearLegal()
  // }, [ id ])
  // if (!legal) return <Spinner />
  return <div> This is the Legal show view! </div>
}

LegalShow.propTypes = propTypes

LegalShow.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {
    // legal: selectors.legal(state)
  }
}

const mapDispatchToProps = {
  // fetchLegal: apiActions.fetchLegal,
  // clearLegal: actions.clearLegal
}

export default compose(
  // connectParams('id'),
  connect(mapStateToProps, mapDispatchToProps)
)(LegalShow)
