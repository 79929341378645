import { isNil } from 'lodash'
import { compareAsc, parseISO } from 'date-fns'

export default function parseAndCompareAsc(dateString1, dateString2) {
  if (isNil(dateString1)) return 1
  if (isNil(dateString2)) return -1

  const date1 = parseISO(dateString1)
  const date2 = parseISO(dateString2)

  return compareAsc(date1, date2)
}
