import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { AccountNumber } from 'components'
import {
  flashSubmitFailure,
  formatCurrency,
  formatISODateStringAsUSMonthDayYear,
  withApiAuth,
} from 'utils'
import { VoidSaleForm } from '../forms'
import * as apiActions from 'api-actions'
import { ACCOUNT_DETAIL_URL, TRANSACTION_TYPE } from 'config'
import { flashSuccessMessage } from 'redux-flash'

const propTypes = {
  requestVoidSale: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}
const defaultProps = {}

function VoidSale({ requestVoidSale, flashSuccessMessage }) {
  const location = useLocation()
  const history = useHistory()
  const { alphaeonAccountNumber, creditLimit, name, referenceId, transaction } =
    location.state
  const {
    amount,
    createdAt,
    locationName,
    planName,
    transactionId,
    transactionType,
  } = transaction
  const transactionLabel =
    transactionType === TRANSACTION_TYPE.SALE ? 'Sale' : 'Refund'

  const handleSubmit = () => {
    return requestVoidSale({ transactionId: transactionId })
  }

  const cancelVoidSale = () => {
    history.goBack()
  }

  const handleSubmitSuccess = () => {
    flashSuccessMessage('Void Success')
    history.push(`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`)
  }
  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Void {transactionLabel}</h1>

        <>
          <div className="account-summary summary">
            <div className="summary__card card m-t-30">
              <div className="card-content">
                <div className="summary__account-info">
                  <p className="card-title">
                    {name?.firstName} {name?.lastName}
                  </p>
                  <div className="info-container sub-title">
                    <p className="card-area">Account Number:</p>
                    <AccountNumber
                      accountNumberString={alphaeonAccountNumber}
                    />
                  </div>

                  <div className="card-main">
                    <div className="card-main__left">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong>Plan</strong> {planName}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Location</strong> {locationName}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Date</strong>{' '}
                          {formatISODateStringAsUSMonthDayYear(createdAt)}
                        </p>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Credit Limit</strong>{' '}
                          {formatCurrency(creditLimit, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Total {transactionLabel} Amount</strong>{' '}
                          {formatCurrency(amount, 2)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Reference ID </strong>
                          {referenceId}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <VoidSaleForm
              onSubmit={handleSubmit}
              onSubmitSuccess={handleSubmitSuccess}
              onSubmitFail={flashSubmitFailure}
              cancelVoidSale={cancelVoidSale}
            />
          </div>
        </>
      </div>
    </>
  )
}

VoidSale.propTypes = propTypes

VoidSale.defaultProps = defaultProps
function mapStateToProps() {
  return {}
}
const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  requestVoidSale: apiActions.voidSale,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(VoidSale)
