import React, { useEffect, useState, useRef } from 'react'
import { SubmitButton } from 'lp-components'
import iconEdit from 'images/utility-icons/edit-2.svg'
import { Field } from 'redux-form'
import { isPresent } from 'utils'

function RadioButtonEditableInput({
  id,
  checked,
  radioGroupName,
  initialValue,
  onChange,
  labelPrefix,
  labelSuffix,
  editField,
  format,
}) {
  const [value, setValue] = useState(initialValue)

  const [editing, setEditing] = useState(false)

  const inputRef = useRef()
  const radioRef = useRef()

  useEffect(() => {
    if (isPresent(initialValue)) setValue(initialValue)
  }, [initialValue])

  const saveValue = () => {
    setValue(inputRef.current.value)
    if (radioRef.current.checked) {
      onChange(inputRef.current.value)
    }
    setEditing(false)
    return false
  }
  const formatValue = (value) => {
    if (format) {
      return format(value)
    } else {
      return value
    }
  }

  return (
    <>
      <fieldset className="m-t-10">
        <div className="input-wrapper">
          <input
            ref={radioRef}
            type="radio"
            id={id}
            name={radioGroupName}
            value={value}
            defaultChecked={checked}
            onClick={() => onChange(value)}
          />
        </div>
        <span>
          <label htmlFor={id} className="">
            {labelPrefix} {formatValue(value)} {labelSuffix}
          </label>
        </span>
        <span
          onClick={() => setEditing(!editing)}
          style={{ paddingLeft: '3%', cursor: 'pointer' }}
        >
          <img
            src={iconEdit}
            alt=""
            style={{ height: '20px', width: '20px' }}
          />
        </span>
      </fieldset>
      {editing && (
        <div className="editableInput m-t-10">
          <Field
            component={editField.component}
            inputType={editField.inputType}
            required
            requiredIndicator=" *"
            ref={inputRef}
            parse={editField.parse}
            name={editField.name}
            placeholder={editField.placeholder}
            label={editField.label}
            maskOptions={editField.maskOptions}
          />
          <SubmitButton onClick={saveValue} className="button-warn-outline">
            SAVE
          </SubmitButton>
        </div>
      )}
    </>
  )
}

export default React.memo(RadioButtonEditableInput)
