import React, { useReducer, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import DoneIcon from '@material-ui/icons/Done'

import { withStyles, makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'
import { Spinner } from 'components'
import * as CreditApplicationWizard from '../wizard_views'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import {
  WIZARD_STEP,
  QUERY_KEY,
  API_ERROR_MAP,
  CREDIT_PORTAL_URL,
} from 'config'
import {
  ApplicationEnvironmentType,
  PracticeType,
  PrequalificationType,
} from 'types'
import { scrollToTop, parseQuery } from 'utils'
import { isNil, isUndefined } from 'lodash'

import { flashErrorMessage } from 'redux-flash'

const propTypes = {
  applicationEnvironment: ApplicationEnvironmentType,
  clearApplicationEnvironment: PropTypes.func.isRequired,
  newCreditApplication: PropTypes.func.isRequired,
  practice: PracticeType,
  prequalification: PrequalificationType,
  setPersonalInfo: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  getCreditApplicationByTokenValidate: PropTypes.func.isRequired,
  setCreditApplication: PropTypes.func.isRequired,
}

const defaultProps = {}

const FormConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useFormStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    width: 'auto',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: 'gray',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'gray',
    textAlign: 'center',
    padding: '5px',
  },
  activeCircle: {
    zIndex: 1,
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#9d1a32',
  },
  activeSubCircle: {
    zIndex: 2,
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'white',
    margin: '7.5px',
  },
  completed: {
    color: 'white',
    zIndex: 1,
    fontSize: 18,
    padding: '3px',
  },
  completedDiv: {
    backgroundColor: '#9d1a32',
    height: 25,
    width: 25,
    borderRadius: '50%',
  },
})

const init = (initialStep) => {
  return { step: initialStep }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'next':
      if (state.step < WIZARD_STEP.APPROVAL) {
        return { step: state.step + 1 }
      } else {
        return { step: WIZARD_STEP.APPROVAL }
      }
    case 'prev':
      if (state.step > WIZARD_STEP.COLLECT_PERSONAL_INFO) {
        return { step: state.step - 1 }
      } else {
        return { step: WIZARD_STEP.COLLECT_PERSONAL_INFO }
      }
    case 'reset':
      return init(action.payload)
    default:
      throw new Error()
  }
}
function Wizard({
  applicationEnvironment,
  clearApplicationEnvironment,
  newCreditApplication,
  practice,
  getCreditApplicationByTokenValidate,
  setPersonalInfo,
  flashErrorMessage,
  setCreditApplication,
  prequalification,
}) {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, 0, init)
  const [payload, setPayload] = useState(null)
  const query = parseQuery(location.search)
  const token = query[QUERY_KEY.TOKEN]
  const history = useHistory()

  const [applicationId, setApplicationId] = useState(null)

  let prequalification_url = applicationEnvironment?.prequalificationUrl

  useEffect(() => {
    scrollToTop()
    if (location.state?.step === 'PRE-QUALIFICATION') {
      dispatch({ type: 'reset', payload: 1 })
    }
  }, [location.pathname])

  useEffect(() => {
    const locationId = practice?.locationId
    newCreditApplication(locationId)
    return () => clearApplicationEnvironment()
  }, [])

  useEffect(() => {
    if (
      !isUndefined(prequalification_url) &&
      !isNil(prequalification_url) &&
      isNil(token) &&
      location.state?.fromfindMyApp !== true
    ) {
      window.location.href = prequalification_url
    } else if (!isNil(token)) {
      getCreditApplicationByTokenValidate(token)
        .then((res) => {
          setPersonalInfo(res.applicant)
          setApplicationId(res?.creditApplication?.applicationId)
          setCreditApplication(res?.creditApplication)
          if (
            isNil(res?.creditApplication?.applicationId) &&
            !isUndefined(prequalification_url) &&
            !isNil(prequalification_url)
          ) {
            window.location.href = prequalification_url
          }
        })
        .catch((error) => {
          if (error?.response?.errorCode === 'no_credit_application_found') {
            flashErrorMessage(
              API_ERROR_MAP.no_credit_application_found_one_time
            )
            history.push(CREDIT_PORTAL_URL)
          }
        })
    }
  }, [prequalification_url])

  useEffect(() => {
    if (prequalification?.applicationId) {
      setApplicationId(prequalification.applicationId)
    }
  }, [prequalification])

  const steps = ['PERSONAL INFO', 'PRE-QUALIFICATION', 'APPROVAL']
  const mobile_steps = [
    'TELL US ABOUT YOURSELF',
    'PRE-QUALIFICATION',
    'PRE-QUALIFICATION',
  ]

  const moveToNextStep = (data) => {
    dispatch({ type: 'next' })
    scrollToTop()
    if (data) {
      setPayload(data)
    }
  }

  const completePrequalification = () => {
    moveToNextStep()
  }

  const getScreen = () => {
    switch (state.step) {
      case WIZARD_STEP.COLLECT_PERSONAL_INFO:
        return (
          <CreditApplicationWizard.PersonalInfo
            onContinue={moveToNextStep}
            allowChangePractice={isNil(token)}
            showFindApplication={isNil(token)}
          />
        )
      case WIZARD_STEP.PERFORM_PREQUALIFICATION:
        return (
          <CreditApplicationWizard.PreQualification
            payload={payload}
            onStepComplete={completePrequalification}
            applicationEnvironment={applicationEnvironment}
          />
        )
      case WIZARD_STEP.APPROVAL:
        return <CreditApplicationWizard.Approval />
      default:
        return <p>Page Not Found</p>
    }
  }

  if (!applicationEnvironment) return <Spinner />

  if (
    !isUndefined(prequalification_url) &&
    !isNil(prequalification_url) &&
    isNil(applicationId)
  )
    return <Spinner />

  return (
    <>
      <div className="wizard">
        <div className="stepper-container">
          <div className="steppper-header">{mobile_steps[state.step]}</div>
          <Stepper
            activeStep={state.step}
            alternativeLabel
            connector={<FormConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={FormStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="content">{getScreen()}</div>
      </div>
    </>
  )
}

Wizard.propTypes = propTypes
Wizard.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    applicationEnvironment: selectors.applicationEnvironment(state),
    practice: selectors.practice(state),
    prequalification: selectors.prequalification(state),
  }
}

const mapDispatchToProps = {
  clearApplicationEnvironment: actions.clearApplicationEnvironment,
  newCreditApplication: apiActions.newCreditApplication,
  getCreditApplicationByTokenValidate:
    apiActions.getCreditApplicationByTokenValidate,
  setPersonalInfo: actions.setPersonalInfo,
  setCreditApplication: actions.setCreditApplication,
  flashErrorMessage: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Wizard)

function FormStepIcon(props) {
  const classes = useFormStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.completedDiv}>
          <DoneIcon className={classes.completed} />
        </div>
      ) : (
        <div className={active ? classes.activeCircle : classes.circle}>
          {active ? <div className={classes.activeSubCircle} /> : <div />}{' '}
        </div>
      )}
    </div>
  )
}
