import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from 'components'
import { withApiAuth } from 'utils'
import * as apiActions from 'api-actions'
import { isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { AccountVerificationType, LocationType } from 'types'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as accountLookUpSelectors } from '../../account_lookup/reducer'
import { SALES_URL, ACCOUNT_LOOKUP_URL, RISK_NUMBER } from 'config'

import { selectors } from '../../reducer'

const propTypes = {
  getAccountVerification: PropTypes.func.isRequired,
  requestAccountVerification: PropTypes.func.isRequired,
  accountVerification: AccountVerificationType,
  loading: PropTypes.bool.isRequired,
  currentLocation: LocationType.isRequired,
}

const defaultProps = {}

function AccountVerification({
  getAccountVerification,
  requestAccountVerification,
  accountVerification,
  loading,
  currentLocation,
}) {
  const location = useLocation()
  const history = useHistory()
  const [postedAccountVerification, setPostedAccountVerification] =
    useState(null)

  if (isNil(location.state?.accountDetail)) {
    history.push(ACCOUNT_LOOKUP_URL)
    return <></>
  }

  const { accountDetail, buyerId } = location.state

  let error_code = accountVerification?.errorCode

  const handlePageRefresh = () => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        accountDetail,
        buyerId,
      },
    })
  }

  useEffect(() => {
    if (isNil(accountVerification)) {
      getAccountVerification(alphaeonAccountIdentifier)
    }

    if (
      !isNil(error_code) &&
      error_code == 'missing_identity_verification' &&
      isNil(postedAccountVerification)
    ) {
      requestAccountVerification(
        alphaeonAccountIdentifier,
        currentLocation.locationId
      ).then((res) => setPostedAccountVerification(res))
    }

    if (
      (!isNil(accountVerification) &&
        (accountVerification.status == 'success' ||
          accountVerification.isWhitelisted === true)) ||
      (!isNil(postedAccountVerification) &&
        (postedAccountVerification.status == 'success' ||
          postedAccountVerification.isWhitelisted === true))
    ) {
      if (history.action === 'POP') {
        history.goBack()
      } else {
        history.push({
          pathname: !isNil(buyerId)
            ? `${SALES_URL}/${alphaeonAccountNumber}/buyer/${buyerId}`
            : `${SALES_URL}/${alphaeonAccountNumber}`,
          state: {
            accountVerification: !isNil(postedAccountVerification)
              ? postedAccountVerification
              : accountVerification,
          },
        })
      }
    }
  }, [accountVerification, error_code, postedAccountVerification])

  if (loading) {
    return <Spinner />
  }

  const {
    name,
    alphaeonAccountNumber,
    authorizedBuyers,
    alphaeonAccountIdentifier,
    primaryPhone,
  } = accountDetail

  const buyer =
    isNil(buyerId) || buyerId == 0
      ? null
      : authorizedBuyers?.find((buyer) => buyer.accountMemberId == buyerId)

  return (
    <>
      <div className="account-lookup m-t-30 account-verification">
        <h1>Patient Verification</h1>
        <>
          <div className="account-summary summary refund-complete">
            <div className="summary__card card m-t-30">
              {isNil(buyer) && (
                <div className="card-content">
                  <div className="card-half">
                    <div className="summary__account-info">
                      <p className="card-title">
                        {`${name.firstName} ${name.lastName} `}
                      </p>

                      <div className="card-main">
                        <div className="card-main__left">
                          <div className="info-container">
                            <p className="card-area">
                              <strong>Responsible Party</strong>
                            </p>
                          </div>
                          <div className="info-container">
                            <p className="card-area">
                              <strong>Mobile number</strong> {primaryPhone}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {buyer && (
                <div className="card-content">
                  <div className="card-half">
                    <div className="summary__account-info">
                      <p className="card-title">
                        {`${buyer.firstName} ${buyer.lastName} `}
                      </p>

                      <div className="card-main">
                        <div className="card-main__left">
                          <div className="info-container">
                            <p className="card-area">
                              <strong>Authorized Buyer</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-half">
                    <div className="summary__account-info">
                      <p className="card-title">
                        {`${name?.firstName} ${name?.lastName}`}
                      </p>

                      <div className="card-main">
                        <div className="card-main__left">
                          <div className="info-container">
                            <p className="card-area">
                              <strong>Responsible Party</strong>
                            </p>
                          </div>
                          <div className="info-container">
                            <p className="card-area">
                              <strong>Mobile number</strong> {primaryPhone}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
        <div className="verification-sub-title account-summary summary">
          <p>
            A text message has been sent to the cardholder and their response is
            needed to complete the transaction. Once they respond and complete
            the verification process, refresh this page by clicking {` `}
            <a href="" onClick={handlePageRefresh}>
              here
            </a>{' '}
            or running the transaction again. Need help? Please call Alphaeon
            Credit Practice Support {` ${RISK_NUMBER}`}.
          </p>
        </div>
      </div>
    </>
  )
}

AccountVerification.propTypes = propTypes

AccountVerification.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    loading: apiSelectors.isLoading(state, 'GET_ACCOUNT_VERIFICATION'),
    accountVerification: accountLookUpSelectors.accountVerification(state),
    currentLocation: selectors.currentLocation(state),
  }
}
const mapDispatchToProps = {}

const mapApiAuthToProps = {
  getAccountVerification: apiActions.getAccountVerification,
  requestAccountVerification: apiActions.requestAccountVerification,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AccountVerification)
