import React from 'react'

export default function TestEnvironmentBanner({ env }) {
  return (
    <div className="test-environment-banner">
      <div className="sandbox-mode-title">{`${env.toUpperCase()} MODE`}</div>
      <p>{`You are viewing the ${env.toUpperCase()} mode used for testing purposes only.`}</p>
    </div>
  )
}
