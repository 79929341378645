import { recordApplicationBuildId } from './global-actions'
import { fetchApplicationBuildId } from 'utils'

export const getApplicationBuildId = () => {
  return async function (dispatch) {
    const applicationBuildId = await fetchApplicationBuildId()

    dispatch(recordApplicationBuildId(applicationBuildId))
  }
}
