import React from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'lp-components'

import { Button } from 'components'
import { selectors } from '../reducer'
import { PracticeType } from 'types'

const propTypes = {
  onContinue: PropTypes.func.isRequired,
  practice: PracticeType,
}
const defaultProps = {}
function PrequalDeclineModal({ onContinue, practice }) {
  return (
    <Modal
      hideCloseButton
      onClose={onContinue}
      overlayClassName="modal-fade-screen prequal-decline-modal-overlay"
      className="modal-inner prequal-decline-modal modal"
    >
      <div>
        <div className="prequal-decline-modal__container">
          <div className="details">
            We were unable to pre-qualify you for an Alphaeon Credit Card.
            <br />
            <br />
            However, you have been pre-qualified for our Access Loan
            {practice && ` at ${practice.name}`}.
          </div>
          <div className="button-block">
            <Button className="button-warn-outline" onClick={onContinue}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

PrequalDeclineModal.propTypes = propTypes
PrequalDeclineModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    practice: selectors.practice(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrequalDeclineModal
)
