import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashSuccessMessage } from 'redux-flash'

import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { CONSUMER_APP_URL, PATH, makePath } from 'config'
import {
  contactFormInitialValues,
  flashSubmitFailure,
  withApiAuth,
} from 'utils'
import { ApplicationInfoType, ApprovedApplicationType } from 'types'

import ContactPatientForm from '../forms/ContactPatientForm'
import { useHistory } from 'react-router-dom'

const propTypes = {
  applicationInfo: ApplicationInfoType.isRequired,
  sendExternalReferencesCommunication: PropTypes.func.isRequired,
  declinedCreditApplication: ApprovedApplicationType.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function DeclinedAccessLoanAvailable({
  applicationInfo,
  sendExternalReferencesCommunication,
  declinedCreditApplication,
  flashSuccessMessage,
}) {
  const callbackUrl =
    makePath(CONSUMER_APP_URL, PATH.CREDIT_PORTAL, PATH.APPLICANTS) +
    '?action=continue'

  const handleSubmit = (data) => {
    const requestBody = {
      ...data,
      application_callback_url: callbackUrl,
    }
    return sendExternalReferencesCommunication(
      declinedCreditApplication?.applicationId,
      requestBody
    )
  }
  const history = useHistory()

  const handleSubmitSuccess = () => {
    flashSuccessMessage('Successfully sent application information to customer')
    history.push(PATH.MERCHANT_PORTAL)
  }

  const handleCancel = () => {
    history.push(PATH.MERCHANT_PORTAL)
  }

  const { email, primaryPhone: sms } = applicationInfo

  return (
    <div className="declined-container">
      <div className="content">
        <h1
          className="first-title"
          style={{ lineHeight: '38px', textAlign: 'center' }}
        >
          Credit Application
        </h1>
        <p>
          We were unable to approve you for an Alphaeon Credit Card. You will
          receive an explanation letter in 7-10 business days.
        </p>
        <p>
          However, <b>THE PATIENT MAY QUALIFY</b> for an Alphaeon Credit Access
          Loan
        </p>

        <p>
          If the patient would like to apply for an Access Loan, Alphaeon can
          send the application link to the patient's email.
          {/* either the patient's email or phone number. */}
          Please select the patient's preferred method of contact below.
        </p>

        <ContactPatientForm
          initialValues={contactFormInitialValues({ email, sms })}
          onSubmit={handleSubmit}
          handleCancel={handleCancel}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={flashSubmitFailure}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    applicationInfo: selectors.applicationInfo(state),
    declinedCreditApplication: selectors.approvedCreditApplication(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  sendExternalReferencesCommunication:
    apiActions.sendExternalReferencesCommunication,
}

DeclinedAccessLoanAvailable.propTypes = propTypes
DeclinedAccessLoanAvailable.defaultProps = defaultProps

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(DeclinedAccessLoanAvailable)
