import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import UserForm from '../forms/UserForm'
import { useParams, useHistory, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import { Spinner } from 'components'
import {
  DEFAULT_PERMISSIONS,
  USER_URL,
  DEFAULT_PERMISSIONS_KEYS,
  ADMIN_PERMISSION_KEYS,
} from 'config'
import { flashMessage } from 'redux-flash'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { selectors as merchantSelectors } from '../../reducer'
import { isUndefined, first } from 'lodash'
import { selectors as apiSelectors } from 'lp-redux-api'
import { MangerDetailType, LocationType } from 'types'
import { flashSubmitFailure, withApiAuth } from 'utils'

const propTypes = {
  newManager: PropTypes.func.isRequired,
  updateManager: PropTypes.func.isRequired,
  getManagerById: PropTypes.func.isRequired,
  managerById: MangerDetailType,
  clearMangerById: PropTypes.func.isRequired,
  flashMessage: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
}
const defaultProps = {}

function UserAdd({
  newManager,
  updateManager,
  flashMessage,
  getManagerById,
  managerById,
  clearMangerById,
  loading,
  allLocations,
}) {
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (id) {
      getManagerById(id)
    } else {
      setInitialValues({
        status: 'active',
        selectAllPermissions: true,
        permissions: DEFAULT_PERMISSIONS_KEYS,
      })
    }

    return () => clearMangerById()
  }, [id])

  useEffect(() => {
    if (managerById) {
      const {
        managerId,
        email,
        firstName,
        lastName,
        locationPermissions,
        assignedLocationIds,
        status,
      } = managerById

      const locationPermission = first(locationPermissions)
      const permission = Object.keys(locationPermission)

      const assignedLocations = assignedLocationIds
        .map((locId) => {
          const { name, adsStoreNumber, locationId } =
            allLocations.find((al) => al.locationId === locId) || {}
          return name
            ? {
                label: `${name}, ${adsStoreNumber}`,
                value: locationId,
              }
            : null
        })
        .filter((n) => n)

      setInitialValues({
        managerId,
        firstName,
        lastName,
        email,
        assignedLocationIds: assignedLocations,
        status,
        selectAllPermissions: true,
        adminPermissions: permission.filter(
          (key) =>
            ADMIN_PERMISSION_KEYS.includes(key) &&
            locationPermission[key] === true
        ),
        permissions: permission.filter(
          (key) =>
            DEFAULT_PERMISSIONS_KEYS.includes(key) &&
            locationPermission[key] === true
        ),
      })
    }
  }, [managerById])

  const handleSubmit = (values) => {
    const data = {
      ...values,
      active: values.status === 'active' ? true : false,
      permissions: parseSelectedPermission(values.permissions),
      assignedLocationIds: parseSelectedLocations(values.assignedLocationIds),
      email: values.email,
    }
    if (!id) {
      return newManager(data)
    }
    return updateManager(data)
  }

  const handleSubmitSuccess = () => {
    flashMessage(id ? 'User Successfully Updated' : 'User Successfully Created')
    history.push(USER_URL)
  }

  if (id && loading) {
    return <Spinner />
  }

  if (id && isUndefined(managerById)) {
    return (
      <div className="account-lookup m-t-30">
        <h3>Error Fetching User Detail</h3>
        <Link to="/">GO HOME</Link>
      </div>
    )
  }

  return (
    <div className="account-lookup full-application users-add m-t-50">
      <h1>{id ? 'Update' : 'New'} User Account</h1>

      <UserForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitFail={flashSubmitFailure}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </div>
  )
}

UserAdd.propTypes = propTypes

UserAdd.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    managerById: selectors.managerById(state),
    loading: apiSelectors.isLoading(state, 'GET_MANAGER_BY_ID'),
    allLocations: merchantSelectors.allLocations(state),
  }
}

const mapDispatchToProps = {
  flashMessage,
  clearMangerById: actions.clearMangerById,
}

const mapApiAuthToProps = {
  newManager: apiActions.newManager,
  updateManager: apiActions.updateManager,
  getManagerById: apiActions.getManagerById,
}

const parseSelectedPermission = (selectedPermissions) => {
  const permissions = DEFAULT_PERMISSIONS

  Object.keys(permissions).forEach((key) => {
    permissions[key] = false
  })

  //only makes selected permissions 'true'
  selectedPermissions.forEach((selectedPerm) => {
    permissions[selectedPerm] = true
  })
  return permissions
}
function parseSelectedLocations(values) {
  return values.map(({ value }) => Number(value))
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(UserAdd)
