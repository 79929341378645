import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as apiActions from 'api-actions'
import RefundRequestForm from '../forms/RefundRequestForm'
import { useHistory, useLocation } from 'react-router-dom'
import {
  REFUND_REQUEST_URL,
  ACCOUNT_DETAIL_URL,
  TRANSACTION_TYPE,
} from 'config'
import { AccountNumber } from 'components'
import {
  flashSubmitFailure,
  formatCurrency,
  formatISODateStringAsUSMonthDayYear,
  withApiAuth,
} from 'utils'
import { LocationType } from 'types'
import { selectors } from '../reducer'
import { flashSuccessMessage } from 'redux-flash'

const propTypes = {
  refundRequest: PropTypes.func.isRequired,
  currentLocation: LocationType,
  flashSuccessMessage: PropTypes.func.isRequired,
  refundLoan: PropTypes.func.isRequired,
}

const defaultProps = {}

function RefundRequest({
  refundRequest,
  currentLocation,
  flashSuccessMessage,
  refundLoan,
}) {
  const history = useHistory()
  const location = useLocation()
  const {
    accountMemberId,
    alphaeonAccountNumber,
    creditLimit,
    name,
    transaction,
  } = location.state

  const {
    amount,
    createdAt,
    locationName,
    planName,
    transactionId,
    transactionType,
  } = transaction

  const handleSubmit = (values) => {
    if (transactionType == TRANSACTION_TYPE.LOAN) {
      return refundLoan(
        alphaeonAccountNumber,
        values.amount,
        currentLocation.locationId
      )
    } else {
      return refundRequest({
        ...values,
        locationId: currentLocation.locationId,
        refundedTransactionId: transactionId,
        account_member_id: accountMemberId,
      })
    }
  }

  const handleSubmitSuccess = () => {
    flashSuccessMessage('Refund Success')
    history.push({
      pathname: `${REFUND_REQUEST_URL}${alphaeonAccountNumber}/complete`,
      state: {
        accountMemberId,
        alphaeonAccountNumber,
        creditLimit,
        name,
        transaction,
      },
    })
  }

  const goToAccountDetailPage = () => {
    history.push(`${ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`)
  }

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Refund Request</h1>
        <>
          <div className="account-summary summary refund-request">
            <div className="summary__info m-t-20">
              You have requested a refund to this credit account. Please confirm
              the information below before finalizing the refund.
            </div>
            <div className="summary__card card m-t-30">
              <div className="card-content">
                <div className="summary__account-info">
                  <p className="card-title">
                    {' '}
                    {name.firstName} {name.lastName}
                  </p>
                  <div className="info-container sub-title">
                    <p className="card-area">Account Number:</p>
                    <AccountNumber
                      accountNumberString={alphaeonAccountNumber}
                    />
                  </div>

                  <div className="card-main">
                    <div className="card-main__left">
                      <div className="info-container ">
                        <p className="card-area">
                          <strong>Transaction Date</strong>{' '}
                          {formatISODateStringAsUSMonthDayYear(createdAt)}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Plan</strong> {planName}
                        </p>
                      </div>
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Location</strong> {locationName}
                        </p>
                      </div>
                    </div>
                    <div className="card-main__right">
                      <div className="info-container">
                        <p className="card-area">
                          <strong>Total Sale Amount </strong>{' '}
                          {formatCurrency(amount, 2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-t-50 refund-request--header">
              Refund Information
            </div>

            <RefundRequestForm
              onSubmit={handleSubmit}
              onSubmitSuccess={handleSubmitSuccess}
              onSubmitFail={flashSubmitFailure}
              handleCancel={goToAccountDetailPage}
            />
          </div>
        </>
      </div>
    </>
  )
}

RefundRequest.propTypes = propTypes

RefundRequest.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    currentLocation: selectors.currentLocation(state),
  }
}
const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  refundRequest: apiActions.refundRequest,
  adjustLoan: apiActions.adjustLoan,
  cancelLoan: apiActions.cancelLoan,
  refundLoan: apiActions.refundLoan,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(RefundRequest)
