import {
  endOfMonth,
  formatISO,
  startOfMonth,
  startOfYear,
  subMonths,
} from 'date-fns'
import { DATE_SELECTION } from 'config'

export default function getReportDates({
  dateFilterType,
  fromDate = null,
  toDate = null,
}) {
  const todaysDate = new Date()
  const startDate =
    dateFilterType === DATE_SELECTION.DATE_RANGE
      ? fromDate
      : startDateFromFilterType(todaysDate, dateFilterType)

  const endDate =
    dateFilterType === DATE_SELECTION.DATE_RANGE
      ? toDate
      : endDateFromFilterType(todaysDate, dateFilterType)

  return {
    startDate: formatISO(startDate, { representation: 'date' }),
    endDate: formatISO(endDate, { representation: 'date' }),
  }
}

function endDateFromFilterType(todaysDate, dateFilterType) {
  switch (dateFilterType) {
    case DATE_SELECTION.PRIOR_MONTH:
      return endOfMonth(subMonths(todaysDate, 1))
    default:
      return todaysDate
  }
}

function startDateFromFilterType(todaysDate, dateFilterType) {
  switch (dateFilterType) {
    case DATE_SELECTION.MONTH_TO_DATE:
      return startOfMonth(todaysDate)
    case DATE_SELECTION.PRIOR_MONTH:
      return startOfMonth(subMonths(todaysDate, 1))
    case DATE_SELECTION.YEAR_TO_DATE:
      return startOfYear(todaysDate)
    default:
      throw new Error('unknown date filter type in getReportDates')
  }
}
