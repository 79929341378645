import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as actions from '../actions'
import * as merchantActions from '../../actions'
import PaymentDetailsForm from '../forms/PaymentDetailsForm'
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'
import { PLAN_SELECTION_URL, ACCOUNT_VERIFICATION_URL } from 'config'
import { AccountNumber, Button, Spinner } from 'components'
import { formatCurrency, withApiAuth } from 'utils'
import { selectors as merchantSelectors } from '../../reducer'
import { LocationType, AccountDetailType, AccountVerificationType } from 'types'
import * as apiActions from 'api-actions'
import { selectors as accountLookUpSelectors } from '../../account_lookup/reducer'
import { clearAccountDetail } from '../../account_lookup/actions'
import { selectors as apiSelectors } from 'lp-redux-api'
import { Modal } from 'lp-components'
import { isNil, isUndefined } from 'lodash'
import { LocationSelectorForm } from '../../forms'
import { setLocation } from 'local-storage'
import AuthBuyerSaleCard from '../components/AuthBuyerSaleCard'

const propTypes = {
  setPaymentDetails: PropTypes.func.isRequired,
  currentLocation: LocationType.isRequired,
  getAccountDetails: PropTypes.func.isRequired,
  accountDetail: AccountDetailType,
  loading: PropTypes.bool.isRequired,
  clearAccountDetail: PropTypes.func.isRequired,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  currentLocationIndex: PropTypes.number.isRequired,
  setCurrentLocationIndex: PropTypes.func.isRequired,
  fetchLocationDetails: PropTypes.func.isRequired,
  accountVerification: AccountVerificationType,
}

const defaultProps = {}

function PaymentDetails({
  getAccountDetails,
  accountDetail,
  setPaymentDetails,
  currentLocation,
  loading,
  clearAccountDetail,
  allLocations,
  currentLocationIndex,
  setCurrentLocationIndex,
  fetchLocationDetails,
  accountVerification,
}) {
  const history = useHistory()
  const { id, buyerId } = useParams()
  const location = useLocation()
  accountVerification = location.state?.accountVerification

  const [displayLocationSelector, setDisplayLocationSelector] = useState(false)
  const closeLocationSelector = () => setDisplayLocationSelector(false)

  useEffect(() => {
    getAccountDetails(id, currentLocation.locationId, buyerId)
    return () => clearAccountDetail()
  }, [])

  useEffect(() => {
    if (internalRiskReason?.startsWith('RISKY') && isNil(accountVerification)) {
      history.push({
        pathname: `${ACCOUNT_VERIFICATION_URL}`,
        state: {
          accountDetail,
        },
      })
    }
  }, [accountDetail, internalRiskReason])

  if (loading) {
    return <Spinner />
  }

  if (isUndefined(accountDetail)) {
    return (
      <div className="account-lookup m-t-30">
        <h3>Error Fetching Account Detail</h3>
        <Link to="/">GO HOME</Link>
      </div>
    )
  }

  const {
    name,
    alphaeonAccountNumber,
    availableCreditLimit,
    address,
    ssnLast4,
    creditLimit,
    consumerCreditInstrumentId,
    derogatoryInd,
    internalRiskReason,
    authorizedBuyers,
  } = accountDetail

  const buyer =
    isNil(buyerId) || buyerId == 0
      ? null
      : authorizedBuyers?.find((buyer) => buyer.accountMemberId == buyerId)

  const accountMemberId =
    isNil(buyerId) || buyerId == 0
      ? accountDetail.accountMemberId
      : parseInt(buyerId)

  const handleSubmit = (values) => {
    const data = {
      ...values,
      accountMemberId,
      consumerCreditInstrumentId,
      name,
      availableCreditLimit,
      address,
      ssnLast4,
      creditLimit,
      alphaeonAccountNumber,
      buyer,
    }
    setPaymentDetails(data)
    history.push(PLAN_SELECTION_URL)
  }

  return (
    <>
      <div className="account-lookup m-t-30">
        <h1>Payment Details, Step 1 of 3</h1>
        <>
          <div className="account-summary summary refund-complete">
            {buyer && (
              <AuthBuyerSaleCard
                paymentDetails={{ ...accountDetail, buyer }}
                currentLocation={currentLocation}
              />
            )}
            {!buyer && (
              <div className="summary__card card m-t-30">
                <div className="card-content">
                  <div className="summary__account-info">
                    <p className="card-title">
                      {name?.firstName} {name?.lastName}
                    </p>
                    <div className="info-container sub-title">
                      <p className="card-area">Account Number:</p>
                      <AccountNumber
                        accountNumberString={alphaeonAccountNumber}
                      />
                    </div>

                    <div className="card-main">
                      <div className="card-main__left">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Available Credit</strong>{' '}
                            {formatCurrency(availableCreditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Social Security Number</strong>{' '}
                            {ssnLast4 ? `XXX-XX-${ssnLast4}` : 'N/A'}
                          </p>
                        </div>
                      </div>

                      <div className="card-main__right">
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Credit Limit</strong>{' '}
                            {formatCurrency(creditLimit, 2)}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <strong>Location </strong> {currentLocation.name} -{' '}
                            {currentLocation.adsStoreNumber}
                          </p>
                        </div>
                        <div className="info-container">
                          <p className="card-area">
                            <Button
                              onClick={() =>
                                setDisplayLocationSelector(
                                  !displayLocationSelector
                                )
                              }
                              className="button-warn-outline"
                            >
                              Change Location
                            </Button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PaymentDetailsForm
              onSubmit={handleSubmit}
              derogatoryInd={derogatoryInd}
              internalRiskReason={internalRiskReason}
            />
          </div>
        </>
      </div>
      {displayLocationSelector && (
        <Modal onClose={closeLocationSelector}>
          <LocationSelectorForm
            allLocations={allLocations}
            initialValues={{ selectedLocationIndex: currentLocationIndex }}
            defaultValue={{
              label: currentLocation.name,
              value: currentLocationIndex,
            }}
            onSubmit={({ selectedLocationIndex }) => {
              closeLocationSelector()

              fetchLocationDetails(
                allLocations[selectedLocationIndex.value].locationId
              ).then((res) => setLocation(res))
              return setCurrentLocationIndex(selectedLocationIndex.value)
            }}
          />
        </Modal>
      )}
    </>
  )
}

PaymentDetails.propTypes = propTypes

PaymentDetails.defaultProps = defaultProps
function mapStateToProps(state) {
  return {
    currentLocation: merchantSelectors.currentLocation(state),
    accountDetail: accountLookUpSelectors.accountDetail(state),
    loading: apiSelectors.isLoading(state, 'ACCOUNT_DETAIL'),
    allLocations: merchantSelectors.allLocations(state),
    currentLocationIndex: merchantSelectors.currentLocationIndex(state),
  }
}
const mapDispatchToProps = {
  setPaymentDetails: actions.setPaymentDetails,
  clearAccountDetail: clearAccountDetail,
  setCurrentLocationIndex: merchantActions.setCurrentLocationIndex,
}

const mapApiAuthToProps = {
  getAccountDetails: apiActions.getAccountDetails,
  fetchLocationDetails: apiActions.fetchLocationDetails,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(PaymentDetails)
