import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  ACCOUNT_FULL_APPLICATION_URL,
  PRINT_URL,
  PATH,
  PUSH_APPLICATION_SMS_TEMPLATE_NAME,
  CONSUMER_APP_URL,
  makePath,
  PERMISSION,
} from 'config'
import StartApplicationForm from '../forms/StartApplicationForm'
import { useHistory } from 'react-router-dom'
import { LinkButton } from 'components'
import { LocationType, PermissionsType } from 'types'
import { selectors } from '../reducer'
import { isNil } from 'lodash'
import PushApplicationForm from '../forms/PushApplicationForm'
import PropTypes from 'prop-types'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { withApiAuth, flashSubmitFailure, operationPermitted } from 'utils'
import { flashSuccessMessage } from 'redux-flash'

const propTypes = {
  currentLocation: LocationType.isRequired,
  loading: PropTypes.bool.isRequired,
  createPushApplication: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  sendExternalReferencesCommunication: PropTypes.func.isRequired,
  currentPermissions: PermissionsType.isRequired,
}

const defaultProps = {}

function StartApplication({
  currentLocation,
  loading,
  createPushApplication,
  flashSuccessMessage,
  sendExternalReferencesCommunication,
  currentPermissions,
}) {
  const history = useHistory()

  const handleSubmit = () => {
    history.push(ACCOUNT_FULL_APPLICATION_URL)
  }

  const handlePushApplicationSubmit = (formValues) => {
    const newCreditApplication = {
      location_id: currentLocation.locationId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      primaryPhone: formValues.primaryPhone,
    }

    return createPushApplication(newCreditApplication).then(
      (creditApplictionResponse) => {
        const {
          creditApplication: { applicationId },
        } = creditApplictionResponse

        const pushAppUrl = makePath(
          CONSUMER_APP_URL,
          PATH.CREDIT_PORTAL,
          PATH.APPLICANTS,
          PATH.PUSH_APPLICATION
        )

        const requestData = {
          applicationCallbackUrl: pushAppUrl,
          sms: newCreditApplication.primaryPhone,
          template: PUSH_APPLICATION_SMS_TEMPLATE_NAME,
        }

        return sendExternalReferencesCommunication(applicationId, requestData)
          .then((tokenResponse) => {
            return {
              token: tokenResponse,
              applicant: creditApplictionResponse.applicant,
            }
          })
          .catch(() => {
            throw new Error(`We were unable to text message
            ${creditApplictionResponse.applicant.firstName} ${creditApplictionResponse.applicant.lastName} at ${creditApplictionResponse.applicant.primaryPhone}.
            Please ensure that the Patient has a working mobile phone number.`)
          })
      }
    )
  }

  const handlePushApplicationSubmitSuccess = (response) => {
    flashSuccessMessage(
      `We have successfully sent an SMS with an application link to ${response.applicant.firstName} ${response.applicant.lastName}`
    )

    history.push(PATH.MERCHANT_PORTAL)
  }

  return (
    <div className="application-container">
      {!isNil(currentLocation) &&
        currentLocation.pushApplicationEnabled === true &&
        operationPermitted(
          currentPermissions,
          PERMISSION.SUBMIT_PUSH_APPLICATIONS
        ) && (
          <div className="account-lookup start-application">
            <h1>Send One-Time Application</h1>
            <div className="start-application__content push-app">
              <div className="start-application__disclosure m-t-50">
                <div className="start-application__disclosure--header">
                  Please enter the information below to send a link to the
                  One-Time Application. Remind your patient to have their mobile
                  device ready to complete the application.
                  <PushApplicationForm
                    onSubmit={handlePushApplicationSubmit}
                    onSubmitSuccess={handlePushApplicationSubmitSuccess}
                    onSubmitFail={flashSubmitFailure}
                  />
                </div>
              </div>
              {!isNil(currentLocation) &&
                currentLocation.address?.state !== 'CA' &&
                operationPermitted(
                  currentPermissions,
                  PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS
                ) && <div className="separator">OR</div>}
            </div>
          </div>
        )}
      {!isNil(currentLocation) &&
        currentLocation.address?.state !== 'CA' &&
        operationPermitted(
          currentPermissions,
          PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS
        ) && (
          <div className="account-lookup start-application">
            <h1>Start Application</h1>
            <div className="start-application__content">
              <div className="start-application__disclosure">
                <div className="start-application__disclosure--header">
                  Please share the disclosures/agreement with the customer to
                  complete the application on their behalf:
                </div>

                <div className="start-application__disclosure--psa">
                  Documents may be pre-printed or use the “Print
                  Disclosures/Agreement” link below
                </div>
                <div className="start-application__disclosure--print">
                  <LinkButton
                    to={`${PRINT_URL}/CCA`}
                    target="_blank"
                    className="button-warn-outline sale"
                  >
                    PRINT DISCLOSURES/AGREEMENT
                  </LinkButton>
                </div>
              </div>

              <div className="start-application__disclosure m-t-50">
                <div className="start-application__disclosure--header">
                  Please confirm the following prior to submitting the
                  application:
                  <StartApplicationForm
                    onSubmit={handleSubmit}
                    submitting={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

StartApplication.propTypes = propTypes

StartApplication.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentLocation: selectors.currentLocation(state),
    loading: apiSelectors.isLoading(state, 'CREATE_PUSH_APPLICATION'),
    currentPermissions: selectors.currentPermissions(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  createPushApplication: apiActions.createPushApplication,
  sendExternalReferencesCommunication:
    apiActions.sendExternalReferencesCommunication,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(StartApplication)
