import {
  ACCESS_LOAN_URL,
  DECLINED_URL,
  PRE_QUALIFICATION_ACCEPT_DECLINED_URL,
  PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
  WIZARD_URL,
} from 'config'

export default function determinePrequalificationResponse(response, modalType) {
  const {
    applicationStatus,
    lenderNextStepUrl,
    applicationServiceProviderPriority,
  } = response

  if (applicationStatus === 'prequalified') {
    return {
      pathname: WIZARD_URL,
      state: {
        step: 'PRE-QUALIFICATION',
      },
    }
  } else if (applicationStatus === 'prequalified_account_exists') {
    return {
      pathname: PRE_QUALIFICATION_ACCOUNT_EXISTS_URL,
    }
  } else if (applicationStatus === 'pending_approval') {
    // An assumption is currently made that pending_approval is returned
    // only for access loans. If additional product types associated with
    // a pending_approval status will be supported, this code this have to be
    // revisited to accommodate them.
    return {
      pathname: ACCESS_LOAN_URL,
      state: {
        lenderNextStepUrl,
        modalType,
      },
    }
  } else {
    if (applicationServiceProviderPriority.productType === 'installment') {
      return {
        pathname: PRE_QUALIFICATION_ACCEPT_DECLINED_URL,
      }
    }

    return {
      pathname: DECLINED_URL,
    }
  }
}
