import React from 'react'
import { CSVLink } from 'react-csv'
import PropTypes from 'prop-types'

const propTypes = {
  csvHeaders: PropTypes.array.isRequired,
  csvData: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const ExportCSV = ({ csvHeaders, csvData, fileName, title }) => {
  return (
    <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
      {title}
    </CSVLink>
  )
}

ExportCSV.propTypes = propTypes

export default ExportCSV
