import React, { useEffect } from 'react'
import exact from 'prop-types-exact'
import {
  ACCOUNT_LOOKUP_URL,
  MERCHANT_PORTAL_URL,
  CREDIT_PORTAL_URL,
  USER_PORTAL_URL,
  PERMISSION,
  REPORT_URL,
  APPLICATION_TRACKING,
  BANK_RECONCILIATION,
  SALES,
  PRIVACY_URL,
} from 'config'
import { Link } from 'react-router-dom'
import { AuthorizedLink } from 'merchant-portal-components'
import logo from 'images/Alphaeon_Credit_Logo.png'

const propTypes = {}
const defaultProps = {}

function Footer() {
  useEffect(() => {
    window.do_chatlio()
  }, [])

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-container__content footer-container__logo">
          <Link to={MERCHANT_PORTAL_URL}>
            <img src={logo} className="logo" alt="Alphaeon rose logo" />
          </Link>
        </div>
        <div className="footer-container__content">
          <div className="footer-container__content--title">
            BUSINESS CENTER
          </div>
          <div className="footer-container__content--links">
            <ul>
              <li>
                <Link to={MERCHANT_PORTAL_URL}>Dashboard</Link>
              </li>
              <li>
                {' '}
                <Link to={CREDIT_PORTAL_URL}>Consumer Application</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-container__content">
          <div className="footer-container__content--title">ACCOUNT LOOKUP</div>
          <div className="footer-container__content--links">
            <ul>
              <li>
                {' '}
                <AuthorizedLink
                  to={ACCOUNT_LOOKUP_URL}
                  requiredPermission={PERMISSION.ACCOUNT_LOOKUP}
                >
                  Account Lookup
                </AuthorizedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-container__content">
          <div className="footer-container__content--title">REPORTS</div>
          <div className="footer-container__content--links">
            <ul>
              <li>
                <AuthorizedLink
                  to={{
                    pathname: REPORT_URL,
                    search: `?type=${APPLICATION_TRACKING}`,
                  }}
                  requiredPermission={
                    PERMISSION.VIEW_APPLICATION_TRACKING_REPORT
                  }
                >
                  Application Tracking
                </AuthorizedLink>
              </li>
              <li>
                <AuthorizedLink
                  to={{
                    pathname: REPORT_URL,
                    search: `?type=${BANK_RECONCILIATION}`,
                  }}
                  requiredPermission={
                    PERMISSION.VIEW_BANK_RECONCILIATION_REPORT
                  }
                >
                  Bank Reconciliation
                </AuthorizedLink>
              </li>
              {/* <li>
                <AuthorizedLink
                  to={{
                    pathname: REPORT_URL,
                    search: `?type=${CREDIT_AVAILABLE}`,
                  }}
                  requiredPermission={PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT}
                >
                  Credit Available
                </AuthorizedLink>
              </li> */}
              <li>
                <AuthorizedLink
                  to={{
                    pathname: REPORT_URL,
                    search: `?type=${SALES}`,
                  }}
                  requiredPermission={PERMISSION.VIEW_SALE_REPORT}
                >
                  Sales
                </AuthorizedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-container__content">
          {' '}
          <div className="footer-container__content--title">ADMIN</div>
          <div className="footer-container__content--links">
            <ul>
              <li>
                <AuthorizedLink
                  to={USER_PORTAL_URL}
                  requiredPermission={PERMISSION.MANAGE_USERS}
                >
                  Manage Users
                </AuthorizedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-container__content">
          <div className="footer-container__content--title">HELP</div>
          <div className="footer-container__content--links">
            <ul>
              <li>
                <Link to="#">Step by Step</Link>
              </li>
              <li>
                <Link to="#">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="seperator"></div>

      <div className="footer__footer">
        <div className="footer-container__content">
          This site gives access to services offered by Alphaeon Credit.
        </div>
        <div>
          <Link to={PRIVACY_URL}>WEBSITE PRIVACY</Link>
        </div>
      </div>
    </footer>
  )
}
Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps
export default React.memo(Footer)
