import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'

import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'

const reducerKey = 'sales'
const slice = 'root.merchantPortal.sales'

const initialState = {}

const reducer = handleActions(
  {
    [actions.setPaymentDetails]: setState('paymentDetails'),
    [actions.setSelectedPlan]: setState('selectedPlan'),
    [actions.clearPlans]: unsetState('plans'),

    [apiActions.salesRequest]: setOnSuccess('processedSale'),
    [apiActions.fetchPlansByLocationId]: setOnSuccess('plans'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  paymentDetails: select('paymentDetails'),
  plans: select('plans'),
  processedSale: select('processedSale'),
  selectedPlan: select('selectedPlan'),
}

export { reducer, selectors, reducerKey }
