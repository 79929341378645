import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { SubmitButton, Input, MaskedInput } from 'lp-components'
import { InputTip } from 'components'
import { Link } from 'react-router-dom'
import { MERCHANT_PORTAL_URL, PERMISSION } from 'config'
import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
import PropTypes from 'prop-types'
import logo from 'images/Alphaeon_Credit_Logo.png'
import { AuthorizedButton } from 'merchant-portal-components'

const propTypes = {
  ...formPropTypes,
  state: PropTypes.string,
  voidSale: PropTypes.func.isRequired,
}

const defaultProps = {}

function SignReceiptForm({ handleSubmit, state, voidSale }) {
  return (
    <div className="start-application__form  m-t-50">
      <form noValidate onSubmit={handleSubmit}>
        <div className="print-receipt-container">
          <Field
            name="type"
            className="border-bottom-none"
            component={Input}
            disabled={true}
            placeholder="Type of Photo ID"
            label="Type of Photo ID"
          />

          {state && (
            <Field
              name="state"
              className="border-bottom-none"
              component={Input}
              disabled={true}
              placeholder="ID State"
              label="ID State"
            />
          )}

          <Field
            name="idExpiration"
            className="border-bottom-none"
            label="Expiration Date (Please enter expiration date now or it can be filled out upon printing for signature.)"
            component={InputTip}
            inputType={MaskedInput}
            placeholder="MM/DD/YYYY"
            maskOptions={{
              date: true,
              datePattern: ['m', 'd', 'Y'],
            }}
          />
        </div>
        <div className="signature-wrapper">
          <Field
            className="border-bottom-none"
            name="accountHolder"
            component={Input}
            disabled={true}
            label="Authorized Signature"
          />
          <div className="print-logo">
            <img src={logo} className="logo" alt="Logo" />
          </div>
        </div>

        <div className="start-application__form--actions m-t-30 m-b-50">
          <div className="submit">
            <SubmitButton type="button" className={`button-warn`}>
              PRINT RECEIPT
            </SubmitButton>
          </div>
          <div className="void-button">
            <AuthorizedButton
              requiredPermission={PERMISSION.PROCESS_VOID}
              className="button-warn-outline"
              onClick={() => voidSale()}
            >
              void
            </AuthorizedButton>
          </div>
          <div className="cancel">
            <div>
              <Link to={MERCHANT_PORTAL_URL}>RETURN TO DASHBOARD</Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

SignReceiptForm.propTypes = propTypes

SignReceiptForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'signReceiptForm',
    constraints: {
      idExpiration: {
        length: { is: 10 },
      },
    },
  }),
  formValues('state'),

  connect(mapStateToProps, mapDispatchToProps)
)(SignReceiptForm)
