import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectors } from '../reducer'
import { ApprovedApplicationType, ApplicationInfoType } from 'types'
import { ACCOUNT_DETAIL_URL, START_APPLICATION_URL } from 'config'
import AccountCard from '../account_lookup/components/AccountCard'

const propTypes = {
  applicationInfo: ApplicationInfoType.isRequired,
  approvedCreditApplication: ApprovedApplicationType.isRequired,
}

function AccountExists({ applicationInfo, approvedCreditApplication }) {
  const history = useHistory()

  if (!applicationInfo) {
    history.push(START_APPLICATION_URL)
    return <></>
  }

  const account = { ...approvedCreditApplication }
  const { firstName, lastName } = applicationInfo

  account.name = {
    firstName,
    lastName,
  }

  return (
    <>
      <div
        className="account-lookup m-t-30"
        style={{ margin: '0 auto', marginTop: '30px' }}
      >
        <h1>Existing Account Found</h1>
        <div className="account-summary summary">
          <div className="summary__info m-t-20">
            The patient has an existing account, already created below:
          </div>
        </div>
        <div className="account-lookup-result">
          <AccountCard
            account={account}
            onAccountSelection={() =>
              history.push(
                `${ACCOUNT_DETAIL_URL}${account.alphaeonAccountNumber}`
              )
            }
          />
        </div>
      </div>
    </>
  )
}

AccountExists.propTypes = propTypes

function mapStateToProps(state) {
  return {
    approvedCreditApplication: selectors.approvedCreditApplication(state),
    applicationInfo: selectors.applicationInfo(state),
  }
}
const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountExists
)
