import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Button } from 'lp-components'
import { AccountNumber, AuthorizedBuyers } from 'components'
import { AccountType } from 'types'
import { formatCurrency } from 'utils'

const propTypes = {
  account: AccountType,
  onAccountSelection: PropTypes.func,
}

const defaultProps = {}

function AccountCard({ account, onAccountSelection }) {
  const {
    alphaeonAccountNumber,
    creditLimit,
    name,
    authorizedBuyers,
    //division,
    consumerCreditInstrumentType,
    serviceDate,
  } = account

  return (
    <div className="card">
      <div className="card-content" style={{ textAlign: 'left' }}>
        <div className="main-card-content">
          <p className="card-title">
            {name.firstName} {name.middleInitial} {name.lastName}
          </p>
          <div className="info-container sub-title">
            <div className="card-area">
              {consumerCreditInstrumentType === 'installment'
                ? 'Loan Number'
                : 'Account Number'}
            </div>
            <AccountNumber accountNumberString={alphaeonAccountNumber} />
          </div>

          <div className="card-main">
            <div className="info-container ">
              <p className="card-area">
                {consumerCreditInstrumentType !== 'installment' && (
                  <strong className="m-r-5">Authorized Buyer(s)</strong>
                )}
                <AuthorizedBuyers authBuyers={authorizedBuyers} />
              </p>
            </div>

            <div className="footer">
              <div className="info-container ml-30">
                <p className="card-area">
                  <strong>
                    {consumerCreditInstrumentType === 'installment'
                      ? 'Loan Amount'
                      : 'Credit Limit'}
                  </strong>{' '}
                  {formatCurrency(creditLimit, 2)}
                </p>
              </div>
            </div>
            <div className="footer">
              <div className="info-container">
                <p className="card-area">
                  <strong>Product Type</strong>
                  <span className="m-l-5 capitalize">
                    {consumerCreditInstrumentType === 'installment'
                      ? 'Access Loan'
                      : 'AC Prime'}
                  </span>
                </p>
                {consumerCreditInstrumentType === 'installment' && (
                  <div className="info-container ml-30">
                    <p className="card-area">
                      <strong>Effective Date</strong>{' '}
                      {format(new Date(serviceDate), 'MM/dd/yyyy')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            className="button-warn-outline qualify"
            onClick={onAccountSelection}
          >
            View Account
          </Button>
        </div>
      </div>
    </div>
  )
}

AccountCard.propTypes = exact(propTypes)
AccountCard.defaultProps = defaultProps

export default AccountCard
