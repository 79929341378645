import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Input, MaskedInput, SubmitButton } from 'lp-components'
import { Field, SubmissionError, propTypes as formPropTypes } from 'redux-form'
import {
  formatISODate,
  parseUSDayMonthYearDate,
  removeNonDigits,
  validDateOfBirth,
} from 'utils'
import { InputTip } from 'components'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function PushApplicationForm({ handleSubmit, submitting, reset }) {
  useEffect(() => {
    reset('pushApplicationForm')
  }, [])

  return (
    <div className="start-application__form m-t-50 push-app__form">
      <form noValidate onSubmit={handleSubmit}>
        <Field
          name="firstName"
          component={Input}
          placeholder="First Name"
          label="First Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="lastName"
          component={Input}
          placeholder="Last Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="primaryPhone"
          placeholder="Mobile Number"
          label="Mobile Number"
          component={InputTip}
          inputType={MaskedInput}
          required
          requiredIndicator=" *"
          parse={removeNonDigits}
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 3, 4],
            delimiters: ['(', ') ', '-'],
          }}
        />
        <div className="date-of-birth">
          <Field
            component={InputTip}
            inputType={MaskedInput}
            name="dateOfBirth"
            placeholder="MM/DD/YYYY"
            required
            requiredIndicator=" *"
            maskOptions={{
              date: true,
              datePattern: ['m', 'd', 'Y'],
            }}
          />
        </div>
        <div className="start-application__push_app">
          <SubmitButton
            className={'button-warn-outline'}
            submitting={submitting}
          >
            SEND APPLICATION
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

PushApplicationForm.propTypes = propTypes

PushApplicationForm.defaultProps = defaultProps

function beforeSubmit(formValues) {
  const dateOfBirth = parseUSDayMonthYearDate(formValues.dateOfBirth)

  if (!validDateOfBirth(dateOfBirth)) {
    throw new SubmissionError({
      dateOfBirth:
        'Date of birth year must be after 1900 and applicant must be at least 18 years old',
    })
  }

  return {
    ...formValues,
    dateOfBirth: formatISODate(dateOfBirth),
  }
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'pushApplicationForm',
    constraints: {
      firstName: { presence: true },
      lastName: { presence: true },
      primaryPhone: {
        presence: { message: "^Mobile Number can't be blank" },
        length: { is: 10 },
      },
      dateOfBirth: { presence: true, length: { is: 10 } },
    },
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(PushApplicationForm)
