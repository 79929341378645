import React, { useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
import {
  Input,
  InputLabel,
  RadioGroup,
  CheckboxGroup,
  Checkbox,
  SubmitButton,
} from 'lp-components'
import { Link } from 'react-router-dom'
import { lpForm } from 'lp-form'
import {
  ADMIN_PERMISSION_OPTIONS,
  PERMISSION_OPTIONS,
  DEFAULT_PERMISSIONS_KEYS,
  USER_URL,
  USER_STATUS,
} from 'config'
import PropTypes from 'prop-types'
import { LocationType } from 'types'
import { locationOptionsWithLabels } from 'utils'
import { selectors } from '../../reducer'
import { Select as MultiSelect } from 'components'
import { isEmpty } from 'lodash'

const propTypes = {
  ...formPropTypes,
  allLocations: PropTypes.arrayOf(LocationType).isRequired,
  managerId: PropTypes.number,
}
const defaultProps = {}

function UserForm({
  handleSubmit,
  allLocations,
  managerId,
  submitting,
  change,
}) {
  const defaultPermissions = DEFAULT_PERMISSIONS_KEYS

  const toggleCheckedAll = (hasAllPermissions) => {
    if (hasAllPermissions) {
      change('permissions', defaultPermissions)
    } else {
      change('permissions', [])
    }
  }
  const locationData = useMemo(
    () => locationOptionsWithLabels(allLocations),
    [allLocations]
  )

  if (!locationData) {
    return
  }
  return (
    <div className="forms-container full-application__form">
      <form noValidate onSubmit={handleSubmit}>
        <Field
          name="firstName"
          component={Input}
          placeholder="First Name"
          label="First Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="lastName"
          component={Input}
          placeholder="Last Name"
          required
          requiredIndicator=" *"
        />
        <Field
          name="email"
          component={Input}
          required
          requiredIndicator=" *"
          placeholder="Email Address"
          type="email"
        />
        {managerId && (
          <Field
            name="assignNewPassword"
            component={Checkbox}
            placeholder="Assign New Password"
          />
        )}
        <div className="radio-group">
          <div className="radio-group__share">
            <Field
              name="status"
              label="Active/Inactive:"
              component={RadioGroup}
              placeholder="Active/Inactive:"
              options={USER_STATUS}
            />
          </div>
        </div>
        <Field
          name="assignedLocationIds"
          component={MultiSelect}
          label="Locations"
          required={true}
          options={locationData}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          allowSelectAll={true}
        />

        <div className="input-tips m-t-20 mb-3">
          <p>You can choose multiple locations.</p>
        </div>
        <Field
          name="adminPermissions"
          label="Grant Admin Access"
          component={CheckboxGroup}
          required
          options={ADMIN_PERMISSION_OPTIONS}
        />
        <br></br>
        <InputLabel label="Permissions" required requiredIndicator="*" />
        <Field
          name="selectAllPermissions"
          className="select-all-permissions-checkbox"
          label="Select all permissions"
          component={Checkbox}
          onChange={toggleCheckedAll}
        />
        <Field
          name="permissions"
          label={false}
          component={CheckboxGroup}
          required
          options={PERMISSION_OPTIONS}
        />
        <div className="start-application__form--actions m-t-50 m-b-50">
          <div className="submit">
            <SubmitButton
              className={`button-warn-outline`}
              disabled={submitting}
              submitting={submitting}
            >
              {managerId ? 'SAVE CHANGES' : 'SUBMIT'}
            </SubmitButton>
          </div>
          <div className="cancel">
            <Link to={USER_URL}>CANCEL</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

UserForm.propTypes = propTypes

UserForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allLocations: selectors.allLocations(state),
  }
}

function beforeSubmit(userFormData) {
  const { adminPermissions, permissions: formPermissions } = userFormData
  let permissions = formPermissions

  if (!isEmpty(adminPermissions)) {
    permissions = [...formPermissions, ...adminPermissions]
  }

  return {
    ...userFormData,
    permissions,
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'userForm',
    enableReinitialize: true,
    constraints: {
      firstName: {
        presence: true,
      },
      lastName: {
        presence: true,
      },
      email: { presence: true, email: true },
      permissions: { presence: true },
      assignedLocationIds: { presence: true },
    },
    submitFilters: { reject: ['adminPermissions', 'selectAllPermissions'] },
  }),
  formValues({
    managerId: 'managerId',
  }),
  connect(mapStateToProps)
)(UserForm)
