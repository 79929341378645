import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function ComenityCapitalPrequalificationTerms() {
  return (
    <div className="page-container">
      <p className="paragraph-basic">
        You understand and agree that by clicking on the “Agree and Continue
        Prequalification” button:
      </p>
      <p className="paragraph-basic">
        We offer, in conjunction with our partner businesses, certain products
        and services to patients that may make payment for treatment more
        convenient. Because of our commitment to protecting the privacy of the
        information you provide as a patient, we would like your authorization
        to use the information described below to offer you these products and
        services. Please read the information below carefully before checking
        off the acknowledgment box at the bottom. A dental office team member is
        standing by, available to answer any questions regarding this
        authorization.
      </p>
      <p className="paragraph-basic">
        You are being prequalified for credit by each of the lenders offering
        financing options through this platform (“Lenders”), and have read and
        agree to the disclosures provided with this prequalification.
      </p>
      <p className="paragraph-basic">
        You are providing “written authorization” to Lenders under the Fair
        Credit Reporting Act authorizing each Lender to obtain information from
        your personal credit report or other information from Experian or other
        credit bureaus. You authorize each Lender to obtain such information
        solely to conduct a prequalification for credit.
      </p>
      <p className="paragraph-basic">
        You understand that a prequalification is not a guarantee of an approval
        for credit and a failure to prequalify is not a denial of credit.
      </p>
      <p className="paragraph-basic">
        When you review your financing options, a soft inquiry will be performed
        on your credit report, which won't impact your credit score. When you
        select a financing offer and submit it for review, a hard inquiry will
        be performed, which may impact your credit score and be viewed by third
        parties.
      </p>
      <p className="paragraph-basic">
        This prequalification and any information submitted with this
        prequalification may be shared with and retained by Alphaeon.
      </p>
      <p className="paragraph-basic">
        Prequalification Terms and Conditions: I agree to be prequalified for
        credit by Comenity Bank/Comenity Capital Bank (“Bank”) for an Alphaeon
        Credit Card. I have read and agree to the disclosures provided on or
        with this prequalification. The information I am submitting is accurate.
        I understand that a prequalification is not a guaranty of credit and a
        failure to prequalify is not a denial of credit. Whether or not I am
        prequalified, I may submit a full application for credit at which time a
        full credit report will be obtained. This prequalification and any
        information I submit to the Bank may be shared with and retained by
        Alphaeon. A copy of these terms and conditions and prequalification
        disclosures may be obtained from the associate.
      </p>
      <p className="paragraph-basic">
        To be prequalified you must: Be at least 18 years of age, have a valid
        government-issued photo ID, have a U.S. Social Security Number, and be a
        United States resident with a street, rural route or APO/FPO mailing
        address.
      </p>
    </div>
  )
}

ComenityCapitalPrequalificationTerms.propTypes = exact(propTypes)
ComenityCapitalPrequalificationTerms.defaultProps = defaultProps

export default memo(ComenityCapitalPrequalificationTerms)
