import { combineReducers } from 'redux'
import {
  reducer as creditApplicationReducer,
  reducerKey as creditApplicationReducerKey,
} from './credit_application'
import {
  reducer as applicantsReducer,
  reducerKey as applicantsReducerKey,
} from './applicants'
const reducerKey = 'creditPortal'

const reducer = combineReducers({
  [applicantsReducerKey]: applicantsReducer,
  [creditApplicationReducerKey]: creditApplicationReducer,
})

export { reducer, reducerKey }
