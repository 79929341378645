import * as productionApiActions from './productionApiActions'
import * as testApiActions from './testApiActions'
import { usingTestApi } from 'config'

const actions = usingTestApi() ? testApiActions : productionApiActions

export const getCreditApplicationsByStatus =
  actions.getCreditApplicationsByStatus
export const getCreditApplicationByToken = actions.getCreditApplicationByToken
export const getCreditApplicationByTokenValidate =
  actions.getCreditApplicationByTokenValidate
export const applyAfterPrequalification = actions.applyAfterPrequalification
export const newCreditApplication = actions.newCreditApplication
export const searchForPractices = actions.searchForPractices
export const requestPrequalification = actions.requestPrequalification
export const prequalifyWithNextLender = actions.prequalifyWithNextLender
export const patchApplicant = actions.patchApplicant
export const prequalifyCreditApplication = actions.prequalifyCreditApplication

//Merchant portal api actions
export const searchForAccounts = actions.searchForAccounts
export const newMerchantCreditApplication = actions.newMerchantCreditApplication
export const applyForCredit = actions.applyForCredit
export const getAccountDetails = actions.getAccountDetails
export const fetchAllLocationsForManager = actions.fetchAllLocationsForManager
export const fetchLocationDetails = actions.fetchLocationDetails
export const voidSale = actions.voidSale
export const refundRequest = actions.refundRequest
export const salesRequest = actions.salesRequest
export const fetchPlansByLocationId = actions.fetchPlansByLocationId
export const fetchTransactions = actions.fetchTransactions
export const fetchApplicationActivity = actions.fetchApplicationActivity
export const getApplicationsReport = actions.getApplicationsReport
export const getSalesReport = actions.getSalesReport
export const getReconciliationReport = actions.getReconciliationReport
export const getLocationNotifications = actions.getLocationNotifications

export const getManagers = actions.getManagers
export const newManager = actions.newManager
export const getManagerById = actions.getManagerById
export const updateManager = actions.updateManager

export const adjustLoan = actions.adjustLoan
export const createNewCreditApplication = actions.createNewCreditApplication
export const cancelLoan = actions.cancelLoan
export const refundLoan = actions.refundLoan
export const getPracticeLocations = actions.getPracticeLocations
export const updateLocationPlans = actions.updateLocationPlans
export const signReceipt = actions.signReceipt
export const sendExternalReferencesCommunication =
  actions.sendExternalReferencesCommunication
export const createExternalCreditInstrumentReference =
  actions.createExternalCreditInstrumentReference
export const getSalesReceipt = actions.getSalesReceipt
export const createPushApplication = actions.createPushApplication
export const createCreditApplicationIdentityVerification =
  actions.createCreditApplicationIdentityVerification

export const getCreditApplicationIdentityVerification =
  actions.getCreditApplicationIdentityVerification
export const requestAccountVerification = actions.requestAccountVerification
export const getAccountVerification = actions.getAccountVerification
