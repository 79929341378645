import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { Checkbox, SubmitButton, MaskedInput } from 'lp-components'
import { InputTip } from 'components'
import { toAmount } from 'utils'

import { Field, propTypes as formPropTypes, formValues } from 'redux-form'
import PropTypes from 'prop-types'

const propTypes = {
  correctAmountProvided: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  ...formPropTypes,
}

const defaultProps = {}

function RefundRequestForm({
  handleSubmit,
  correctAmountProvided,
  handleCancel,
  submitting,
}) {
  return (
    <div className="start-application__form  m-t-50">
      <form noValidate onSubmit={handleSubmit}>
        <div className="refund-amount">
          <Field
            component={InputTip}
            inputType={MaskedInput}
            name="amount"
            placeholder="Refund Amount"
            required
            requiredIndicator=" *"
            parse={toAmount}
            maskOptions={{
              prefix: '$',
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
            }}
          />
        </div>

        <div className="">
          <div className="checkbox-container">
            <Field
              name="correctAmountProvided"
              component={Checkbox}
              label={false}
            />
            I confirm that this is the correct account and amount to be
            refunded.
          </div>
        </div>
        <div className="start-application__form--actions m-t-50 m-b-50">
          <div className="submit">
            <SubmitButton
              disabled={!correctAmountProvided || submitting}
              submitting={submitting}
              className={`button-warn ${
                !correctAmountProvided ? `warn-disabled` : ``
              }`}
            >
              SUBMIT REFUND
            </SubmitButton>
          </div>
          <div className="cancel">
            <div role="presentation" onClick={() => handleCancel()}>
              CANCEL
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

RefundRequestForm.propTypes = propTypes

RefundRequestForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  lpForm({
    name: 'refundRequestForm',
    constraints: {
      plan: { presence: true },
      amount: { presence: true },
    },
    submitFilters: { reject: ['correctAmountProvided'] },
  }),
  formValues('correctAmountProvided'),
  connect(mapStateToProps, mapDispatchToProps)
)(RefundRequestForm)
